import {
  DKButton,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKLabel,
  DKTooltipWrapper,
  INPUT_TYPE,
  TOAST_TYPE,
  showAlert,
  showToast,
  DKSpinner,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ic_warning_red from '../../Assets/Icons/ic_warning_red.png';
import {
  API_STATUS,
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  QTY_ROUNDOFF_PRECISION,
  QTY_ROUNDOFF_PRECISION_BACKEND,
  ROW_RACK_BIN_CONSTANT,
  STATUS_TYPE
} from '../../Constants/Constant';
import { useAppSelector } from '../../Redux/Hooks';
import {
  selectBatchTrackingProduct,
  selectBatchTrackingProductsLoadingStatus
} from '../../Redux/Slices/BatchTrackingSlice';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../Utility/Utility';

import { addYears } from 'date-fns';
import '../../App.scss';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import DateFormatService from '../../Services/DateFormat';
import { DynamicPopupWrapper } from '../PopupWrapper';
import WarehouseManagementHelper from '../WarehouseManagement/WarehouseManagementHelper';
import ProductService from '../../Services/Product';
import { getNewColumn } from '../../Components/Accounting/JournalEntry/JEHelper';
import { selectBatchSerialCustomFields } from '../../Redux/Slices/CommonDataSlice';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import { DocumentUOMSchemaDefinition } from '../../Models/SecurityGateEntry';

const rrbColumnRenderer = ({ rowData }: any, value: string, column: string) => {
  if (rowData?.invalidFields?.includes(column)) {
    let errorMessage = `Row with selected ${column} already exist`;
    if (!value) {
      errorMessage = `${column} is required, can not be empty`;
    }
    return (
      <div className="row justify-content-between">
        <DKLabel text={`${value || ''}`} />
        <DKTooltipWrapper content={errorMessage} tooltipClassName="">
          <div className="row">
            <DKIcon
              src={ic_warning_red}
              className="ic-xs ml-s cursor-hand"
              onClick={() => {}}
            />
          </div>
        </DKTooltipWrapper>
      </div>
    );
  }
  return value || '';
};

const BatchTrackingAssignment: React.FC<any> = (props) => {
  const { t, i18n } = useTranslation();
  const [item] = useState(props.itemDetails);

  const [pendingQuantity] = useState(
    props.itemDetails.documentUOMSchemaDefinition
      ? Utility.getUomQuantity(
          item.quantityRequired || Utility.pendingToBeReceivedQuantity(item),
          props.itemDetails.documentUOMSchemaDefinition,
          QTY_ROUNDOFF_PRECISION_BACKEND
        )
      : item.quantityRequired || Utility.pendingToBeReceivedQuantity(item)
  );
  const [totalAllocatedItem, setTotalAllocatedItem] = useState(0);
  const productInventoryWarehouseData = useAppSelector(
    selectBatchTrackingProduct
  );
  const [productInventoryWarehouse, setProductInventoryWarehouse] = useState(
    productInventoryWarehouseData
  );
  const [availableBatchData, setAvailableBatchData] = useState<any[]>([]);
  const [availableRowData, setAvailableRowData] = useState<any[]>([]);
  const [availableRackData, setAvailableRackData] = useState<any[]>([]);
  const [availableBinData, setAvailableBinData] = useState<any[]>([]);

  const batchSerialCFfromStore = useAppSelector(selectBatchSerialCustomFields);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const productInventoryDataLoading = useAppSelector(
    selectBatchTrackingProductsLoadingStatus
  );
  const [gridData, setGridData] = useState<any>([
    {
      warehouseCode: '',
      serialBatchNumber: '',
      manufacturingDate: '',
      expiryDate: '',
      batchSize: 0,
      batchSizeFulfilled: 0,
      allowToDelete: true,
      invalidFields: ['warehouseCode']
    }
  ]);
  const [requiredQuantity] = useState<any>(item.requiredQuantity);

  const getGridColumnConfig = () => {
    let qtyName = 'Quantity Used';
    if (props.stockModule === MODULES_NAME.STOCK_ISSUE) {
      qtyName = 'Issue Qty';
    } else if (props.action && props.action !== null) {
      if (props.action === 'PASSED_QTY') {
        qtyName = 'Passed Qty';
      } else if (props.action === 'REJECTED_QTY') {
        qtyName = 'Rejected Qty';
      }
    }

    let columns = [
      {
        key: 'warehouseCode',
        name: 'Warehouse',
        type: INPUT_TYPE.DROPDOWN,
        textAlign: 'left',
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: ({ rowData }: any) =>
          rrbColumnRenderer(
            { rowData },
            rowData?.warehouseCode?.name,
            'warehouseCode'
          ),
        formatter: (obj: any) => {
          return obj.value.name;
        },
        dropdownConfig: {
          className: '',
          style: {},
          allowSearch: false,
          data: [],
          renderer: (index: any, obj: any) => {
            return <DKLabel text={obj.name} />;
          },
          onSelect: (index: any, value: any) => {}
        }
      },
      {
        key: 'serialBatchNumber',
        name: 'Batch Number',
        type: INPUT_TYPE.DROPDOWN,
        textAlign: 'left',
        width: 130,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: (obj: any) => {
          return obj.value.serialBatchNumber;
        },
        renderer: ({ rowData }: any) =>
          rrbColumnRenderer(
            { rowData },
            `${rowData.serialBatchNumber?.serialBatchNumber || ''}`,
            'serialBatchNumber'
          ),
        dropdownConfig: {
          className: '',
          style: {},
          allowSearch: false,
          data: [],
          renderer: (index: any, obj: any) => {
            return <DKLabel text={obj.serialBatchNumber} />;
          },
          onSelect: (index: any, value: any) => {}
        }
      },
      {
        key: 'row',
        name: 'Select Row',
        type: INPUT_TYPE.DROPDOWN,
        textAlign: 'left',
        width: 120,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: ({ rowData }: any) =>
          rrbColumnRenderer({ rowData }, rowData?.row?.rowName, 'row'),
        formatter: (obj: any) => {
          return obj?.value ? obj?.value?.rowName : '';
        },
        dropdownConfig: {
          data: [],
          renderer: (index: any, value: any) => {
            return value?.rowName ?? '';
          },
          onSelect: (index: any, value: any) => {}
        }
      },
      {
        key: 'rack',
        name: 'Select Rack',
        type: INPUT_TYPE.DROPDOWN,
        textAlign: 'left',
        width: 120,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: ({ rowData }: any) =>
          rrbColumnRenderer({ rowData }, rowData?.rack?.rackName, 'rack'),
        formatter: (obj: any) => {
          return obj?.value ? obj?.value?.rackName : '';
        },
        dropdownConfig: {
          data: [],
          renderer: (index: any, value: any) => {
            return value?.rackName ?? '';
          },
          onSelect: (index: any, value: any) => {}
        }
      },
      {
        key: 'bin',
        name: 'Select Bin',
        type: INPUT_TYPE.DROPDOWN,
        textAlign: 'left',
        width: 120,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: ({ rowData }: any) =>
          rrbColumnRenderer({ rowData }, rowData?.bin?.binName, 'bin'),
        dropdownConfig: {
          data: [],
          renderer: (index: any, value: any) => {
            return value?.binName ?? '';
          },
          onSelect: (index: any, value: any) => {}
        }
      },
      {
        key: 'destinationWarehouseName',
        name: 'Destination Warehouse',
        type: INPUT_TYPE.TEXT,
        width: 150,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        key: 'manufacturingDate',
        name: 'Manufactured Date',
        textAlign: 'center',
        type: INPUT_TYPE.DATE,
        width: 150,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        key: 'expiryDate',
        name: 'Expiry Date',
        type: INPUT_TYPE.DATE,
        width: 120,
        textAlign: 'center',
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        key: 'batchSize',
        name: 'Available Qty',
        type: INPUT_TYPE.NUMBER,
        textAlign: 'right',
        width: 120,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        formatter: (obj: any = 0) => {
          return `${Utility.roundingOff(
            Number(obj.value),
            QTY_ROUNDOFF_PRECISION
          )}`;
        }
      },
      {
        key: 'batchSizeFulfilled',
        name: qtyName,
        type: INPUT_TYPE.NUMBER,
        textAlign: 'right',
        width: 120,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: (obj: any) => {
          if (
            obj.rowData.invalidFields &&
            obj.rowData.invalidFields.length > 0 &&
            obj.rowData.invalidFields.includes('batchSizeFulfilled') &&
            obj.rowData.serialBatchNumber !== ''
          ) {
            let errorMessage = `Quantity should be less than or equal to available quantity (${obj.rowData.batchSize})`;
            if (
              Number(obj.rowData.batchSizeFulfilled) <
              Number(obj.rowData?.reservedQuantity)
            ) {
              errorMessage = `Quantity should be greater than or equal to previously allocated quantity`;
            }
            return (
              <div className="row justify-content-between">
                <DKTooltipWrapper content={errorMessage} tooltipClassName="">
                  <div className="row">
                    <DKIcon
                      src={ic_warning_red}
                      className="ic-xs  ml-s cursor-hand"
                      onClick={() => {}}
                    />
                  </div>
                </DKTooltipWrapper>

                <DKLabel
                  text={`${Utility.roundingOff(
                    Number(obj.value),
                    QTY_ROUNDOFF_PRECISION
                  )}`}
                />
              </div>
            );
          } else {
            return (
              <div className="row justify-content-between">
                <div></div>
                <DKLabel
                  text={`${Utility.roundingOff(
                    Number(obj.value),
                    QTY_ROUNDOFF_PRECISION
                  )}`}
                />
              </div>
            );
          }
        },
        formatter: (obj: any = 0) => {
          return `${Utility.roundingOff(
            Number(obj.value),
            QTY_ROUNDOFF_PRECISION
          )}`;
        }
      }
      // {
      //   id: 'action',
      //   key: 'action',
      //   name: '',
      //   type: INPUT_TYPE.BUTTON,
      //   width: 50,
      //   options: []
      // }
    ];
    if (
      props?.documentType === DOC_TYPE.FULFILLMENT &&
      props?.itemDetails?.isLocalizedUomQty &&
      Utility.isNotEmpty(props?.itemDetails?.documentUOMSchemaDefinition)
    ) {
      columns.push({
        key: 'uom',
        name: 'UOM',
        type: INPUT_TYPE.TEXT,
        textAlign: 'left',
        width: 120,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        formatter: uomFormatter
      });
      columns.push({
        key: 'localizedBatchSize',
        name: 'Base UOM Quantity Assigned',
        type: INPUT_TYPE.NUMBER,
        textAlign: 'right',
        width: 190,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: (obj: any) => {
          if (
            obj.rowData.invalidFields &&
            obj.rowData.invalidFields.length > 0 &&
            obj.rowData.invalidFields.includes('localizedBatchSize') &&
            obj.rowData.serialBatchNumber !== ''
          ) {
            return (
              <div className="row justify-content-between">
                <DKTooltipWrapper
                  content={
                    'Quantity should be less than or equal to required quantity (' +
                    pendingQuantity +
                    ')'
                  }
                  tooltipClassName=""
                >
                  <div className="row">
                    <DKIcon
                      src={ic_warning_red}
                      className="ic-xs  ml-s cursor-hand"
                      onClick={() => {}}
                    />
                  </div>
                </DKTooltipWrapper>

                <DKLabel
                  text={`${Utility.roundingOff(
                    Number(obj.value),
                    QTY_ROUNDOFF_PRECISION
                  )}`}
                />
              </div>
            );
          } else {
            return (
              <div className="row justify-content-between">
                <div></div>
                <DKLabel
                  text={`${Utility.roundingOff(
                    Number(obj.value),
                    QTY_ROUNDOFF_PRECISION
                  )}`}
                />
              </div>
            );
          }
        },
        formatter: (obj: any = 0) => {
          return `${Utility.roundingOff(
            Number(obj.value),
            QTY_ROUNDOFF_PRECISION
          )}`;
        }
      });
    }

    let updatedColumns = getUpdatedColumnConfigs(columns);

    if (props?.showOnlyQAWarehouse) {
      return updatedColumns;
    } else {
      return updatedColumns?.filter?.((col: any) => {
        return col.key !== 'destinationWarehouseName';
      });
    }
  };
  const uomFormatter = (obj: any) => {
    const value = props?.itemDetails?.documentUOMSchemaDefinition;
    if (
      !Utility.isEmpty(value) &&
      obj.rowData.batchSize !== 0 &&
      obj.rowData.batchSizeFulfilled !== 0
    ) {
      return `${
        value.name
      } <br><div style="font-size: 10px; color:gray; margin-top: 3px;">${
        obj.rowData?.localizedBatchSize !== undefined &&
        obj.rowData?.localizedBatchSize !== null &&
        obj.rowData?.localizedBatchSize !== 0
          ? `${Utility.roundOff(obj.rowData?.localizedBatchSize)} ${
              DocumentConfigUtility.getBaseUomName(value).name
            }`
          : value.sourceConversionFactor
          ? `${Utility.roundOff(
              (value.sourceConversionFactor / value.sinkConversionFactor) *
                obj.rowData.batchSize
            )} ${DocumentConfigUtility.getBaseUomName(value).name}`
          : ''
      }</div>`;
    } else {
      return value.name;
    }
  };

  const getUpdatedColumnConfigs = (columns: any) => {
    let rrbEnabled = tenantInfo?.additionalSettings?.ROW_RACK_BIN?.filter(
      (item: any) => item?.enable
    );

    const enabledRRBNames = rrbEnabled
      ?.filter((rrb: any) => rrb.enable)
      ?.map((itemRRB: any) => {
        return itemRRB?.name;
      });

    const updatedColumns: any = [];
    columns?.forEach((col: any) => {
      if (
        col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.ROW ||
        col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.RACK ||
        col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.BIN
      ) {
        //check and push ROW RACK BIN here
        if (enabledRRBNames?.includes(col.key.toUpperCase())) {
          const foundRRB = rrbEnabled?.find(
            (rrb: any) => rrb.name === col.key.toUpperCase()
          );
          col = {
            ...col,
            name: `Select ${foundRRB?.label ?? ''}`
          };
          updatedColumns.push(col);
        }
      } else {
        updatedColumns.push(col);
      }
    });

    return updatedColumns;
  };

  const [gridColumnConfig, setGridColumnConfig] = useState(
    getGridColumnConfig()
  );

  const [productDetails, setProductDetails] = useState<any>();

  const [isAutoAllocateButtonVisible, setIsAutoAllocateButtonVisible] =
    useState<boolean>(props?.showAutoAllocateButton ?? true);

  const fetchProductDetails = () => {
    ProductService.getProductsByProductIds([props.itemDetails?.productCode])
      .then((res: any) => {
        setProductDetails(res?.[0]);
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    updateConfig();
    fetchProductDetails();
    return () => {
      setProductInventoryWarehouse([]);
      setAvailableBatchData([]);
      setAvailableRowData([]);
      setAvailableRackData([]);
      setAvailableBinData([]);
    };
  }, []);

  useEffect(() => {
    if (productInventoryDataLoading === API_STATUS.LOADING) {
      showLoader();
    } else {
      removeLoader();
    }
  }, [productInventoryDataLoading]);

  useEffect(() => {
    updateConfig();
  }, [
    availableBatchData,
    availableBinData,
    availableRackData,
    availableRowData,
    batchSerialCFfromStore
  ]);

  useEffect(() => {
    if (
      props &&
      props.isFromStockIssue &&
      !Utility.isEmpty(props.targetWarehouse)
    ) {
      if (
        !Utility.isEmpty(productInventoryWarehouseData) &&
        productInventoryWarehouseData.length > 0
      ) {
        // let warehouseData = productInventoryWarehouseData.filter(
        //   (warehouse: any) => warehouse.code !== props.targetWarehouse
        // );
        let warehouseData = productInventoryWarehouse.filter((item: any) => {
          return WarehouseManagementHelper.isRRBEnabledForWarehouse(item)
            ? item
            : item.code !== props.targetWarehouse;
        });
        setProductInventoryWarehouse(warehouseData);
      }
    } else {
      setProductInventoryWarehouse(productInventoryWarehouseData);
    }
  }, [productInventoryWarehouseData]);

  const filterAlreadySelectedBatches = () => {
    let copyProductInventoryWarehouse = [...productInventoryWarehouseData];
    copyProductInventoryWarehouse = copyProductInventoryWarehouse?.map(
      (warehouseObj: any) => {
        let currentWarehouse = { ...warehouseObj };
        let batchAdvancedTrackingData = Utility.isNotEmpty(
          currentWarehouse?.advancedTrackingMeta
        )
          ? [...currentWarehouse?.advancedTrackingMeta]
          : [];
        batchAdvancedTrackingData = batchAdvancedTrackingData?.map(
          (advTrackObj: any) => {
            let copyAdvObj = { ...advTrackObj };
            let findInAlloted = props?.allotedAdvancedTrackingData?.findIndex(
              (item: any) =>
                item?.productCode === copyAdvObj?.productVariantCode &&
                item?.serialBatchNumber === copyAdvObj?.serialBatchNumber &&
                item?.warehouseCode === copyAdvObj?.warehouseCode &&
                item?.rowCode === copyAdvObj?.rowCode &&
                item?.rackCode === copyAdvObj?.rackCode &&
                item?.binCode === copyAdvObj?.binCode
            );
            if (findInAlloted >= 0) {
              copyAdvObj.batchSize =
                copyAdvObj.batchSize -
                props?.allotedAdvancedTrackingData[findInAlloted]?.qtyToFulfil;
            }
            return copyAdvObj;
          }
        );
        currentWarehouse.advancedTrackingMeta = batchAdvancedTrackingData;
        return currentWarehouse;
      }
    );
    setProductInventoryWarehouse(copyProductInventoryWarehouse);
  };

  useEffect(() => {
    filterAlreadySelectedBatches();
  }, [props.allotedAdvancedTrackingData]);

  useEffect(() => {
    if (
      Utility.isEmpty(productInventoryWarehouseData) ||
      Utility.isEmpty(productDetails)
    ) {
      return;
    }
    let initialGridData: any = [];
    let serialData: any[] = [];
    let updatedData: any[] = [];
    let result: any[] = [];

    let productInventoryWarehouseDataLocal: any[] = [];
    if (props.taggedWarehouse) {
      productInventoryWarehouseDataLocal = productInventoryWarehouse.filter(
        (wh: any) => wh.code === props.taggedWarehouse?.code
      );
      if (
        Utility.isBinAllocationMandatory() &&
        Utility.isNotEmpty(productInventoryWarehouseDataLocal)
      ) {
        let filteredAdvancedTrackData =
          productInventoryWarehouseDataLocal?.[0]?.advancedTrackingMeta?.filter(
            (ele: any) =>
              ele.rowCode == props.taggedWarehouse?.rowCode &&
              ele.rackCode == props.taggedWarehouse?.rackCode &&
              ele.binCode == props.taggedWarehouse?.binCode
          );
        const taggedRRBDetails =
          productInventoryWarehouseDataLocal?.[0]?.rowRackBinProductAvailableQuantityDtos?.filter(
            (RRBItem: any) =>
              RRBItem.rowCode == props.taggedWarehouse?.rowCode &&
              RRBItem.rackCode == props.taggedWarehouse?.rackCode &&
              RRBItem.binCode == props.taggedWarehouse?.binCode
          );

        productInventoryWarehouseDataLocal = [
          {
            ...productInventoryWarehouseDataLocal[0],
            rowRackBinProductAvailableQuantityDtos: taggedRRBDetails,
            advancedTrackingMeta: filteredAdvancedTrackData ?? []
          }
        ];
      }
    } else {
      productInventoryWarehouseDataLocal = productInventoryWarehouse;
    }

    productInventoryWarehouseDataLocal?.forEach((item: any) => {
      item?.advancedTrackingMeta?.forEach((item1: any) => {
        result.push({
          ...item1,
          warehouseName: item?.name
        });
        return {
          ...item1,
          warehouseName: item?.name
        };
      });
    });
    if (
      props?.isMrpFlow ||
      Utility.isEmpty(props?.itemDetails.reservedQuantitiesData)
    ) {
      serialData = result?.filter(
        (serial: any) =>
          serial.batchSizeFulfilled <= serial.batchSize &&
          serial.reservedQuantity <= serial.batchSize
      );
    } else {
      serialData = result?.filter(
        (serial: any) => serial.batchSizeFulfilled <= serial.batchSize
      );
    }
    let advancedTrackingUpdatedData = props?.isMrpFlow
      ? props.itemDetails.advancedTrackingFulfilmentData
      : Utility.isEmpty(props.itemDetails.advancedTrackingFulfilmentData) &&
        !Utility.isEmpty(props?.itemDetails.reservedQuantitiesData)
      ? props?.itemDetails.reservedQuantitiesData
      : props.itemDetails.advancedTrackingFulfilmentData;

    if (advancedTrackingUpdatedData?.length > 0 && serialData.length > 0) {
      if (props?.isMrpFlow && props?.filterBatchData) {
        let selectedBatches =
          props?.filteredWarehouseInventoryData?.warehouseInventoryData?.reduce(
            (prev: any[], current: any) => [
              ...prev,
              ...current?.advancedTrackingData?.map((item: any) => {
                return {
                  ...item,
                  warehouseCode: current?.warehouseCode
                };
              })
            ],
            []
          );
        let tempArray: any = [];
        selectedBatches.forEach((selectedBatchItem: any) => {
          let selectedBatchInMainData = serialData?.find(
            (currentBatchItem: any) =>
              currentBatchItem?.warehouseCode ===
                selectedBatchItem?.warehouseCode &&
              currentBatchItem?.serialBatchNumber ===
                selectedBatchItem?.serialBatchNumber
          );
          if (!Utility.isEmpty(selectedBatchInMainData)) {
            tempArray.push({
              ...selectedBatchInMainData,
              batchSize: selectedBatchItem?.qtyToFulfil,
              reservedQuantity: 0
            });
          }
        });
        serialData = tempArray;
      }
      const { existingWarehouseInventoryData } = props;
      let allExistingAdvancedTrackingData: any[] = [];
      if (
        props?.isMrpFlow &&
        !Utility.isEmptyObject(existingWarehouseInventoryData)
      ) {
        allExistingAdvancedTrackingData =
          existingWarehouseInventoryData?.flatMap((item: any) => {
            const advancedTrackingData = item?.advancedTrackingData.map(
              (subItem: any) => ({
                ...subItem,
                ...item,
                warehouseName: item.warehouseName,
                warehouseCode: item.warehouseCode
              })
            );
            return advancedTrackingData;
          }) || [];
      }

      advancedTrackingUpdatedData.forEach((advTrackingItem: any) => {
        advTrackingItem = props?.isMrpFlow
          ? advTrackingItem
          : Utility.isEmpty(props.itemDetails.advancedTrackingFulfilmentData) &&
            !Utility.isEmpty(props?.itemDetails.reservedQuantitiesData)
          ? advTrackingItem?.advancedTrackingMetaDtos?.[0] || advTrackingItem
          : advTrackingItem;

        serialData.forEach((serialDataItem: any) => {
          let productInventoryWarehouseData: any[] = [];
          if (props.taggedWarehouse) {
            productInventoryWarehouseData = productInventoryWarehouse.filter(
              (wh: any) => wh.code === props.taggedWarehouse?.code
            );
            if (
              Utility.isBinAllocationMandatory() &&
              Utility.isNotEmpty(productInventoryWarehouseData)
            ) {
              let filteredAdvancedTrackData =
                productInventoryWarehouseData?.[0]?.advancedTrackingMeta?.filter(
                  (ele: any) =>
                    ele.rowCode == props.taggedWarehouse?.rowCode &&
                    ele.rackCode == props.taggedWarehouse?.rackCode &&
                    ele.binCode == props.taggedWarehouse?.binCode
                );

              const taggedRRBDetails =
                productInventoryWarehouseData?.[0]?.rowRackBinProductAvailableQuantityDtos?.filter(
                  (RRBItem: any) =>
                    RRBItem.rowCode == props.taggedWarehouse?.rowCode &&
                    RRBItem.rackCode == props.taggedWarehouse?.rackCode &&
                    RRBItem.binCode == props.taggedWarehouse?.binCode
                );
              productInventoryWarehouseData = [
                {
                  ...productInventoryWarehouseData[0],
                  rowRackBinProductAvailableQuantityDtos: taggedRRBDetails,
                  advancedTrackingMeta: filteredAdvancedTrackData ?? []
                }
              ];
            }
          } else {
            productInventoryWarehouseData = productInventoryWarehouse;
          }
          const warehouse = productInventoryWarehouseData.find(
            (warehouse: any) => warehouse.code === advTrackingItem.warehouseCode
          );
          const row =
            warehouse?.warehouseRowInfos?.find(
              (row: any) => row.code === advTrackingItem?.rowCode
            ) || null;
          const rack =
            warehouse?.warehouseRackInfos?.find(
              (row: any) => row.code === advTrackingItem?.rackCode
            ) || null;
          const bin =
            warehouse?.warehouseBinInfos?.find(
              (row: any) => row.code === advTrackingItem?.binCode
            ) || null;
          if (
            advTrackingItem.serialBatchNumber ===
              serialDataItem.serialBatchNumber &&
            advTrackingItem.warehouseCode === serialDataItem.warehouseCode &&
            advTrackingItem.rowCode == serialDataItem.rowCode &&
            advTrackingItem.rackCode == serialDataItem.rackCode &&
            advTrackingItem.binCode == serialDataItem.binCode
          ) {
            let item = {
              ...advTrackingItem,
              row: row
                ? {
                    ...row,
                    rowName: row?.name || null,
                    rowCode: row?.code || null
                  }
                : null,
              rack: rack
                ? {
                    ...rack,
                    rackName: rack?.name || null,
                    rackCode: rack?.code || null
                  }
                : null,
              bin: bin
                ? {
                    ...bin,
                    binName: bin?.name || null,
                    binCode: bin?.code || null
                  }
                : null,
              destinationWarehouseName:
                advTrackingItem?.destinationWarehouseName,
              destinationWarehouseCode:
                advTrackingItem?.destinationWarehouseCode,
              warehouseCode: warehouse,
              serialBatchNumber: serialData.find(
                (serialItem: any) =>
                  serialItem.serialBatchNumber ===
                  advTrackingItem.serialBatchNumber
              ),
              manufacturingDate: DateFormatService.getDateFromStr(
                serialDataItem.manufacturingDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
              expiryDate: DateFormatService.getDateFromStr(
                serialDataItem.expiryDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
              // batchSizeFulfilled: props.itemDetails.documentUOMSchemaDefinition
              //   ? Utility.getUomQuantity(
              //       advTrackingItem.qtyToFulfil,
              //       props.itemDetails.documentUOMSchemaDefinition
              //     ) || 0
              //   : advTrackingItem.qtyToFulfil || 0,
              batchSizeFulfilled: props.itemDetails.documentUOMSchemaDefinition
                ? props.itemDetails?.isLocalizedUomQty
                  ? Utility.getUomQuantity(
                      parseFloat(advTrackingItem.qtyToFulfil || 0),
                      props.itemDetails.unlocalizedDocumentUOMSchemaDefinition,
                      QTY_ROUNDOFF_PRECISION_BACKEND
                    )
                  : Utility.getUomQuantity(
                      parseFloat(advTrackingItem.qtyToFulfil || 0),
                      props.itemDetails.documentUOMSchemaDefinition,
                      QTY_ROUNDOFF_PRECISION_BACKEND
                    )
                : parseFloat(advTrackingItem.qtyToFulfil || 0),
              reservedQuantity: advTrackingItem?.qtyToFulfil,
              allowToDelete: isRowItemDeletable(
                advTrackingItem,
                allExistingAdvancedTrackingData
              ),
              invalidFields: [],
              customField:
                advTrackingItem?.customField ??
                serialData.find(
                  (serialItem: any) =>
                    serialItem.serialBatchNumber ===
                    advTrackingItem.serialBatchNumber
                )?.customField
            };
            item = addProductCustomFieldsToLineItem(item);
            item['batchSize'] = Utility.roundingOff(
              getAvailableQtyConsideringDocReservedQty(warehouse, item),
              QTY_ROUNDOFF_PRECISION
            );
            // if (
            //   Utility.isEmpty(
            //     props.itemDetails.advancedTrackingFulfilmentData
            //   ) &&
            //   !Utility.isEmpty(props?.itemDetails.reservedQuantitiesData) &&
            //   !props?.isMrpFlow
            // ) {
            //   item['batchSize'] =
            //     advTrackingItem.batchSize - advTrackingItem.batchSizeFulfilled;
            // }
            updatedData.push(item);
          }
          //for RRB
          let warehouseData: any[] = [];
          if (props.taggedWarehouse) {
            warehouseData = productInventoryWarehouse.filter(
              (wh: any) => wh.code === props.taggedWarehouse?.code
            );
            if (
              Utility.isBinAllocationMandatory() &&
              Utility.isNotEmpty(warehouseData)
            ) {
              let filteredAdvancedTrackData =
                warehouseData?.[0]?.advancedTrackingMeta?.filter(
                  (ele: any) =>
                    ele.rowCode == props.taggedWarehouse?.rowCode &&
                    ele.rackCode == props.taggedWarehouse?.rackCode &&
                    ele.binCode == props.taggedWarehouse?.binCode
                );
              const taggedRRBDetails =
                warehouseData?.[0]?.rowRackBinProductAvailableQuantityDtos?.filter(
                  (RRBItem: any) =>
                    RRBItem.rowCode == props.taggedWarehouse?.rowCode &&
                    RRBItem.rackCode == props.taggedWarehouse?.rackCode &&
                    RRBItem.binCode == props.taggedWarehouse?.binCode
                );
              warehouseData = [
                {
                  ...warehouseData[0],
                  rowRackBinProductAvailableQuantityDtos: taggedRRBDetails,
                  advancedTrackingMeta: filteredAdvancedTrackData ?? []
                }
              ];
            }
          } else {
            warehouseData = productInventoryWarehouse;
          }
          let inventoryWarehouse = warehouseData?.find(
            (warehouse: any) => warehouse.code === advTrackingItem.warehouseCode
          );
          if (inventoryWarehouse?.advancedTrackingMeta) {
            const rrbDtoForProduct = [
              ...inventoryWarehouse?.advancedTrackingMeta
            ];
            //rows
            const uniqueRows = WarehouseManagementHelper.getUniqueRows(
              rrbDtoForProduct ?? [],
              item?.rackCode,
              item?.binCode
            );
            //racks
            const uniqueRacks = WarehouseManagementHelper.getUniqueRacks(
              rrbDtoForProduct ?? [],
              item?.rowCode,
              item?.binCode
            );
            //bins
            const uniqueBins = WarehouseManagementHelper.getUniqueBins(
              rrbDtoForProduct ?? [],
              item?.rowCode,
              item?.rackCode
            );
            setAvailableRowData(uniqueRows);
            setAvailableRackData(uniqueRacks);
            setAvailableBinData(uniqueBins);
          }
        });
      });
      initialGridData = updatedData;
      let totalItem = updatedData.reduce(
        (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled || 0),
        0
      );
      setTotalAllocatedItem(
        Utility.roundingOff(totalItem, QTY_ROUNDOFF_PRECISION)
      );
      setAvailableBatchData(serialData);
    } else {
      let defaultWarehouse = productInventoryWarehouseDataLocal?.find(
        (warehouse: any) =>
          warehouse?.code === productDetails?.inventory?.warehouseCode
      );
      if (Utility.isEmpty(defaultWarehouse)) {
        defaultWarehouse =
          productInventoryWarehouseDataLocal?.find(
            (warehouse: any) => warehouse?.primary
          ) || productInventoryWarehouseDataLocal?.[0];
      }

      if (props?.isMrpFlow && props?.filterBatchData) {
        // Filter from tagged wh if prop is enabled
        let warehouses =
          props.targetWarehouse && props?.isFromStockIssue
            ? productInventoryWarehouse.filter((item: any) => {
                return WarehouseManagementHelper.isRRBEnabledForWarehouse(item)
                  ? item
                  : item.code !== props.targetWarehouse;
              })
            : productInventoryWarehouse;
        defaultWarehouse =
          warehouses?.filter((item: any) => {
            return props?.filteredWarehouseInventoryData?.warehouseInventoryData?.find(
              (item2: any) => {
                return item2.warehouseCode === item.code;
              }
            );
          })?.[0] ?? [];
      }

      initialGridData = [
        {
          warehouseCode: defaultWarehouse,
          serialBatchNumber: '',
          manufacturingDate: '',
          expiryDate: '',
          batchSize: 0,
          allowToDelete: true,
          batchSizeFulfilled: 0,
          invalidFields: defaultWarehouse ? [] : ['warehouseCode']
        }
      ];
    }

    setGridData((prevState: any[]) => getMappedRows(initialGridData));
  }, [productInventoryWarehouseData, productDetails]);

  const updateConfig = (data: any = null) => {
    let config = [...gridColumnConfig];
    let activeProductCustomFields = [];
    if (!Utility.isEmpty(batchSerialCFfromStore?.content)) {
      let productCf = batchSerialCFfromStore?.content?.filter((item: any) => {
        return item.status === STATUS_TYPE.ACTIVE;
      });
      activeProductCustomFields = productCf.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
    }

    activeProductCustomFields?.forEach((accCF: any) => {
      let newItem: any = getNewColumn(accCF);
      newItem['editable'] = false;
      const newItemInExistingColConfig = config.find(
        (config: any) => config.code === accCF.code
      );
      if (Utility.isEmpty(newItemInExistingColConfig)) {
        config.push({ ...newItem });
      }
    });
    config = config.filter((ele: any) => ele.id !== 'action');
    const newItemInExistingColConfig = config.find(
      (config: any) => config.id === 'action'
    );
    if (Utility.isEmpty(newItemInExistingColConfig)) {
      config.push({
        id: 'action',
        key: 'action',
        name: '',
        type: INPUT_TYPE.BUTTON,
        width: 50,
        options: []
      });
    }

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'warehouseCode':
          conf.dropdownConfig.data = getFilteredWarehouses() ?? [];
          break;
        case 'serialBatchNumber':
          onRowClick(data);
          const updatedAvailableBatchData =
            availableBatchData?.filter((item: any) => {
              const batchSize = Utility.roundingOff(
                item?.batchSize ?? 0,
                QTY_ROUNDOFF_PRECISION_BACKEND
              );
              const batchSizeFulfilled = Utility.roundingOff(
                item?.batchSizeFulfilled ?? 0,
                QTY_ROUNDOFF_PRECISION_BACKEND
              );
              const reservedQuantity = Utility.roundingOff(
                item?.reservedQuantity ?? 0,
                QTY_ROUNDOFF_PRECISION_BACKEND
              );
              const reservedQuantityFulfilled = Utility.roundingOff(
                item?.reservedQuantityFulfilled ?? 0,
                QTY_ROUNDOFF_PRECISION_BACKEND
              );
              const nonZeroBatches =
                batchSize -
                  (batchSizeFulfilled +
                    reservedQuantity -
                    reservedQuantityFulfilled) >
                0;
              return nonZeroBatches;
            }) ?? 0;
          conf.dropdownConfig.data = updatedAvailableBatchData ?? [];
          break;
        case 'row':
          onRowClick(data);
          conf.dropdownConfig.data = availableRowData ?? [];
          break;
        case 'rack':
          onRowClick(data);
          conf.dropdownConfig.data = availableRackData ?? [];
          break;
        case 'bin':
          onRowClick(data);
          conf.dropdownConfig.data = availableBinData ?? [];
          break;
      }
    });

    setGridColumnConfig([...config]);
  };

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.BACKORDER,
        data: item
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  const filterWarehousesForEdit = (item: any, batchData: any) => {
    let flatArrayOfWarehouseObject: any = [];
    props?.filteredWarehouseInventoryData?.warehouseInventoryData?.forEach(
      (wid: any) => {
        wid?.advancedTrackingData?.forEach((adTD: any) => {
          flatArrayOfWarehouseObject.push({
            ...adTD,
            ...wid
          });
        });
      }
    );
    let sameWarehouseFilteredSerialOrBatches =
      flatArrayOfWarehouseObject?.filter((x: any) => {
        return x.warehouseCode === item.warehouseCode.code;
      });
    let filteredAllottedData: any[] = [];

    batchData.forEach((advData: any) => {
      let advDataInFiltered = sameWarehouseFilteredSerialOrBatches?.find(
        (aData: any) => aData?.serialBatchNumber === advData?.serialBatchNumber
      );
      if (!Utility.isEmpty(advDataInFiltered)) {
        filteredAllottedData.push({
          ...advDataInFiltered,
          ...advData,
          batchSize: advDataInFiltered?.qtyToFulfil,
          reservedQuantity: 0,
          batchSizeFulfilled: 0,
          batchSizeReturned: 0
        });
      }
    });
    return filteredAllottedData;
  };
  const filterOutBatchData = (data: any[]) => {
    if (
      !!props?.isMrpFlow ||
      Utility.isEmpty(props?.itemDetails.reservedQuantitiesData)
    ) {
      if (props?.showSelectedDataOnly) {
        let batchData = data?.filter(
          (serial: any) => serial.batchSizeFulfilled < serial.batchSize
        );
        batchData = batchData.map((dataCopy: any) => ({
          ...dataCopy,
          reservedQuantity: 0
        }));
        return batchData;
      } else if (props?.isConsumptionType) {
        let batchData = data?.filter(
          (serial: any) => serial.batchSizeFulfilled <= serial.batchSize
        );
        return batchData;
      } else {
        return data?.filter(
          (serial: any) =>
            serial.batchSizeFulfilled < serial.batchSize &&
            serial.reservedQuantity < serial.batchSize
        );
      }
    }
    return data?.filter(
      (serial: any) => serial.batchSizeFulfilled < serial.batchSize
    );
  };

  const validationForJWOReservation = (
    updatedLineItems: any,
    data: any,
    index: any,
    columnKey: any
  ) => {
    if (
      props.parentDocType !== DOC_TYPE.JWO_RESERVATION ||
      updatedLineItems.length < 2 ||
      index === 0
    ) {
      return true;
    }
    let alreadyPresentData = updatedLineItems[0];
    if (
      (columnKey === 'row' &&
        data.row?.rowCode !== alreadyPresentData.row?.rowCode) ||
      (columnKey === 'rack' &&
        data.rack?.rackCode !== alreadyPresentData.rack?.rackCode) ||
      (columnKey === 'bin' &&
        data.bin?.binCode !== alreadyPresentData.bin?.binCode) ||
      data?.warehouseCode?.code !== alreadyPresentData?.warehouseCode?.code
    ) {
      updatedLineItems.splice(index, 1);
      setGridData([...updatedLineItems]);
      showAlert(
        'Warning',
        'Please select unique Row rack bins to proceed for JWO Reservation'
      );
      return false;
    }
    return true;
  };

  const onRowUpdate = (val: any) => {
    const key = val['columnKey'];
    const rowIndex = val['rowIndex'];
    const item = val['rowData'];

    let tmpData = [...gridData];

    let invalidFields: any[] = tmpData?.[rowIndex]?.['invalidFields'];
    let productInventoryWarehouseDataLocal: any[] = [];
    if (props.taggedWarehouse) {
      productInventoryWarehouseDataLocal = productInventoryWarehouse.filter(
        (wh: any) => wh.code === props.taggedWarehouse?.code
      );
      if (
        Utility.isBinAllocationMandatory() &&
        Utility.isNotEmpty(productInventoryWarehouseDataLocal)
      ) {
        let filteredAdvancedTrackData =
          productInventoryWarehouseDataLocal?.[0]?.advancedTrackingMeta?.filter(
            (ele: any) =>
              ele.rowCode == props.taggedWarehouse?.rowCode &&
              ele.rackCode == props.taggedWarehouse?.rackCode &&
              ele.binCode == props.taggedWarehouse?.binCode
          );
        const taggedRRBDetails =
          productInventoryWarehouseDataLocal?.[0]?.rowRackBinProductAvailableQuantityDtos?.filter(
            (RRBItem: any) =>
              RRBItem.rowCode == props.taggedWarehouse?.rowCode &&
              RRBItem.rackCode == props.taggedWarehouse?.rackCode &&
              RRBItem.binCode == props.taggedWarehouse?.binCode
          );
        productInventoryWarehouseDataLocal = [
          {
            ...productInventoryWarehouseDataLocal[0],
            rowRackBinProductAvailableQuantityDtos: taggedRRBDetails,
            advancedTrackingMeta: filteredAdvancedTrackData ?? []
          }
        ];
      }
    } else {
      productInventoryWarehouseDataLocal = productInventoryWarehouse;
    }

    let rrbWarehouseSelected =
      productInventoryWarehouseDataLocal &&
      productInventoryWarehouseDataLocal.length > 0 &&
      productInventoryWarehouseDataLocal?.find(
        (warehouse: any) => warehouse.code === item.warehouseCode.code
      );

    let productWarehouseData: any[] = [];
    if (props.taggedWarehouse) {
      productWarehouseData = productInventoryWarehouse.filter(
        (wh: any) => wh.code === props.taggedWarehouse?.code
      );
      if (
        Utility.isBinAllocationMandatory() &&
        Utility.isNotEmpty(productWarehouseData)
      ) {
        let filteredAdvancedTrackData =
          productInventoryWarehouseDataLocal?.[0]?.advancedTrackingMeta?.filter(
            (ele: any) =>
              ele.rowCode == props.taggedWarehouse?.rowCode &&
              ele.rackCode == props.taggedWarehouse?.rackCode &&
              ele.binCode == props.taggedWarehouse?.binCode
          );
        const taggedRRBDetails =
          productWarehouseData?.[0]?.rowRackBinProductAvailableQuantityDtos?.filter(
            (RRBItem: any) =>
              RRBItem.rowCode == props.taggedWarehouse?.rowCode &&
              RRBItem.rackCode == props.taggedWarehouse?.rackCode &&
              RRBItem.binCode == props.taggedWarehouse?.binCode
          );
        productWarehouseData = [
          {
            ...productWarehouseData[0],
            rowRackBinProductAvailableQuantityDtos: taggedRRBDetails,
            advancedTrackingMeta: filteredAdvancedTrackData ?? []
          }
        ];
      }
    } else {
      productWarehouseData = productInventoryWarehouse;
    }
    let inventoryWarehouse = productWarehouseData.find(
      (warehouse: any) => warehouse.code === item.warehouseCode.code
    );
    //for RRB
    let rrbDtoForProduct: any = [];
    if (
      inventoryWarehouse?.advancedTrackingMeta &&
      Array.isArray(inventoryWarehouse?.advancedTrackingMeta)
    ) {
      rrbDtoForProduct = [...inventoryWarehouse?.advancedTrackingMeta];
    }
    //rows
    const uniqueRows = WarehouseManagementHelper.getUniqueRows(
      rrbDtoForProduct ?? [],
      item?.rack?.rackCode,
      item?.bin?.binCode
    );
    //racks
    const uniqueRacks = WarehouseManagementHelper.getUniqueRacks(
      rrbDtoForProduct ?? [],
      item?.row?.rowCode,
      item?.bin?.binCode
    );
    //bins
    const uniqueBins = WarehouseManagementHelper.getUniqueBins(
      rrbDtoForProduct ?? [],
      item?.row?.rowCode,
      item?.rack?.rackCode
    );

    // if (!validationForJWOReservation(tmpData, item, rowIndex, key)) {
    //   return;
    // }

    if (key === 'warehouseCode') {
      let result = inventoryWarehouse?.advancedTrackingMeta?.map(
        (item: any) => {
          return { ...item, warehouseName: inventoryWarehouse?.name };
        }
      );
      let batchData: any = filterOutBatchData(result);
      setAvailableRowData(uniqueRows);
      setAvailableRackData(uniqueRacks);
      setAvailableBinData(uniqueBins);
      let filteredAllottedData: any[] = batchData;
      if (!!props?.isMrpFlow && props?.filterBatchData) {
        filteredAllottedData = filterWarehousesForEdit(item, batchData);
      }
      setAvailableBatchData(filteredAllottedData);
      if (tmpData[rowIndex]) {
        tmpData[rowIndex]['row'] = null;
        tmpData[rowIndex]['rack'] = null;
        tmpData[rowIndex]['bin'] = null;
        tmpData[rowIndex]['serialBatchNumber'] = null;
        tmpData[rowIndex]['batchSizeFulfilled'] = 0;
      }
      let totalItem = 0;
      totalItem = gridData.reduce(
        (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled || 0),
        0
      );
      setTotalAllocatedItem(
        Utility.roundingOff(totalItem, QTY_ROUNDOFF_PRECISION)
      );
    } else if (key === 'serialBatchNumber') {
      if (tmpData[rowIndex]) {
        if (typeof item.serialBatchNumber !== 'string') {
          tmpData[rowIndex]['manufacturingDate'] =
            DateFormatService.getDateFromStr(
              item.serialBatchNumber?.manufacturingDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            );
          tmpData[rowIndex]['expiryDate'] = DateFormatService.getDateFromStr(
            item.serialBatchNumber?.expiryDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          );
          tmpData[rowIndex]['serialBatchNumber'] = item.serialBatchNumber;
          tmpData[rowIndex]['destinationWarehouseName'] =
            item?.serialBatchNumber?.destinationWarehouseName ?? '';
          tmpData[rowIndex]['destinationWarehouseCode'] =
            item?.serialBatchNumber?.destinationWarehouseCode ?? '';
          tmpData[rowIndex] = addProductCustomFieldsToLineItem({
            ...tmpData[rowIndex],
            customField: item.serialBatchNumber.customField
          });
        } else {
          tmpData[rowIndex]['serialBatchNumber'] = item.serialBatchNumber;
          tmpData[rowIndex]['batchSize'] = 0;
        }
        tmpData[rowIndex]['editable'] = false;
        tmpData[rowIndex]['row'] = null;
        tmpData[rowIndex]['rack'] = null;
        tmpData[rowIndex]['bin'] = null;
        tmpData[rowIndex]['batchSizeFulfilled'] = 0;
      }
    } else if (key === 'batchSizeFulfilled') {
      let totalItem = 0;
      if (tmpData[rowIndex]) {
        tmpData[rowIndex]['batchSizeFulfilled'] = Utility.roundingOff(
          Number(item?.batchSizeFulfilled) || 0,
          QTY_ROUNDOFF_PRECISION
        );
      }

      // if(tmpData[rowIndex]['batchSizeFulfilled'] > item['batchSize']){
      //   invalidFields.push('')
      // }
      tmpData[rowIndex] = { ...tmpData[rowIndex], invalidFields };
      totalItem = gridData.reduce(
        (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled || 0),
        0
      );
      setTotalAllocatedItem(
        Utility.roundingOff(totalItem, QTY_ROUNDOFF_PRECISION)
      );
      if (Utility.isNotEmpty(props?.itemDetails?.documentUOMSchemaDefinition)) {
        tmpData[rowIndex]['localizedBatchSize'] =
          Utility.roundOffToTenantDecimalScale(
            Utility.getUomWarehouseQuantityWithoutRoundOff(
              tmpData[rowIndex]['batchSizeFulfilled'],
              props?.itemDetails?.documentUOMSchemaDefinition
            )
          );
      } else {
        tmpData[rowIndex]['localizedBatchSize'] = 0;
      }
    } else if (key === 'batchSize') {
      let totalItem = 0;
      setTotalAllocatedItem(
        Utility.roundingOff(totalItem, QTY_ROUNDOFF_PRECISION)
      );
    } else if (key === 'localizedBatchSize') {
      tmpData[rowIndex]['localizedBatchSize'] =
        Utility.roundOffToTenantDecimalScale(item?.localizedBatchSize);
    } else if (key === 'manufacturingDate') {
      if (tmpData[rowIndex]) {
        let isoDate = tmpData[rowIndex]['expiryDate']
          ? addYears(tmpData[rowIndex]['expiryDate'], 1).toISOString()
          : addYears(tmpData[rowIndex]['manufacturingDate'], 1).toISOString();
        tmpData[rowIndex]['manufacturingDate'] =
          item.serialBatchNumber.manufacturingDate;
      }
    } else if (key === 'expiryDate') {
      if (tmpData[rowIndex]) {
        tmpData[rowIndex]['expiryDate'] = item.serialBatchNumber.expiryDate;
      }
    } else if (key === 'row') {
      if (tmpData[rowIndex]) {
        tmpData[rowIndex]['row'] = item?.row;
        tmpData[rowIndex]['rack'] = null;
        tmpData[rowIndex]['bin'] = null;
      }
      setAvailableRackData(uniqueRacks);
      setAvailableBinData(uniqueBins);
    } else if (key === 'rack') {
      if (tmpData[rowIndex]) {
        tmpData[rowIndex]['rack'] = item?.rack;
        tmpData[rowIndex]['bin'] = null;
      }
      setAvailableBinData(uniqueBins);
    } else if (key === 'bin' && tmpData[rowIndex]) {
      tmpData[rowIndex]['bin'] = item?.bin;
    }
    if (tmpData[rowIndex]) {
      tmpData[rowIndex]['batchSize'] = Utility.roundingOff(
        getAvailableQtyConsideringDocReservedQty(rrbWarehouseSelected, item),
        QTY_ROUNDOFF_PRECISION
      );

      tmpData[rowIndex]['invalidFields'] = getInvalidFields({
        row: tmpData[rowIndex],
        index: rowIndex,
        racks: uniqueRacks,
        rows: uniqueRows,
        bins: uniqueBins
      });
    }
    setGridData(getMappedRows(tmpData));
  };

  /**
   *
   * @param param0 - object consisting currentRow, currentIndex, for RRB rows,racks, bins
   * @returns array of string consisting invalid fields for currentRow
   * @description this function validates current row against the BatchTrack Assignment rules and returns a array of invalid strings
   */
  const getInvalidFields = ({
    row,
    index,
    rows,
    racks,
    bins
  }: any): string[] => {
    const invalidFields: string[] = [];

    if (
      Utility.isEmpty(rows) &&
      Utility.isEmpty(racks) &&
      Utility.isEmpty(bins)
    ) {
      if (Utility.isEmptyObject(row.warehouseCode)) {
        invalidFields.push('warehouseCode');
      }
    }
    if (
      row.warehouseCode &&
      !Utility.isEmptyObject(row?.serialBatchNumber) &&
      Utility.isEmpty(rows) &&
      Utility.isEmpty(racks) &&
      Utility.isEmpty(bins)
    ) {
      const indexOfExisting = gridData.findIndex(
        (lineItem: any) =>
          lineItem?.warehouseCode?.code === row?.warehouseCode?.code &&
          lineItem?.serialBatchNumber?.serialBatchNumber ===
            row?.serialBatchNumber?.serialBatchNumber
      );
      if (
        row?.warehouseCode?.code &&
        indexOfExisting !== -1 &&
        indexOfExisting !== index
      ) {
        invalidFields.push('serialBatchNumber');
      }
    } else if (
      row.warehouseCode &&
      Utility.isEmptyObject(row?.serialBatchNumber)
    ) {
      invalidFields.push('serialBatchNumber');
    }
    if (
      row.warehouseCode &&
      !Utility.isEmptyObject(row?.serialBatchNumber) &&
      !Utility.isEmpty(rows) &&
      Utility.isEmpty(racks) &&
      Utility.isEmpty(bins)
    ) {
      const indexOfExisting = gridData.findIndex(
        (lineItem: any) =>
          lineItem?.warehouseCode?.code === row?.warehouseCode?.code &&
          lineItem?.serialBatchNumber?.serialBatchNumber ===
            row?.serialBatchNumber?.serialBatchNumber &&
          lineItem?.row?.rowCode === row?.row?.rowCode
      );
      if (
        row?.warehouseCode?.code &&
        indexOfExisting !== -1 &&
        indexOfExisting !== index
      ) {
        invalidFields.push('row');
      }
    } else {
      if (!Utility.isEmpty(rows) && Utility.isEmpty(row?.row?.rowCode)) {
        invalidFields.push('row');
      }
    }
    if (
      row?.warehouseCode?.code &&
      !Utility.isEmptyObject(row?.serialBatchNumber) &&
      !Utility.isEmpty(rows) &&
      !Utility.isEmpty(racks) &&
      Utility.isEmpty(bins)
    ) {
      const indexOfExisting = gridData.findIndex(
        (lineItem: any) =>
          lineItem?.warehouseCode?.code === row?.warehouseCode?.code &&
          lineItem?.serialBatchNumber?.serialBatchNumber ===
            row?.serialBatchNumber?.serialBatchNumber &&
          lineItem?.row?.rowCode === row?.row?.rowCode &&
          lineItem?.rack?.rackCode === row?.rack?.rackCode
      );
      if (
        row?.warehouseCode?.code &&
        indexOfExisting !== -1 &&
        indexOfExisting !== index
      ) {
        invalidFields.push('rack');
      }
    } else {
      if (!Utility.isEmpty(racks) && Utility.isEmpty(row?.rack?.rackCode)) {
        invalidFields.push('rack');
      }
    }
    if (
      row?.warehouseCode?.code &&
      !Utility.isEmptyObject(row?.serialBatchNumber) &&
      !Utility.isEmpty(rows) &&
      !Utility.isEmpty(racks) &&
      !Utility.isEmpty(bins)
    ) {
      const indexOfExisting = gridData.findIndex(
        (lineItem: any) =>
          lineItem?.warehouseCode?.code === row?.warehouseCode?.code &&
          lineItem?.serialBatchNumber?.serialBatchNumber ===
            row?.serialBatchNumber?.serialBatchNumber &&
          lineItem?.row?.rowCode === row?.row?.rowCode &&
          lineItem?.rack?.rackCode === row?.rack?.rackCode &&
          lineItem?.bin?.binCode === row?.bin?.binCode
      );
      if (
        row?.warehouseCode?.code &&
        indexOfExisting !== -1 &&
        indexOfExisting !== index
      ) {
        invalidFields.push('bin');
      } else {
        if (!Utility.isEmpty(bins) && Utility.isEmpty(row?.bin?.binCode)) {
          invalidFields.push('bin');
        }
      }
    }
    if (
      row?.expiryDate?.setHours?.(0, 0, 0, 0) <
      row?.manufacturingDate?.setHours?.(0, 0, 0, 0)
    ) {
      invalidFields.push('manufacturingDate');
    }

    if (invalidFields.length === 0) {
      if (props.isMrpFlow) {
        const reservedQty = getExistingWHInventoryData(row)?.quantity || 0;
        row['existingReservedQuantity'] = reservedQty;
        const newAllocatedQty = row['batchSizeFulfilled'] - reservedQty;
        if (newAllocatedQty < 0 && newAllocatedQty !== 0) {
          invalidFields.push('batchSizeFulfilled');
        } else {
          if (newAllocatedQty > Number(row?.batchSize || 0)) {
            invalidFields.push('batchSizeFulfilled');
          }
        }
      } else {
        if (Number(row['batchSizeFulfilled']) > Number(row['batchSize'])) {
          invalidFields.push('batchSizeFulfilled');
          if (
            checkToleranceWithAllocatedQuantity(
              Number(row['batchSizeFulfilled']),
              Number(row['batchSize'])
            )
          ) {
            invalidFields.push('isLinkedDocument');
          }
        }
      }
    }
    return invalidFields;
  };

  const addNewItem = () => {
    let rows = [...gridData];
    const nextYearDate = new Date();
    nextYearDate.setDate(nextYearDate.getDay() + 365);
    let newRow = {
      warehouseCode: '',
      serialBatchNumber: '',
      manufacturingDate: new Date(),
      expiryDate: nextYearDate,
      batchSize: '',
      batchSizeFulfilled: 0,
      allowToDelete: true,
      invalidFields: ['warehouseCode']
    };

    rows.push(newRow);
    let totalItem = 0;
    totalItem = rows.reduce(
      (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled),
      0
    );

    setTotalAllocatedItem(
      Utility.roundingOff(totalItem, QTY_ROUNDOFF_PRECISION)
    );

    setGridData(getMappedRows(rows));
    setAvailableRowData([]);
    setAvailableRackData([]);
    setAvailableBinData([]);
  };

  const onDelete = ({ rowIndex }: any) => {
    let rows = [...gridData];
    rows.splice(rowIndex, 1);
    let totalItem = 0;
    totalItem = rows.reduce(
      (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled),
      0
    );
    setTotalAllocatedItem(
      Utility.roundingOff(totalItem, QTY_ROUNDOFF_PRECISION)
    );
    setGridData(getMappedRows(rows));
  };

  const getBatchTrackingGrid = () => {
    return (
      <DKDataGrid
        needShadow={true}
        buttons={
          // props.isMrpFlow &&
          isAutoAllocateButtonVisible &&
          !props?.showOnlyQAWarehouse &&
          Utility.isEmpty(props?.itemDetails.reservedQuantitiesData)
            ? [
                {
                  title: 'Auto Allocate',
                  className: 'text-blue underline',
                  onClick: () => {
                    allocateAutomatically();
                  }
                }
              ]
            : []
        }
        needBorder={true}
        needColumnIcons={false}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        allowColumnAdd={false}
        allowColumnEdit={false}
        allowRowEdit={true}
        onRowUpdate={onRowUpdate}
        currentPage={1}
        totalPageCount={1}
        title={''}
        dateFormat={convertBooksDateFormatToUILibraryFormat(
          tenantInfo.dateFormat
        )}
        columns={[...(gridColumnConfig ?? [])]}
        rows={gridData}
        onRowClick={(data: any) => {
          updateConfig(data);
        }}
      />
    );
  };

  const onRowClick = (data: any) => {
    if (data) {
      const rowIndex = data?.['rowIndex'];
      const item = data?.['rowData'];
      let inventoryWarehouseData: any[] = [];
      if (props.taggedWarehouse) {
        inventoryWarehouseData = productInventoryWarehouse.filter(
          (wh: any) => wh.code === props.taggedWarehouse?.code
        );

        if (
          Utility.isBinAllocationMandatory() &&
          Utility.isNotEmpty(inventoryWarehouseData)
        ) {
          let filteredAdvancedTrackData =
            inventoryWarehouseData?.[0]?.advancedTrackingMeta?.filter(
              (ele: any) =>
                ele.rowCode == props.taggedWarehouse?.rowCode &&
                ele.rackCode == props.taggedWarehouse?.rackCode &&
                ele.binCode == props.taggedWarehouse?.binCode
            );
          const taggedRRBDetails =
            inventoryWarehouseData?.[0]?.rowRackBinProductAvailableQuantityDtos?.filter(
              (RRBItem: any) =>
                RRBItem.rowCode == props.taggedWarehouse?.rowCode &&
                RRBItem.rackCode == props.taggedWarehouse?.rackCode &&
                RRBItem.binCode == props.taggedWarehouse?.binCode
            );
          inventoryWarehouseData = [
            {
              ...inventoryWarehouseData[0],
              rowRackBinProductAvailableQuantityDtos: taggedRRBDetails,
              advancedTrackingMeta: filteredAdvancedTrackData ?? []
            }
          ];
        }
      } else {
        inventoryWarehouseData = productInventoryWarehouse;
      }
      let inventoryWarehouse = inventoryWarehouseData.find(
        (warehouse: any) => warehouse?.code === item.warehouseCode?.code
      );
      //for RRB
      const rrbDtoForProduct =
        inventoryWarehouse &&
        inventoryWarehouse?.advancedTrackingMeta &&
        Array.isArray(inventoryWarehouse?.advancedTrackingMeta)
          ? [...inventoryWarehouse?.advancedTrackingMeta]
          : [];
      //   let rrbWarehouseSelected =
      //     productInventoryWarehouse &&
      //     productInventoryWarehouse.length > 0 &&
      //     productInventoryWarehouse?.find(
      //       (warehouse: any) => warehouse.code === item.warehouseCode.code
      //     );
      //rows
      let uniqueRows = WarehouseManagementHelper.getUniqueRows(
        rrbDtoForProduct ?? [],
        item?.rack?.rackCode,
        item?.bin?.binCode
      );
      //racks
      let uniqueRacks = WarehouseManagementHelper.getUniqueRacks(
        rrbDtoForProduct ?? [],
        item?.row?.rowCode,
        item?.bin?.binCode
      );
      //bins
      let uniqueBins = WarehouseManagementHelper.getUniqueBins(
        rrbDtoForProduct ?? [],
        item?.row?.rowCode,
        item?.rack?.rackCode
      );
      // filter only available data to show
      const batchDetailsRowCode = data?.rowData?.serialBatchNumber?.rowCode;
      const batchDetailsRackCode = data?.rowData?.serialBatchNumber?.rackCode;
      const batchDetailsBinCode = data?.rowData?.serialBatchNumber?.binCode;
      uniqueRows = uniqueRows?.filter(
        (uRow: any) => batchDetailsRowCode == uRow.rowCode
      );
      uniqueRacks = uniqueRacks?.filter(
        (uRack: any) => batchDetailsRackCode == uRack.rackCode
      );
      uniqueBins = uniqueBins?.filter(
        (uBin: any) => batchDetailsBinCode == uBin.binCode
      );
      if (props?.isFromStockIssue) {
        const rowCode = props?.rrbDetails?.targetRowCode;
        const rackCode = props?.rrbDetails?.targetRackCode;
        const binCode = props?.rrbDetails?.targetBinCode;
        if (
          (rowCode && rackCode && binCode) ||
          (rowCode === null && rackCode && binCode) ||
          (rowCode === null && rackCode === null && binCode)
        ) {
          uniqueBins = uniqueBins?.filter(
            (uBin: any) => binCode !== uBin.binCode
          );
        }
        if (
          (rowCode && rackCode && binCode === null) ||
          (rowCode === null && rackCode && binCode === null)
        ) {
          uniqueRacks = uniqueRacks?.filter(
            (uRack: any) => rackCode !== uRack.rackCode
          );
        }
        if (rowCode && rackCode == null && binCode === null) {
          uniqueRows = uniqueRows?.filter(
            (uRow: any) => rowCode !== uRow.rowCode
          );
        }
      }
      if (data?.columnData?.key === 'serialBatchNumber') {
        let result;
        let batchData: any;
        result = inventoryWarehouse?.advancedTrackingMeta?.map((item: any) => {
          return { ...item, warehouseName: inventoryWarehouse?.name };
        });
        if (
          props?.isMrpFlow ||
          Utility.isEmpty(props?.itemDetails.reservedQuantitiesData)
        ) {
          if (props?.showSelectedDataOnly) {
            batchData = result?.filter(
              (serial: any) => serial.batchSizeFulfilled < serial.batchSize
            );
            batchData = batchData.map((dataCopy: any) => ({
              ...dataCopy,
              reservedQuantity: 0
            }));
          } else if (props?.isConsumptionType) {
            batchData = filterOutBatchData(result);
            batchData = filterWarehousesForEdit(item, batchData);
          } else {
            batchData = result?.filter(
              (serial: any) =>
                serial.batchSizeFulfilled < serial.batchSize &&
                serial.reservedQuantity < serial.batchSize
            );
          }
        } else {
          batchData = result?.filter(
            (serial: any) => serial.batchSizeFulfilled < serial.batchSize
          );
        }
        if (props?.isMrpFlow && props?.filterBatchData) {
          let filteredAllottedData: any[] = filterWarehousesForEdit(
            item,
            batchData
          );
          setAvailableBatchData(filteredAllottedData);
        } else {
          setAvailableBatchData(batchData);
        }
        // setColumnConfig(getDataGridColumns());
      }
      setAvailableRackData(uniqueRacks);
      setAvailableRowData(uniqueRows);
      setAvailableBinData(uniqueBins);
    }
  };

  const getFilteredWarehouses = () => {
    let warehouses: any[] = [];
    if (props.taggedWarehouse) {
      warehouses = productInventoryWarehouse.filter(
        (wh: any) => wh.code === props.taggedWarehouse?.code
      );

      if (
        Utility.isBinAllocationMandatory() &&
        Utility.isNotEmpty(warehouses)
      ) {
        let filteredAdvancedTrackData =
          warehouses?.[0]?.advancedTrackingMeta?.filter(
            (ele: any) =>
              ele.rowCode == props.taggedWarehouse?.rowCode &&
              ele.rackCode == props.taggedWarehouse?.rackCode &&
              ele.binCode == props.taggedWarehouse?.binCode
          );
        const taggedRRBDetails =
          warehouses?.[0]?.rowRackBinProductAvailableQuantityDtos?.filter(
            (RRBItem: any) =>
              RRBItem.rowCode == props.taggedWarehouse?.rowCode &&
              RRBItem.rackCode == props.taggedWarehouse?.rackCode &&
              RRBItem.binCode == props.taggedWarehouse?.binCode
          );
        warehouses = [
          {
            ...warehouses[0],
            rowRackBinProductAvailableQuantityDtos: taggedRRBDetails,
            advancedTrackingMeta: filteredAdvancedTrackData ?? []
          }
        ];
      }
    } else {
      // From stock issue we don't want to show same warehouse list
      warehouses =
        props.targetWarehouse && props?.isFromStockIssue
          ? productInventoryWarehouse.filter((item: any) => {
              return WarehouseManagementHelper.isRRBEnabledForWarehouse(item)
                ? item
                : item.code !== props.targetWarehouse;
            })
          : productInventoryWarehouse;
    }
    if (props?.isMrpFlow && props?.filterBatchData) {
      // Filter from tagged wh if prop is enabled
      warehouses =
        warehouses?.filter((item: any) => {
          return props?.filteredWarehouseInventoryData?.warehouseInventoryData?.find(
            (item2: any) => {
              return item2.warehouseCode === item.code;
            }
          );
        }) ?? [];
    }

    return warehouses;
  };

  const getUniqueWHCodes = (arr: any[]) => {
    let unique = [];
    let distinct = [];
    for (let i = 0; i < arr.length; i++) {
      if (!unique[arr[i].warehouseCode]) {
        distinct.push(arr[i].warehouseCode);
        unique[arr[i].warehouseCode] = 1;
      }
    }
    return distinct;
  };

  const filterWHData = (allWHData: any[]) => {
    let selectedBatches =
      props?.filteredWarehouseInventoryData?.warehouseInventoryData?.reduce(
        (prev: any[], current: any) => [
          ...prev,
          ...current?.advancedTrackingData?.map((item: any) => {
            return {
              ...item,
              warehouseCode: current?.warehouseCode
            };
          })
        ],
        []
      );
    let filteredWH: any = [];
    selectedBatches.forEach((selectedBatchItem: any) => {
      let selectedBatchInMainData = allWHData?.find(
        (currentBatchItem: any) =>
          currentBatchItem?.code === selectedBatchItem?.warehouseCode
      );
      selectedBatchInMainData = deepClone(selectedBatchInMainData);
      selectedBatchInMainData.advancedTrackingMeta =
        selectedBatchInMainData.advancedTrackingMeta.filter(
          (trackingData: any) =>
            trackingData.serialBatchNumber ===
            selectedBatchItem?.serialBatchNumber
        );

      if (!Utility.isEmpty(selectedBatchInMainData)) {
        filteredWH.push({
          ...selectedBatchInMainData,
          batchSize: selectedBatchItem?.qtyToFulfil,
          reservedQuantity: 0
        });
      }
    });
    return filteredWH;
  };

  const allocateAutomatically = () => {
    const { existingWarehouseInventoryData = [] } = props;
    let existingWHCodes = getUniqueWHCodes(existingWarehouseInventoryData);
    let copyWarehouseData: any[] = [];
    let warehouses: any[] = [];
    if (props.taggedWarehouse) {
      warehouses = productInventoryWarehouse.filter(
        (wh: any) => wh.code === props.taggedWarehouse?.code
      );
      if (
        Utility.isBinAllocationMandatory() &&
        Utility.isNotEmpty(warehouses)
      ) {
        let filteredAdvancedTrackData =
          warehouses?.[0]?.advancedTrackingMeta?.filter(
            (ele: any) =>
              ele.rowCode == props.taggedWarehouse?.rowCode &&
              ele.rackCode == props.taggedWarehouse?.rackCode &&
              ele.binCode == props.taggedWarehouse?.binCode
          );
        const taggedRRBDetails =
          warehouses?.[0]?.rowRackBinProductAvailableQuantityDtos?.filter(
            (RRBItem: any) =>
              RRBItem.rowCode == props.taggedWarehouse?.rowCode &&
              RRBItem.rackCode == props.taggedWarehouse?.rackCode &&
              RRBItem.binCode == props.taggedWarehouse?.binCode
          );
        warehouses = [
          {
            ...warehouses[0],
            rowRackBinProductAvailableQuantityDtos: taggedRRBDetails,
            advancedTrackingMeta: filteredAdvancedTrackData ?? []
          }
        ];
      }
    } else {
      warehouses = productInventoryWarehouse;
    }
    [...existingWHCodes].forEach((whCode: string) => {
      const whByProduct = [...warehouses].find((wh: any) => wh.code === whCode);
      if (!Utility.isEmpty(whByProduct)) {
        copyWarehouseData.push(whByProduct);
      }
    });
    const remainingWH = [...warehouses].filter(
      (wh: any) => !existingWHCodes.includes(wh.code)
    );
    copyWarehouseData = [...copyWarehouseData, ...remainingWH];

    if (props?.isMrpFlow && props?.filterBatchData) {
      copyWarehouseData = filterWHData(copyWarehouseData);
    }
    const existingTotalAllocatedQty = existingWarehouseInventoryData.reduce(
      (prev: number, next: any) => prev + next.quantity,
      0
    );

    let localRequiredQuantity = requiredQuantity - existingTotalAllocatedQty;

    let productAvailableQuantity = copyWarehouseData.reduce(
      (prev: any[], current: any) => {
        let advTracking =
          current?.advancedTrackingMeta?.map((item: any) => {
            return {
              ...item,
              batchSize:
                props?.isMrpFlow && props?.filterBatchData
                  ? Utility.getUomQuantity(
                      current?.batchSize,
                      props.itemDetails.documentUOMSchemaDefinition,
                      QTY_ROUNDOFF_PRECISION_BACKEND
                    ) || 0
                  : props.itemDetails.documentUOMSchemaDefinition
                  ? props.itemDetails?.isLocalizedUomQty
                    ? Utility.roundingOff(
                        getAvailableQtyConsideringDocReservedQty(current, item),
                        QTY_ROUNDOFF_PRECISION
                      )
                    : Utility.getUomQuantity(
                        Utility.roundingOff(
                          getAvailableQtyConsideringDocReservedQty(
                            current,
                            item
                          ),
                          QTY_ROUNDOFF_PRECISION_BACKEND
                        ),
                        props.itemDetails.documentUOMSchemaDefinition,
                        QTY_ROUNDOFF_PRECISION_BACKEND
                      )
                  : Utility.roundingOff(
                      getAvailableQtyConsideringDocReservedQty(current, item),
                      QTY_ROUNDOFF_PRECISION
                    ),
              warehouseName: current.name
            };
          }) ?? [];

        return [...prev, ...advTracking];
      },
      []
    );

    if (!Utility.isEmpty(existingWarehouseInventoryData)) {
      let existingAllocatedWHBatches: any[] = [];
      existingWarehouseInventoryData?.forEach((existingInventoryData: any) => {
        const existingIndex = productAvailableQuantity?.findIndex(
          (whData: any) =>
            existingInventoryData.warehouseCode == whData.warehouseCode &&
            existingInventoryData.advancedTrackingData?.[0]
              ?.serialBatchNumber == whData.serialBatchNumber &&
            existingInventoryData?.rowCode == whData?.rowCode &&
            existingInventoryData?.rackCode == whData?.rackCode &&
            existingInventoryData?.binCode == whData?.binCode
        );
        if (existingIndex !== -1) {
          existingAllocatedWHBatches.push(
            productAvailableQuantity[existingIndex]
          );
          productAvailableQuantity.splice(existingIndex, 1);
        }
      });
      productAvailableQuantity = [
        ...existingAllocatedWHBatches,
        ...productAvailableQuantity
      ];
    }
    const docSchema = props.itemDetails?.isLocalizedUomQty
      ? props.itemDetails.unlocalizedDocumentUOMSchemaDefinition
      : props.itemDetails.documentUOMSchemaDefinition;
    let allocated = 0;
    let batchTrackingData: any[] = [];
    productAvailableQuantity?.every((element: any) => {
      let availableQtyToAssign = 0;
      if (props?.isMrpFlow && props?.filterBatchData) {
        availableQtyToAssign = element?.batchSize;
      } else {
        /**
         * TODO: Need to fix availableQtyToAssign conditions for MRP flow below
         * @todo
         * Not need to subtract reservedQuantity again in case of mrp flow now,
         * as it's taken care in this method getAvailableQtyConsideringDocReservedQty above.
         * Auto allocate Issue: when available batch quantity gets equal or lesser than reservedQty
         */
        availableQtyToAssign = docSchema
          ? Utility.getUomWarehouseQuantity(
              parseFloat(element.batchSize),
              docSchema
            )
          : parseFloat(element.batchSize) -
            parseFloat(
              props?.isMrpFlow
                ? 0
                : Utility.isEmpty(props?.itemDetails.reservedQuantitiesData)
                ? element.reservedQuantity
                : 0
            );
        availableQtyToAssign = Utility.roundingOff(
          availableQtyToAssign,
          QTY_ROUNDOFF_PRECISION
        );
      }

      //for RRB
      let warehouseData = copyWarehouseData;
      let inventoryWarehouse = warehouseData?.find(
        (warehouse: any) => warehouse.code === element?.warehouseCode
      );
      let uniqueRows: any[] = [];
      let uniqueRacks: any[] = [];
      let uniqueBins: any[] = [];
      if (inventoryWarehouse?.advancedTrackingMeta) {
        const rrbDtoForProduct = [...inventoryWarehouse?.advancedTrackingMeta];
        //rows
        uniqueRows = WarehouseManagementHelper.getUniqueRows(
          rrbDtoForProduct ?? [],
          item?.rackCode,
          item?.binCode
        );
        //racks
        uniqueRacks = WarehouseManagementHelper.getUniqueRacks(
          rrbDtoForProduct ?? [],
          item?.rowCode,
          item?.binCode
        );
        //bins
        uniqueBins = WarehouseManagementHelper.getUniqueBins(
          rrbDtoForProduct ?? [],
          item?.rowCode,
          item?.rackCode
        );
        if (props?.isFromStockIssue) {
          const rowCode = props?.rrbDetails?.targetRowCode;
          const rackCode = props?.rrbDetails?.targetRackCode;
          const binCode = props?.rrbDetails?.targetBinCode;
          if (
            (rowCode && rackCode && binCode) ||
            (rowCode === null && rackCode && binCode) ||
            (rowCode === null && rackCode === null && binCode)
          ) {
            uniqueBins = uniqueBins?.filter(
              (uBin: any) => binCode !== uBin.binCode
            );
          }
          if (
            (rowCode && rackCode && binCode === null) ||
            (rowCode === null && rackCode && binCode === null)
          ) {
            uniqueRacks = uniqueRacks?.filter(
              (uRack: any) => rackCode !== uRack.rackCode
            );
          }
          if (rowCode && rackCode == null && binCode === null) {
            uniqueRows = uniqueRows?.filter(
              (uRow: any) => rowCode !== uRow.rowCode
            );
          }
        }
      }
      const row = uniqueRows?.find((x: any) => x.rowCode === element?.rowCode);
      const rack = uniqueRacks?.find(
        (x: any) => x.rackCode === element?.rackCode
      );
      const bin = uniqueBins?.find((x: any) => x.binCode === element?.binCode);
      const warehouse = copyWarehouseData?.find(
        (wh: any) => wh.code === element?.warehouseCode
      );
      let lineItem: any = {
        acquiredCost: element?.acquiredCost || 0,
        batchSize: element?.batchSize,
        costPerUnit: Utility.roundOffToTenantDecimalScale(
          Number(element?.acquiredCost) / Number(element?.batchSize)
        ),
        qtyToFulfil: 0,
        batchSizeFulfilled: 0,
        serialBatchNumber: element,
        manufacturingDate: DateFormatService.getDateFromStr(
          element.manufacturingDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        expiryDate: DateFormatService.getDateFromStr(
          element.expiryDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        row: row ?? null,
        rowName: row?.rowName ?? null,
        rowCode: element?.rowCode ?? null,
        rack: rack ?? null,
        rackName: rack?.rackName ?? null,
        rackCode: element?.rackCode ?? null,
        bin: bin ?? null,
        binName: bin?.binName ?? null,
        binCode: element?.binCode,
        warehouseCode: warehouse ?? null,
        warehouseName: warehouse?.name,
        allowToDelete: true,
        invalidFields: [],
        customField: element?.customField
      };
      lineItem = addProductCustomFieldsToLineItem(lineItem);

      const gridDataCopy = [...gridData];
      const existingLineIndexInGrid = gridDataCopy.findIndex(
        (data: any) =>
          data.warehouseCode?.code == lineItem.warehouseCode?.code &&
          data.serialBatchNumber?.serialBatchNumber ==
            lineItem.serialBatchNumber?.serialBatchNumber &&
          data.rowCode == lineItem.rowCode &&
          data.rackCode == lineItem.rackCode &&
          data.binCode == lineItem.binCode
      );
      const existingLineInventoryData =
        existingLineIndexInGrid !== -1
          ? getExistingWHInventoryData(gridDataCopy[existingLineIndexInGrid])
          : null;
      if (
        existingLineIndexInGrid !== -1 &&
        !Utility.isEmpty(existingLineInventoryData)
      ) {
        lineItem = { ...gridDataCopy[existingLineIndexInGrid] };
        lineItem.qtyToFulfil = Utility.roundingOff(
          existingLineInventoryData?.quantity,
          QTY_ROUNDOFF_PRECISION_BACKEND
        );
        lineItem.batchSizeFulfilled = docSchema
          ? Utility.getUomWarehouseQuantityWithoutRoundOff(
              lineItem.qtyToFulfil,
              docSchema
            )
          : lineItem.qtyToFulfil;
        allocated += lineItem.qtyToFulfil;
      }

      lineItem.invalidFields = [];

      if (allocated < requiredQuantity) {
        if (requiredQuantity - allocated > 0) {
          if (availableQtyToAssign > 0) {
            if (availableQtyToAssign < requiredQuantity - allocated) {
              if (Utility.isEmpty(existingLineInventoryData)) {
                lineItem.qtyToFulfil = availableQtyToAssign;
                allocated = allocated + lineItem.qtyToFulfil;
              } else {
                let qtyToAllocate =
                  availableQtyToAssign > localRequiredQuantity
                    ? localRequiredQuantity
                    : availableQtyToAssign;
                localRequiredQuantity -= qtyToAllocate;
                lineItem.qtyToFulfil = qtyToAllocate + lineItem.qtyToFulfil;
                allocated += qtyToAllocate;
              }
              lineItem.qtyToFulfil = Utility.roundingOff(
                lineItem.qtyToFulfil,
                QTY_ROUNDOFF_PRECISION_BACKEND
              );
              allocated = Utility.roundingOff(
                allocated,
                QTY_ROUNDOFF_PRECISION
              );
              lineItem.batchSizeFulfilled = lineItem.qtyToFulfil;
              batchTrackingData.push(lineItem);
            } else {
              if (Utility.isEmpty(existingLineInventoryData)) {
                lineItem.qtyToFulfil = Utility.roundingOff(
                  requiredQuantity - allocated + lineItem.qtyToFulfil,
                  QTY_ROUNDOFF_PRECISION_BACKEND
                );
                allocated = allocated + lineItem.qtyToFulfil;
              } else {
                const differenceOfRequired = requiredQuantity - allocated;
                let qtyToAllocate =
                  differenceOfRequired > localRequiredQuantity
                    ? localRequiredQuantity
                    : differenceOfRequired;
                localRequiredQuantity -= qtyToAllocate;
                lineItem.qtyToFulfil = Utility.roundingOff(
                  qtyToAllocate + lineItem.qtyToFulfil,
                  QTY_ROUNDOFF_PRECISION_BACKEND
                );
                allocated += qtyToAllocate;
              }
              lineItem.qtyToFulfil = Utility.roundingOff(
                lineItem.qtyToFulfil,
                QTY_ROUNDOFF_PRECISION_BACKEND
              );
              allocated = Utility.roundingOff(
                allocated,
                QTY_ROUNDOFF_PRECISION
              );
              lineItem.batchSizeFulfilled = lineItem.qtyToFulfil;
              batchTrackingData.push(lineItem);
            }
          } else {
            if (!Utility.isEmpty(existingLineInventoryData)) {
              batchTrackingData.push(lineItem);
            }
          }
          return true;
        }
      } else {
        if (!Utility.isEmpty(existingLineInventoryData)) {
          batchTrackingData.push(lineItem);
        }
        return false;
      }
    });
    if (props?.itemDetails?.isLocalizedUomQty) {
      batchTrackingData = batchTrackingData?.map((ele: any) => {
        return {
          ...ele,
          localizedBatchSize: Utility.roundingOff(
            Utility.getUomWarehouseQuantity(
              ele.qtyToFulfil,
              props?.itemDetails?.documentUOMSchemaDefinition
            ),
            QTY_ROUNDOFF_PRECISION_BACKEND
          )
        };
      });
    }
    setTotalAllocatedItem(
      Utility.roundingOff(allocated, QTY_ROUNDOFF_PRECISION)
    );
    setGridData(getMappedRows(batchTrackingData));
    setTimeout(() => {
      onSave(batchTrackingData, allocated);
    }, 500);
    // props.onBatchSave(batchTrackingData, allocated);
  };

  const onSave = (updatedBatchTrackingData?: any, allocatedItem?: any) => {
    const updatedGridData = updatedBatchTrackingData ?? gridData;
    let hasError: any;
    let buttons = [
      {
        title: 'Cancel',
        className: 'bg-white border-m',
        onClick: () => {}
      },
      {
        title: 'Continue',
        className: 'bg-button border-m text-white',
        onClick: () => {
          if (props.onBatchSave) {
            onBatchSave(updatedBatchTrackingData, allocatedItem);
          }
        }
      }
    ];
    updatedGridData.forEach((item: any, i: any) => {
      if (item && item.invalidFields && item.invalidFields.length > 0) {
        hasError = item.invalidFields;
      }
    });
    const sumofLocalizedUomQty = Utility.roundOffToTenantDecimalScale(
      gridData?.reduce((acc: any, item: any) => {
        return Number(item?.localizedBatchSize ?? 0) + Number(acc ?? 0);
      }, 0) ?? 0
    );
    if (
      hasError &&
      hasError.length > 0 &&
      hasError.includes('batchSizeFulfilled')
    ) {
      let errorMessage =
        'Quantity used cannot be more than the available quantity';
      if (hasError.includes('isLinkedDocument')) {
        const { isInvoiceLinked, isQuotationLinked, isReservedQuantity } =
          Utility.getSellsLinkedDocument(
            props.parentDocumentType,
            props.parentDocumentDetails
          );
        if (isInvoiceLinked || isQuotationLinked) {
          errorMessage = `Quantity used cannot be more than required quantity as the Invoice/${
            Utility.isIndiaOrganisation() ? 'Quotation' : 'Estimates'
          } is already generated for this document.`;
        } else if (isReservedQuantity) {
          errorMessage =
            'Quantity used cannot be more than required quantity in case of reserved quantity.';
        }
      }
      showToast(errorMessage, TOAST_TYPE.FAILURE);
      return false;
    } else if (
      hasError &&
      hasError.length > 0 &&
      hasError.includes('serialBatchNumber')
    ) {
      showAlert('', 'Duplicate batch number is not allowed', buttons);
      return false;
    } else if (totalAllocatedItem > pendingQuantity) {
      showAlert(
        'Limit Exceeded',
        `Quantity allocated cannot be more than the required quantity ( ${requiredQuantity} )`,
        buttons
      );

      return false;
    } else if (
      sumofLocalizedUomQty >
        +props?.itemDetails?.uomLocalizedquantityRequired &&
      props?.documentType === DOC_TYPE.FULFILLMENT &&
      props?.itemDetails?.isLocalizedUomQty &&
      // tenantInfo.additionalSettings?.LOCALIZED_UOM_CONVERSION &&
      Utility.isNotEmpty(props?.itemDetails?.documentUOMSchemaDefinition)
    ) {
      showAlert(
        'Limit Exceeded',
        `Quantity allocated cannot be more than the required quantity ( ${props?.itemDetails?.uomLocalizedquantityRequired} )`,
        buttons
      );

      return false;
    }

    let showExpiryAlert = false;
    updatedGridData.forEach((data: any) => {
      if (
        data.expiryDate.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
      ) {
        showExpiryAlert = true;
      }
    });
    if (showExpiryAlert) {
      showAlert(
        'Batch expired',
        'Please note, the Batch selected belongs to a date which is beyond the Expiry Date. Do you wish to proceed further with allocation',
        buttons
      );
      return;
    }

    if (props.onBatchSave) {
      onBatchSave(updatedGridData, allocatedItem);
    }
  };

  const onBatchSave = (updatedBatchTrackingData?: any, allocatedItem?: any) => {
    const updatedGridData = updatedBatchTrackingData ?? gridData;
    const batchTrackingData = updatedGridData.map((item: any, i: any) => {
      let object = {
        acquiredCost: item?.serialBatchNumber?.acquiredCost || 0,
        qtyToFulfil: props.itemDetails.documentUOMSchemaDefinition
          ? props.itemDetails?.isLocalizedUomQty
            ? Utility.roundingOff(
                Utility.getUomWarehouseQuantityWithoutRoundOff(
                  parseFloat(item.batchSizeFulfilled || 0),
                  props.itemDetails.unlocalizedDocumentUOMSchemaDefinition
                ),
                QTY_ROUNDOFF_PRECISION_BACKEND
              )
            : Utility.roundingOff(
                Utility.getUomWarehouseQuantityWithoutRoundOff(
                  parseFloat(item.batchSizeFulfilled || 0),
                  props.itemDetails.documentUOMSchemaDefinition
                ),
                QTY_ROUNDOFF_PRECISION_BACKEND
              )
          : parseFloat(item.batchSizeFulfilled || 0),
        batchSize: item?.serialBatchNumber?.batchSize,
        costPerUnit: Utility.roundOffToTenantDecimalScale(
          Number(item?.serialBatchNumber?.acquiredCost) /
            Number(item?.serialBatchNumber?.batchSize)
        ),
        serialBatchNumber: item.serialBatchNumber.serialBatchNumber,
        warehouseCode: item?.warehouseCode?.code,
        warehouseName: item?.warehouseCode?.name,
        destinationWarehouseCode: item?.destinationWarehouseCode,
        destinationWarehouseName: item?.destinationWarehouseName,
        manufacturingDate: DateFormatService.getDateStrFromDate(
          item.manufacturingDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        expiryDate: DateFormatService.getDateStrFromDate(
          item.expiryDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        row: item?.row,
        rowCode: item?.row?.rowCode ?? null,
        rowName: item?.row?.rowName ?? null,
        rack: item?.rack,
        rackCode: item?.rack?.rackCode ?? null,
        rackName: item?.rack?.rackName ?? null,
        bin: item?.bin,
        binCode: item?.bin?.binCode ?? null,
        binName: item?.bin?.binName || null,
        customField: getLineItemCFs(item),
        localizedBatchSize: props?.itemDetails?.isLocalizedUomQty
          ? item?.localizedBatchSize ?? 0
          : 0
      };
      return { ...object, advancedTrackingData: [{ ...object }] };
    });

    let updatedLineItems = batchTrackingData.map((item: any) => ({
      ...item, // Spread existing properties of each item
      id: props?.itemDetails?.id, // Add or overwrite the 'id' property
      productCode: props.itemDetails.productCode
    }));

    props.onBatchSave(
      updatedLineItems,
      allocatedItem ? allocatedItem : totalAllocatedItem
    );
  };

  const checkToleranceWithAllocatedQuantity = (
    allocatedItemQuantity: any,
    requiredQuantity: any
  ) => {
    return (
      !Utility.isSellsToleranceSettingsEnabled(
        props.parentDocumentType,
        props.parentDocumentDetails
      ) && allocatedItemQuantity > requiredQuantity
    );
  };

  const getHeader = () => {
    const allRowsValid = gridData?.every(
      (row: any) => (row?.invalidFields?.length || 0) === 0
    );
    const disableSave =
      !allRowsValid ||
      totalAllocatedItem <= 0 ||
      props?.disableBtn ||
      checkToleranceWithAllocatedQuantity(totalAllocatedItem, requiredQuantity);

    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1 rounded-t-md">
        <div className="row pop-header-drag-handle">
          <DKLabel
            // text="Allocate Batch-Tracked Products"
            text={item?.product?.name || item?.product?.itemName}
            className="fw-m fs-l mr-r"
          />
          {productInventoryDataLoading === API_STATUS.LOADING && <DKSpinner />}
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />

          <DKButton
            title={'Save'}
            className={
              !disableSave ? 'bg-button text-white' : 'bg-gray-300 text-white'
            }
            disabled={disableSave}
            onClick={() => {
              onSave();
            }}
          />
        </div>
      </div>
    );
  };

  const getHeaderSection = () => {
    return (
      <div className="row justify-content-between">
        <div className="column width-auto " style={{ maxWidth: 500 }}>
          <DKLabel
            text={`${props?.mrpTitle ? 'Material' : 'Product'} Name`}
            className="fs-r pl-2 font-medium "
          />
          <DKLabel
            text={item?.product?.name || item?.product?.itemName}
            className="fs-r pl-2"
          />
        </div>
        <div className="column width-auto">
          <DKLabel
            text={`${props?.mrpTitle ? 'Material' : 'Product'} Code`}
            className="fs-r pl-2 font-medium "
          />
          <DKLabel
            text={item?.documentSequenceCode || item?.productCode}
            className="fs-r pl-2"
          />
        </div>
        <div className="column width-auto">
          <DKLabel
            text="Required Quantity"
            className="fs-r pl-2 font-medium "
          />
          <div className="row justify-content-end align-items-end gap-1 ">
            <DKLabel
              text={requiredQuantity >= 0 ? requiredQuantity : '-'}
              className=""
            />
            <DKLabel
              style={{
                height: 15
              }}
              text={
                item?.documentUOMSchemaDefinition &&
                props.parentDocType !== DOC_TYPE.JWO_RESERVATION
                  ? item?.documentUOMSchemaDefinition?.name
                  : props.isMrpFlow
                  ? Utility.getUOMForStockUOMId(item?.product?.stockUom)?.name
                  : ''
              }
              className="fs-s fw-m text-gray "
            />
          </div>
        </div>
        <div className="column width-auto">
          <DKLabel
            text="Quantity Allocated"
            className="fs-r pl-2 font-medium "
          />
          <div className="row justify-content-end align-items-end gap-1 ">
            <DKLabel
              text={totalAllocatedItem >= 0 ? totalAllocatedItem : '-'}
              className=""
            />
            <DKLabel
              style={{
                height: 15
              }}
              text={
                item?.documentUOMSchemaDefinition &&
                props.parentDocType !== DOC_TYPE.JWO_RESERVATION
                  ? item?.documentUOMSchemaDefinition?.name
                  : props.isMrpFlow
                  ? Utility.getUOMForStockUOMId(item?.product?.stockUom)?.name
                  : ''
              }
              className="fs-s fw-m text-gray "
            />
          </div>
        </div>
      </div>
    );
  };

  const getRowButtons = (lineItem: any) => {
    let buttons: any[] = [];

    if (lineItem?.allowToDelete) {
      buttons.push({
        icon: DKIcons.ic_delete,
        className: 'ic-xs',
        onClick: onDelete
      });
    }

    return buttons;
  };

  const isRowItemDeletable = (
    row: any,
    allExistingAdvancedTrackingData: any
  ) => {
    let item = null;
    item = allExistingAdvancedTrackingData?.find(
      (existingData: any) =>
        existingData.warehouseCode == row.warehouseCode &&
        existingData.serialBatchNumber == row.serialBatchNumber &&
        existingData.rowCode == row.rowCode &&
        existingData.rackCode == row.rackCode &&
        existingData.binCode == row.binCode
    );
    return Utility.isEmptyObject(item);
  };

  const getExistingWHInventoryData = (row: any) => {
    const { existingWarehouseInventoryData } = props;
    let item = null;
    if (!Utility.isEmptyObject(existingWarehouseInventoryData)) {
      item = existingWarehouseInventoryData?.find(
        (existingData: any) =>
          existingData.warehouseCode == row.warehouseCode?.code &&
          existingData.advancedTrackingData?.[0]?.serialBatchNumber ==
            row.serialBatchNumber?.serialBatchNumber &&
          existingData.rowCode == row.rowCode &&
          existingData.rackCode == row.rackCode &&
          existingData.binCode == row.binCode
      );
    }
    return item;
  };

  const getMappedRows = (rows: any[]) => {
    return rows.map((row: any) => {
      const newRow = {
        ...row,
        rowButtons: getRowButtons(row),
        rowContextMenu: null
      };
      if (props.isMrpFlow) {
        newRow['nonEditableColumns'] = row?.allowToDelete
          ? []
          : ['warehouseCode', 'serialBatchNumber', 'rack', 'bin', 'row'];
      }
      return newRow;
    });
  };

  const getAllocatedQuantity = (selectedWarehouse?: any) => {
    const currentId = props.itemDetails.id;
    const productCode = props.itemDetails.productCode;
    const warehouseCode = selectedWarehouse?.warehouseCode;
    const serialBatchNumber = selectedWarehouse?.serialBatchNumber;

    // Check if it's an edit case
    const isEditCase = props.batchMultipleWarehouseData.some(
      (element: any) => element.id === currentId
    );

    if (isEditCase) {
      // For edit case, find all entries with the same productCode and warehouseCode, excluding the current ID
      const relevantEntries = props.batchMultipleWarehouseData.filter(
        (element: any) =>
          element.productCode === productCode &&
          element.warehouseCode === warehouseCode &&
          element.serialBatchNumber === serialBatchNumber &&
          element.id !== currentId
      );

      // Calculate the total allocated quantity for the selected warehouse and product code
      const totalAllocatedQty = relevantEntries.reduce(
        (total: number, element: any) => total + (element.qtyToFulfil ?? 0),
        0
      );

      // Return available quantity as allocated quantity + previously allocated quantity
      return totalAllocatedQty;
    } else {
      // For non-edit case, just return the allocated quantity
      const totalAllocatedQty = props.batchMultipleWarehouseData
        .filter(
          (element: any) =>
            element.productCode === productCode &&
            element.warehouseCode === warehouseCode &&
            element.serialBatchNumber === serialBatchNumber
        )
        .reduce(
          (total: number, element: any) => total + (element.qtyToFulfil ?? 0),
          0
        );

      return totalAllocatedQty;
    }
  };

  const getAvailableQtyConsideringDocReservedQty = (
    wareHouseDefaultData: any,
    item: any
  ) => {
    const copyOfWareHouseDefaultData = { ...wareHouseDefaultData };
    if (!!props.isMrpFlow && props?.filterBatchData) {
      let batchData: any = filterOutBatchData(
        copyOfWareHouseDefaultData?.advancedTrackingMeta
      );
      copyOfWareHouseDefaultData['advancedTrackingMeta'] =
        filterWarehousesForEdit(item, batchData);
    }
    const { code, advancedTrackingMeta } = copyOfWareHouseDefaultData;
    const { isMrpFlow = false, itemDetails } = props;
    const {
      reservedQuantitiesData = [],
      productCode,
      documentUOMSchemaDefinition = {}
    } = itemDetails;

    item = {
      ...item,
      rowCode: item?.['row']?.rowCode || item?.rowCode,
      binCode: item?.['bin']?.binCode || item?.binCode,
      rackCode: item?.['rack']?.rackCode || item?.rackCode
    };

    const { serialBatchNumber = null, rowCode, rackCode, binCode } = item;
    let advancedTrackingData: any = {};
    advancedTrackingData = advancedTrackingMeta?.find(
      (item: any) =>
        item['warehouseCode'] == code &&
        item['serialBatchNumber'] ==
          (serialBatchNumber?.serialBatchNumber || serialBatchNumber) &&
        item['rowCode'] == rowCode &&
        item['rackCode'] == rackCode &&
        item['binCode'] == binCode
    );

    let {
      batchSize: availableProductQty = 0,
      reservedQuantity: productReservedQuantity = 0,
      reservedQuantityFulfilled = 0,
      batchSizeFulfilled = 0
    } = advancedTrackingData || {};

    if (Utility.isNotEmpty(documentUOMSchemaDefinition)) {
      if (!props.batchMultipleWarehouseData) {
        availableProductQty = Utility.getUomQuantity(
          availableProductQty,
          documentUOMSchemaDefinition,
          QTY_ROUNDOFF_PRECISION_BACKEND
        );
      }

      productReservedQuantity = Utility.getUomQuantity(
        productReservedQuantity,
        documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
      batchSizeFulfilled = Utility.getUomQuantity(
        batchSizeFulfilled,
        documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
      reservedQuantityFulfilled = Utility.getUomQuantity(
        reservedQuantityFulfilled,
        documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
    }

    if (props.batchMultipleWarehouseData) {
      availableProductQty =
        availableProductQty - getAllocatedQuantity(advancedTrackingData);
      if (Utility.isNotEmpty(documentUOMSchemaDefinition)) {
        availableProductQty = Utility.getUomQuantity(
          availableProductQty,
          documentUOMSchemaDefinition,
          QTY_ROUNDOFF_PRECISION_BACKEND
        );
      }
    }

    if (props.showSelectedDataOnly) {
      return availableProductQty;
    }

    if (isMrpFlow && props?.filterBatchData && !props?.isQCFlow) {
      return Utility.getUomQuantity(
        availableProductQty,
        documentUOMSchemaDefinition,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
    }

    if (isMrpFlow || Utility.isEmpty(reservedQuantitiesData)) {
      if (
        props?.parentDocumentType === DOC_TYPE.SALES_ORDER &&
        props?.parentDocumentDetails.reservedStock
      ) {
        const warehouseCode = advancedTrackingData?.warehouseCode;
        const serialBatchNumber = advancedTrackingData?.serialBatchNumber;
        let isAllItemsAreReserved =
          props?.parentDocumentDetails?.salesOrderItems
            ?.filter(
              (ele: any) => ele.productCode === props?.itemDetails?.productCode
            )
            .every((item: any) =>
              Utility.isNotEmpty(item.reservedQuantitiesData)
            );
        const relevantEntries = props?.parentDocumentDetails?.salesOrderItems
          ?.filter(
            (ele: any) => ele.productCode === props?.itemDetails?.productCode
          )
          ?.flatMap((item: any) => item.reservedQuantitiesData)
          ?.flatMap((ele: any) => ele.advancedTrackingMetaDtos)
          ?.filter(
            (ele: any) =>
              ele.serialBatchNumber === serialBatchNumber &&
              ele.warehouseCode === warehouseCode
          );

        if (!isAllItemsAreReserved && Utility.isNotEmpty(relevantEntries)) {
          return (
            availableProductQty -
            productReservedQuantity -
            batchSizeFulfilled +
            reservedQuantityFulfilled +
            getAllocatedQuantity(advancedTrackingData)
          );
        } else {
          return (
            availableProductQty -
            productReservedQuantity -
            batchSizeFulfilled +
            reservedQuantityFulfilled
          );
        }
      } else {
        return (
          availableProductQty -
          productReservedQuantity -
          batchSizeFulfilled +
          reservedQuantityFulfilled
        );
      }
    }

    if (Utility.isNotEmpty(reservedQuantitiesData)) {
      const prodReservedQtyInWH = productReservedQuantity;
      const prodReservedQtyInfoInDoc = reservedQuantitiesData?.find(
        (resData: any) => {
          if (Utility.isNotEmpty(resData?.advancedTrackingMetaDtos?.[0])) {
            const {
              serialBatchNumber: innerSerialBatchNumber,
              rowCode: innerRowCode,
              rackCode: innerRackCode,
              binCode: innerBinCode
            } = resData?.advancedTrackingMetaDtos?.[0];
            return (
              code === resData?.warehouseCode &&
              productCode === resData.productCode &&
              innerSerialBatchNumber === serialBatchNumber?.serialBatchNumber &&
              innerRowCode == rowCode &&
              innerRackCode == rackCode &&
              innerBinCode == binCode
            );
          }
        }
      );

      let prodReservedQtyInDoc = 0;
      if (Utility.isNotEmpty(prodReservedQtyInfoInDoc)) {
        let reservedQty =
          prodReservedQtyInfoInDoc?.advancedTrackingMetaDtos?.[0]
            ?.reservedQuantity || 0;
        let reservedQtyFufilled =
          prodReservedQtyInfoInDoc?.advancedTrackingMetaDtos?.[0]
            ?.reservedQuantityFulfilled || 0;
        prodReservedQtyInDoc = reservedQty - reservedQtyFufilled;
      }
      if (Utility.isNotEmpty(documentUOMSchemaDefinition)) {
        prodReservedQtyInDoc = Utility.getUomQuantity(
          prodReservedQtyInDoc,
          documentUOMSchemaDefinition,
          QTY_ROUNDOFF_PRECISION_BACKEND
        );
      }

      return (
        availableProductQty -
        batchSizeFulfilled +
        reservedQuantityFulfilled -
        prodReservedQtyInWH +
        prodReservedQtyInDoc
      );
    }

    return availableProductQty;
  };

  const addProductCustomFieldsToLineItem = (lineItem: any, product?: any) => {
    let cfs: any[] = [];
    if (!Utility.isEmpty(batchSerialCFfromStore?.content)) {
      // Set default values of CFs when new line is added
      lineItem?.customField?.forEach((productCF: any) => {
        const filteredCF = batchSerialCFfromStore?.content?.find(
          (field: any) =>
            field.id === productCF.id && field.status === STATUS_TYPE.ACTIVE
        );
        if (filteredCF) {
          let cfToUpdate = {
            id: filteredCF.id,
            shortName: filteredCF.shortName,
            module: filteredCF.module,
            code: filteredCF.code,
            label: filteredCF.label,
            value: ''
          };
          let valueOfCF = '';
          if (
            typeof productCF.value !== 'undefined' &&
            productCF.value !== null &&
            productCF.value !== ''
          ) {
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              lineItem[productCF.id] = DateFormatService.getDateFromStr(
                productCF.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (filteredCF.fieldType.toLowerCase() === 'user') {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.code === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DROPDOWN.toLowerCase()
            ) {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.value === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else {
              lineItem[productCF.id] = productCF.value;
            }
            valueOfCF = productCF.value;
          } else {
            lineItem[productCF.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        }
      });
      if (Utility.isEmpty(lineItem?.customField)) {
        batchSerialCFfromStore?.content.forEach((ele: any) => {
          lineItem[ele.id] = '';
        });
      }
    }
    return { ...lineItem, customField: cfs };
  };
  const getLineItemCFs = (lineItem: any) => {
    let oldColConfigs = gridColumnConfig;
    let colConfigsWithOnlyCF = oldColConfigs?.filter(
      (item: any) => item.isCustomField
    );
    let newCfs: any[] = [];
    if (!Utility.isEmpty(batchSerialCFfromStore?.content)) {
      colConfigsWithOnlyCF.forEach((colConfigItem: any) => {
        const cf: any = batchSerialCFfromStore?.content.find(
          (cfItem: any) => colConfigItem.id === cfItem.id
        );
        if (typeof cf !== 'undefined' && cf !== null) {
          let cfValue;
          if (cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
            cfValue = DateFormatService.getDateStrFromDate(
              new Date(lineItem[cf.id]),
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (cf.fieldType.toLowerCase() === 'user') {
            const tempCF = cf?.attributes?.find(
              (attr: any) => attr.code === lineItem[cf.id]?.code
            );
            if (tempCF) {
              cfValue = tempCF.code;
            }
          } else if (
            cf.fieldType.toLowerCase() === INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue = lineItem[cf.id] ? lineItem[cf.id].value : '';
          } else {
            cfValue = lineItem[cf.id] ? lineItem[cf.id] : '';
          }

          newCfs.push({
            id: cf.id,
            code: cf.code,
            label: cf.label,
            module: 'BATCHSERIAL',
            shortName: cf.shortName,
            value: cfValue
          });
        }
      });
    }

    return newCfs;
  };
  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: '90%',
            width: Utility.isRRBEnabled() ? 1320 : 900,
            maxHeight: '95%',
            minHeight: 300,
            padding: 0,
            overflow: 'visible'
          }}
        >
          {getHeader()}
          <div className="column parent-size p-5 1overflow-y-auto show-scroll-bar">
            {getHeaderSection()}
            <div className="column parent-size1 mt-2 parent-width batch-tracking-grid">
              {getBatchTrackingGrid()}
            </div>
            {!props?.disableBtn && (
              <div className="">
                <DKButton
                  title={`+ ${t('DOCUMENT.ADD_ITEM')}`}
                  onClick={() => {
                    if (props.disableAddRow && gridData?.length === 1) {
                      return;
                    }
                    addNewItem();
                  }}
                  className={` fw-m p-0 ${
                    props.disableAddRow && gridData.length === 1
                      ? 'text-gray'
                      : 'text-blue'
                  }`}
                  style={{ zIndex: 1, paddingLeft: 0, paddingTop: 0 }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};

export default BatchTrackingAssignment;
