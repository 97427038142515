import { DateFormat, TableColumnPopupType } from "../Constants/Constants";
import { getDateFrom, getDateString } from "../Utilities/Utility";
import TemplateSettingsManager from "./TemplateSettingsManager";

export const CustomModuleType = {
    product: "PRODUCT",
    account: "ACCOUNT",
}

export const MODULES_NAME = {
    ESTIMATE: 'ESTIMATE',
    BOM_ASSEMBLY: 'BOMASSEMBLY',
    REV_REC: 'REV_REC',
    ASSET: 'FIXEDASSET',
    CONTACT: 'CONTACT',
    CONTACT_ADDRESS: 'CONTACTADDRESS',
    EXPENSE_BILL: 'EXPENSE_BILL',
    FORM_1099_CONTRACTOR: 'FORM_1099_CONTRACTOR',
    PRODUCT: 'PRODUCT',
    MATERIAL_MASTER: 'MATERIAL_MASTER',
    INVOICE: 'INVOICE',
    QUOTES: 'QUOTES',
    QUOTATION: 'QUOTATION',
    RFQ: 'RFQ',
    PURCHASE_ORDER: 'PURCHASE ORDER',
    REQUISITION: 'REQUISITION',
    PURCHASE_REQUISITION: 'PURCHASE_REQUISITION',
    SUPPLIER_QUOTE: 'SUPPLIER_QUOTE',
    SALESORDER: 'SALESORDER', // This is used only for cusom fields currently.
    JOURNAL: 'JOURNAL',
    ACCOUNTING: 'ACCOUNTING',
    BILL: 'BILL',
    SALES_ORDER: 'SALES_ORDER',
    EXPENSE: 'EXPENSE',
    DEPOSIT: 'DEPOSIT',
    DEBITNOTE: 'DEBITNOTE',
    CREDITNOTE: 'CREDITNOTE',
    DRAFTS: 'DRAFTS',
    SETTINGS: 'SETTINGS',
    JOURNAL_ENTRY: 'JOURNAL_ENTRY',
    COA: 'CHART_OF_ACCOUNTS',
    ACCOUNT: 'ACCOUNT',
    WAREHOUSE: 'WAREHOUSE',
    IMPORT: 'IMPORT',
    ORDER: 'ORDER',
    OB_INVOICES: 'OB_INVOICES',
    PRICE_LIST: 'PRICE_LIST',
    PRICE_BOOK: 'PRICE_BOOK',
    QUOTE: 'QUOTE',
    BANK: 'BANK',
    REVENUE_ARRANGEMENT: 'REVENUE_ARRANGEMENT',
    UPDATE_REVENUE_ARRANGEMENT: 'UPDATE_REVENUE_ARRANGEMENT',
    APPS: 'APPS',
    EWAY_BILL: 'EWAY_BILL',
    CLASSES: 'CLASSES',
    BANK_DETAILS: 'BANK_DETAILS',
    PAYMENT_TERMS: 'PAYMENT_TERMS',
    PREPAYMENT: 'EXPENSE_PREPAYMENT',
    RULES: 'RULES',
    AUDIT_LOG: 'AUDIT_LOG',
    CURRENCY: 'CURRENCY',
    TAX: 'TAX',
    IMPORT_LOG: 'IMPORT_LOG',
    IMPORT_DRAFT: 'IMPORT_DRAFT',
    REV_REC_JE_LOGS: 'REV_REC_JE_LOGS',
    UOM: 'UOM',
    UOM_SCHEMA: 'UOM_SCHEMA',
    TDS_RATES: 'TDS_RATES',
    NATURE_OF_PAYMENT: 'NATURE_OF_PAYMENT',
    DEDUCTEE_TYPE: 'DEDUCTEE_TYPE',
    TCS_RATES: 'TCS_RATES',
    FY_CLOSING: 'FY_CLOSING',
    REPORTS: 'REPORTS',
    DEBIT_REPORT: 'Debit Notes',
    CREDIT_REPORT: 'Credit Notes',
    INVOICE_REPORT: 'Invoice',
    TENANT: 'TENANT',
    BILL_REPORT: 'Bill List',
    RECONCILIATION_LOG_REPORT: 'Reconciliation Log Report',
    GST_REPORT_LOG: 'GST Log Report',
    ORDER_REPORT: 'PO List',
    STOCK_MOVEMENT_REPORT: 'Stock Movement',
    QUOTATION_REPORT: 'Quotation Report',
    SALES_BY_PRODUCT: 'Sales By Product',
    SALES_BY_CONTACT: 'Sales By Contact',
    FULFILLMENT_REPORT: 'Fulfillment Report',
    GOODS_RECEIVED_NOTES: 'Goods Received Report',
    PURCHASE_ORDER_DETAIL_REPORT: 'Purchase Order Detail Report',
    SALES_ORDER_DETAIL_REPORT: 'Sales Order Detail Report',
    FULFILLMENT_REPORT_IN: 'Delivery Order Report',
    PURCHASE_BY_PRODUCT: 'Purchase By Product',
    PURCHASE_BY_CONTACT: 'Purchase By Contact',
    RECEIPT_LIST: 'Receipt List',
    PAYMENT_LIST: 'Payment_List',
    AGED_RECEIVABLES_REPORT: 'Aged Receivables',
    MILESTONE_REPORT: 'Payment Milestone',
    AGED_PAYABLES_REPORT: 'Aged Payables',
    FULFILLMENT_REPORT_EXPORT: 'FULFILLMENT_REPORT',
    INVOICE_EXPORT: 'INVOICE_SALES',
    SALES_ORDER_EXPORT: 'SALES_ORDER',
    PURCHASE_ORDER_EXPORT: 'ORDERS',
    REQUISITION_EXPORT: 'REQUISITION',
    SUPPLIER_QUOTE_EXPORT: 'SUPPLIER_QUOTE',
    BILLS_EXPORT: 'INVOICE_PURCHASE',
    VARIANT: 'VARIANT',
    SALES_TAX_RETURN: 'SALES_TAX_RETURN',
    PICK_PACK_SHIP_ALL: 'PICK_PACK_SHIP_ALL',
    PICK_PACK_SHIP_PENDING: 'PICK_PACK_SHIP_PENDING',
    PICK_PACK_SHIP_PICKING: 'PICK_PACK_SHIP_PICKING',
    PICK_PACK_SHIP_PACKING: 'PICK_PACK_SHIP_PACKING',
    PICK_PACK_SHIP_SHIPPING: 'PICK_PACK_SHIP_SHIPPING',
    SERVICE_TAX_RETURN: 'SERVICE_TAX_RETURN',
    AUTOMATIONS: 'Automations',
    INVENTORY_TAB: 'Inventory',
    PICK_PACK_SHIP_ALL_LINE_ITEM: 'allLineItems',
    PICK_PACK_SHIP_PICK_LINE_ITEM: 'pickLineItem',
    AUDIT_TRAIL: 'AUDIT_TRAIL',
    CUSTOM_FIELD_LIST: 'CUSTOM_FIELD_LIST',
    LOCATION_LIST: 'LOCATION_LIST',
    CUSTOM_REPORTS: 'CUSTOM_REPORTS',
    PRINT_CHECKS: 'PRINT_CHECKS',
    BOOKKEEPER_TEAM: 'BOOKKEEPER_TEAM',
    BOOKKEEPER_CLIENT: 'BOOKKEEPER_CLIENT',
    ITEM_LANDED_COST: 'ITEM_LANDED_COST',
    ITEM_LANDED_COST_REPORT: 'Item Landed Cost Report',
    AGENCIES: 'Agencies',
    EWB_REPORTS: 'E-Way Bill Report',
    PICK_LIST: 'PICKLIST',
    PACK_LIST: 'PACKLIST',
    SHIP_LIST: 'SHIPLIST',
    BANK_DOCUMENTS_DETAIL: 'BANK_DOCUMENTS_DETAIL',
    BUDGETING: 'BUDGETING',
    OB_INVENTORY: 'OB_INVENTORY',
    CONSOLIDATION_FINANCIAL_REPORT: 'CONSOLIDATION_FINANCIAL_REPORT',
    CONSOLIDATION_FINANCIAL_REPORT_LIST: 'CONSOLIDATION_FINANCIAL_REPORT_LIST',
    SALES_SERVICE_DETAILS: 'SALES_SERVICE_DETAILS',
    OPERATIONS: 'OPERATIONS',
    MACHINE_MAINTAINCE_AND_TOOLS: 'MACHINE_MAINTAINCE_AND_TOOLS',
    MACHINE_SCHEDULING: 'MACHINE_SCHEDULING',
    FORECASTING: 'FORECASTING',
    MRP_PRODUCTS: 'MRP_PRODUCTS',
    MRP_HOLIDAY_LIST: 'MRP_HOLIDAY_LIST',
    WORKSTATIONS: 'mrp_workstations',
    WORK_ORDERS: 'MRP_WORK_ORDER',
    MRP_QUALITY_CHECK: 'MRP_QUALITY_CHECK',
    MRP_ROUTING_TEMPLATES: 'MRP_ROUTING_TEMPLATES',
    MRP_REPORTS_YIELD_SUMMARY: 'MRP_REPORTS_YIELD_SUMMARY',
    MRP_REPORTS_YIELD_DETAILED_SUMMARY: 'MRP_REPORTS_YIELD_DETAILED_SUMMARY',
    MRP_REPORTS_DAILY_CONSUMPTION: 'MRP_REPORTS_DAILY_CONSUMPTION',
    PRODUCTION_PLAN: 'MRP_PRODUCTION_PLAN',
    JOB_CARDS: 'JOB_CARDS',
    MRP_OPERATOR_LIST: 'MRP_OPERATOR_LIST',
    BOM_EXPLODE: 'BOM_EXPLODE',
    BOM_EXPLOSION: 'BOM_EXPLOSION',
    BOM_EXPLOSION_SUB_GRID: 'BOM_EXPLOSION_SUB_GRID',
    STOCK_ADJUSTMENT: 'STOCKADJUSTMENT',
    STOCK_TRANSFER: 'STOCKTRANSFER',
    STOCK_TRANSFER_LOG: 'STOCKTRANSFER_LOG',
    STOCK_ISSUE: 'STOCKISSUE',
    STOCK_REQUEST: 'STOCKREQUEST',
    JOB_WORK_OUT: 'JOBWORKOUTORDER',
    FORECAST: 'FORECAST',
    MASTER_FORECAST: 'MASTER_FORECAST',
    SALES_BY_CLASS: 'SALES_BY_CLASS',
    JOB_BRIEF: 'JOB_BRIEF',
    RGASSET: 'RGASSET',
    MULTI_COMPANY_CONSOLIDATION_FINANCIAL_REPORT_LIST: 'MULTI_COMPANY_CONSOLIDATION_FINANCIAL_REPORT_LIST',
    FIXEDASSET: 'FIXEDASSET',
    AMORTIZATION: 'AMORTIZATION',
    DEFERRED_EXPENSE: 'DEFERRED_EXPENSE',
    VAT_RETURN: 'VAT_RETURN',
    AMORTIZATION_TEMPLATES: 'AMORTIZATION_TEMPLATES',
    AMORTIZATION_SCHEDULE: 'AMORTIZATION_SCHEDULE',
    AMORTIZATION_JE: 'AMORTIZATION_JE',
    LINK_JOURNAL_ENTRY: 'LINK_JOURNAL_ENTRY',
    PRINT_TEMPLATE: 'PRINT_TEMPLATE',
    LABEL_TEMPLATE: 'LABEL_TEMPLATE',
    MRP_JOB_CARD: 'JOB_CARD',
    WORK_ORDER: 'WORK_ORDER',
    //*info added for Custom-field only
    MRP_WORK_ORDER: 'WORKORDER',
    MRP_JOB_WORK_OUT: 'JOB_WORK_OUT',
    MRP_OPERATOR: 'OPERATOR',
    MRP_PRODUCT: 'PRODUCT',
    MRP_OPERATION: 'OPERATION',
    MRP_MACHINE: 'MACHINE',
    BATCH_SERIAL: 'BATCHSERIAL',
    MRP_MACHINE_SCHEDULE: 'SCHEDULE',
    PRODUCTION_CONSUMPTION_DETAILS: 'PRODUCTION_CONSUMPTION_DETAILS',
    MRP_WORKSTATION: 'WORKSTATION',
    WORKORDER_DOCUMENT: 'WORKORDER_DOCUMENT',
    SKU_ORDER_TRACKING: 'SKU Wise Order Tracking',
    SALES_TAX_AGENCY: 'Sales Tax Report',
    USER: 'USER',
    ROLE: 'ROLE',
    MASTER_FORECAST_DETAILS: 'MASTER_FORECAST_DETAILS',
    VALUATION_BY_WAREHOUSE: 'Valuation By Warehouse',
    VALUATION_BY_WAREHOUSE_DETAILS: 'Valuation By Warehouse Details',
    PICK_PACK_SHIP: 'PICKPACKSHIP',
    GOODS_RECEIPT_LIST: 'Goods Receipt List',
    GOODS_RECEIPT_TOLERANCE_LEVEL_REPORT: 'Goods Receipt - Tolerance Level report',
    FULFILLMENT_TOLERANCE_LEVEL_REPORT: 'Fulfillment - Tolerance Level report',
    DELIVERY_ORDER_TOLERANCE_LEVEL_REPORT: 'Delivery Order - Tolerance Level report',
    TOLERANCE_LEVEL_REJECTION_STOCK_REPORT: 'Goods Receipt - Tolerance Level Rejection Stock Report',
    SO_TO_WO_CONVERSION_REPORT: 'SO_WO_CONVERSION_REPORT',
    MACHINE_ASSET_MAINTAINCE_SCHEUDLE: 'MACHINE_ASSET_MAINTAINCE_SCHEUDLE',
    SALES_TAX_LIABILITY_DETAILS_REPORT: 'SALES_TAX_LIABILITY_DETAILS_REPORT',
    ORDER_LEVELS_REPORT: 'Order Levels Report',
    QC_DOCUMENT_DETAILS: 'QC_DOCUMENT_DETAILS',
    GENERATE_BANK_FILE_INVOICE: 'GENERATE_BANK_FILE_INVOICE',
    GENERATE_BANK_FILE_BILL: 'GENERATE_BANK_FILE_BILL',
    SECURITY_GATE_ENTRY: 'GATEENTRY',
    UPLOAD_TO_FTP_LOGS: 'UPLOAD_TO_FTP_LOGS',
    PAYMENT_LOGS: 'PAYMENT_LOGS',
    SALES_PERSON_MASTER: 'SALES_PERSON_MASTER',
    BANK_BOOK_REPORT: 'BANK_BOOK_REPORT',
    CONSOLIDATE_BANK_BOOK_REPORT: 'CONSOLIDATE_BANK_BOOK_REPORT',
    WHATSAPP_API_CONNECTIONS: 'WHATSAPP_API_CONNECTIONS',
    REQUEST_FOR_QUOTATION: 'REQUESTFORQUOTATION',
    RFQ_CONTACT_MASTER: 'RFQ_CONTACT_MASTER',
    PREFERRED_VENDOR: 'PREFERREDVENDOR',
    PRODUCTS_BY_VENDOR: 'PRODUCTS_BY_VENDOR'
}

export const CUSTOM_FIELDS_MODULES = [
    {
        name: 'Contact',
        isChecked: false,
        module: MODULES_NAME.CONTACT
    },
    {
        name: 'Contact Address',
        isChecked: false,
        module: MODULES_NAME.CONTACT_ADDRESS
    },
    {
        name: 'Product',
        isChecked: false,
        module: MODULES_NAME.PRODUCT
    },
    // {
    //   name: 'BOM Assembly',
    //   isChecked: false,
    //   module: 'bom_assembly'
    // },
    {
        name: 'Account',
        isChecked: false,
        module: MODULES_NAME.ACCOUNT
    },
    {
        name: 'Journal',
        isChecked: false,
        module: MODULES_NAME.JOURNAL,
        allowUserCF: true
    },
    {
        name: 'Expense',
        isChecked: false,
        module: MODULES_NAME.EXPENSE
    },
    {
        name: 'Deposit',
        isChecked: false,
        module: MODULES_NAME.DEPOSIT
    },
    {
        name: 'Debit Note',
        isChecked: false,
        module: MODULES_NAME.DEBITNOTE
    },
    {
        name: 'Credit Note',
        isChecked: false,
        module: MODULES_NAME.CREDITNOTE
    },
    // {
    //   name: 'Stock Adjustment',
    //   isChecked: false,
    //   module: 'stock_adjustment'
    // },
    // {
    //   name: 'Stock Transfer',
    //   isChecked: false,
    //   module: 'stock_transfer'
    // },
    {
        name: 'Quotation',
        isChecked: false,
        module: MODULES_NAME.QUOTATION,
        allowUserCF: true
    },
    {
        name: 'Sales Order',
        isChecked: false,
        module: MODULES_NAME.SALESORDER,
        allowUserCF: true
    },
    {
        name: 'Invoice',
        isChecked: false,
        module: MODULES_NAME.INVOICE,
        allowUserCF: true
    },
    {
        name: 'Purchase Order',
        isChecked: false,
        module: MODULES_NAME.ORDER,
        allowUserCF: true
    },
    {
        name: 'Bill',
        isChecked: false,
        module: MODULES_NAME.BILL,
        allowUserCF: true
    },
    {
        name: 'Requisition',
        isChecked: false,
        module: MODULES_NAME.REQUISITION,
        allowUserCF: true
    },
    {
        name: 'Fixed Asset',
        isChecked: false,
        module: MODULES_NAME.ASSET
    },
    {
        name: 'Build Assembly',
        isChecked: false,
        module: MODULES_NAME.BOM_ASSEMBLY
    },
    {
        name: 'Stock Adjustment',
        isChecked: false,
        module: MODULES_NAME.STOCK_ADJUSTMENT
    },
    {
        name: 'Stock Transfer',
        isChecked: false,
        module: MODULES_NAME.STOCK_TRANSFER
    },
    {
        name: 'Stock Request',
        isChecked: false,
        module: MODULES_NAME.STOCK_REQUEST
    },
    {
        name: 'Stock Issue',
        isChecked: false,
        module: MODULES_NAME.STOCK_ISSUE
    },
    {
        name: 'Operators',
        isChecked: false,
        module: MODULES_NAME.MRP_OPERATOR
    },
    {
        name: 'Operations',
        isChecked: false,
        module: MODULES_NAME.MRP_OPERATION
    },
    {
        name: 'Workorder',
        isChecked: false,
        module: MODULES_NAME.MRP_WORK_ORDER
    },
    {
        name: 'Job Work Outs',
        isChecked: false,
        module: MODULES_NAME.JOB_WORK_OUT
    },
    {
        name: 'Security Gate Entry',
        isChecked: false,
        module: MODULES_NAME.SECURITY_GATE_ENTRY
    },
    {
        name: 'Pick Pack Ship',
        isChecked: false,
        module: MODULES_NAME.PICK_PACK_SHIP
    },
    {
        name: 'Request For Quotation',
        isChecked: false,
        module: MODULES_NAME.REQUEST_FOR_QUOTATION
    },
    {
        name: 'Machine',
        isChecked: false,
        module: MODULES_NAME.MRP_MACHINE
    },
    {
        name: 'Preferred Vendor',
        isChecked: false,
        module: MODULES_NAME.PREFERRED_VENDOR
    },
    {
        name: 'Batch/Serial',
        isChecked: false,
        module: MODULES_NAME.BATCH_SERIAL
    }
];

export default class CustomFieldManager {

    static customFieldOptions = [];

    static masterCustomFieldOptions = []

    static productCustomFieldOptions = []
    
    static childCustomFieldOptions = []

    static accountCustomFieldOptions = []

    static dateFormat = ""

    static tenantDateFormat = ""

    static preferredVendorCustomFieldOptions = [];

    static getDateFormat() {
        let dateFormat = CustomFieldManager.dateFormat

        var isCheck = TemplateSettingsManager.getDateFormatIsSystemDefault()
        if (isCheck) {
            if (this.tenantDateFormat && this.tenantDateFormat !== null) {
                if(this.tenantDateFormat !== ''){
                    dateFormat = this.tenantDateFormat
                }
            }
        }

        return dateFormat;
    }

    static setDateFormat(dateformat) {
        CustomFieldManager.dateFormat = dateformat
    }

    static setTenantDateFormat(dateformat) {
        CustomFieldManager.tenantDateFormat = dateformat
    }

    static getMaterCustomFields(includeInactive) {
        let nameArray = []
        CustomFieldManager.masterCustomFieldOptions.forEach((item) => {
            if (item.status === 'ACTIVE' || includeInactive) {
                nameArray.push(item)
            }
        })
        return nameArray
    }

    static getMaterCustomFieldCodes() {
        let nameArray = []
        CustomFieldManager.masterCustomFieldOptions.forEach((item) => {
            if (item.status === 'ACTIVE') {
                nameArray.push(item.code)
            }
        })

        return nameArray
    }

    static setMasterCustomFields(items) {
        CustomFieldManager.masterCustomFieldOptions = items
        this.setProductCustomFieldsFromMasterList(items)
        this.setAccountCustomFieldsFromMasterList(items)
    }

    static getCustomFields() {
        let nameArray = []
        if(CustomFieldManager.customFieldOptions !== undefined && CustomFieldManager.customFieldOptions !== null) {
            if (CustomFieldManager.customFieldOptions.length > 0) {
                CustomFieldManager.customFieldOptions.forEach((item) => {
                    var activeItem = CustomFieldManager.masterCustomFieldOptions.find(x => x.code === item.code)
                    if (activeItem !== undefined) {
                        item.label = activeItem.label
                        item.type = activeItem.fieldType
                        item.id = activeItem.id
                        nameArray.push(item)
                    }
                })
            }
        }
        return nameArray
    }

    static getActiveCustomFields() {
        let nameArray = []
        if (CustomFieldManager.customFieldOptions !== undefined && CustomFieldManager.customFieldOptions !== null) {
            if (CustomFieldManager.customFieldOptions.length > 0) {
                CustomFieldManager.customFieldOptions.forEach((item) => {
                    var activeItem = CustomFieldManager.masterCustomFieldOptions.find(x => x.code === item.code)
                    if (activeItem !== undefined) {
                        if (activeItem.status === 'ACTIVE') {
                            item.label = activeItem.label
                            item.type = activeItem.fieldType
                            item.id = activeItem.id
                            nameArray.push(item)
                        }
                    }
                })
            }
        }
        return nameArray
    }

    static setCustomFields(items) {
        CustomFieldManager.customFieldOptions = items
    }

    static getCustomFieldAt(index) {
        return CustomFieldManager.customFieldOptions[index]
    }

    static getCustomFieldByCode(code) {
        if (CustomFieldManager.customFieldOptions !== undefined && CustomFieldManager.customFieldOptions !== null) {
            if (CustomFieldManager.customFieldOptions.length > 0) {
                var item = CustomFieldManager.customFieldOptions.filter(x => x.code === code)
                if (item.length > 0) {
                    return item[0]
                }
            }
        }

        return undefined
    }

    static getCustomFieldByCodeFromApi(data, code, module) {
        var customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldList

        if (data !== undefined && data !== null) {
            if (data.customFields !== undefined && data.customFields !== null) {
                if (data.customFields.length > 0) {
                    var item = data.customFields.find(x => x.code === code)

                    let cfModule = module ?? ''
                    if(cfModule !== '') {
                        item = data.customFields.find(x => x.code === code && x.module === cfModule)
                    }
                    if (item !== undefined) {
                        if(customFieldList !== undefined && customFieldList !== null) {
                            if(customFieldList.length > 0) {
                                var masterCF = customFieldList.find(x => x.id === item.id)
                                if(masterCF !== undefined) {
                                    item.type = masterCF.type
                                }
                            }
                        }

                        return item
                    }
                }
            }
        }

        return undefined
    }

    static getSelectedGlobalCustomFieldList(data, position) {
        var customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldList
        if(position !== undefined && position !== null) {
            if(position === 'bottom') {
                customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.bottomCustomFieldList
            }
        }

        if (customFieldList === undefined || customFieldList === null) {
            customFieldList = []
        }
        if(customFieldList.length > 0) {
            customFieldList = customFieldList.filter(x => x.isSelected)
        }
        if (TemplateSettingsManager.getIsMultipleDocument()) {
            var customField = data.customFields
            if (customField !== undefined && customField !== null) {
                if (customField.length > 0) {
                    var customFieldCodes = customField.map(x => x.code)
                    var customFieldListWithMultiDoc = TemplateSettingsManager.getCustomFieldListWithMultiDoc()
                    if (position !== undefined && position !== null) {
                        if (position === 'bottom') {
                            customFieldListWithMultiDoc = TemplateSettingsManager.getBottomCustomFieldListWithMultiDoc()
                        }
                    }
                    let customFieldListPerDoc = customFieldListWithMultiDoc.find(x => x.documentId === data.refNumber)
                    if (customFieldListPerDoc !== undefined) {
                        let cfListPerDoc = []
                        if (customFieldListPerDoc.customList && customFieldListPerDoc.customList !== null) {
                            cfListPerDoc = [...customFieldListPerDoc.customList]
                        }
                        if (cfListPerDoc !== undefined) {
                            if (cfListPerDoc.length > 0) {
                                cfListPerDoc.forEach(element => {
                                    // element.isSelected = customFieldCodes.includes(element.code)
                                    if (element.isSelected === true) {
                                        let parentCF = customFieldList.find(x => x.code === element.code)
                                        if (parentCF) {
                                            element.isSelected = parentCF.isSelected
                                        }
                                    }
                                });
                            }
                            customFieldList = cfListPerDoc.filter(x => x.isSelected)

                            let newCFList = []

                            customFieldList.forEach(element => {
                                if (customFieldCodes.includes(element.code)) {
                                    newCFList.push(element)
                                }
                            });

                            if (newCFList.length > 0) {
                                customFieldList = newCFList
                            }
                        }
                    }
                }
            }
        }
        return customFieldList
    }

    static getIsContainsRenderSystemField() {
        if (CustomFieldManager.getCustomFields().length > 0) {
            return true
        }

        return false
    }

    static setProductCustomFieldsFromMasterList(masterList) {
        if(masterList === undefined || masterList === null) {
            return
        }

        if(masterList.length === 0) {
            return
        }

        var newProductCustomFieldList = []
        var newPreferredVendorCustomFieldList = []

        masterList.forEach(element => {
            if (element.status === 'ACTIVE') {
                if (element.modules !== undefined && element.modules !== null) {
                    if(element.modules.length > 0) {
                        if(element.modules.includes(CustomModuleType.product)) {
                            newProductCustomFieldList.push(element)
                        } else if(element.modules.includes("PREFERREDVENDOR")) {
                            newPreferredVendorCustomFieldList.push(element)
                        }
                    }
                }
            }
        });

        this.setProductCustomFields(newProductCustomFieldList)
        this.setPreferredVendorProductCustomFields(newPreferredVendorCustomFieldList)
    }

    static setProductCustomFields(items) {
        CustomFieldManager.productCustomFieldOptions = items
    }

    static getProductCustomFields() {
        if(TemplateSettingsManager.defaultTemplateSettings?.productTableConfiguration?.isIncludesPreferredVendorProductCustomField){
            return [...CustomFieldManager.productCustomFieldOptions,...CustomFieldManager.preferredVendorCustomFieldOptions]
        } else {
            return CustomFieldManager.productCustomFieldOptions
        }
    }

    static getProductWithPreferredVendorCustomFields(isIncludesPreferredVendorProductCustomField) {
        if(isIncludesPreferredVendorProductCustomField){
            return [...CustomFieldManager.productCustomFieldOptions,...CustomFieldManager.preferredVendorCustomFieldOptions]
        } else {
            return CustomFieldManager.productCustomFieldOptions
        }
    }

    static setPreferredVendorProductCustomFields(items) {
        CustomFieldManager.preferredVendorCustomFieldOptions = items
    }

    static getPreferredVendorProductCustomFields() {
        return CustomFieldManager.preferredVendorCustomFieldOptions
    }

    static getActiveProductCustomFields() {
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        var productCustomFieldList = CustomFieldManager.getProductCustomFields()
        var list = []
        var prevList = []
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.combinedProductCustomField !== undefined) {
                prevList = productTableConfiguration.combinedProductCustomField
            }
        }

        if (productCustomFieldList.length > 0) {
            if (prevList.length === 0) {
                productCustomFieldList.forEach(element => {
                    list.push(this.convertProductCustomField(element))
                });
            }
            else {
                productCustomFieldList.forEach(element => {
                    var prevItem = prevList.find(x => x.code === element.code)
                    if (prevItem !== undefined) {
                        list.push(prevItem)
                    }
                    else {
                        list.push(this.convertProductCustomField(element))
                    }
                });
            }
        }
        return list
    }

    static convertProductCustomField(element) {
        var item = {}
        item.code = element.code
        item.label = element.label
        item.type = TableColumnPopupType.productCustomField
        item.isSelected = false
        return item
    }

    static getActiveProductCustomFieldsTableOptions(columnList) {
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        var list = []
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.isIncludesProductCustomField !== undefined) {
                if (productTableConfiguration.isIncludesProductCustomField) {
                    var productCustomFieldList = CustomFieldManager.getProductCustomFields()
                    var productCustomFieldCodeList = []
                    columnList.forEach((element) => {
                        if (element.type === TableColumnPopupType.productCustomField && element.code !== undefined && productCustomFieldList.length > 0) {
                            var productCustomField = productCustomFieldList.find(x => x.code === element.code)
                            if(productCustomField !== undefined) {
                                list.push(element)
                                productCustomFieldCodeList.push(element.code)
                            }
                        }
                        else {
                            list.push(element)
                        }
                    });

                    if (productCustomFieldList.length > 0) {
                        var newProductCustomFieldList = []
                        productCustomFieldList.forEach(element => {
                            if(!productCustomFieldCodeList.includes(element.code)) {
                                newProductCustomFieldList.push(element)
                            }
                        });

                        if (newProductCustomFieldList.length > 0) {
                            newProductCustomFieldList.forEach(element => {
                                var item = {}
                                item.code = element.code
                                item.name = element.label
                                item.cfType = element.type
                                item.type = TableColumnPopupType.productCustomField
                                item.isSelected = false
                                list.push(item)
                            });
                            list.forEach((element, index) => {
                                if(element.index === undefined) {
                                    element.index = index
                                }
                            });
                        }
                    }
                }
                else {
                    if (columnList.length > 0) {
                        //remove product custom field
                        columnList.forEach(element => {
                            if (!(element.code !== undefined && element.type === TableColumnPopupType.productCustomField)) {
                                list.push(element)
                            }
                        });
                    }
                }
            }
        }
        else {
            list = columnList
        }

        return list
    }

    static getNumericProductCustomField(selectedList) {
        var numericProductCustomFieldList = []

        if(selectedList === undefined || selectedList === null) {
            return numericProductCustomFieldList
        }

        selectedList.forEach(element => {
            if(element.code !== undefined) {
                var productCustomField = this.productCustomFieldOptions.find(x => x.code === element.code)
                if(productCustomField !== undefined) {
                    if (productCustomField.fieldType === 'NUMBER') {
                        numericProductCustomFieldList.push(productCustomField)
                    }
                }
            }
        });
        return numericProductCustomFieldList
    }

    static setAccountCustomFieldsFromMasterList(masterList) {
        if (masterList === undefined || masterList === null) {
            return
        }

        if (masterList.length === 0) {
            return
        }

        var newAccountCustomFieldList = []
        masterList.forEach(element => {
            if (element.status === 'ACTIVE') {
                if (element.modules !== undefined && element.modules !== null) {
                    if (element.modules.length > 0) {
                        if (element.modules.includes(CustomModuleType.account)) {
                            newAccountCustomFieldList.push(element)
                        }
                    }
                }
            }
        });

        this.setAccountCustomFields(newAccountCustomFieldList)
    }

    static setAccountCustomFields(items) {
        CustomFieldManager.accountCustomFieldOptions = items
    }

    static getAccountCustomFields() {
        return CustomFieldManager.accountCustomFieldOptions
    }

    static getActiveAccountCustomFields() {
        var accountTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.accountTableConfiguration
        var accountCustomFieldList = CustomFieldManager.getAccountCustomFields()
        var list = []
        var prevList = []
        if (accountTableConfiguration !== undefined) {
            if (accountTableConfiguration.combinedAccountCustomField !== undefined) {
                prevList = accountTableConfiguration.combinedAccountCustomField
            }
        }
        if (accountCustomFieldList.length > 0) {
            if (prevList.length === 0) {
                accountCustomFieldList.forEach(element => {
                    list.push(this.convertAccountCustomField(element))
                });
            }
            else {
                accountCustomFieldList.forEach(element => {
                    var prevItem = prevList.find(x => x.code === element.code)
                    if (prevItem !== undefined) {
                        list.push(prevItem)
                    }
                    else {
                        list.push(this.convertAccountCustomField(element))
                    }
                });
            }
        }
        return list
    }

    static convertAccountCustomField(element) {
        var item = {}
        item.code = element.code
        item.label = element.label
        item.type = TableColumnPopupType.accountCustomField
        item.isSelected = false
        return item
    }

    static getActiveAccountCustomFieldsTableOptions(columnList) {
        var accountTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.accountTableConfiguration
        var list = []
        if (accountTableConfiguration !== undefined) {
            if (accountTableConfiguration.isIncludesAccountCustomField !== undefined) {
                if (accountTableConfiguration.isIncludesAccountCustomField) {
                    var accountCustomFieldList = CustomFieldManager.getAccountCustomFields()
                    var accountCustomFieldCodeList = []
                    columnList.forEach((element) => {
                        if (element.type === TableColumnPopupType.accountCustomField && element.code !== undefined && accountCustomFieldList.length > 0) {
                            var productCustomField = accountCustomFieldList.find(x => x.code === element.code)
                            if (productCustomField !== undefined) {
                                list.push(element)
                                accountCustomFieldCodeList.push(element.code)
                            }
                        }
                        else {
                            list.push(element)
                        }
                    });

                    if (accountCustomFieldList.length > 0) {
                        var newAccountCustomFieldList = []
                        accountCustomFieldList.forEach(element => {
                            if (!accountCustomFieldCodeList.includes(element.code)) {
                                newAccountCustomFieldList.push(element)
                            }
                        });

                        if (newAccountCustomFieldList.length > 0) {
                            newAccountCustomFieldList.forEach(element => {
                                var item = {}
                                item.code = element.code
                                item.name = element.label
                                item.cfType = element.type
                                item.type = TableColumnPopupType.accountCustomField
                                item.isSelected = false
                                list.push(item)
                            });
                            list.forEach((element, index) => {
                                if (element.index === undefined) {
                                    element.index = index
                                }
                            });
                        }
                    }
                }
                else {
                    if (columnList.length > 0) {
                        //remove product custom field
                        columnList.forEach(element => {
                            if (element.code === undefined) {
                                list.push(element)
                            }
                        });
                    }
                }
            }
        }
        else {
            list = columnList
        }
        return list
    }

    static parseCustomField(data, code, module) {
        var customFieldValue = ''
        var customField = CustomFieldManager.getCustomFieldByCodeFromApi(data, code, module)
        if(customField !== undefined) {
            if (customField.type === "DATE") {
                customFieldValue = '-'
                if(customField.value !== undefined && customField.value !== null) {
                    if(customField.value !== '' ) {
                        const dateFormat = CustomFieldManager.getDateFormat();
                        customFieldValue = getDateString(getDateFrom(customField.value, DateFormat.MM_DD_YYYY_SLASH), dateFormat);
                    }
                }
            } else {
                if (customField !== undefined) {
                    if(customField.value !== null && customField.value !== '') {
                        customFieldValue = customField.value
                    }
                    else {
                        customFieldValue = ' '
                    }
                }
                else {
                    customFieldValue = ' '
                }
            }
        }
        return customFieldValue
    }

    static isDateCustomField(cfId) {
        const masterCustomFields = CustomFieldManager.getMaterCustomFields(true);
        if (Array.isArray(masterCustomFields)) {
            return masterCustomFields.findIndex(cf => cf.id === cfId && cf.fieldType === "DATE") !== -1;
        }

        return false;
    }

    static setChildCustomFields(items) {
        CustomFieldManager.childCustomFieldOptions = items
    }

    static getChildCustomFieldAt(index) {
        return CustomFieldManager.childCustomFieldOptions[index]
    }

    static getChildCustomFields() {
        let nameArray = []
        if (CustomFieldManager.childCustomFieldOptions !== undefined && CustomFieldManager.childCustomFieldOptions !== null) {
            if (CustomFieldManager.childCustomFieldOptions.length > 0) {
                CustomFieldManager.childCustomFieldOptions.forEach((item) => {
                    var activeItem = CustomFieldManager.masterCustomFieldOptions.find(x => x.code === item.code)
                    if (activeItem !== undefined) {
                        item.label = activeItem.label
                        item.type = activeItem.fieldType
                        item.id = activeItem.id
                        nameArray.push(item)
                    }
                })
            }
        }
        return nameArray
    }

    static getSelectedChildCustomFieldList(data) {
        var customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.childCustomFieldList
        if (customFieldList === undefined || customFieldList === null) {
            customFieldList = []
        }
        if (customFieldList.length > 0) {
            customFieldList = customFieldList.filter(x => x.isSelected)
        }
        if (TemplateSettingsManager.getIsMultipleDocument()) {
            var customField = data.childCustomFields
            if (customField !== undefined && customField !== null) {
                if (customField.length > 0) {
                    // var customFieldCodes = customField.map(x => x.code)
                    var customFieldListWithMultiDoc = TemplateSettingsManager.getChildCustomFieldListWithMultiDoc()
                    
                    let customFieldListPerDoc = customFieldListWithMultiDoc.find(x => x.documentId === data.refNumber)
                    if (customFieldListPerDoc !== undefined) {
                        let cfListPerDoc = customFieldListPerDoc.customList
                        if (cfListPerDoc !== undefined) {
                            if (cfListPerDoc.length > 0) {
                                cfListPerDoc.forEach(element => {
                                    // element.isSelected = customFieldCodes.includes(element.code)
                                    if (element.isSelected === true) {
                                        let parentCF = customFieldList.find(x => x.code === element.code)
                                        if (parentCF) {
                                            element.isSelected = parentCF.isSelected
                                        }
                                    }
                                });
                            }
                            customFieldList = cfListPerDoc.filter(x => x.isSelected)
                        }
                    }
                }
            }
        }
        return customFieldList
    }

    static getChildCustomFieldByCodeFromApi(data, code) {
        var customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.childCustomFieldList

        if (data !== undefined && data !== null) {
            if (data.childCustomFields !== undefined && data.childCustomFields !== null) {
                if (data.childCustomFields.length > 0) {
                    var item = data.childCustomFields.find(x => x.code === code)
                    if (item !== undefined) {
                        if (customFieldList !== undefined && customFieldList !== null) {
                            if (customFieldList.length > 0) {
                                var masterCF = customFieldList.find(x => x.id === item.id)
                                if (masterCF !== undefined) {
                                    item.type = masterCF.type
                                }
                            }
                        }

                        return item
                    }
                }
            }
        }

        return undefined
    }

    static getCombineSerialBatchCustomField(rawItemValue, data) {
        var val = "";
        const serialBatchCustomFieldType = rawItemValue.serialBatchCustomFieldType

        if (serialBatchCustomFieldType && serialBatchCustomFieldType !== 'NONE') {
            const serialBatchCustomFields = rawItemValue?.serialBatchCustomFields ?? undefined
            if (serialBatchCustomFieldType === 'BATCH') {
                if (serialBatchCustomFields && serialBatchCustomFields.length > 0) {
                    serialBatchCustomFields.forEach((batch) => {
                        if (batch.customField && batch.customField.length > 0) {
                            batch.customField.forEach((cfBatchEle) => {
                                val += cfBatchEle.label + ": <b>" + cfBatchEle.value + "</b>\n";
                            });
                        }
                    });
                }
            }

            if (serialBatchCustomFieldType === 'SERIAL') {
                const serialNumbers = rawItemValue?.serialNumbers ?? []
                if (serialNumbers.length > 0 && serialBatchCustomFields !== undefined) {
                    serialNumbers.forEach((element) => {
                        let cf = serialBatchCustomFields[element]
                        if (cf && cf !== null) {
                            cf.forEach((cfElement) => {
                                val += cfElement.label + ": <b>" + cfElement.value + "</b>\n";
                            });
                        }
                    });
                }
            }

            if (serialBatchCustomFieldType === 'SERIAL_BATCH') {
                const serialNumbers = rawItemValue?.serialBatchList ?? []
                if (serialNumbers.length > 0 && serialBatchCustomFields !== undefined) {
                    serialNumbers.forEach((element) => {
                        let cf = serialBatchCustomFields[element]
                        if (cf && cf !== null) {
                            val += element + ',\n'
                            cf.forEach((cfElement) => {
                                var cfType = cf.cfType;
                                var value = cfElement.value 
                                if(cfType === undefined) {
                                    const dateValue = getDateFrom(cfElement.value, DateFormat.MM_DD_YYYY_SLASH)
                                    if(dateValue instanceof Date && !isNaN(dateValue)) {
                                        cfType = 'DATE'
                                    }
                                }
                                if (cfType === "DATE") {
                                    value = getDateString(getDateFrom(cfElement.value, DateFormat.MM_DD_YYYY_SLASH), data.dateFormat) || cfElement.value;
                                }
                                val += cfElement.label + ": <b>" + value + "</b>\n";
                            });
                        }
                    });
                }
            }
        }
        return val;
    }


    static getMaterCustomFieldsWithType(customModuleType) {
        let nameArray = []
        CustomFieldManager.masterCustomFieldOptions.forEach((item) => {
            if (item.status === 'ACTIVE') {
                let modules = item?.modules ?? []
                if (typeof customModuleType === 'string') {
                    if (modules.includes(customModuleType)) {
                        nameArray.push(item)
                    }
                }
                if(Array.isArray(customModuleType)) {
                    if (modules.some(module => customModuleType.includes(module))) {
                        nameArray.push(item)
                    }
                }
            }
        })
        return nameArray
    }
}
