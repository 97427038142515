import { useEffect, useState } from 'react';
import { showAlert } from 'deskera-ui-library';
import Utility, { deepClone } from '../../../Utility/Utility';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  DOCUMENT_MODE,
  DOC_TYPE,
  JWO_DOC_PATH_WITH_ID_REGEX,
  LABELS,
  POPUP_CALLBACKS_TYPE,
  RECORD_SAVED_EVENT_DOC_TYPE,
  TRACKING_TYPE
} from '../../../Constants/Constant';
import NewDocument2 from '../../../SharedComponents/DocumentForm/NewDocument2';
import { Document } from '../../../Models/Document';
import {
  deleteDrafts,
  draftTableId,
  draftTypeColumnId,
  fetchDrafts,
  isSaveColumnId,
  removeDraft,
  selectDraftsColumnConfig,
  setDraftActionAvailibility,
  setDraftValidationDisplayStatus,
  updatePopulateFormData
} from '../../../Redux/Slices/DraftsSlice';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { fetchOrders } from '../../../Redux/Slices/PurchaseOrdersSlice';
import { setItemsFromJobWorkOutItems } from './WorkOutHelper';
import { activeTenantInfo, userInfo } from '../../../Redux/Slices/AuthSlice';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../../../Constants/Enum';
import {
  checkGSTINPresentForSelectedContact,
  customFieldsContainsErrors,
  getDocumentAlert,
  showAlertOnDocAPIError,
  updateAddressAsPerLocationCF
} from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import { fetchapprovalConditionList } from '../../../Redux/Slices/AutomationSlice';
import AuthService from '../../../Services/Auth';
import PurchaseOrderService from '../../../Services/PurchaseOrder';
import NumberFormatService from '../../../Services/NumberFormat';
import { DraftTypes } from '../../../Models/Drafts';
import { PAGE_ROUTES } from '../../../Managers/RouteManager';
import DateFormatService from '../../../Services/DateFormat';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';
import { useHistory } from 'react-router-dom';
import DraftService from '../../../Services/Drafts';
import WorkoutService from '../../../Services/Workout';
import {
  fetchWorkOuts,
  updateRefreshCallback
} from '../../../Redux/Slices/WorkOutSlice';
import { JobWorkOutInitialState, WorkOut } from '../../../Models/Workout';
import {
  COMMON_EVENTS,
  IRecordSavedEventData,
  commonCustomEvent
} from '../../../Services/event/commonEvents';
import { updatePOPRAssociation } from '../../../Redux/Slices/MRP/WorkOrderSlice';

const NewWorkOut = (props: any) => {
  const [isCenterAlign, setIsCenterAlign] = useState<boolean>(
    props.isCenterAlign
  );
  const [workOut, setWorkOut] = useState<WorkOut>(
    !Utility.isEmpty(props.populateFormData)
      ? setItemsFromJobWorkOutItems(props.populateFormData)
      : { ...JobWorkOutInitialState }
  );
  const [updatedWorkOut, setUpdatedWorkOut] = useState<WorkOut>(workOut);
  const draftsTableId = useAppSelector(draftTableId);
  const isSavedColumnId = useAppSelector(isSaveColumnId);
  const draftTypeColId = useAppSelector(draftTypeColumnId);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const dispatch = useAppDispatch();
  let history = useHistory();
  const userDetails = useAppSelector(userInfo);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_AS_DRAFT,
        data: () => {
          onSaveAsDraft(false);
        }
      });

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_DRAFT_POPUP,
        data: () => {
          closeDraftPopup();
        }
      });

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.UPDATE_ORDER,
        data: () => {
          updateWorkOutDetails();
        }
      });
    }

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_ORDER,
        data: (closeDoc: boolean) => {
          createJobWorkOut(closeDoc);
        }
      });
    }
  };

  useEffect(() => {
    if (
      workOut &&
      workOut.dropShip &&
      props.documentMode === DOCUMENT_MODE.EDIT
    ) {
      showAlert(
        'Dropship Purchase Order',
        'This is a Dropship document and has only limited edit functionality'
      );
    }
    setIsCenterAlign(props.isCenterAlign);
    loadApprovalConditionList();
    return () => {
      console.log('cleanup..');
    };
  }, []);

  useEffect(() => {
    setIsCenterAlign(props.isCenterAlign);
  }, [props]);

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    const onLinkedRecordUpdated = (data: { detail: IRecordSavedEventData }) => {
      if (
        !data?.detail ||
        data.detail.linkedDocType !==
          RECORD_SAVED_EVENT_DOC_TYPE.JOB_WORK_OUT ||
        data.detail.linkedDocId !== updatedWorkOut.jwoCode
      )
        return;

      updateForm(updatedWorkOut, updatedWorkOut, true);
    };

    commonCustomEvent.on(
      COMMON_EVENTS.RECORD_SAVED,
      onLinkedRecordUpdated,
      true
    );

    return () =>
      commonCustomEvent.remove(
        COMMON_EVENTS.RECORD_SAVED,
        onLinkedRecordUpdated
      );
  }, [updatedWorkOut]);

  useEffect(() => {
    if (!Utility.isEmpty(props.populateFormData)) {
      setWorkOut({ ...props.populateFormData });
    }
  }, [props.populateFormData]);

  const loadApprovalConditionList = () => {
    dispatch(fetchapprovalConditionList());
  };

  const onSaveAsDraft = (isApprovalRequired: boolean) => {
    const payload = { ...updatedWorkOut };

    if (isApprovalRequired) {
      payload['approvalStatus'] = APPROVAL_STATUS['PENDING_FOR_APPROVAL'];
      payload['multiApprovalDetails'] = {
        currentLevel: payload?.['multiApprovalDetails']?.currentLevel || 1,
        approvalHistory:
          payload?.['multiApprovalDetails']?.approvalHistory || [],
        approvalRequiredFor: userDetails?.[0]?.email || ''
      };
    } else {
      payload['approvalStatus'] = payload['approvalStatus']
        ? payload['approvalStatus']
        : APPROVAL_STATUS['NOT_REQUIRED'];
    }
    payload['createdUserName'] = AuthService.getUserName();

    setButtonStatus(true);
    props.formData(payload);

    if (isApprovalRequired) {
      sendTriggerOnApproval(payload);
      if (props.documentMode === DOCUMENT_MODE.COPY) {
        let draftsData: any = {
          data: {
            type: LABELS.WORK_OUT,
            tableId: draftsTableId,
            columnConfig: draftsColumnConfig
          }
        };
        DraftService.createRecord(payload, draftsData).then((response: any) => {
          dispatch(
            fetchDrafts({
              tableId: draftsTableId,
              isSaveColumnId: isSavedColumnId,
              draftTypeColId: draftTypeColId,
              draftTypeColValue: LABELS.WORK_OUT
            })
          );
        });
      }
    }
    replaceURLToModuleURL();
  };

  // Change URL to base module URL
  const replaceURLToModuleURL = () => {
    if (
      JWO_DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
      history.location?.pathname?.includes(PAGE_ROUTES.MRP_WORK_OUT)
    ) {
      history.replace(PAGE_ROUTES.MRP_WORK_OUT);
    }
  };

  const closeDraftPopup = () => {
    replaceURLToModuleURL();
  };

  const isDocValid = (docToValidate: any) => {
    // Validate Manual Document Sequence Code
    if (
      Utility.isEmpty(docToValidate.documentSequenceCode) &&
      Utility.isEmpty(docToValidate.sequenceFormat) &&
      docToValidate.manualMode
    ) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.contact)) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.jobWorkOutOrderItems)) {
      return false;
    }

    if (!Utility.isEmpty(docToValidate.documentDate)) {
      const docDate = DateFormatService.getDateFromStr(
        docToValidate.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantInfo,
          'Order date',
          docToValidate.documentType
        )
      ) {
        return false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      docToValidate.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    // Line item errors
    let lineItemsHasErrors = false;
    for (let i = 0; i < docToValidate.jobWorkOutOrderItems.length; i++) {
      const item = docToValidate.jobWorkOutOrderItems[i];
      if (item.hasError || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }

    if (lineItemsHasErrors) {
      return false;
    }
    // Line item errors ends

    // Contact GSTIN check
    if (!checkGSTINPresentForSelectedContact(docToValidate)) {
      return false;
    }
    // Contact GSTIN check ends

    // Additional charges check
    let additionalChargesHasErrors = false;
    const additionalCharges =
      docToValidate.additionalCharges?.additionalChargesDetails;
    if (!Utility.isEmpty(additionalCharges)) {
      for (let i = 0; i < additionalCharges.length; i++) {
        const item = additionalCharges[i];
        if (item.hasError) {
          additionalChargesHasErrors = true;
          break;
        }
      }
    }
    if (additionalChargesHasErrors) {
      return false;
    }
    // Additional charges check ends

    // Discount check
    const discountHasErrors =
      docToValidate.additionalCharges?.globalDiscount?.hasError;
    if (discountHasErrors) {
      return false;
    }
    // Discount check ends

    return true;
  };

  const setButtonStatus = (status: boolean) => {
    dispatch(
      setDraftActionAvailibility({ id: props.draftData.id, status: status })
    );
  };

  const setValidationDisplayStatus = (status: boolean) => {
    dispatch(
      setDraftValidationDisplayStatus({
        id: props.draftData.id,
        status: status
      })
    );
  };

  const updateForm = (
    docResp: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = true
  ) => {
    WorkoutService.fetchJobWorkOutDetails(docResp.jwoCode).then(
      (doc: any) => {
        const formData = {
          ...doc,
          documentType: DOC_TYPE.JOB_WORK_OUT_ORDER,
          items: [...doc.jobWorkOutOrderItems],
          documentDate: payload.documentDate,
          fulfillmentDate: payload.fulfillmentDate,
          validTillDate: payload.validTillDate,
          contactCode: payload.contactCode
        };
        if (!closeOnUpdate) {
          dispatch(
            updatePopulateFormData({
              id: props.draftData.id,
              formdata: formData,
              draftType: DraftTypes.UPDATE,
              actionFromDocument: true
            })
          );
        } else {
          dispatch(removeDraft(props.draftData.id));
          if (payload.showCustomAlert) {
            showAlert(
              'Job Work Out Created!',
              `Job Work Out has been created successfully. ${
                props.populateFormData?.isCreateFromMRP
                  ? 'Please save current WO to link the created JWO.'
                  : ''
              }`
            );
            dispatch(updateRefreshCallback(new Date()));
            return;
          }
          if (!isUpdate) {
            getDocumentAlert(
              'Job Work Out created!',
              'Job Work Out has been created successfully.',
              formData,
              props.draftData,
              DOCUMENT_MODE.EDIT,
              PAGE_ROUTES.MRP_WORK_OUT
            );
          }
        }
      },
      (err) => {
        console.error('Error loading updated doc: ', err);
      }
    );
  };

  const filterSameSerialBatch = (advanceTrackMeta: any, exisitingMeta: any) => {
    exisitingMeta.forEach((meta: any, existingIndex: any) => {
      let serialBatchToFind = meta.serialBatchNumber;
      advanceTrackMeta?.forEach((adMeta: any, index: any) => {
        if (adMeta.serialBatchNumber === serialBatchToFind) {
          exisitingMeta[existingIndex].reservedQuantity +=
            adMeta.reservedQuantity;
          advanceTrackMeta.splice(index, 1);
        }
      });
    });
    if (advanceTrackMeta == null) {
      advanceTrackMeta = [];
    }
    return [...advanceTrackMeta, ...exisitingMeta];
  };

  const isWhRBBSame = (obj1: any, obj2: any) => {
    if (obj1 && obj2) {
      if (
        obj1.productCode === obj2.productCode &&
        obj1.warehouseCode === obj2.warehouseCode &&
        obj1.rowCode == obj2.rowCode &&
        obj1.rackCode == obj2.rackCode &&
        obj1.binCode == obj2.binCode
      ) {
        return true;
      } else return false;
    }
    return false;
  };

  const updatePayloadForReserveStock = (payload: any) => {
    payload.jobWorkOutOrderItems.forEach((item: any, index: any) => {
      if (item?.jwoData?.length) {
        item.jwoData = item.jwoData.filter(
          (row: any) => row.reservedQuantity > 0
        );

        let previousReservedData = payload.items[index].reservedQuantitiesData;
        payload.jobWorkOutOrderItems[index].reservedQuantitiesData = [];
        if (previousReservedData?.length) {
          payload.jobWorkOutOrderItems[index].reservedQuantitiesData = [
            ...previousReservedData
          ];
        }

        item.jwoData.forEach((jwo: any) => {
          const componentCode = jwo.productCode;
          let exisitingMetaDtos =
            previousReservedData?.find(
              (row: any) => row.productCode === componentCode
            )?.advancedTrackingMetaDtos ?? [];

          if (exisitingMetaDtos.length) {
            exisitingMetaDtos = exisitingMetaDtos.map((data: any) => {
              return {
                ...data,
                advancedTrackingData: [data]
              };
            });
          }

          const prevReservedJWOItem = previousReservedData?.find(
            (prevReserv: any) => {
              return prevReserv?.productCode === jwo?.productCode;
            }
          );

          jwo.warehouseInventoryData?.forEach((row: any) => {
            if (jwo.advancedTracking === TRACKING_TYPE.NONE) {
              row = { ...row, reservedQuantity: row.quantity };
            }
            let alreadyExistingWithSameWhRRBIndex: any = -1;
            payload.jobWorkOutOrderItems[index].reservedQuantitiesData?.forEach(
              (reserveData: any, index: any) => {
                if (isWhRBBSame(reserveData, row)) {
                  alreadyExistingWithSameWhRRBIndex = index;
                }
              }
            );

            if (alreadyExistingWithSameWhRRBIndex === -1) {
              let obj = {
                productCode: jwo.productCode,
                warehouseCode:
                  row.warehouseCode ?? prevReservedJWOItem?.warehouseCode,
                //availableQuantity: jwo.availableQuantity,

                advancedTrackingType: jwo.advancedTracking,
                advancedTrackingMetaDtos:
                  jwo.advancedTracking === TRACKING_TYPE.NONE
                    ? []
                    : row.advancedTrackingData.map((data: any) => {
                        return { ...data, reservedQuantity: data.qtyToFulfil };
                      }),
                documentType: 'JOB_WORK_OUT_ORDER',
                rowCode: row?.row?.rowCode ?? row?.rowCode ?? null,
                rowName: null,
                rackCode: row?.row?.rackCode ?? row?.rackCode ?? null,
                rackName: null,
                binCode: row?.row?.binCode ?? row?.binCode ?? null,
                binName: null,
                reservedQuantity: row.reservedQuantity ?? row.quantity
              };

              payload.jobWorkOutOrderItems[index].reservedQuantitiesData.push(
                obj
              );
            } else {
              let obj: any =
                payload.jobWorkOutOrderItems[index].reservedQuantitiesData[
                  alreadyExistingWithSameWhRRBIndex
                ];
              obj = {
                ...obj,
                reservedQuantity: obj.reservedQuantity + row.reservedQuantity,
                advancedTrackingMetaDtos: filterSameSerialBatch(
                  obj.advancedTrackingMetaDtos,
                  row.advancedTrackingData?.map((data: any) => {
                    return { ...data, reservedQuantity: data.qtyToFulfil };
                  }) ?? []
                )
                //  [
                //   ...obj.advancedTrackingMetaDtos,
                //   ...row.advancedTrackingData.map((data: any) => {
                //     return { ...data, reservedQuantity: data.qtyToFulfil };
                //   })
                // ]
              };
              payload.jobWorkOutOrderItems[index].reservedQuantitiesData[
                alreadyExistingWithSameWhRRBIndex
              ] = obj;
            }
          });

          payload = { ...payload, reservedStock: true };

          delete payload.jobWorkOutOrderItems[index].jwoData;
        });
      }
    });
    return payload;
  };

  // Create Order
  const createJobWorkOut = async (closeDoc: boolean) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedWorkOut);

    payload = updatePayloadForReserveStock(payload);

    payload = updateAddressAsPerLocationCF(payload);
    delete payload?.items;

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (!isDocValid(payload)) {
      setButtonStatus(false);
      return;
    }

    let isApproval = await Utility.isApprovalRequired(payload);
    if (isApproval === null) {
      setButtonStatus(false);
      return;
    } else if (isApproval) {
      onSaveAsDraft(isApproval);
      return;
    } else {
      payload['approvalStatus'] = APPROVAL_STATUS['NOT_REQUIRED'];
    }

    WorkoutService.createWorkout(
      payload,
      Utility.getTenantSpecificApiCode(
        COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER
      )
    ).then(
      (response: any) => {
        if (props.draftData) {
          if (closeDoc) {
            // Remove draft popup
            dispatch(removeDraft(props.draftData.id));
          }
          if (props.draftData.draftType === DraftTypes.DRAFT) {
            dispatch(
              deleteDrafts({
                recordId: props.draftData.id,
                tableId: draftsTableId
              })
            );
          }
          setButtonStatus(false);
          updateForm(response, payload, false);
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.WORK_OUT
          })
        );
        dispatch(fetchWorkOuts());
        setButtonStatus(false);

        commonCustomEvent.dispatch(COMMON_EVENTS.RECORD_SAVED, {
          id: response?.jwoCode,
          type: RECORD_SAVED_EVENT_DOC_TYPE.JOB_WORK_OUT,
          linkedDocId: response?.linkedDocuments?.[0]?.documentCode,
          linkedDocType: response?.linkedDocuments?.[0]?.documentType,
          isEdit: false
        });
        if (payload?.updateResponseInStore) {
          let updatedRes: any = { ...response };

          dispatch(updatePOPRAssociation(updatedRes));
        }
      },
      (err) => {
        console.error('Error while creating job work out: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
      }
    );
  };

  const updateWorkOutDetails = (closeOnUpdate = true) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedWorkOut);
    payload = updatePayloadForReserveStock(payload);
    payload = { ...payload, contact: payload.contactDto };
    payload = updateAddressAsPerLocationCF(payload);
    delete payload?.items;
    delete payload?.contactDto;

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (!isDocValid(payload)) {
      setButtonStatus(false);
      return;
    }
    WorkoutService.updateWorkout(
      payload,
      Utility.getTenantSpecificApiCode(
        COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER
      )
    ).then(
      (res: any) => {
        if (props.draftData) {
          if (closeOnUpdate) {
            dispatch(removeDraft(props.draftData.id));
          }
          setButtonStatus(false);
          updateForm(res, payload, true, closeOnUpdate);
        }

        commonCustomEvent.dispatch(COMMON_EVENTS.RECORD_SAVED, {
          id: res?.jwoCode,
          type: RECORD_SAVED_EVENT_DOC_TYPE.JOB_WORK_OUT,
          linkedDocId: res?.linkedDocuments?.[0]?.documentCode,
          linkedDocType:
            res?.linkedDocuments?.[0]?.documentType ||
            RECORD_SAVED_EVENT_DOC_TYPE.JOB_WORK_OUT,
          isEdit: true
        });

        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.WORK_OUT
          })
        );
        dispatch(fetchWorkOuts());
        setButtonStatus(false);
        replaceURLToModuleURL();
      },
      (err) => {
        console.error('Error updating PO: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
        replaceURLToModuleURL();
      }
    );
  };

  const handleWorkOutUpdate = (doc: Document) => {
    let orderWithUpdate: WorkOut = {
      ...workOut,
      ...doc,
      jobWorkOutOrderItems: doc.items,
      orderDueDate: doc.validTillDate,
      dueDate: doc.validTillDate,
      receiveByDate: doc.fulfillmentDate,
      vendorCode: doc.contactCode,
      currencyCode: doc.currencyCode,
      currency: doc.currency,
      documentDate: doc.documentDate,
      totalAmount: doc.totalAmount,
      dropShipName: doc.dropShipName || ''
    };

    let editBillWithExtraKeys = {
      documentCode: '',
      documentType: DOC_TYPE.ORDER,
      currencyCode: doc.currencyCode,
      currency: doc.currency,
      entityId:
        props.documentMode === DOCUMENT_MODE.EDIT ? workOut?.id : undefined,
      jobWorkOutOrderItems: orderWithUpdate.jobWorkOutOrderItems?.map(
        (billDoc) => {
          return {
            ...billDoc,
            documentItemCode: billDoc.jobWorkOutOrderItemCode
          };
        }
      )
    };

    if (props.documentMode === DOCUMENT_MODE.EDIT) {
      orderWithUpdate = { ...orderWithUpdate, ...editBillWithExtraKeys };
    }
    setUpdatedWorkOut(orderWithUpdate);
  };

  const sendTriggerOnApproval = (payload: any) => {
    let emails = Utility.getApproverEmail(payload);
    let sum =
      payload &&
      payload.jobWorkOutOrderItems
        .map((item: any) => item.totalAmount)
        .reduce((prev: any, curr: any) => prev + curr, 0);
    let payloadObj = {
      contactCode: payload.contactCode,
      totalAmount: NumberFormatService.getNumber(sum),
      userName: AuthService.getUserName(),
      currency: Utility.getCurrencySymbolFromCode(payload.currency),
      approverMap: Object.fromEntries(emails),
      currentLevel: 1,
      approvalHistory: payload['multiApprovalDetails']?.approvalHistory || []
    };
    PurchaseOrderService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {},
      (err: any) => {
        console.error('Error while creating draft: ', err);
      }
    );
  };

  return (
    <NewDocument2
      permissionKeys={PERMISSIONS_BY_MODULE.JOB_WORKOUTS}
      booksDocument={workOut}
      documentMode={props.documentMode ? props.documentMode : DOCUMENT_MODE.NEW}
      draftData={props.draftData}
      draftType={props.draftData.draftType}
      canValidate={props.draftData.canValidate}
      onDocumentUpdate={(x: Document) => handleWorkOutUpdate(x)}
      isCenterAlign={isCenterAlign}
      updateDocWithoutClosing={() => updateWorkOutDetails(false)}
    />
  );
};

export default NewWorkOut;
