import http from '../Services/Interceptor';
import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';

export interface PIQAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  sort?: string;
  sortBy?: string;
  Query?: string;
  QueryParam?: any;
  fields?: string;
}

export const defaultConfig: PIQAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  sort: 'DESC',
  sortBy: '',
  Query: '',
  QueryParam: ''
};

class PIQService {
  static apiConfig: PIQAPIConfig;

  static getConfigUrl() {
    let urlString = `?fetchAttachmentDetails=true&limit=${
      typeof this.apiConfig.Limit === 'undefined' ||
      this.apiConfig.Limit === null
        ? 25
        : this.apiConfig.Limit
    }&search=${this.apiConfig?.SearchTerm || ''}&page=${
      this.apiConfig?.Page || 0
    }&sort=${this.apiConfig.sortBy ? this.apiConfig.sortBy : ''}&query=${
      this.apiConfig?.Query || ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}&sortDir=${
      this.apiConfig.sort ? this.apiConfig.sort : 'DESC'
    }`;
    return urlString;
  }

  static fetchAllPIQs() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let finalURL =
      ApiConstants.URL.PURCHASE_INWARD_QUOTATION.FETCH_ALL_PIQ +
      this.getConfigUrl();
    return http
      .get(finalURL)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static getPIQDetailByCode(code: any) {
    let finalURL =
      ApiConstants.URL.PURCHASE_INWARD_QUOTATION.GET_PIQ_DETAIL_BY_CODE(code);
    return http
      .get(finalURL)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
}

export default PIQService;
