import {
  DKIcon,
  TOAST_TYPE,
  removeLoader,
  showAlert,
  showLoader,
  showToast,
  DKIcons
} from 'deskera-ui-library';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ic_barcode_green from '../../Assets/Icons/ic_barcode_green.svg';
import ic_delete from '../../Assets/Icons/ic_delete.png';
import { GranularPermissionsHelper } from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import {
  BOOKS_DATE_FORMAT,
  COUNTRY_CODES,
  DOC_TYPE,
  POPUP_CALLBACKS_TYPE,
  TEMPLATE_CATEGORY,
  TRACKING_TYPE
} from '../../Constants/Constant';

import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import PrintPreview from '../Printing/PrintPreview';
import DateFormatService from '../../Services/DateFormat';
import { CallBackPayloadType } from '../../Models/Interfaces';
import { fetchSerialTrackingProducts } from '../../Redux/Slices/SerialTrackingSlice';
import SalesReturnService from '../../Services/SalesReturn';
import SalesReturnTable from './SalesReturnTable';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import Utility from '../../Utility/Utility';
import SalesReturnRecordDetailWithTable from './SalesReturnRecordDetailWithTable';
import { Address } from '../../Models/Address';
import { selectUOMs } from '../../Redux/Slices/CommonDataSlice';
import { selectWarehouse } from '../../Redux/Slices/WarehouseSlice';
import { localizedText } from '../../Services/Localization/Localization';
import NumberFormatService from '../../Services/NumberFormat';
import { getPrintPreview } from '../DocumentForm/NewDocumentHelper';

export interface SalesReturnsRecordProps {
  documentDetails: any;
  salesReturnsDetails: any;
  passingInteraction?: (callback: CallBackPayloadType) => void;
  documentType: DOC_TYPE;
  isDeleted: (val: boolean) => void;
  isClosed: (val: boolean) => void;
}

const SalesReturnRecords: React.FC<SalesReturnsRecordProps> = (props) => {
  const [fulfillmentDetails, setFulfillmentDetails] = useState<any>(
    props.salesReturnsDetails
  );
  const [fulfillmentData, setFulfillmentData] = useState<any>(
    fulfillmentDetails?.[0]
  );
  const [documentDetails, setDocumentDetails] = useState<any>(
    props.documentDetails
  );
  const [itemsDescriptionArray, setItemDescriptionArray] = useState<any[]>([]);
  const [salesReturnCode, setsalesReturnCode] = useState<any>(
    fulfillmentDetails?.[0].salesReturnCode
  );
  const [documentUOMSchemaDefinition, setDocumentUOMSchemaDefinition] =
    useState<any>(fulfillmentDetails?.[0]?.documentUOMSchemaDefinition);

  const [res, setRes] = useState<any>({});

  const tenantInfo = useAppSelector(activeTenantInfo);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isVisibleState, setIsVisibleState] = useState<boolean>(false);
  const [showPrintPreview, setShowPrintPreview] = useState<boolean>(false);
  const [isSerail, setIsSerial] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    let itemsArray =
      documentDetails?.salesInvoiceItems ||
      documentDetails?.quotationItemDtoList;
    setItemDescriptionArray(itemsArray);
    if (tenantInfo.country === COUNTRY_CODES.IN) setIsVisibleState(true);
  }, [itemsDescriptionArray]);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.FULFILLMENT_RECORDS,
        data: () => {
          closePopup();
        }
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  const closePopup = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
  };

  const getReturnedQuantity = (salesReturnItems = []) => {
    return salesReturnItems.reduce(
      (a, b) =>
        (a +=
          b['documentUOMSchemaDefinition'] &&
          b['advancedTrackingType'] !== TRACKING_TYPE.SERIAL
            ? Utility.getUomQuantity(
                b['quantityToReturn'],
                b['documentUOMSchemaDefinition']
              )
            : b['quantityToReturn']),
      0
    );
  };

  const getSalesReturnDetails = (salesReturnCode: any) => {
    let fulfillmentData = fulfillmentDetails.find(
      (item: any) => item.salesReturnCode === salesReturnCode
    );
    setsalesReturnCode(fulfillmentData.salesReturnCode);
    setDocumentUOMSchemaDefinition(fulfillmentData.documentUOMSchemaDefinition);
    setFulfillmentData(fulfillmentData);
  };

  const deleteSalesReturn = (fulfillmentData: any, deleteAll = false) => {
    let payload = {
      salesReturnCode: fulfillmentData.salesReturnCode
    };
    deleteInvoiceReturn(payload);
  };

  const deleteInvoiceReturn = (payload: any) => {
    showLoader();
    SalesReturnService.deleteSalesReturn(payload.salesReturnCode)
      .then((res: any) => {
        props.isDeleted(true);
        updateFulfillmentRecords(payload);
        showToast(
          'Sales Returns record deleted successfully',
          TOAST_TYPE.SUCCESS
        );
        removeLoader();
        if (fulfillmentDetails.length === 1) {
          props.isClosed(true);
        }
      })
      .catch((error: any) => {
        props.isDeleted(false);
        if (error && error['status'] === 500) {
          showAlert(
            'Error!',
            'Sales return can not be deleted,product quantity will go in negative'
          );
        } else {
          if (error && error['data'] && error['data']['errorMessage']) {
            showAlert('Error!', error['data']['errorMessage']);
          } else {
            showAlert('Error!', 'Unable to delete sales returns record.');
          }
        }
        removeLoader();
      });
  };

  const updateFulfillmentRecords = (payload: any) => {
    let salesReturnDetailsData = [...fulfillmentDetails];
    let fulfillmentData = salesReturnDetailsData.find((item: any) => {
      const itemCode = item.parentsalesReturnCode
        ? item.parentsalesReturnCode
        : item.salesReturnCode;
      return itemCode === payload.salesReturnCode;
    });

    const indexRecord = salesReturnDetailsData.indexOf(fulfillmentData);
    fulfillmentData.salesReturnItems.forEach((element: any) => {
      if (
        element.advancedTrackingType === TRACKING_TYPE.BATCH ||
        element.advancedTrackingType === TRACKING_TYPE.SERIAL
      ) {
        dispatch(
          fetchSerialTrackingProducts({
            productCode: element?.productCode,
            enableQCWarehouse: false
          })
        );
      }
    });
    if (indexRecord > -1) {
      salesReturnDetailsData.splice(indexRecord, 1);
      if (salesReturnDetailsData && salesReturnDetailsData.length > 0) {
        setFulfillmentDetails((prevState: any[]) => salesReturnDetailsData);
        setsalesReturnCode(salesReturnDetailsData[0]?.salesReturnCode);
        getSalesReturnDetails(salesReturnDetailsData[0]?.salesReturnCode);
      } else {
        closePopup();
      }
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setShowPopup(false);
        break;
    }
  };


  const setSalesReturnForPrint = (salesReturn: any) => {
    getPrintPreview(DOC_TYPE.SALES_RETURN, {
      ...salesReturn,
      contactCode: '',
      documentCode: salesReturn.salesReturnCode,
      documentType: DOC_TYPE.SALES_RETURN
    });
  };

  return (
    <div className="flex justify-content-between p-2">
      {showPrintPreview && (
        <PrintPreview
          documentType={TEMPLATE_CATEGORY.FULFILLMENT}
          document={{ ...documentDetails, documentCode: salesReturnCode }}
          closePreview={(val: boolean) => setShowPrintPreview(val)}
        />
      )}
      <div
        className="flex"
        style={{
          width: '30%',
          minHeight: '250px',
          borderRight: '1px solid rgba(0, 0, 0, 0.12'
        }}
      >
        <ul className="flex flex-col w-full">
          {fulfillmentDetails &&
            fulfillmentDetails.map((item: any, i: any) => {
              return (
                <li
                  className={
                    'flex width-90 cursor-hand p-2 flex-col hover:bg-blue-100 rounded mb-2' +
                    (salesReturnCode === item.salesReturnCode
                      ? ' bg-blue-100 '
                      : '')
                  }
                  onClick={() => getSalesReturnDetails(item.salesReturnCode)}
                >
                  <div className="text-black fs-r ml-0 flex justify-content-between">
                    <span className="pr-2  font-medium">
                      {item.salesReturnCode}
                    </span>
                    {GranularPermissionsHelper.hasPermissionFor(
                      PERMISSIONS_BY_MODULE.PURCHASE_SELL_RETURN.DELETE
                    ) && (
                      <div className="flex">
                        <span className="align-items-end pt-1 pr-1">
                          <DKIcon
                            src={DKIcons.ic_printer}
                            className="ic-s cursor-hand"
                            title="Delete"
                            onClick={() => {
                              setSalesReturnForPrint(item);
                            }}
                          />
                        </span>
                        <span className="align-items-end pt-1">
                          <DKIcon
                            src={ic_delete}
                            className="ic-s cursor-hand"
                            title="Delete"
                            onClick={() => {
                              const docDate = DateFormatService.getDateFromStr(
                                item?.salesReturnDate,
                                BOOKS_DATE_FORMAT['DD-MM-YYYY']
                              );
                              if (
                                !Utility.checkActiveDateRangeValidation(
                                  docDate,
                                  tenantInfo,
                                  'Sales Return date',
                                  DOC_TYPE.SALES_RETURN
                                )
                              ) {
                                return;
                              }
                              let buttons: any[] = [];
                              if (item.isBulkFulfillment) {
                                buttons = [
                                  {
                                    title: 'No',
                                    className: 'bg-gray2 border-m ',
                                    onClick: () => {}
                                  },
                                  {
                                    title: 'Delete',
                                    className: 'bg-red text-white ml-r',
                                    onClick: () => {
                                      deleteSalesReturn(item, false);
                                    }
                                  },
                                  {
                                    title: 'Delete All',
                                    className: 'bg-red text-white ml-r',
                                    onClick: () => {
                                      deleteSalesReturn(item, true);
                                    }
                                  }
                                ];

                                showAlert(
                                  'Are you sure you want to delete this Sales Return?',
                                  'Note: Any issued Credit Notes or Direct Expenses need to be deleted separately',
                                  buttons
                                );
                              } else {
                                buttons = [
                                  {
                                    title: 'No',
                                    className: 'bg-gray2 border-m ',
                                    onClick: () => {}
                                  },
                                  {
                                    title: 'Delete',
                                    className: 'bg-red text-white ml-r',
                                    onClick: () => {
                                      deleteSalesReturn(item, false);
                                    }
                                  }
                                ];

                                showAlert(
                                  'Are you sure you want to delete this Sales Return?',
                                  'Note: Any issued Credit Notes or Direct Expenses need to be deleted separately',
                                  buttons
                                );
                              }
                            }}
                          />
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="flex justify-content-between mt-1">
                    <span className="text-gray mr-1">
                      {DateFormatService.getFormattedDateString(
                        item.salesReturnDate,
                        BOOKS_DATE_FORMAT['DD-MM-YYYY']
                      )}
                    </span>
                    <span className="align-items-end text-gray text-align-right">
                      {getReturnedQuantity(item.salesReturnItems)} {} Item(s)
                      returned
                    </span>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      <div
        className="flex align-self-start p-4"
        style={{
          width: '80%'
        }}
      >
        <SalesReturnRecordDetailWithTable
          documentDetails={props.documentDetails}
          salesReturnsDetails={props.salesReturnsDetails.filter(
            (salesReturn: any) =>
              salesReturn.salesReturnCode === salesReturnCode
          )}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          isDeleted={(val: boolean) => {}}
          isClosed={(val: boolean) => {}}
          documentType={DOC_TYPE.INVOICE}
        />
      </div>
      {showPopup && (
        <SalesReturnTable
          populateFormData={res}
          isSerial={isSerail}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};

export default SalesReturnRecords;
