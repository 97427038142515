import React, { useEffect, useState, useRef } from 'react';
import {
  DKLabel,
  DKIcon,
  DKIcons,
  DKCalendar,
  DKListPicker2,
  DKDataGrid,
  DKButton,
  INPUT_TYPE,
  showToast,
  TOAST_TYPE,
  showAlert,
  showLoader,
  removeLoader,
  DKInput,
  DKCheckMark,
  DKTooltipWrapper
} from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  BUY_TYPE,
  CLASS_ASSIGNMENT,
  COUNTRY_CODES,
  CURRENCY_PRECISION,
  CUSTOM_FIELD_TYPE,
  CUSTOM_NUMBER_INPUT_MODULES,
  DOCUMENT_MODE,
  DOC_PATH_WITH_ID_REGEX,
  DOC_TYPE,
  gstTreatmentWithoutGSTIN,
  LOCATION_CLASS_ENUM,
  MODULES_NAME,
  NUMBER_30,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  RECEIVED_GOODS_STATUS,
  STATUS_TYPE,
  TAX_SYSTEM,
  INPUT_VIEW_DIRECTION,
  GST_TYPE,
  APPLY_TO_TYPE,
  ITC_OPTIONS,
  PricingColumnKeys,
  PAYMENT_STATUS,
  APPROVAL_STATUS,
  ROUNDING_METHOD,
  CURRENCY_HISTORY_CONSTANTS,
  DATE_FORMAT,
  LABELS
} from '../../Constants/Constant';
import {
  expenseBillGridItem,
  ExpenseBillPayload,
  ExpenseBillProps,
  EXPENSE_BILL_CALENDAR_TYPE,
  initialExpenseBillState,
  InvoiceAccount
} from '../../Models/ExpenseBill';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import ContactService, { ContactAPIConfig } from '../../Services/Contact';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone,
  getCapitalized
} from '../../Utility/Utility';
import {
  BooksAddress,
  BtnType,
  CallBackPayloadType,
  LocationDTO,
  PopupClickActionType,
  ReactSelectOptionsType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import FormatAmount from '../../SharedComponents/FormatAmount';
import { Store as ReduxStore, Store } from '../../Redux/Store';
import BillService from '../../Services/Bill';
import {
  fetchBills,
  selectBillsColumnConfig,
  selectBillsColumnConfigTableId
} from '../../Redux/Slices/BillsSlice';
import CustomNumberFormatInput from '../../SharedComponents/CustomNumberFormat/CustomNumberFormatInput';
import DateFormatService from '../../Services/DateFormat';
import {
  deleteDrafts,
  draftTableId,
  draftTypeColumnId,
  fetchDrafts,
  isSaveColumnId,
  removeDraft,
  setDraftActionAvailibility,
  setDraftValidationDisplayStatus,
  updatePopulateFormData
} from '../../Redux/Slices/DraftsSlice';
import {
  DOCS_SUPPORTING_FULLSCREEN,
  calculateTaxGroup,
  convertToCurrenctExchangeRate,
  customFieldsContainsErrors,
  getAccountsAndCFForBudgetValidation,
  getFormattedAddressObj,
  getNewCFItem,
  getNewColumnForCF,
  getTaxDetail,
  getTenantTaxSystem,
  getValidTillDateFromDocDate,
  isGSTExchangeRateRequired,
  roundingOffStr,
  updateColumnConfigOnRowClick,
  updateRowDataWithParentCFValues,
  validateBudgetForAccounts,
  rcmAppliedIndia,
  renderItcIneligibleSection,
  getPrimaryCurrencyCheck,
  checkIfLineLevelCustomFieldIsValid,
  rcmAppliedIndiaWithCheckRCMApply
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import { useTranslation } from 'react-i18next';
import { BillingAddressType, ShippingAddressType } from '../../Models/Document';
import {
  fetchContacts,
  selectContacts
} from '../../Redux/Slices/ContactsSlice';
import { DocumentConfigManager } from '../../Managers/DocumentConfigManger';
import { DocumentItem } from '../../Models/DocumentItem';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import PopupWrapper from '../../SharedComponents/PopupWrapper';
import AddContact from '../Contacts/AddContact';
import {
  fetchClassesByDimensionId,
  fetchCurrencyExchangeRateByDocDate,
  fetchTaxes,
  selectCurrencyListWithExchangeRate,
  selectCurrencyListWithExchangeRateByDocDate,
  selectCustomFields,
  selectPurchaseTax,
  selectShowMainDocsInFullScreen
} from '../../Redux/Slices/CommonDataSlice';
import AddTax from '../Settings/Tax/AddTax';
import AttachmentService from '../../Services/Attachment';
import { triggerDownload } from '../ImportExport/utility/ExportData';
import {
  fetchDefaultAccounts,
  selectDefaultAccounts,
  selectedAccounts
} from '../../Redux/Slices/AccountsSlice';
import { DEFAULT_TDS_PAYABLE_ACCOUNT_CODE } from '../../Models/TDS';
import TDSCalculation from '../../SharedComponents/DocumentForm/TDSCalculation';
import { CustomFieldsHolder } from '../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import NumberFormatService from '../../Services/NumberFormat';
import { addDays } from 'date-fns';
import AddCoaPopup from '../Accounting/COA/AddCoaPopup';
import { ConfigUtility } from '../../Utility/ConfigUtility';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import LocationService from '../../Services/Location';
import {
  classesDimensionId,
  fetchCategoryDimensions,
  locationsData,
  selectDimensions
} from '../../Redux/Slices/LocationSlice';
import { DraftTypes } from '../../Models/Drafts';
import DocumentActionMenu from '../../SharedComponents/DocumentForm/DocumentActionMenu';
import AddClass from '../Settings/Classes/AddClass';
import {
  COMPLIANCE_SPECIFIC_FIELD_NAME,
  CONTACT_FORM_TAB
} from '../../Constants/Enum';
import {
  GranularPermissionsHelper,
  checkUserPermission
} from '../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { isViewportLarge } from '../../Utility/ViewportSizeUtils';
import AppManager from '../../Managers/AppManager';
import { useHistory } from 'react-router-dom';
import CustomFieldSettings from '../../SharedComponents/CustomFieldsHolder/CustomFieldSettings';
import CustomFieldService from '../../Services/CustomField';
import useScreenResize from '../../Hooks/useScreenResize';
import { ExpenseDepositHelper } from '../Bank/ExpenseDeposit/ExpenseDepositHelper';
import ItcOptions from '../../SharedComponents/DocumentForm/ItcOptions';

import TaxValueEditForm from '../../SharedComponents/DocumentForm/TaxValueEditForm';
import { DEFAULT_ACCOUNT_MODULE } from '../../Models/Common';
import DefaultAccountsSettingsPopup from '../Accounting/COA/DefaultAccountsSettingsPopup';
import ReCheckButton from '../../SharedComponents/ReCheckButton';
import { COUNTRIES_WITH_CURRENCIES } from '../PriceBook/CountriesWithCurrencies';
import CurrencyService, {
  RealtimeCurrencyAPIConfig
} from '../../Services/Currency';
import { selectPaymentTerms } from '../../Redux/Slices/PaymentTermsSlice';

const refInitialState: UpdateCallBacksRefType = {
  pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
  storeCallbacksRef: { updateContact: 'click' }
};

const NewExpenseBill: React.FC<ExpenseBillProps> = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const defaultAccounts = useAppSelector(selectDefaultAccounts);
  const paymentTerms = useAppSelector(selectPaymentTerms);
  const [expenseBillState, setExpenseBillState] = useState<any>(
    deepClone(initialExpenseBillState(tenantInfo))
  );
  const [contact, setContact] = useState<any>(null);
  const [billingAddress, setBillingAddress] =
    useState<BillingAddressType | null>(null);
  const [shippingAddress, setShippingAddress] =
    useState<ShippingAddressType | null>(null);

  const [shipFromAddress, setShipFromAddress] = useState<any>(null);
  const [openContactSelector, setOpenContactSelector] = useState(false);
  type ReactSelectOptionType = ReactSelectOptionsType<string, string>;
  const [columnConfig, setColumnConfig] = useState(
    DocumentConfigManager.get(DOC_TYPE.EXPENSE_BILL)
  );
  const [productRows, setProductRows] = useState<DocumentItem[]>([]);
  const [lastUpdatedColumn, setLastUpdatedColumn] = useState<any>(null);
  const [lastUpdatedIndex, setLastUpdatedIndex] = useState<any>(null);
  const [showAddContactPopup, setShowAddContactPopup] = useState(false);
  const [showAccountPopup, setShowAccountPopup] = useState(false);
  const [showTaxPopup, setShowTaxPopup] = useState(false);
  const [attachments, setAttachments] = useState<any>([]);
  const [isTDSDeducted, setIsTDSDeducted] = useState(false);
  const [openShipFromForBuy, setOpenShipFromForBuy] = useState(false);
  const [activeContactTab, setActiveContactTab] = useState<CONTACT_FORM_TAB>(
    CONTACT_FORM_TAB.GENERAL_INFO
  );
  const [currentRowTDSInfo, setCurrentRowTDSInfo] = useState<any>(null);
  const [showTDSCalculationPopup, setShowTDSCalculationPopup] = useState(false);
  const [contactMode, setContactMode] = useState(DOCUMENT_MODE.NEW);
  const [showPaymentGroupDetails, setShowPaymentGroupDetails] = useState(false);
  const [tempExchangeRate, setTempExchangeRate] = useState(1);
  const [showAddClassPopup, setShowAddClassPopup] = useState(false);
  const [updatedSGTax, setUpdatedSGTax] = useState<any>([]);
  const [showDefaultAccountSettings, setShowDefaultAccountsSettings] =
    useState(false);
  const [doesDefaultRCMAccountExists, setDoesDefaultRCMAccountExists] =
    useState(false);
  const reduxStore = ReduxStore;
  const tenantDetails = reduxStore.getState().authInfo.currentTenantInfo.data;
  const taxList = useAppSelector(selectPurchaseTax);
  const dispatch = useAppDispatch();
  const draftsTableId = useAppSelector(draftTableId);
  const isSavedColumnId = useAppSelector(isSaveColumnId);
  const draftTypeColId = useAppSelector(draftTypeColumnId);
  const contactsData = useAppSelector(selectContacts);
  const accountsData = useAppSelector(selectedAccounts);
  const billsColumnConfig = useAppSelector(selectBillsColumnConfig);
  const billsColumnConfigTableId = useAppSelector(
    selectBillsColumnConfigTableId
  );
  const dimensionData = useAppSelector(selectDimensions);
  const availableCustomFields = useAppSelector(selectCustomFields);
  const [customFieldsData, setCustomFieldsData] = useState<any[]>([]);
  //refs
  const addContactRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const addCoaRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const addTaxRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const tdsAmountRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const skipTDS = useRef<boolean>(false);
  const currentDate = new Date();
  const [documentDate, setDocumentDate] = useState<Date>(currentDate);
  const [isdocumentDateChanged, setIsdocumentDateChanged] =
    useState<Boolean>(false);
  const [showMultiCurrencyList, setShowMultiCurrencyList] = useState(false);
  const activeMultiCurrencyList = useAppSelector(
    selectCurrencyListWithExchangeRateByDocDate
  );

  const [isInitislStateSet, setIsInitislStateSet] = useState(false);
  const [customLocation, setCustomLocation] = useState<LocationDTO>();
  const locationData = useAppSelector(locationsData);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const [openShipFrom, setOpenShipFrom] = useState(false);
  const showMainDocsInFullScreen = useAppSelector(
    selectShowMainDocsInFullScreen
  );
  const isDocumentInFullScreen =
    showMainDocsInFullScreen &&
    DOCS_SUPPORTING_FULLSCREEN.includes(DOC_TYPE.BILL);

  const [openAccountCFSettings, setOpenAccountCFSettings] = useState(false);
  const [accountsCFData, setAccountsCFData] = useState<any[]>([]);
  const cfUpdatedTimeMap = useRef<any>({});
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const [gridWidth] = useScreenResize(gridContainerRef);
  const [gstType, setGstType] = useState<any>(GST_TYPE.INTRA);
  const [unitPriceGstInclusive, setUnitPriceGstInclusive] = useState(
    props?.populateFormData?.unitPriceGstInclusive
      ? props?.populateFormData?.unitPriceGstInclusive
      : false
  );
  const [primaryCurrencyHistory, setPrimaryCurrencyHistory] = useState<any[]>(
    []
  );
  const [currencyHistory, setCurrencyHistory] = useState<any[]>([]);
  const [sourceOfSupply, setSourceOfSupply] = useState('');
  const [destinationOfSupply, setDestinationOfSupply] = useState('');
  const [showTaxDetails, setshowTaxDetails] = useState(false);
  const [currentRowTaxInfo, setCurrentRowTaxInfo] = useState<any>(null);
  const [showTaxRowPopup, setShowTaxRowPopup] = useState(false);
  const [currentRowITCInfo, setCurrentRowITCInfo] = useState<any>(null);
  const [showITCPopup, setShowITCPopup] = useState(false);
  const [tempPrimaryExchangeRate, setTempPrimaryExchangeRate] = useState(
    expenseBillState?.primaryExchangeRate
  );
  const [checkifCustomFieldCanEdit, setCheckifCustomFieldCanEdit] =
    useState(true);

  const [roundOffValue, setRoundOffValue] = useState(
    DOCUMENT_MODE.NEW === props?.documentMode
      ? 0
      : props?.populateFormData?.roundOffAmountInDocumentCurrency
  );
  const recalculateRounding = useRef<boolean>(true);
  const isManualRoundOff = useRef<boolean>(false);

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  useEffect(() => {
    const defaultAccountCl = deepClone(defaultAccounts?.content || []);
    const doesRCmAccountEXists = defaultAccountCl.find(
      (defaults) => defaults.module === DEFAULT_ACCOUNT_MODULE.GL_FOR_ITC_BLOCK
    );
    if (
      !Utility.isEmpty(doesRCmAccountEXists?.accountCode) &&
      doesRCmAccountEXists?.accountCode !== 'NA'
    ) {
      setDoesDefaultRCMAccountExists(true);
    } else {
      setDoesDefaultRCMAccountExists(false);
    }
  }, [defaultAccounts]);

  useEffect(() => {
    if (!taxList?.length) {
      dispatch(fetchTaxes());
    }

    if (!contactsData?.content) {
      dispatch(fetchContacts());
    }

    if (getPrimaryCurrencyCheck()) {
      const primaryCurrencyCode =
        tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
      showLoader();
      getCurrencyHistoryViaCode(primaryCurrencyCode)
        .then((currencyHistory) => {
          setPrimaryCurrencyHistory(currencyHistory);
          updatePrimaryExchangeRate(
            currencyHistory,
            documentDate ?? currentDate
          );
        })
        .finally(() => {
          removeLoader();
        });
    } else if(props?.documentMode === DOCUMENT_MODE.NEW) {
      const currencyCode =
        props.populateFormData?.currency ?? tenantInfo?.currency;
      showLoader();
      getCurrencyHistoryViaCode(currencyCode)
        .then(async (currencyHistory) => {
          setCurrencyHistory(currencyHistory);
          let docDateFilteredCurr: any =
            await getExchangeRateDetailsFromHistory(
              currencyHistory,
              documentDate ?? currentDate,
              currencyCode
            );
          if (
            typeof docDateFilteredCurr !== 'undefined' &&
            docDateFilteredCurr !== null
          ) {
            updateCurrencyAndExchangeRate(
              docDateFilteredCurr.currencyCode,
              docDateFilteredCurr.currencyExchangeRate
            );
          }
        })
        .finally(() => {
          removeLoader();
        });
    }
    if (!Utility.isEmpty(accountsData['content'])) {
      setInitialState();
    } else {
      showLoader('Please wait! loading accounts...');
    }

    if (!accountsCFData.length) {
      getAccountsCFData();
    }

    return () => {
      // cleanup
    };
  }, []);
  useEffect(() => {
    if (!isInitislStateSet && !Utility.isEmpty(accountsData['content'])) {
      setInitialState();
      removeLoader();
    }
  }, [accountsData, props.populateFormData]);

  useEffect(() => {
    if (isInitislStateSet && !Utility.isEmpty(accountsData['content'])) {
      setInitialState();
    }
  }, [props.populateFormData]);

  useEffect(() => {
    if (isdocumentDateChanged === true) {
      setIsdocumentDateChanged(false);
      updateDocOnDateChange();
    }
  }, [documentDate]);

  const updateDocOnDateChange = async () => {
    if (getPrimaryCurrencyCheck()) {
      await updatePrimaryExchangeRate(primaryCurrencyHistory, documentDate);
    }
    let currencyCode = expenseBillState?.docCurrencyCode;
    let docDateFilteredCurr = await getExchangeRateDetailsFromHistory(
      currencyHistory,
      documentDate,
      currencyCode
    );
    if (
      typeof docDateFilteredCurr !== 'undefined' &&
      docDateFilteredCurr !== null
    ) {
      updateCurrencyAndExchangeRate(
        docDateFilteredCurr.currencyCode,
        docDateFilteredCurr.currencyExchangeRate
      );
    }
  };

  const getExchangeRateDetailsFromHistory = async (
    currencyHistory: any[],
    date: any,
    currencyCode: string
  ) => {
    if (
      Utility.isEmpty(currencyHistory) ||
      date === null ||
      date === undefined ||
      Utility.isEmpty(currencyCode)
    ) {
      return null;
    }

    let docDateFilteredCurr: any = currencyHistory.find((curr: any) => {
      if (
        Utility.isValidDate(curr?.exchangeRateDate) &&
        Utility.isValidDate(date) &&
        Utility.compareDateWithoutTime(
          new Date(curr?.exchangeRateDate),
          date
        ) <= 0
      ) {
        return curr.currencyCode === currencyCode;
      }
      return false;
    });
    const filteredCurr = currencyHistory
      .slice()
      .reverse()
      .find((curr: any) => {
        return curr.currencyCode === currencyCode;
      });
    if (docDateFilteredCurr === undefined || docDateFilteredCurr === null) {
      try {
        const realtimeExchangeRateData =
          await getRealTimeCurrencyHistoryViaCode(currencyCode);
        if (Utility.isNotEmpty(realtimeExchangeRateData)) {
          docDateFilteredCurr = realtimeExchangeRateData?.[0];
        } else {
          docDateFilteredCurr = filteredCurr;
        }
      } catch (err) {
        docDateFilteredCurr = filteredCurr;
      }
    }
    return docDateFilteredCurr;
  };

  useEffect(() => {
    if (currentRowTaxInfo) {
      setShowTaxRowPopup(true);
    }
  }, [currentRowTaxInfo]);

  const setInitialState = async () => {
    setIsInitislStateSet(true);
    let updatedState = expenseBillState;
    let taxListOptions: ReactSelectOptionType[] = [];
    let activeAccountListOptions: ReactSelectOptionType[] = [];
    const accountList: any[] = accountsData['content'];
    const activeAccountList = accountList?.filter(
      (account) => account.status === STATUS_TYPE.ACTIVE
    );
    updatedState.docCurrencyCode = tenantDetails.currency;

    taxList?.forEach((tax) => {
      let option: ReactSelectOptionType = {
        label: tax.name,
        value: tax.code
      };
      taxListOptions.push(option);
    });

    activeAccountList?.forEach((account) => {
      let option: ReactSelectOptionType = {
        label: account.name,
        value: account.code
      };
      activeAccountListOptions.push(option);
    });

    updatedState.dueDate.date = DateFormatService.getDateStrFromDate(
      addDays(new Date(), NUMBER_30),
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );

    updatedState.billDate.date = DateFormatService.getDateStrFromDate(
      new Date(),
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );

    const expenseBill: any = { ...props.populateFormData };

    if (
      expenseBill?.paymentStatus !== undefined &&
      expenseBill?.paymentStatus !== null
    ) {
      setCheckifCustomFieldCanEdit(
        !(expenseBill.paymentStatus !== PAYMENT_STATUS.PENDING)
      );
    }

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      let isRCMApplicable =
        expenseBill?.applyRcmCheck !== null &&
        expenseBill?.applyRcmCheck !== undefined &&
        expenseBill?.applyRcmCheck;
      updatedState.applyRcmCheck = isRCMApplicable;
    }

    updatedState.customField = expenseBill.customField
      ? expenseBill.customField
      : [];

    if (
      props.draftData.draftType === DraftTypes.DRAFT &&
      props.populateFormData?.attachments?.length
    ) {
      setAttachments([...props.populateFormData.attachments]);
    } else {
      // attachment API call
      AttachmentService.attachmentConfig = {
        ...AttachmentService.attachmentConfig,
        Module: 'INVOICE',
        EntityId: expenseBill.id
      };
      AttachmentService.getAllAttachments().then(
        (attachmentList: any) => {
          setAttachments(attachmentList);
        },
        (err) => console.error('Loading attachments failed: ', err)
      );
    }

    // Populate state variable
    if (!Utility.isEmpty(expenseBill)) {
      if (expenseBill.contactDto) {
        updatedState.contact = expenseBill.contactDto;
      } else {
        updatedState.contact = expenseBill.contact;
      }
      let contactName = expenseBill?.contactDto?.name;
      if (
        (props?.documentMode === DOCUMENT_MODE.VIEW ||
          props?.documentMode === DOCUMENT_MODE.EDIT) &&
        Utility.isNotEmpty(expenseBill?.contact?.name)
      ) {
        contactName = expenseBill?.contact?.name;
      }
      updatedState.contact = {
        ...updatedState.contact,
        name: contactName ?? updatedState.contact?.name
      };
      if (expenseBill.journalEntryCode) {
        updatedState.journalEntryCode = expenseBill.journalEntryCode;
      }

      if (!Utility.isEmpty(expenseBill.shipFrom)) {
        updatedState.shipFrom = expenseBill.shipFrom;
      }

      if (
        (props.documentMode === DOCUMENT_MODE.NEW ||
          props.documentMode === DOCUMENT_MODE.COPY) &&
        props.draftData.draftType === DraftTypes.DRAFT &&
        expenseBill.contact?.id
      ) {
        setDetailedContact(expenseBill.contact.id);
      } else {
        setContact(
          expenseBill.contactDto
            ? { ...expenseBill.contactDto, name: contactName }
            : { ...expenseBill.contact, name: contactName }
        );
      }

      if (expenseBill.documentDate) {
        updatedState.billDate.date = expenseBill.documentDate;
      }

      if (expenseBill.purchaseInvoiceDueDate) {
        updatedState.dueDate.date = expenseBill.purchaseInvoiceDueDate;
      }

      updatedState.supplierInvoiceNo = expenseBill.supplierInvoiceNo
        ? expenseBill.supplierInvoiceNo
        : '';

      if (expenseBill.documentSequenceCode) {
        const documentSequenceCode: string = expenseBill.documentSequenceCode;
        const sequenceFormat: string = expenseBill.sequenceFormat;

        updatedState.documentSequenceCode = documentSequenceCode;
        updatedState.sequenceFormat = sequenceFormat;
      }

      if (expenseBill.purchaseInvoiceAccounts?.length > 0) {
        const inlineGridItemsList: any[] = [];
        const inlineGridList = expenseBill.purchaseInvoiceAccounts;
        let totalTaxAmt = 0;
        let subTotal = 0;
        let amountInBaseCurrency = 0;
        inlineGridList.forEach((item: any, index: number) => {
          const initialGridItem = deepClone(expenseBillGridItem);
          if (item.accountCode) {
            const acc = accountList.find(
              (account) => account.code === item.accountCode
            );
            initialGridItem.account = acc ? acc : null;
          }

          if (item.accountDescription) {
            initialGridItem.description = item.accountDescription;
          }

          if (item.amount) {
            initialGridItem.amount = item.amount;
            initialGridItem.baseAmount = Utility.roundingOff(
              Number(Number(item.amount) / Number(expenseBill.exchangeRate)),
              tenantInfo.decimalScale
            );
            amountInBaseCurrency += Number(initialGridItem.baseAmount);
            subTotal += item.amount;
          }

          if (item.totalAmount) {
            initialGridItem.totalAmount = item.totalAmount;
          }

          if (item.taxCode) {
            const taxOption = taxList.find((tax) => tax.code === item.taxCode);
            initialGridItem.tax = taxOption ? taxOption : null;
            if (taxOption) {
              initialGridItem['taxDetails'] = updateTaxDetails({
                ...initialGridItem
              });
            }
          }

          if (!Utility.isEmpty(item.tdsInfoIndia)) {
            initialGridItem.tdsInfoIndia = item.tdsInfoIndia;
            initialGridItem.tdsAccount = item.tdsInfoIndia.payableAccount;
            initialGridItem.tdsRate = item.tdsInfoIndia.tdsRate;
            initialGridItem.tdsAmount = item.tdsInfoIndia.tdsAmount;
            initialGridItem.isTdsApplicableContact =
              item.isTdsApplicableContact;
            initialGridItem.isTdsApplicableAccount =
              item.isTdsApplicableAccount;
            initialGridItem.isTdsApplicableProduct =
              item.isTdsApplicableProduct;
          }

          initialGridItem.taxAmount = item.taxAmount;
          totalTaxAmt += item.taxAmount;

          initialGridItem.itcIneligibleType = item?.itcIneligibleType;

          if (item.customField && item.customField.length) {
            initialGridItem.customField = [...item.customField];
          }

          initialGridItem['userSetTaxes'] = item?.userSetTaxes || false;

          initialGridItem.id = item?.id;

          inlineGridItemsList.push(initialGridItem);
          // newCalculations(item, index, 'tax');
        });

        updatedState.gridInfo.inlineItems = inlineGridItemsList;
        updatedState.summaryInfo.subTotal = Number(subTotal);
        updatedState.summaryInfo.taxAmount = totalTaxAmt;
        updatedState.summaryInfo.totalTdsAmount = !isNaN(
          expenseBill.totalTdsAmount
        )
          ? expenseBill.totalTdsAmount
          : 0;
        updatedState.summaryInfo.totalAmount =
          subTotal +
          totalTaxAmt -
          (expenseBill.totalTdsAmount ? expenseBill.totalTdsAmount : 0);
        updatedState.memo = expenseBill.memo;
        updatedState.amountInBaseCurrency = amountInBaseCurrency;
        setProductRows(inlineGridItemsList);
      }
      updatedState.docCurrencyCode =
        expenseBill.currency || expenseBill.currencyCode;
      updatedState.docExchangeRate = expenseBill.exchangeRate;
      setTempExchangeRate(
        +roundingOffStr(
          expenseBill.exchangeRate ? 1 / expenseBill.exchangeRate : 0,
          CURRENCY_PRECISION
        )
      );
      updatedState.gstExchangeRate = expenseBill.gstExchangeRate;
      updatedState.knockoffInfo = expenseBill.knockoffInfo;
      updatedState.seqCodeAlreadyDumped =
        expenseBill?.seqCodeAlreadyDumped || false;
      // calculation(updatedState);
    } else {
      setTempExchangeRate(+roundingOffStr(1, CURRENCY_PRECISION));
    }
    updatedState.summaryInfo.currency = tenantDetails.currency;
    setExpenseBillState({ ...updatedState });
    updateConfig();
  };

  useEffect(() => {
    updateConfig();
  }, [contactsData, taxList, productRows, dimensionData]);

  useEffect(() => {
    updateDocOnContactUpdate();
  }, [contact]);

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    if (Utility.isNotEmpty(expenseBillState.gridInfo.inlineItems)) {
      expenseBillState.gridInfo.inlineItems.forEach((line: any, index: any) => {
        recalculateRounding.current = true;
        newCalculations(line, index);
      });
      updateConfig();
    }
  }, [
    gstType,
    sourceOfSupply,
    destinationOfSupply,
    tempExchangeRate,
    expenseBillState?.applyRcmCheck
  ]);

  useEffect(() => {
    updateGstTypeAndSupplyRenderer();
  }, []);

  useEffect(() => {
    updateGstTypeAndSupplyRenderer();
    return () => {};
  }, [contact, tenantInfo]);

  const updateDocOnContactUpdate = async () => {
    if (!Utility.isEmpty(contact)) {
      let billingAddress: BooksAddress | undefined;
      let shippingAddress: BooksAddress | undefined;
      let shipFromAddress: BooksAddress | undefined;

      billingAddress = tenantInfo.billingAddresses?.filter(
        (address: any) => address.preferred
      )[0];

      if (
        Utility.isEmpty(billingAddress) &&
        tenantInfo.billingAddresses?.length
      ) {
        billingAddress = tenantInfo.billingAddresses[0];
      }

      shippingAddress = tenantInfo.shippingAddresses?.filter(
        (address: any) => address.preferred
      )[0];

      if (
        Utility.isEmpty(shippingAddress) &&
        tenantInfo.shippingAddresses?.length
      ) {
        shippingAddress = tenantInfo.shippingAddresses[0];
      }

      shipFromAddress = contact.shippingAddress?.filter(
        (address: any) => address.preferred
      )[0];

      if (shipFromAddress) {
        setShipFromAddress(shipFromAddress);
      }

      if (typeof billingAddress !== 'undefined') {
        setBillingAddress({
          billingAddress: contact.billingAddress,
          currentBillingAddress: billingAddress
        });
      }
      if (shippingAddress) {
        setShippingAddress({
          shippingAddress: contact.shippingAddress,
          currentShippingAddress: shippingAddress
        });
      }
      if (getPrimaryCurrencyCheck()) {
        const primaryCurrencyCode =
          tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
        try {
          showLoader();
          let currencyHistory = await getCurrencyHistoryViaCode(
            primaryCurrencyCode
          );
          setPrimaryCurrencyHistory(currencyHistory);
          updatePrimaryExchangeRate(currencyHistory, documentDate);
        } finally {
          removeLoader();
        }
      } else if (
        props?.documentMode === DOCUMENT_MODE.NEW ||
        props?.documentMode === DOCUMENT_MODE.COPY
      ) {
        const currencyCode =
          props.populateFormData?.currency ?? contact?.currencyCode;
        try {
          showLoader();
          let currencyHistory = await getCurrencyHistoryViaCode(currencyCode);
          setCurrencyHistory(currencyHistory);
          let docDateFilteredCurr: any =
            await getExchangeRateDetailsFromHistory(
              currencyHistory,
              documentDate,
              currencyCode
            );
          if (
            typeof docDateFilteredCurr !== 'undefined' &&
            docDateFilteredCurr !== null
          ) {
            updateCurrencyAndExchangeRate(
              docDateFilteredCurr.currencyCode,
              docDateFilteredCurr.currencyExchangeRate
            );
          }
        } finally {
          removeLoader();
        }
      }
    }
  };

  const updateGstTypeAndSupplyRenderer = () => {
    let getGstType: any;
    if (Utility.isEmpty(tenantInfo)) return;
    if (Utility.isEmpty(contact)) return;

    getGstType = ExpenseDepositHelper.getCustomerGstType(
      tenantInfo,
      getPreferredShippingState(),
      contact.customerType,
      contact.gstTreatment
    );

    setGstType(getGstType);

    if (!Utility.isEmpty(expenseBillState?.gridInfo?.inlineItems)) {
      const lineItems = expenseBillState?.gridInfo?.inlineItems.map(
        (item: any) => {
          return {
            ...item,
            gstType: getGstType
          };
        }
      );

      let copyExpenseBill = expenseBillState;
      copyExpenseBill.gridInfo.inlineItems = lineItems;
      setExpenseBillState(copyExpenseBill);
    }

    contact?.shippingAddress?.forEach((data: any) => {
      if (data?.preferred) {
        setSourceOfSupply(data?.state);
      }
    });
    tenantInfo?.shippingAddresses?.forEach((data: any) => {
      if (data?.preferred) {
        setDestinationOfSupply(data?.state);
      }
    });
  };

  const updatePrimaryExchangeRate = async (
    primaryCurrencyHistory: any[],
    documentDate: Date
  ) => {
    if (
      documentDate === undefined ||
      documentDate === null ||
      !getPrimaryCurrencyCheck()
    ) {
      return;
    }
    const primaryCurrencyCode =
      tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
    if (Utility.isNotEmpty(primaryCurrencyCode)) {
      let docDatePrimaryFilteredCurr: any = primaryCurrencyHistory.find(
        (curr: any) => {
          if (
            Utility.isValidDate(curr?.exchangeRateDate) &&
            Utility.isValidDate(documentDate) &&
            Utility.compareDateWithoutTime(
              new Date(curr?.exchangeRateDate),
              documentDate
            ) <= 0
          ) {
            return curr.currencyCode === primaryCurrencyCode;
          }
          return false;
        }
      );
      const primaryFilteredCurr = primaryCurrencyHistory
        .slice()
        .reverse()
        .find((curr: any) => {
          return curr.currencyCode === primaryCurrencyCode;
        });
      if (
        docDatePrimaryFilteredCurr === undefined ||
        docDatePrimaryFilteredCurr === null
      ) {
        const realtimeExchangeRateData =
          await getRealTimeCurrencyHistoryViaCode(primaryCurrencyCode);
        if (Utility.isNotEmpty(realtimeExchangeRateData)) {
          docDatePrimaryFilteredCurr = realtimeExchangeRateData?.[0];
        } else {
          docDatePrimaryFilteredCurr = primaryFilteredCurr;
        }
      }
      let primaryExchangeRate =
        docDatePrimaryFilteredCurr?.currencyExchangeRate ??
        tempPrimaryExchangeRate;
      if (primaryExchangeRate !== undefined && primaryExchangeRate !== null) {
        expenseBillState.primaryExchangeRate = primaryExchangeRate;
        onPrimaryCurrencyChange(primaryExchangeRate);
        setTempPrimaryExchangeRate(+primaryExchangeRate);
      }
    }
  };
  const updateTaxBifurcation = (expenseBillLine: any) => {
    let edited: any = { ...expenseBillLine };
    edited = calculateTaxBifurcation(
      edited,
      edited?.gstType ? edited?.gstType : gstType
    );
    return edited;
  };

  const getRealTimeCurrencyHistoryViaCode = async (code: string) => {
    if (
      Utility.isNotEmpty(code) &&
      Utility.isNotEmpty(tenantInfo?.currency) &&
      code !== tenantInfo?.currency &&
      documentDate !== null &&
      documentDate !== undefined
    ) {
      let payload: RealtimeCurrencyAPIConfig = {
        startDate: Utility.formatDate(documentDate, DATE_FORMAT.DD_MM_YYYY),
        endDate: Utility.formatDate(documentDate, DATE_FORMAT.DD_MM_YYYY),
        baseCurrency: tenantInfo.currency,
        primaryCurrency: code
      };
      showLoader();
      try {
        const response = await CurrencyService.getRealtimeCurrencyHistory(
          payload
        );
        removeLoader();
        return Utility.mapToCurrencyHistoryList(response) ?? [];
      } catch {
        removeLoader();
        return [];
      }
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!Utility.isEmpty(expenseBillState?.gridInfo?.inlineItems)) {
      expenseBillState?.gridInfo?.inlineItems.map((item: any, index: any) => {
        recalculateRounding.current = true;
        newCalculations(item, index);
      });
    }
  }, [unitPriceGstInclusive]);

  const calculateTaxGroupDetails = (element: any) => {
    let tmpTaxGroupDetail: any = [];
    let taxPercent: any = element?.tax?.percent || 0;
    let paymentAmount: any = element?.amount || 0;
    let totalTaxAmtPre: any = 0;
    let totalTaxAmtAfter: any = 0;
    let sumTaxRatesPre: any = 0;
    let preTaxComponents: any[] = [];
    let afterTaxComponents: any[] = [];
    let preTaxComponentsCount: any = 0;
    let adjustTaxAmountInPreTaxLastComponent: boolean = false;
    let adjustTaxAmountInAfterTaxLastComponent: boolean = false;
    let tmpTaxList: any = [];
    if (unitPriceGstInclusive) {
      paymentAmount = Utility.roundingOff(
        (Number(element?.amount) * 100) /
          Number(100 + Number(element?.tax?.percent)),
        CURRENCY_PRECISION
      );
    }

    preTaxComponents = element?.tax?.taxGroupDetails?.filter(
      (ele: any) => ele.applyTo === APPLY_TO_TYPE.PRE
    );
    afterTaxComponents = element?.tax?.taxGroupDetails?.filter(
      (ele: any) =>
        ele.applyTo === APPLY_TO_TYPE.AFTER ||
        ele.applyTo === APPLY_TO_TYPE.POST
    );

    if (Utility.isEmpty(afterTaxComponents) && unitPriceGstInclusive) {
      adjustTaxAmountInPreTaxLastComponent = true;
    } else if (unitPriceGstInclusive) {
      adjustTaxAmountInAfterTaxLastComponent = true;
    }

    preTaxComponents?.forEach((pretax: any) => {
      sumTaxRatesPre += pretax.percentage;
      let tempTaxAmtPre = 0;
      if (
        adjustTaxAmountInPreTaxLastComponent &&
        preTaxComponentsCount === preTaxComponents.length - 1
      ) {
        const totalTaxAmount = Utility.roundingOff(
          element.amount - paymentAmount,
          CURRENCY_PRECISION
        );

        tempTaxAmtPre = Utility.roundingOff(
          totalTaxAmount - totalTaxAmtPre,
          CURRENCY_PRECISION
        );
        let taxobj = {
          [pretax.name]: tempTaxAmtPre
        };
        tmpTaxGroupDetail.push(taxobj);
        let taxDetail = getTaxDetail(pretax, paymentAmount);
        taxDetail.taxAmount = tempTaxAmtPre;
        tmpTaxList.push(taxDetail);
      } else {
        let taxAmount = paymentAmount * (pretax.percentage / 100);
        if (tenantInfo?.country === COUNTRY_CODES.UK) {
          let editedTax = element?.taxList?.find(
            (taxListElement: any) => taxListElement?.taxSeqCode === pretax?.code
          );
          if (editedTax) {
            taxAmount = editedTax?.taxAmount ?? 0;
          }
        }
        tempTaxAmtPre = Utility.roundingOff(taxAmount, CURRENCY_PRECISION);
        let taxobj = {
          [pretax.name]: taxAmount
        };
        tmpTaxGroupDetail.push(taxobj);
        let taxDetail = getTaxDetail(pretax, paymentAmount);
        taxDetail.taxAmount = taxAmount;
        if (taxDetail) {
          tmpTaxList.push(taxDetail);
        }
      }
      totalTaxAmtPre = totalTaxAmtPre + tempTaxAmtPre;
      preTaxComponentsCount = preTaxComponentsCount + 1;
    });

    let tempTaxAmtAfter = 0;
    if (adjustTaxAmountInAfterTaxLastComponent) {
      const totalTaxAmount = Utility.roundingOff(
        element.amount - paymentAmount,
        CURRENCY_PRECISION
      );

      tempTaxAmtAfter = Utility.roundingOff(
        totalTaxAmount - totalTaxAmtPre,
        CURRENCY_PRECISION
      );
      let taxDetail = getTaxDetail(afterTaxComponents[0], tempTaxAmtAfter);
      tmpTaxList.push(taxDetail);
    } else {
      if (afterTaxComponents?.[0]) {
        tempTaxAmtAfter = Utility.roundingOff(
          (paymentAmount * afterTaxComponents?.[0]?.percentage) / 100,
          CURRENCY_PRECISION
        );
        tempTaxAmtAfter = Utility.roundingOff(
          tempTaxAmtAfter * (1 + sumTaxRatesPre / 100),
          CURRENCY_PRECISION
        );
        let taxDetail: any =
          afterTaxComponents?.[0] &&
          getTaxDetail(afterTaxComponents?.[0], tempTaxAmtAfter);
        if (tenantInfo.country === COUNTRY_CODES.UK) {
          let updatedTaxDetail = element?.taxList?.find(
            (taxListElement: any) =>
              taxListElement?.taxSeqCode === afterTaxComponents?.[0]?.code
          );
          if (updatedTaxDetail) {
            taxDetail = { ...taxDetail, taxAmount: updatedTaxDetail.taxAmount };
          }
        }
        let taxobjAfter = {
          [taxDetail.taxName]: taxDetail?.taxAmount
        };
        totalTaxAmtPre = totalTaxAmtPre + taxDetail?.taxAmount;
        tmpTaxGroupDetail.push(taxobjAfter);
        if (taxDetail) {
          tmpTaxList.push(taxDetail);
        }
      }
    }
    totalTaxAmtAfter = Utility.roundingOff(
      totalTaxAmtAfter + tempTaxAmtAfter,
      CURRENCY_PRECISION
    );

    let returnObj: any = {};
    if (element?.tax?.isTaxGroup) {
      returnObj['taxgrpDetail'] = tmpTaxGroupDetail;
      returnObj['taxAmount'] = Utility.roundingOff(
        totalTaxAmtPre,
        CURRENCY_PRECISION
      );
      returnObj['lineLevelTotal'] = Utility.roundingOff(
        totalTaxAmtPre + paymentAmount,
        CURRENCY_PRECISION
      );
      returnObj['taxList'] = tmpTaxList;
    } else {
      let taxAmount = Utility.roundingOff(
        paymentAmount * (taxPercent / 100),
        CURRENCY_PRECISION
      );

      returnObj['lineLevelTotal'] = unitPriceGstInclusive
        ? element.amount
        : element.amount + taxAmount;
      returnObj['taxAmount'] = Utility.roundingOff(
        taxAmount,
        CURRENCY_PRECISION
      );
      let taxDetail = element?.tax && getTaxDetail(element?.tax, paymentAmount);
      if (taxDetail) {
        tmpTaxList.push(taxDetail);
      }
      returnObj['taxList'] = tmpTaxList;
    }
    let lineGstType = element?.gstType ? element?.gstType : gstType;
    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      lineGstType === GST_TYPE.EXEMPT
    ) {
      returnObj['lineLevelTotal'] = element.amount;
      returnObj['taxAmount'] = 0;
    }

    return returnObj;
  };

  const calculateTaxBifurcation = (record: any, getGstType: any) => {
    let edited = { ...record };
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      switch (getGstType) {
        case GST_TYPE.INTER:
          if (record?.selectedTaxCode?.additionalTaxIn) {
            edited.igstRate = 0.0;
            edited.taxBifurcation = [
              { IGST: 0.0 },
              { [record.selectedTaxCode.name]: edited.taxAmount }
            ];
          } else {
            edited.igstRate = edited.taxAmount;
            edited.taxBifurcation = [{ IGST: edited.taxAmount }];
          }
          break;
        case GST_TYPE.INTRA:
          if (record?.selectedTaxCode?.additionalTaxIn) {
            edited.cgstRate = 0.0;
            edited.sgstRate = 0.0;
            edited.taxBifurcation = [
              { CGST: edited.cgstRate },
              { SGST: edited.sgstRate },
              { [record.selectedTaxCode.name]: edited.taxAmount }
            ];
          } else {
            const devideGstVal = edited.taxAmount / 2;
            edited.cgstRate = devideGstVal;
            edited.sgstRate = devideGstVal;
            edited.taxBifurcation = [
              { CGST: devideGstVal },
              { SGST: devideGstVal }
            ];
          }
          break;
        case GST_TYPE.EXEMPT:
          edited.taxBifurcation = [{ EXEMPT: '' }];
          break;
      }
    } else {
      edited.taxBifurcation = [{ '': edited.taxAmount }];
    }
    return edited;
  };

  const getPreferredShippingState = () => {
    let filtered = contact?.shippingAddress?.filter(
      (add: any) => add.preferred
    );
    if (!Utility.isEmpty(filtered)) {
      return filtered[0]?.state;
    }
  };

  const taxInclusiveCheckbox = (
    <div className="flex flex-row items-end justify-end w-full mb-l">
      <DKCheckMark
        color="bg-button"
        isSelected={unitPriceGstInclusive}
        onClick={() => {
          if (checkifCustomFieldCanEdit) {
            setUnitPriceGstInclusive(!unitPriceGstInclusive);
          }
        }}
        className="text-black z-index-1"
        title={t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.UNIT_PRICE_IS_TAX_INCLUSIVE`)}
      />
    </div>
  );

  const showTaxHeader = () => {
    const country = tenantInfo?.country;
    switch (country) {
      case COUNTRY_CODES.IN:
        return Utility.isComplianceEnabled();
      case COUNTRY_CODES.US:
        return false;
      default:
        return true;
    }
  };

  const getClassDimensionData = () => {
    if (dimensionData) {
      const classData = dimensionData?.content?.find(
        (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
      );
      const classFields: any[] = classData?.attributes?.map((attr: any) => {
        return {
          id: classData.id,
          shortName: classData.shortName,
          module: classData.module,
          code: classData.code,
          label: classData.label,
          value: attr.value
        };
      });
      return classFields;
    }
  };

  const hideClassColumn = () => {
    let hideClassCol = false;
    const classSettings = tenantInfo.additionalSettings?.CLASS;
    if (
      !classSettings?.trackClasses ||
      classSettings?.assignClasses === CLASS_ASSIGNMENT.TRANSACTION
    ) {
      hideClassCol = true;
    }
    return hideClassCol;
  };

  const updateLineItemForSGTaxCalculation = () => {
    if (getTenantTaxSystem() !== TAX_SYSTEM.SG) {
      return;
    }

    let items = expenseBillState.gridInfo?.inlineItems?.map((item: any) => {
      let copyItem = { ...item, tax: getUpdatedSGTax(item.tax) };
      let taxDetails = updateTaxDetails(copyItem);
      copyItem.taxDetails = taxDetails;
      if (taxDetails?.length) {
        copyItem.taxAmount = taxDetails[0].taxAmount;
        copyItem.totalAmount =
          taxDetails[0].taxableAmount + taxDetails[0].taxAmount;
      } else {
        copyItem.taxAmount = 0;
      }
      return copyItem;
    });
    setProductRows([...items]);
    reCalculateAmounts({
      ...expenseBillState,
      gridInfo: { ...expenseBillState.gridInfo, inlineItems: items }
    });
  };

  const getUpdatedSGTax = (tax: any) => {
    if (tax === undefined) return tax;
    let taxList = getUpdatedSGTaxList();
    if (getTenantTaxSystem() === TAX_SYSTEM.SG) {
      if (taxList.length > 0) {
        let newTax = taxList.filter((item: any) => {
          if (
            item?.description?.replace(/[0-9]/g, '') ===
            tax?.description?.replace(/[0-9]/g, '')
          ) {
            return item;
          }
        });
        tax = newTax[0];
      }
    }
    return tax;
  };

  const getRCMTootltipContent = (data: any) => {
    let contentString: string = '<div>';
    data.map((item: any) => {
      return Object.entries(item).map(([key, value]) => {
        contentString += `<p>${key} - `;
        contentString += `${NumberFormatService.getNumber(
          value ? (value as number) : 0
        )}</p>`;
      });
    });
    contentString += '</div>';
    return contentString;
  };
  const updateConfig = () => {
    let config = columnConfig;

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'account':
          let newData = Utility.getAccountsStructured(accountsData?.content);
          ConfigUtility.fillStructuredAccountArray(newData);
          let newStructuredData = ConfigUtility.structuredAccountsArr;
          conf.dropdownConfig.data = newStructuredData;
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return ConfigUtility.getAccountRow(obj);
          };
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            const query = 'status=active';
            return DocumentConfigManager.getAccountURL(search, query);
          };
          //Commenting code for showing user sub accounts on flat level (Require research on how to show structured data for search)
          // conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
          //   return Utility.getAccountsData(response?.content);
          // };
          if (
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.COA.CREATE
            )
          ) {
            conf.dropdownConfig.button.onClick = () => {
              setShowAccountPopup(true);
            };
          }

          if (
            !GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.COA.CREATE
            )
          ) {
            if (conf.dropdownConfig) {
              conf.dropdownConfig.button = null;
            }
          }
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftData.draftType !== DraftTypes.READONLY &&
            checkifCustomFieldCanEdit;
          break;
        case 'description':
          conf.width = !showTaxHeader() ? 390 : conf.width;
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftData.draftType !== DraftTypes.READONLY &&
            checkifCustomFieldCanEdit;
          break;
        case 'amount':
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftData.draftType !== DraftTypes.READONLY &&
            checkifCustomFieldCanEdit;
          conf.formatter = (data: any) => {
            return DocumentConfigUtility.amountFormatter(
              data.value,
              expenseBillState.docCurrencyCode
            );
          };
          break;
        case 'tax':
          let taxOptions =
            tenantInfo.country === COUNTRY_CODES.SG
              ? getUpdatedSGTaxList()
              : taxList;
          conf.hidden = !showTaxHeader();
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftData.draftType !== DraftTypes.READONLY &&
            checkifCustomFieldCanEdit;
          conf.dropdownConfig.data = taxOptions?.length ? taxOptions : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            DocumentConfigManager.getTaxURL(
              search,
              DateFormatService.getFormattedDateString(
                expenseBillState.billDate.date,
                BOOKS_DATE_FORMAT['DD-MM-YYYY'],
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              )
            );
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
            DocumentConfigUtility.taxDataParser(data, DOC_TYPE.EXPENSE_BILL);
          // conf.dropdownConfig.button.onClick = () => {
          //   setShowTaxPopup(true);
          // };
          if (
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.BILL.EDIT_TAX
            )
          ) {
            conf.dropdownConfig.button.onClick = () => {
              setShowTaxPopup(true);
            };
          }

          if (
            !GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.BILL.EDIT_TAX
            )
          ) {
            if (conf.dropdownConfig) {
              conf.dropdownConfig.button = null;
            }
          }
          break;
        case 'taxAmount':
          conf.renderer = (data: any) => {
            if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
              let records = data?.rowData?.taxBifurcation || [];
              let itcIneligibleType: string = data?.rowData?.itcIneligibleType;
              if (data?.rowData?.isRcmApplied) {
                return (
                  <div
                    style={{ width: '100%' }}
                    className="column m-s z-index-3"
                  >
                    <div className="row  justify-content-between">
                      <div className="column">
                        <DKLabel className="text-align-left" text={'NA'} />
                        <DKLabel
                          className="text-align-left text-gray fw-r fs-s"
                          text={`RCM ${
                            data?.rowData?.tax?.percent
                              ? data?.rowData?.tax?.percent
                              : 0
                          }%`}
                        />
                      </div>
                      {!Utility.isEmpty(records) && (
                        <DKTooltipWrapper
                          content={getRCMTootltipContent(records)}
                          tooltipClassName="bg-deskera-secondary"
                        >
                          <DKIcon
                            src={DKIcons.ic_info}
                            className="ic-xs mr-l ic-r opacity-60 cursor-pointer"
                          />
                        </DKTooltipWrapper>
                      )}
                    </div>
                    {renderItcIneligibleSection(itcIneligibleType)}
                  </div>
                );
              } else {
                return (
                  <div className="column">
                    {records.map((item: any) => {
                      return Object.entries(item).map(([key, value]) => {
                        return (
                          <div className="row gap-2 justify-content-start">
                            <DKLabel className="text-align-left" text={key} />
                            <DKLabel
                              className="text-align-right"
                              text={NumberFormatService.getNumber(
                                value ? (value as number) : 0
                              )}
                            />
                          </div>
                        );
                      });
                    })}
                    {renderItcIneligibleSection(itcIneligibleType)}
                  </div>
                );
              }
            } else {
              return (
                <DKLabel
                  className="parent-width text-align-right"
                  text={`${Utility.getCurrencySymbolFromCode(
                    tenantInfo?.currency ? tenantInfo?.currency : ''
                  )} ${NumberFormatService.getNumber(
                    data?.rowData.taxAmount ? data?.rowData.taxAmount : 0
                  )}`}
                />
              );
            }
          };
          break;
        case 'totalAmount':
          conf.formatter = (data: any) =>
            DocumentConfigUtility.amountFormatter(
              data.value,
              expenseBillState.docCurrencyCode
            );
          break;
        default:
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftData.draftType !== DraftTypes.READONLY;
          const classData = dimensionData?.content?.find(
            (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
          );
          if (classData && classData.id === conf.id) {
            conf.hidden = hideClassColumn();
            conf.dropdownConfig.button = {
              title: '+ Add New',
              className: 'bg-button text-white',
              onClick: () => setShowAddClassPopup(true)
            };
          }
          if (conf.type === INPUT_TYPE.DROPDOWN) {
            conf.dropdownConfig.allowSearch =
              conf.dropdownConfig?.data?.length > 5;
            conf.dropdownConfig.searchableKey = 'value';
          }
          break;
      }

      if (!checkUserPermission(PERMISSIONS_BY_MODULE.BILL.VIEW_PRICE)) {
        if (PricingColumnKeys.includes(conf.key)) {
          conf.hidden = true;
        }
      }
    });
    setColumnConfig(config.filter((col: any) => !col.hidden));
  };

  const getUpdatedSGTaxList = () => {
    return taxList.filter((taxItem: any) => {
      if (
        taxItem.effectiveEndDate !== undefined &&
        taxItem.effectiveEndDate !== null
      ) {
        if (
          DateFormatService.getDateFromStr(
            expenseBillState.billDate.date,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ) >=
            DateFormatService.getDateFromStr(
              taxItem.effectiveStartDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            ) &&
          DateFormatService.getDateFromStr(
            expenseBillState.billDate.date,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ) <=
            DateFormatService.getDateFromStr(
              taxItem.effectiveEndDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
        ) {
          return taxItem;
        }
      } else {
        if (
          DateFormatService.getDateFromStr(
            expenseBillState.billDate.date,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ) >=
          DateFormatService.getDateFromStr(
            taxItem.effectiveStartDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        ) {
          return taxItem;
        }
      }
    });
  };

  const getCustomFields = () => {
    const docCFs =
      props.documentMode !== DOCUMENT_MODE.NEW ||
      props.draftData.draftType !== DraftTypes.NEW
        ? props.populateFormData?.customField
        : expenseBillState.customField;

    return (
      <div
        className={`${docCFs?.length ? 'mb-r' : ''} ${
          isDocumentInFullScreen ? 'mt-r mb-l' : ''
        }`}
        style={{ marginLeft: docCFs?.length ? 0 : -12 }}
      >
        <CustomFieldsHolder
          moduleName={MODULES_NAME.BILL}
          customFieldsList={docCFs}
          columnConfig={billsColumnConfig}
          columnConfigTableId={billsColumnConfigTableId as string}
          documentMode={props.documentMode}
          draftType={props.draftData.draftType}
          contact={contact}
          onUpdate={async (updatedCFList) => {
            // Handle location CF, when CF list is updated
            const listContainsLocation = updatedCFList.find(
              (cf: any) => cf.label === LOCATION_CLASS_ENUM.LOCATION
            );
            if (listContainsLocation && listContainsLocation?.value) {
              const locationObjForCF = locationData.find(
                (loc: any) => loc.label === listContainsLocation.value
              );
              if (locationObjForCF) {
                setCustomLocation(locationObjForCF);
              } else if (
                props.documentMode === DOCUMENT_MODE.EDIT ||
                props.documentMode === DOCUMENT_MODE.VIEW
              ) {
                // Handle edit mode when location CF is deleted ...
                // Get the address from payload
                const locationCFinDocument =
                  expenseBillState?.customField?.find(
                    (x: any) => x.label === LOCATION_CLASS_ENUM.LOCATION
                  );

                if (locationCFinDocument) {
                  if (locationCFinDocument.value === '') {
                    setCustomLocation(undefined);
                  } else {
                    try {
                      const locationObj =
                        await LocationService.getLocationByLabel(
                          locationCFinDocument.value
                        );
                      if (!Utility.isEmpty(locationObj)) {
                        setCustomLocation(locationObj);
                      }
                    } catch (err) {}
                  }
                }
              }
            }

            setExpenseBillState((prevState: any) => {
              return {
                ...prevState,
                customField: [...updatedCFList]
              };
            });
          }}
          onLocationUpdate={(loc: LocationDTO) => {
            setCustomLocation(loc);
          }}
        />
      </div>
    );
  };

  const onRowClick = ({ columnData, rowData, rowIndex }: any) => {
    setLastUpdatedIndex(rowIndex);
    const availableCFs: any[] = accountsCFData;
    updateColumnConfigOnRowClick(
      columnData,
      rowData,
      columnConfig,
      availableCFs,
      cfUpdatedTimeMap.current
    );
  };

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.CLOSE_DRAFT_POPUP,
      data: () => {
        replaceURLToModuleURL();
      }
    });

    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.SAVE_AS_DRAFT,
      data: () => {
        onSaveAsDraft();
      }
    });

    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.CREATE_EXPENSE_BILL,
      data: (closeDoc: boolean) => {
        setValidationDisplayStatus(true);
        createExpenseBill(closeDoc);
      }
    });

    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.UPDATE_BILL,
      data: () => {
        setValidationDisplayStatus(true);
        updateExpenseBill();
      }
    });
  };

  const onSaveAsDraft = () => {
    const payload = makePayload();
    if (
      typeof payload.documentSequenceCode !== 'undefined' &&
      payload.documentSequenceCode !== null &&
      payload.documentSequenceCode !== ''
    ) {
      delete payload.documentSequenceCode;
    }
    payload['dueAmount'] = 0;
    payload['approvalStatus'] = APPROVAL_STATUS['NOT_REQUIRED'];
    setButtonStatus(true);
    props.formData(payload);
  };

  const showCalendar = (calendarType: EXPENSE_BILL_CALENDAR_TYPE) => {
    const newState = expenseBillState;
    switch (calendarType) {
      case EXPENSE_BILL_CALENDAR_TYPE.DUE_DATE:
        newState.dueDate.isOpen = true;
        break;
      case EXPENSE_BILL_CALENDAR_TYPE.BILL_DATE:
        newState.billDate.isOpen = true;
        break;
      default:
        break;
    }
    setExpenseBillState({ ...newState });
  };

  const hideCalendar = () => {
    const newState = expenseBillState;
    newState.dueDate.isOpen = false;
    newState.billDate.isOpen = false;
    setExpenseBillState({ ...newState });
  };

  const dateSelected = (
    calendarType: EXPENSE_BILL_CALENDAR_TYPE,
    date: Date
  ) => {
    const newState = expenseBillState;
    switch (calendarType) {
      case EXPENSE_BILL_CALENDAR_TYPE.BILL_DATE:
        newState.billDate = {
          isOpen: false,
          date: DateFormatService.getDateStrFromDate(
            date,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
        // Update due date here
        let updatedValidTillDate = addDays(date, NUMBER_30);
        if (!Utility.isEmpty(newState.contact)) {
          updatedValidTillDate = getValidTillDateFromDocDate(
            date,
            newState.contact
          );
        }

        newState.dueDate.date = updatedValidTillDate
          ? DateFormatService.getDateStrFromDate(
              updatedValidTillDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
          : newState.dueDate.date;
        setDocumentDate(date);
        setIsdocumentDateChanged(true);
        break;
      case EXPENSE_BILL_CALENDAR_TYPE.DUE_DATE:
        newState.dueDate = {
          isOpen: false,
          date: DateFormatService.getDateStrFromDate(
            date,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
        break;
      default:
        break;
    }
    setExpenseBillState((prevState: any) => ({ ...prevState, ...newState }));
  };

  const setDetailedContact = async (id: number) => {
    const detailedContact = await ContactService.getContactDetailsById(id);
    setContact({
      ...detailedContact,
      name: contact?.name ?? detailedContact?.name
    });
    setExpenseBillState((prevState: any) => ({
      ...prevState,
      contact: {
        ...detailedContact,
        name: contact?.name ?? detailedContact?.name
      }
    }));
  };
  const isDplChecked = () => {
    if (
      !dimensionData ||
      !contact ||
      Utility.isEmpty(props?.draftData?.draftType) ||
      Utility.isEmpty(props?.documentMode) ||
      props?.documentMode === DOCUMENT_MODE.VIEW ||
      props?.draftData?.draftType === DraftTypes.READONLY
    )
      return false;
    const DplCustomFieldId = dimensionData?.content?.filter(
      (customField: any) =>
        customField?.system &&
        customField?.shortName === 'denied_parties_custom_field'
    )?.[0]?.id;
    const dplContactCustomField = contact?.customField?.filter(
      (cField: any) => cField?.id == DplCustomFieldId
    );
    if (!dplContactCustomField || dplContactCustomField?.length == 0)
      return false;
    return true;
  };
  const checkForDpl = (contact: any) => {
    const DplCustomFieldId = dimensionData?.content?.filter(
      (customField: any) =>
        customField?.system &&
        customField?.shortName === 'denied_parties_custom_field'
    )?.[0]?.id;
    const dplContactCustomField = contact?.customField?.filter(
      (cField: any) => cField?.id == DplCustomFieldId
    )?.[0];
    if (!dplContactCustomField) return;
    if (dplContactCustomField?.value === 'Yes') {
      showToast(
        'The contact you are using is under denied party list',
        TOAST_TYPE.SUCCESS
      );
    }
  };

  const onContactChange = async (data: any) => {
    const updatedState = expenseBillState;
    let contactName = data?.contact?.name;
    if (
      (props?.documentMode === DOCUMENT_MODE.VIEW ||
        props?.documentMode === DOCUMENT_MODE.EDIT) &&
      Utility.isNotEmpty(updatedState?.contact?.name)
    ) {
      contactName = updatedState?.contact?.name;
    }
    data = { ...data, name: contactName ?? data?.name };
    updatedState.contact = data;
    updatedState.isDocumentTouched = true;
    if (Utility.isUSorg() && Utility.isDPLSettingEnabled()) {
      checkForDpl(data);
    }
    const updatedValidTillDate = getValidTillDateFromDocDate(
      DateFormatService.getDateFromStr(
        updatedState.billDate.date,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      data
    );
    updatedState.dueDate.date = updatedValidTillDate
      ? DateFormatService.getDateStrFromDate(
          updatedValidTillDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      : updatedState.dueDate.date;
    setExpenseBillState({ ...updatedState });
    setContact({ ...data });
    if (getPrimaryCurrencyCheck()) {
      const primaryCurrencyCode =
        tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
      try {
        showLoader();
        let currencyHistory = await getCurrencyHistoryViaCode(
          primaryCurrencyCode
        );
        setPrimaryCurrencyHistory(currencyHistory);
        await updatePrimaryExchangeRate(currencyHistory, documentDate);
      } finally {
        removeLoader();
      }
    } else {
      const currencyCode =
        contact?.currencyCode ?? props?.populateFormData?.currency;
      try {
        showLoader();
        let currencyHistory = await getCurrencyHistoryViaCode(currencyCode);
        setCurrencyHistory(currencyHistory);
        const contactCurrency = await getExchangeRateDetailsFromHistory(
          currencyHistory,
          data.currencyCode,
          updatedState.billDate.date
        );
        if (
          typeof contactCurrency !== 'undefined' &&
          contactCurrency !== null
        ) {
          updateCurrencyAndExchangeRate(
            contactCurrency.currencyCode,
            contactCurrency.currencyExchangeRate
          );
        }
      } finally {
        removeLoader();
      }
    }
  };

  const getLineItemCFs = (lineItem: any) => {
    let oldColConfigs = [...columnConfig];
    let colConfigsWithOnlyCF = oldColConfigs.filter(
      (item: any) => item.isCustomField
    );
    let newCfs: any[] = [];
    if (!Utility.isEmpty(accountsCFData)) {
      colConfigsWithOnlyCF.forEach((colConfigItem: any) => {
        const cf: any = accountsCFData.find(
          (cfItem: any) => colConfigItem.id === cfItem.id
        );
        if (typeof cf !== 'undefined' && cf !== null) {
          let cfValue;
          if (cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
            cfValue = DateFormatService.getDateStrFromDate(
              lineItem[cf.id],
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (cf.fieldType.toLowerCase() === 'user') {
            const tempCF = cf?.attributes?.find(
              (attr: any) => attr.code === lineItem[cf.id]?.code
            );
            if (tempCF) {
              cfValue = tempCF.code;
            }
          } else if (
            cf.fieldType.toLowerCase() === INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue = lineItem[cf.id] ? lineItem[cf.id].value : '';
            if (cfValue === undefined) cfValue = lineItem[cf.id];
          } else {
            cfValue = lineItem[cf.id] ? lineItem[cf.id] : '';
          }

          newCfs.push({
            id: cf.id,
            code: cf.code,
            label: cf.label,
            module: 'ACCOUNT',
            shortName: cf.shortName,
            value: cfValue
          });
        }
      });
    }

    return newCfs;
  };

  const handleAndUpdateRCMCheck = (check: boolean) => {
    if (check === true) {
      if (doesDefaultRCMAccountExists === true) {
        setExpenseBillState((prev: any) => {
          return {
            ...prev,
            applyRcmCheck: check
          };
        });
      } else {
        const defaultAccountUpdateBtn = [
          {
            title: 'Cancel',
            className: 'bg-gray-2 border-m',
            onClick: () => {}
          },
          {
            title: 'Yes, Update',
            className: 'bg-blue text-white ml-r',
            onClick: () => {
              setShowDefaultAccountsSettings(true);
            }
          }
        ];
        showAlert(
          'Alert',
          'Please map default RCM account.',
          defaultAccountUpdateBtn
        );
      }
    } else {
      setExpenseBillState((prev: any) => {
        return {
          ...prev,
          applyRcmCheck: check
        };
      });
    }
  };

  const makePayload = (): ExpenseBillPayload => {
    const invoiceAccountList: InvoiceAccount[] = [];
    const attachmentIds =
      attachments && attachments.length > 0
        ? attachments.map((attachment: any) => attachment.attachmentId)
        : [];

    expenseBillState.gridInfo.inlineItems.forEach((row: any, index: any) => {
      let invoiceAccount: InvoiceAccount = {
        name: row?.account?.name,
        accountCode: row?.account?.code ? row?.account?.code : '',
        taxDetails: row?.taxDetails ? row?.taxDetails : [],
        accountDescription: row.description,
        tax: row?.tax ? row?.tax : null,
        taxCode: row?.tax?.code ? row?.tax?.code : null,
        taxName: row?.tax?.name ? row?.tax?.name : null,
        taxAmount: Number(Number(row.taxAmount)),
        amount: Number(Number(row.amount)),
        currency: expenseBillState.docCurrencyCode,
        totalAmount: Number(Number(row.totalAmount)),
        tdsInfoIndia: row.tdsInfoIndia,
        isTdsApplicableAccount: row?.account?.isTdsApplicable,
        isTdsApplicableContact:
          expenseBillState?.contact?.tdsInfoIndia?.deductionApplicable,
        isTdsApplicableProduct: false,
        customField: [...getLineItemCFs(row)],
        lineNumber: index + 1,
        invalidFields: row.invalidFields ? row.invalidFields : [],
        userSetTaxes: row?.userSetTaxes || false,
        id: row?.id
      };

      if (
        row?.itcAdjustment !== '' &&
        undefined !== row?.itcAdjustment &&
        null !== row?.itcAdjustment &&
        'NA' !== row?.itcAdjustment
      ) {
        invoiceAccount.itcAdjustment = row.itcAdjustment;
      } else {
        delete invoiceAccount.itcAdjustment;
      }

      if (Utility.isNotEmpty(row?.account?.glAccountCode)) {
        invoiceAccount.glAccountCode = row?.account?.glAccountCode;
      } else {
        delete invoiceAccount.glAccountCode;
      }

      if (
        row?.itcIneligibleType !== '' &&
        undefined !== row?.itcIneligibleType &&
        null !== row?.itcIneligibleType
      ) {
        invoiceAccount.itcIneligibleType = row.itcIneligibleType;
      } else {
        delete invoiceAccount.itcIneligibleType;
      }

      if (props.documentMode === DOCUMENT_MODE.EDIT) {
        const accountList: any[] = accountsData['content'];
        invoiceAccount.id = accountList.find(
          (x) => x.code === row.account.code
        ).id;
      }
      invoiceAccountList.push(invoiceAccount);
    });
    const paymentTermValue = paymentTerms?.content.find(
      (paymentTerm: any) => String(paymentTerm.id) === contact?.paymentTermCode
    );

    const expenseBillPayload: ExpenseBillPayload = {
      contactCode: contact?.code,
      currency: expenseBillState.docCurrencyCode,
      paymentTerm: paymentTermValue?.termName,
      customField: expenseBillState.customField,
      currencyCode: expenseBillState.docCurrencyCode,
      status: STATUS_TYPE.OPEN,
      documentType: DOC_TYPE.BILL,
      exchangeRate: expenseBillState.docExchangeRate,
      gstExchangeRate: expenseBillState.gstExchangeRate,
      primaryExchangeRate: expenseBillState.primaryExchangeRate,
      shipFrom: !Utility.isEmpty(expenseBillState?.shipFrom)
        ? expenseBillState.shipFrom
        : shipFromAddress,
      shipTo: shippingAddress?.currentShippingAddress,
      billTo: billingAddress?.currentBillingAddress,
      contact: contact,
      documentDate: expenseBillState.billDate.date,
      validTillDate: expenseBillState.dueDate.date,
      fulfillmentDate: expenseBillState.billDate.date,
      purchaseInvoiceDate: expenseBillState.billDate.date,
      purchaseInvoiceDueDate: expenseBillState.dueDate.date,
      receiveGoodsStatus: RECEIVED_GOODS_STATUS.NOT_APPLICABLE,
      buyType: BUY_TYPE.EXPENSE,
      dropShip: false,
      purchaseInvoiceType: DOC_TYPE.EXPENSE,
      purchaseInvoiceAccounts: invoiceAccountList,
      totalAmount: expenseBillState.summaryInfo.totalAmount,
      totalAmountInBaseCurrency: expenseBillState.amountInBaseCurrency,
      taxSystem: getTenantTaxSystem(),
      dueAmount: expenseBillState.summaryInfo.totalAmount,
      totalTdsAmount: expenseBillState.summaryInfo.totalTdsAmount,
      tdsProcessedFlag: expenseBillState.tdsProcessedFlag
        ? expenseBillState.tdsProcessedFlag
        : null,
      roundOffAmountInDocumentCurrency:
        // expenseBillState.roundOffAmountInDocumentCurrency
        //   ? expenseBillState.roundOffAmountInDocumentCurrency
        //   : 0,
        parseFloat(roundOffValue),
      roundOffAmountInBaseCurrency: parseFloat(roundOffValue),
      openingInvoice: false,
      memo: expenseBillState.memo,
      sequenceFormat: expenseBillState.sequenceFormat,
      documentSequenceCode: expenseBillState.documentSequenceCode,
      manualMode: expenseBillState.manualMode,
      attachmentIds,
      attachments: attachments,
      isDocumentTouched: expenseBillState.isDocumentTouched,
      unitPriceGstInclusive: unitPriceGstInclusive,
      supplierInvoiceNo: expenseBillState.supplierInvoiceNo,
      placeOfSupply: sourceOfSupply,
      gstin: contact?.gstin ? contact?.gstin : '',
      applyRcmCheck: expenseBillState?.applyRcmCheck,
      seqCodeAlreadyDumped: expenseBillState.seqCodeAlreadyDumped || false,
      draftReferenceId: ''
    };

    if (
      DOCUMENT_MODE.EDIT === props?.documentMode ||
      DOCUMENT_MODE.VIEW === props?.documentMode
    ) {
      expenseBillPayload.attachments = expenseBillPayload.attachmentIds.map(
        (id: any) => `${id}`
      );
    }

    if (props.draftData?.draftType === DraftTypes.DRAFT) {
      expenseBillPayload[
        'draftReferenceId'
      ] = `${props.draftData?.data?.tableId}/record/${props.draftData?.data?.id}`;
    }
    return expenseBillPayload;
  };

  const setButtonStatus = (status: boolean) => {
    dispatch(
      setDraftActionAvailibility({ id: props.draftData.id, status: status })
    );
  };

  useEffect(() => {
    if (props.canValidate) {
      if (!productRows.length) {
        addNewItem();
      }

      if (
        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
        expenseBillState.contact &&
        !gstTreatmentWithoutGSTIN.includes(
          expenseBillState.contact?.gstTreatment
        ) &&
        (!expenseBillState.contact?.gstin ||
          expenseBillState.contact?.gstin === '')
      ) {
        const alertButtonConfig = [
          {
            title: 'Cancel',
            className: 'bg-gray2 border-m mr-s mt-r',
            onClick: () => {}
          },
          {
            title: 'Update',
            className: 'bg-button text-white mt-r',
            onClick: () => {
              setContactMode(DOCUMENT_MODE.EDIT);
              setShowAddContactPopup(true);
            }
          }
        ];
        showAlert(
          'GSTIN required',
          'Please add GSTIN in contact form.',
          alertButtonConfig
        );
      }
    }
  }, [props.canValidate]);

  const isDocValid = (docToValidate: any, closeDoc: boolean) => {
    const taxSystem = getTenantTaxSystem();
    if (
      Utility.isEmpty(docToValidate.documentSequenceCode) &&
      Utility.isEmpty(docToValidate.sequenceFormat) &&
      docToValidate.manualMode
    ) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.contact)) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.purchaseInvoiceAccounts)) {
      return false;
    }

    if (!Utility.isEmpty(docToValidate.documentDate)) {
      const docDate = DateFormatService.getDateFromStr(
        docToValidate.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantInfo,
          'Bill date',
          'PURCHASE_INVOICE'
        )
      ) {
        return false;
      }
      if (!Utility.checkClosingDate(docDate, 'Bill date')) {
        return false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      docToValidate.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    // Line item errors
    let lineItemsHasErrors = false;
    for (let i = 0; i < docToValidate.purchaseInvoiceAccounts.length; i++) {
      const item = docToValidate.purchaseInvoiceAccounts[i];
      if (Utility.isEmpty(item.accountCode) || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }
    if (lineItemsHasErrors) {
      return false;
    }
    // Line item errors ends

    // Contact GSTIN check
    if (
      taxSystem === TAX_SYSTEM.INDIA_GST &&
      docToValidate.contact &&
      !gstTreatmentWithoutGSTIN.includes(docToValidate.contact.gstTreatment) &&
      (!docToValidate.contact.gstin || docToValidate.contact.gstin === '')
    ) {
      return false;
    }
    // Contact GSTIN check ends

    // Check TDS not deducted
    if (taxSystem === TAX_SYSTEM.INDIA_GST && !skipTDS.current) {
      let tdsNotDeductedArray: any[] = [];
      docToValidate.purchaseInvoiceAccounts.forEach((ele: any) => {
        if (!ele.tdsInfoIndia && ele.isTdsApplicableAccount) {
          tdsNotDeductedArray.push(ele.name);
        }
      });
      if (
        tdsNotDeductedArray.length > 0 &&
        (docToValidate.contact.tdsApplicableIndia ||
          (docToValidate.contact.tdsInfoIndia &&
            docToValidate.contact.tdsInfoIndia.deducteeType))
      ) {
        openTDSDialog(
          tdsNotDeductedArray
            .map((str: string) => {
              return `<li>${str}</li>`;
            })
            .join(''),
          closeDoc
        );
        return false;
      }
    }

    return true;
  };

  const openTDSDialog = (accountNames = '', closeDoc: boolean) => {
    let buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m ',
        onClick: () => {
          skipTDS.current = false;
        }
      },
      {
        title: t(`CONFIRMATION_POPUP.YES`),
        className: 'bg-button text-white ml-r',
        onClick: () => {
          skipTDS.current = true;
          if (props.documentMode === DOCUMENT_MODE.EDIT) {
            setValidationDisplayStatus(true);
            updateExpenseBill();
          }
          if (
            props.documentMode === DOCUMENT_MODE.NEW ||
            props.documentMode === DOCUMENT_MODE.COPY
          ) {
            setValidationDisplayStatus(true);
            createExpenseBill(closeDoc);
          }
        }
      }
    ];
    showAlert(
      '',
      `<ul style="list-style: disc; margin-left: 14px;">${accountNames}</ul><div class="mt-r">TDS is applicable on the above items. Do you wish to skip deducting TDS?</div>`,
      buttons
    );
  };

  const setValidationDisplayStatus = (status: boolean) => {
    dispatch(
      setDraftValidationDisplayStatus({
        id: props.draftData.id,
        status: status
      })
    );
  };

  const updateForm = (docResp: any) => {
    BillService.getBillDetailsByCode(docResp.purchaseInvoiceCode).then(
      (doc: any) => {
        dispatch(
          updatePopulateFormData({
            id: props.draftData.id,
            formdata: {
              ...doc,
              documentType: DOC_TYPE.BILL,
              items: [...doc.purchaseInvoiceAccounts],
              receiveGoodsStatus:
                doc.receiptStatus ||
                doc.receiveGoodsStatus ||
                RECEIVED_GOODS_STATUS.NOT_RECEIVED
            },
            draftType: DraftTypes.UPDATE,
            actionFromDocument: true
          })
        );
        setButtonStatus(false);
      },
      (err) => {
        console.error('Error loading updated doc: ', err);
        setButtonStatus(false);
      }
    );
  };

  // Change URL to base module URL
  const replaceURLToModuleURL = () => {
    if (
      DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
      history.location?.pathname?.includes(PAGE_ROUTES.BILLS)
    ) {
      history.replace(PAGE_ROUTES.BILLS);
    }
  };

  const createExpenseBill = (closeDoc: boolean) => {
    const expenseBillPayload: ExpenseBillPayload = makePayload();
    expenseBillPayload.sequenceFormat = expenseBillState.sequenceFormat;
    expenseBillPayload.documentSequenceCode =
      expenseBillState.documentSequenceCode;
    // expenseBillPayload.paymentStatus = PAYMENT_STATUS.PENDING;
    if (expenseBillPayload.attachmentIds?.length) {
      expenseBillPayload.attachments = expenseBillPayload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (isDocValid(expenseBillPayload, closeDoc)) {
      setButtonStatus(true);
      validateAccountsBudget(expenseBillPayload, false, closeDoc);
    } else {
      setButtonStatus(false);
    }
  };

  const makeCreateAPICall = (payload: any, closeDoc: boolean) => {
    const countrySpecificEndpointCode = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.BILL
    );
    BillService.createExpenseBill(payload, countrySpecificEndpointCode)
      .then((res: any) => {
        if (props.draftData) {
          dispatch(removeDraft(props.draftData.id));
          setButtonStatus(false);
          if (res?.draft) {
            const buttons = [
              {
                title: 'Ok',
                className: 'bg-button, border-m',
                onClick: () => {}
              },
              {
                title: 'Goto Bills',
                className: ' bg-blue text-white ml-r',
                onClick: () => {
                  RouteManager.navigateToPage(PAGE_ROUTES.BILLS);
                }
              }
            ];
            showAlert(
              'Expense Bill sent for approval!',
              'Document has been created successfully.',
              buttons
            );
          } else {
            updateForm(res);
          }
        } else {
          setButtonStatus(false);
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.EXPENSE_BILL
          })
        );
        dispatch(fetchBills());
        if (!res?.draft) {
          showSuccessAlert();
        }
      })
      .catch((err) => {
        setButtonStatus(false);
      });
  };

  const makeUpdateAPICall = (payload: any, closeDoc: boolean) => {
    BillService.updateBill(
      payload,
      Utility.getTenantSpecificApiCode(COMPLIANCE_SPECIFIC_FIELD_NAME.BILL)
    )
      .then((res) => {
        if (props.draftData) {
          dispatch(removeDraft(props.draftData.id));
          dispatch(
            deleteDrafts({
              recordId: props.draftData.id,
              tableId: draftsTableId
            })
          );
          dispatch(
            setDraftActionAvailibility({
              id: props.draftData.id,
              status: false
            })
          );
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.EXPENSE_BILL
          })
        );
        dispatch(fetchBills());
        setButtonStatus(false);
        replaceURLToModuleURL();
      })
      .catch((err) => {
        showAlert('Error!', 'Error updating expense bill.');
        setButtonStatus(false);
        replaceURLToModuleURL();
      });
  };

  const showSuccessAlert = () => {
    const buttons = [
      {
        title: 'Ok',
        className: 'bg-button, border-m',
        onClick: () => {
          dispatch(removeDraft(props.draftData.id));
        }
      },
      {
        title: 'Goto list',
        className: ' bg-blue text-white ml-r',
        onClick: () => {
          if (window.location.pathname !== PAGE_ROUTES.BILLS) {
            dispatch(removeDraft(props.draftData.id));
            RouteManager.navigateToPage(PAGE_ROUTES.BILLS);
          } else {
            dispatch(removeDraft(props.draftData.id));
          }
        }
      }
    ];
    showAlert(
      'Expense Bill created!',
      'Expense Bill has been created successfully.',
      buttons
    );
  };

  const onTDSDeduct = (tdsData: any) => {
    let rows = [...productRows] as any;
    setCurrentRowTDSInfo(null);
    rows[lastUpdatedIndex].tdsInfoIndia = tdsData;
    setProductRows(rows);
    let updatedState = { ...expenseBillState };
    updatedState.gridInfo.inlineItems = rows;
    let totalTDS = 0;
    updatedState.gridInfo.inlineItems.forEach((item: any, rowIndex: any) => {
      if (!Utility.isEmpty(item) && !Utility.isEmpty(item.tdsInfoIndia)) {
        totalTDS += item.tdsInfoIndia.tdsAmount;
      }
    });
    updatedState.summaryInfo = {
      ...updatedState.summaryInfo,
      totalTdsAmount: totalTDS,
      totalAmount:
        updatedState.summaryInfo.subTotal +
        updatedState.summaryInfo.taxAmount -
        totalTDS
    };

    if (
      Store.getState().authInfo.currentTenantInfo.data.additionalSettings
        ?.ROUND_OFF?.autoRoundOff &&
      recalculateRounding.current &&
      !isManualRoundOff.current
    ) {
      const roundOffDiff = calculateRoundOffDiff(
        updatedState.summaryInfo['totalAmount']
      );

      updatedState.summaryInfo['totalAmount'] = Math.round(
        updatedState.summaryInfo['totalAmount'] + roundOffDiff
      );
      updatedState.summaryInfo['roundOffDifference'] = roundOffDiff;
    }

    setExpenseBillState({ ...updatedState });
  };

  const onDeductTDS = ({ rowIndex, rowData }: any) => {
    let rows = [...productRows];
    const lineAmount = rowData.amount;
    const payableAccount = DEFAULT_TDS_PAYABLE_ACCOUNT_CODE;
    const assessableAmount = lineAmount;
    const incomePayment = rowData?.account?.natureOfIncomePayment;
    let documentDate = expenseBillState.billDate.date;

    if (!expenseBillState.contact && !documentDate && !incomePayment) return;

    let tdsInfoData = {
      lineAmount,
      assessableAmount,
      incomePayment,
      payableAccount,
      contact: expenseBillState.contact,
      documentDate: DateFormatService.getFormattedDateString(
        documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY'],
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      ),
      isDeductedTds: isTDSDeducted
    };
    if (
      rowData?.tdsInfoIndia &&
      tdsInfoData.lineAmount === rowData.tdsInfoIndia.lineAmount
    ) {
      tdsInfoData = {
        ...rowData.tdsInfoIndia,
        contact: expenseBillState.contact,
        isDeductedTds: isTDSDeducted,
        documentDate: DateFormatService.getFormattedDateString(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY'],
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      };
    }

    rows[rowIndex] = {
      ...rowData,
      isTdsApplicableContact:
        expenseBillState?.contact?.tdsInfoIndia?.deductionApplicable,
      isTdsApplicableProduct: false,
      isTdsApplicableAccount: rowData.account.isTdsApplicable
    };

    setCurrentRowTDSInfo(tdsInfoData);
    setProductRows(rows);

    let updatedState = { ...expenseBillState };
    updatedState.gridInfo.inlineItems = rows;
    setExpenseBillState({ ...updatedState });
    setLastUpdatedIndex(rowIndex);
  };

  useEffect(() => {
    if (currentRowTDSInfo) {
      setShowTDSCalculationPopup(true);
    }
  }, [currentRowTDSInfo]);

  const onOpenTaxValue = ({ rowIndex, rowData }: any) => {
    setLastUpdatedIndex(rowIndex);
    setCurrentRowTaxInfo({ rowIndex: rowIndex, rowData: rowData });
  };

  const onTaxValueChange = (taxData: any) => {
    let tempExpenseBillState = { ...expenseBillState };
    setShowTaxRowPopup(false);
    let currentRow =
      tempExpenseBillState?.gridInfo?.inlineItems[taxData.rowIndex];
    const prevTaxAmount = +currentRow?.taxAmount || 0;
    const amountWithoutTax = +currentRow.amount || 0;

    if (taxData?.isTaxGroup) {
      currentRow.taxDetails = currentRow.taxDetails.map(
        (taxDetailsRow: any) => {
          let tempTaxDetailsRow = { ...taxDetailsRow };
          const taxAmount =
            taxData.taxDetailGroup.find(
              (taxRow: any) => taxRow.taxId === tempTaxDetailsRow.taxId
            )?.taxAmount || 0;
          tempTaxDetailsRow.taxAmount = Utility.roundOff(
            taxAmount,
            CURRENCY_PRECISION
          );

          return tempTaxDetailsRow;
        }
      );
    }
    //summary Info Calculation
    tempExpenseBillState.summaryInfo.taxAmount =
      tempExpenseBillState.summaryInfo.taxAmount +
      (Number(taxData.taxAmount) - prevTaxAmount);
    tempExpenseBillState.summaryInfo.totalAmount =
      tempExpenseBillState.summaryInfo.totalAmount +
      (Number(taxData.taxAmount) - prevTaxAmount);

    currentRow.taxAmount = Utility.roundOff(
      Number(taxData.taxAmount),
      CURRENCY_PRECISION
    );
    currentRow.totalAmount = Utility.roundOff(
      amountWithoutTax + Number(taxData.taxAmount),
      CURRENCY_PRECISION
    );
    currentRow.userSetTaxes = true;
    tempExpenseBillState.gridInfo.inlineItems[taxData.rowIndex] = currentRow;
    setExpenseBillState({ ...tempExpenseBillState });
  };

  useEffect(() => {
    if (currentRowITCInfo) {
      setShowITCPopup(true);
    }
  }, [currentRowITCInfo]);

  const onOpenITCOptions = ({ rowIndex, rowData }: any) => {
    let itcValue = rowData.itcIneligibleType;
    setLastUpdatedIndex(rowIndex);
    setCurrentRowITCInfo({ rowIndex: rowIndex, itcValue: itcValue });
  };

  const onITCOptionSelected = (itcData: any) => {
    let rows = [...expenseBillState.gridInfo.inlineItems];
    setShowITCPopup(false);
    setCurrentRowITCInfo(null);
    rows[itcData.rowIndex].itcIneligibleType = itcData.itcValue;
    let updatedData = expenseBillState;
    updatedData.gridInfo.inlineItems = rows;
    setExpenseBillState(updatedData);
  };
  const AddCopyofRow = (data: any) => {
    const { rowData, rowIndex } = data;
    let tempProductRows = [...expenseBillState.gridInfo.inlineItems];
    let copiedRow: any = { ...tempProductRows[rowIndex] };
    copiedRow.id = null;
    tempProductRows.splice(rowIndex + 1, 0, copiedRow);
    tempProductRows.forEach((item, index) => {
      if (item) {
        item.lineNumber = index + 1;
      }
    });

    let tempExpenseBillState = { ...expenseBillState };
    tempExpenseBillState.gridInfo.inlineItems = tempProductRows;
    setExpenseBillState(tempExpenseBillState);
  };

  const InsertNewItem = (data: any) => {
    const { rowData, rowIndex } = data;
    let tempProductRows = [...expenseBillState.gridInfo.inlineItems];
    let newRow: any = addNewItem(true);
    tempProductRows.splice(rowIndex + 1, 0, newRow);
    tempProductRows.forEach((item, index) => {
      if (item) {
        item.lineNumber = index + 1;
      }
    });

    let tempExpenseBillState = { ...expenseBillState };
    tempExpenseBillState.gridInfo.inlineItems = tempProductRows;
    setExpenseBillState(tempExpenseBillState);
  };

  const getContextMenu = (row: any) => {
    let contactObj = contact || expenseBillState.contact;
    let contextMenu = [];
    if (
      contactObj?.tdsInfoIndia?.deductionApplicable &&
      row?.account?.isTdsApplicable
    ) {
      contextMenu.push({
        title: 'Deduct TDS',
        icon: DKIcons.ic_add,
        className: ' p-0',
        onClick: (data: any) => onDeductTDS(data)
      });
    }
    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      !Utility.isEmpty(row?.account)
    ) {
      contextMenu.push({
        title: 'ITC',
        icon: DKIcons.ic_edit,
        className: ' p-0',
        onClick: (data: any) => onOpenITCOptions(data)
      });
    }
    contextMenu.push({
      title: 'Arrange Custom Fields',
      icon: DKIcons.ic_settings,
      className: ' p-0',
      onClick: (data: any) => setOpenAccountCFSettings(true)
    });

    if (checkifCustomFieldCanEdit) {
      contextMenu.push({
        title: 'Insert',
        icon: DKIcons.ic_add,
        className: ' p-0',
        onClick: (data: any, index: any) => InsertNewItem(data)
      });

      contextMenu.push({
        title: 'Copy',
        icon: DKIcons.ic_copy,
        className: ' p-0',
        onClick: (data: any, index: any) => AddCopyofRow(data)
      });
      contextMenu.push({
        title: 'Delete',
        icon: DKIcons.ic_delete,
        className: ' p-0',
        onClick: (data: any) => onDelete(data)
      });

      if (
        getTenantTaxSystem() !== TAX_SYSTEM.INDIA_GST &&
        getTenantTaxSystem() !== TAX_SYSTEM.US &&
        getTenantTaxSystem() !== TAX_SYSTEM.UK
      ) {
        contextMenu.push({
          title: 'Tax',
          icon: DKIcons.ic_edit,
          className: ' p-0',
          onClick: (data: any) => onOpenTaxValue(data)
        });
      }
    }

    return contextMenu;
  };

  const updateExpenseBill = () => {
    const expenseBillData: any = props.populateFormData;
    const expenseBillPayload: ExpenseBillPayload = makePayload();
    expenseBillPayload.id = expenseBillData.id;
    expenseBillPayload.entityId = expenseBillData.id;
    expenseBillPayload.documentCode = expenseBillData.documentCode;
    expenseBillPayload.purchaseInvoiceCode =
      expenseBillData.purchaseInvoiceCode;
    expenseBillPayload.documentSequenceCode =
      expenseBillData.documentSequenceCode;

    if (isDocValid(expenseBillPayload, true)) {
      setButtonStatus(true);
      setValidationDisplayStatus(true);
      validateAccountsBudget(expenseBillPayload, true, true);
    } else {
      setButtonStatus(false);
    }
  };

  const validateAccountsBudget = (
    doc: any,
    isUpdate: boolean,
    closeDoc: boolean
  ) => {
    const accountsAndCFInfo = getAccountsAndCFForBudgetValidation(
      [...doc.purchaseInvoiceAccounts],
      doc.customField,
      'accountCode',
      'tax',
      doc.exchangeRate ? doc.exchangeRate : 1,
      false
    );
    const allAccountsData = accountsAndCFInfo?.accountsInfo;
    const customField = accountsAndCFInfo?.customField;

    if (allAccountsData.length) {
      validateBudgetForAccounts(
        doc.documentDate,
        allAccountsData,
        customField,
        expenseBillState.journalEntryCode
          ? expenseBillState.journalEntryCode
          : null
      ).then(
        (budgetResp: { data: any[]; limitCrossed: boolean }) => {
          if (budgetResp.limitCrossed) {
            let buttons = [
              {
                title: 'Cancel',
                className: 'bg-gray2 border-m ',
                onClick: () => {
                  setButtonStatus(false);
                }
              },
              {
                title: 'Proceed',
                className: 'bg-button text-white ml-r',
                onClick: () => {
                  isUpdate
                    ? makeUpdateAPICall(doc, closeDoc)
                    : makeCreateAPICall(doc, closeDoc);
                }
              }
            ];
            let message =
              '<ul class="text-align-left" style="list-style-type: disc; margin-left: 5px;">';
            budgetResp.data?.forEach((alertData: any) => {
              message += `<li>${alertData.message}</li>`;
            });
            message +=
              '<div class="text-align-center mt-l">Do you want to continue?</div>';
            message += '</ul>';
            showAlert('Warning!', message, buttons);
          } else {
            isUpdate
              ? makeUpdateAPICall(doc, closeDoc)
              : makeCreateAPICall(doc, closeDoc);
          }
        },
        (err) => {
          console.error('Error validating accounts budget: ', err);
        }
      );
    }
  };

  /**
   * Custom Numbering Format
   */
  const selectedFormat = (selected: any) => {
    /**
     * RECEIVE Selected format {id: "", text: ""}
     */
    const updatedState: any = {};
    if (selected.manualMode) {
      updatedState.documentSequenceCode = selected.text;
      updatedState.sequenceFormat = selected.id;
      updatedState.manualMode = selected.manualMode;
    } else {
      if (selected.id) {
        updatedState.sequenceFormat = selected.id;
        updatedState.manualMode = selected.manualMode;
      }
    }
    setExpenseBillState((prevState: any) => {
      return { ...prevState, ...updatedState };
    });
  };
  /**
   * Custom Numbering Format
   */

  const getFormattedAddress = (address: any, isVendorType?: boolean) => {
    const { contactName, line1, line2, cityAndState, countryAndPostalCode } =
      getFormattedAddressObj(address);

    let formattedAddress = '';
    if (!Utility.isEmpty(contactName) && !isVendorType) {
      formattedAddress += contactName + ', ';
    }
    if (!Utility.isEmpty(line1) && !isVendorType) {
      formattedAddress += line1 + ', ';
    }
    if (!Utility.isEmpty(line2) && !isVendorType) {
      formattedAddress += line2 + ', ';
    }

    if (isVendorType && !Utility.isEmpty(address.state)) {
      formattedAddress += address.state + ', ';
    } else if (!Utility.isEmpty(cityAndState)) {
      formattedAddress += cityAndState + ', ';
    }

    if (!Utility.isEmpty(countryAndPostalCode)) {
      formattedAddress += countryAndPostalCode;
    }

    return formattedAddress;
  };

  const getContactSelector = () => {
    return (
      <div
        className={`${
          props.canValidate
            ? 'bg-chip-red border-red text-red'
            : 'bg-chip-blue border-blue text-blue'
        } p-v-s p-h-r border-radius-s cursor-pointer`}
        style={{ border: 'dashed', borderWidth: 1 }}
        onClick={() => setOpenContactSelector((prevValue) => !prevValue)}
      >
        <DKLabel text={`+ Add a Contact`} />
      </div>
    );
  };

  const getContactPicker = () => {
    const contactList =
      contactsData?.content?.filter(
        (contact: any) => contact.status.toLowerCase() === STATUS_TYPE.active
      ) || [];

    const contactPickerTop = 40;

    return openContactSelector ? (
      <DKListPicker2
        data={contactList}
        className="position-absolute z-index-3 bg-white border-m"
        style={{
          top: contactPickerTop,
          left: 0,
          minWidth: 200
        }}
        renderer={(index: number, contactObj: any) => contactObj.name}
        onSelect={(index: number, contactObj: any) => {
          setOpenContactSelector(false);
          onContactChange(contactObj);

          const preferredAddress = contactObj?.billingAddress.find(
            (address: any) => address?.preferred
          );
          setExpenseBillState((prevState: any) => {
            return {
              ...prevState,
              shipFrom: preferredAddress
            };
          });
        }}
        onClose={() => setTimeout(() => setOpenContactSelector(false), 100)}
        allowSearch={true}
        searchApiConfig={{
          getUrl: (searchValue: string) => {
            const config: ContactAPIConfig = {
              ...ContactService.apiConfig,
              Page: 0,
              SearchTerm: searchValue,
              Limit: 20,
              IncludeOpeningAmounts: false,
              IncludeOweAmounts: false,
              Query: 'status=active'
            };

            ContactService.apiConfig = config;

            return ContactService.getContactsApiUrl();
          },
          dataParser: (response: any) => {
            return response?.content || [];
          },
          debounceTime: 300
        }}
        button={
          GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.CONTACTS.CREATE
          )
            ? {
                title: '+ Add New',
                icon: DKIcons.ic_contact,
                className: 'bg-button text-white',
                style: {},
                onClick: () => {
                  setContactMode(DOCUMENT_MODE.NEW);
                  setShowAddContactPopup(true);
                }
              }
            : null
        }
      />
    ) : null;
  };

  const getCustomCompanyDetails = (title?: string) => {
    const orgName = customLocation?.locationDetails?.title;
    const address = getFormattedAddress(
      customLocation?.locationDetails?.address
    );
    const phone = customLocation?.locationDetails?.phone;
    const email = customLocation?.locationDetails?.email;

    return (
      <div
        className={`column`}
        style={{
          maxWidth: 250
        }}
      >
        <div className="column width-auto">
          <div className="row">
            <DKLabel text={title} className="fw-b fs-r text-gray" />
          </div>
          <div className={`row`}>
            <DKLabel text={orgName} className="fw-m fs-r" />
          </div>
          <DKLabel text={address} className="parent-width" />
          {phone && <DKLabel text={phone} className="parent-width" />}
          {email && <DKLabel text={email} className="parent-width" />}
        </div>
      </div>
    );
  };

  const showSelectedContactAddress = () => {
    const canEditContact = props.documentMode !== DOCUMENT_MODE.EDIT;
    const companyAddress = !Utility.isEmpty(expenseBillState.shipFrom)
      ? expenseBillState.shipFrom
      : contact?.billingAddress?.[0];
    return (
      <div
        className={`column`}
        style={{
          maxWidth: 200,
          marginTop: -10,
          marginLeft: -10,
          padding: 10
        }}
      >
        <div
          className={`${
            !Utility.isEmpty(contact)
              ? 'cursor-pointer listPickerBG border-box'
              : ''
          }`}
          onClick={() => {
            if (!Utility.isEmpty(contact) || canEditContact) {
              setOpenContactSelector((prevValue) => !prevValue);
            }
          }}
        >
          {!Utility.isEmpty(contact) && (
            <DKLabel text={contact.name} className="fw-m fs-l fw-b" />
          )}
        </div>

        <div
          className="row width-auto listPickerBG cursor-pointer"
          onClick={() => {
            if (!Utility.isEmpty(contact)) {
              setOpenShipFrom(true);
            }
          }}
        >
          {Utility.isEmpty(companyAddress) ? null : (
            <DKLabel
              text={getFormattedAddress(companyAddress)}
              className="mt-s parent-width "
            />
          )}
        </div>
        {openShipFrom && (
          <DKListPicker2
            data={contact?.billingAddress}
            className="position-absolute z-index-3 bg-white border-m shadow-m"
            style={{ minWidth: 250, top: 70, left: -10 }}
            renderer={(index: number, addressObj: any) => (
              <div
                style={{
                  width: 200,
                  whiteSpace: 'pre-wrap',
                  textAlign: 'left'
                }}
                dangerouslySetInnerHTML={{
                  __html: getFormattedAddress(addressObj)
                }}
              ></div>
            )}
            onEdit={(index: number, obj: any) => {}}
            button={
              checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.EDIT)
                ? {
                    title: 'Manage address',
                    className:
                      'text-white fw-m bg-button justify-content-center',
                    onClick: () => {
                      setActiveContactTab(CONTACT_FORM_TAB.ADDRESS_INFO);
                      setContactMode(DOCUMENT_MODE.EDIT);
                      setShowAddContactPopup(true);
                      setOpenShipFrom(false);
                    }
                  }
                : null
            }
            canEdit={false}
            onSelect={(index: number, addressObj: any) => {
              setExpenseBillState((prevState: any) => {
                return {
                  ...prevState,
                  shipFrom: addressObj
                };
              });
              setOpenShipFrom(false);
            }}
            onClose={() => {
              setOpenShipFrom(false);
            }}
            allowSearch={false}
          />
        )}
      </div>
    );
  };

  const getContactDetails = () => {
    return (
      <div>
        {Utility.isEmpty(contact) && getContactSelector()}
        {getContactPicker()}
        {showSelectedContactAddress()}
      </div>
    );
  };

  const getTitleAndAmount = (
    title: string,
    amount: number,
    icon: any,
    titleClassName: string,
    amountClassName?: string,
    currencyCode?: string
  ) => {
    const amountText = `${amount < 0 ? '(' : ''}${NumberFormatService.getNumber(
      Math.abs(amount)
    )}${amount < 0 ? ')' : ''}`;
    return (
      <div className="row mb-l justify-content-between">
        <div className="row parent-width" style={{ width: '300px' }}>
          {icon && (
            <DKIcon src={icon} className="ic-s" style={{ opacity: 0.6 }} />
          )}
          <DKLabel text={title} className={'ml-r ' + titleClassName} />
        </div>
        <DKLabel
          text={`${Utility.getCurrencySymbolFromCode(
            currencyCode ? currencyCode : props.populateFormData.currency
          )} ${amountText}`}
          style={{
            wordBreak: 'break-all'
          }}
          className={`ml-r text-wrap ${amountClassName || ''}`}
        />
      </div>
    );
  };

  const summaryTotalAmountSection = (
    title: string,
    amount: number,
    icon: any,
    titleClassName: string,
    amountClassName?: string
  ) => {
    return (
      <>
        <div
          className="row parent-width mb-l justify-content-between align-items-start"
          style={{ width: '100%' }}
        >
          <div
            className="row width-auto"
            style={{
              minWidth: 100
            }}
          >
            {icon && (
              <DKIcon src={icon} className="ic-s" style={{ opacity: 0.6 }} />
            )}
            <DKLabel text={title} className={'ml-r ' + titleClassName} />
            {tenantInfo.multicurrencyEnabled && getCurrencySelector()}
          </div>
          <DKLabel
            text={`${Utility.getCurrencySymbolFromCode(
              expenseBillState.docCurrencyCode
            )} ${NumberFormatService.getNumber(amount)}`}
            style={{
              wordBreak: 'break-all'
            }}
            className={`ml-r text-wrap ${amountClassName || ''}`}
          />
        </div>
        {tenantDetails.currency !== expenseBillState.docCurrencyCode &&
          getCurrencyRateField()}
        {getPrimaryCurrencyRateField()}
      </>
    );
  };

  const getCurrencyRateField = () => {
    return (
      <div className="row parent-width mb-l justify-content-between align-items-start number-hide-arrows fs-s">
        <div className="parent-width">
          <DKLabel
            text={`Conversion: ${NumberFormatService.getNumber(
              expenseBillState.amountInBaseCurrency
            )}(${tenantInfo.currency}) at`}
          />
          <div
            className={
              'row border rounded border-gray-300 w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder p-1 mt-s ' +
              'focus:outline-none focus:ring-2 focus:ring-blue-200'
            }
          >
            <div className="row currency-select-trigger align-items-center content-start w-fit">
              <div className="ml-1">{`1 ${expenseBillState.docCurrencyCode}`}</div>
              <div className="currency-dropdown-flag ml-2">
                <span
                  className={`currency-dropdown-selector-flag flag ${expenseBillState.docCurrencyCode}`}
                ></span>
              </div>
              <DKIcon
                className="ml-r"
                style={{
                  transform: 'rotate(90deg)'
                }}
                src={DKIcons.ic_sort}
              />
            </div>
            <div className="row align-items-start content-end">
              <input
                onBlur={(e) => {
                  const rate = e.target.value
                    ? +e.target.value
                    : expenseBillState.docExchangeRate;
                  if (
                    +e.target.value !== 0 &&
                    expenseBillState.docExchangeRate !== 1 / rate
                  ) {
                    setTempExchangeRate(+e.target.value);
                    updateCurrencyExchangeRate(1 / rate + '');
                  }
                }}
                className={`text-align-right outline-none hover:bg-blue-100 focus:bg-blue-100`}
                value={tempExchangeRate}
                type={'number'}
                onChange={(e) => {
                  setTempExchangeRate(+e.target.value);
                }}
                style={{ width: '60%' }}
                disabled={checkifCustomFieldCanEdit === false}
              />
              <div className="ml-1">{`${tenantInfo.currency}`}</div>
              <div className="currency-dropdown-flag ml-2">
                <span
                  className={`currency-dropdown-selector-flag flag ${tenantInfo.currency}`}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const onPrimaryCurrencyChange = (primaryExchangeRate: number) => {
    setExpenseBillState((prevState: any) => {
      return {
        ...prevState,
        primaryExchangeRate: primaryExchangeRate
      };
    });
  };

  const getPrimaryCurrencyRateField = () => {
    const PrimaryCurrTotal: any =
      (expenseBillState.amountInBaseCurrency ?? 0) *
      (tempPrimaryExchangeRate || 1);
    const primaryCurrencyCode =
      tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
    return (
      expenseBillState.docCurrencyCode !== primaryCurrencyCode &&
      getPrimaryCurrencyCheck() && (
        <div className="row parent-width mb-l justify-content-between align-items-start number-hide-arrows fs-s">
          <div className="parent-width">
            <DKLabel
              text={`Primary Conversion: ${NumberFormatService.getNumber(
                PrimaryCurrTotal
              )}(${primaryCurrencyCode}) at`}
            />
            <div
              className={
                'row border rounded border-gray-300 w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder p-1 mt-s ' +
                'focus:outline-none focus:ring-2 focus:ring-blue-200'
              }
            >
              <div
                className="row currency-select-trigger align-items-center content-start"
                style={{ width: 'fit-content' }}
              >
                <div className="ml-1">{`1 ${tenantInfo.currency}`}</div>
                <div className="currency-dropdown-flag ml-2">
                  <span
                    className={`currency-dropdown-selector-flag flag ${tenantInfo.currency}`}
                  ></span>
                </div>
                <DKIcon
                  className="ml-r"
                  style={{
                    transform: 'rotate(90deg)'
                  }}
                  src={DKIcons.ic_sort}
                />
              </div>
              <div className="row align-items-start content-end ml-s">
                <input
                  onBlur={(e) => {
                    const rate = e.target.value
                      ? +e.target.value
                      : expenseBillState.primaryExchangeRate;
                    if (
                      +e.target.value !== 0 &&
                      expenseBillState.primaryExchangeRate !==
                        1 / (rate as number)
                    ) {
                      onPrimaryCurrencyChange(rate as number);
                    }
                  }}
                  className={`text-align-right outline-none hover:bg-blue-100 focus:bg-blue-100`}
                  value={tempPrimaryExchangeRate ?? 1}
                  type={'number'}
                  onChange={(e: any) => {
                    setTempPrimaryExchangeRate(+e.target.value);
                  }}
                  style={{ width: '62%' }}
                  disabled={
                    props.documentMode === DOCUMENT_MODE.VIEW ||
                    checkifCustomFieldCanEdit === false
                  }
                />
                <div className="ml-1">{`${primaryCurrencyCode}`}</div>
                <div className="currency-dropdown-flag ml-2">
                  <span
                    className={`currency-dropdown-selector-flag flag ${primaryCurrencyCode}`}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const getCurrencySelector = () => {
    return (
      <div className="position-relative ml-s">
        <DKButton
          title={expenseBillState.docCurrencyCode}
          className="text-black bg-gray-200 border-radius-l"
          style={{
            padding: '2px 12px'
          }}
          onClick={() => {
            if (tenantInfo.multicurrencyEnabled && checkifCustomFieldCanEdit) {
              setShowMultiCurrencyList((prevValue) => !prevValue);
            }
          }}
          icon={tenantInfo.multicurrencyEnabled && DKIcons.ic_arrow_down2}
          isReverse
        />
        {tenantInfo.multicurrencyEnabled && showMultiCurrencyList && (
          <DKListPicker2
            title="Currencies"
            data={activeMultiCurrencyList.filter((item: any) => {
              return item.currencyStatus === 'ACTIVE';
            })}
            style={{
              width: 280
            }}
            allowSearch={true}
            searchableKey="currencyName"
            className="position-absolute z-index-3 right-0 bottom-7 shadow-m"
            onSelect={async (index: number, currency: any) => {
              const currencyCode = currency?.currencyCode;
              if (expenseBillState?.docCurrencyCode !== currencyCode) {
                try {
                  showLoader();
                  const currencyHistory = await getCurrencyHistoryViaCode(
                    currencyCode
                  );
                  setCurrencyHistory(currencyHistory);
                  const docDateFilteredCurr: any =
                    await getExchangeRateDetailsFromHistory(
                      currencyHistory,
                      documentDate,
                      currencyCode
                    );
                  const currentExchangeRate =
                    docDateFilteredCurr?.currencyExchangeRate;
                  updateCurrencyAndExchangeRate(
                    currencyCode,
                    currentExchangeRate
                  );
                } finally {
                  removeLoader();
                }
              }
              setShowMultiCurrencyList(false);
            }}
            onClose={() => {
              setTimeout(() => {
                setShowMultiCurrencyList(false);
              }, 100);
            }}
            renderer={(index: number, obj: any) => {
              return (
                <div className="row parent-width justify-content-between">
                  <div>{obj.currencyName}</div>
                  <div className="ml-s">{`(${obj.currencyCode})`}</div>
                </div>
              );
            }}
          />
        )}
      </div>
    );
  };
  const validateAndUpdateDate = (
    type: EXPENSE_BILL_CALENDAR_TYPE,
    newDate: Date,
    minAcceptedDate: Date,
    warningMessage: string,
    isDocDate: boolean,
    oldDate?: Date
  ) => {
    if (newDate.getTime() >= minAcceptedDate.getTime()) {
      let closeDateFY: Date = Utility.getCloseDateFY();
      const tenantCloseDateFY = tenantInfo.fyClosingPeriodEndDate;
      if (tenantCloseDateFY && closeDateFY.getTime() > newDate.getTime()) {
        if (typeof oldDate !== 'undefined' && oldDate !== null) {
          dateSelected(type, oldDate);
        }
        showAlert(
          'Invalid Date',
          `${`Bill date should not before financial year close date`}`
        );
        return;
      }
      if (isDocDate) {
        if (
          !Utility.checkActiveDateRangeValidation(
            newDate,
            tenantInfo,
            'Bill date',
            'PURCHASE_INVOICE'
          )
        ) {
          return;
        }
      }
      dateSelected(type, newDate);
    } else {
      if (typeof oldDate !== 'undefined' && oldDate !== null) {
        dateSelected(type, oldDate);
      }
      showAlert('Invalid Date', getCapitalized(warningMessage.toLowerCase()));
    }
  };

  const updateCurrencyAndExchangeRate = (
    currencyCode: string,
    exchangeRate: number
  ) => {
    const preciseCurrencyExchangeRate = Utility.roundOff(
      1 / exchangeRate,
      CURRENCY_PRECISION
    );
    updateCurrency({
      currencyCode: currencyCode,
      exchangeRate: 1 / preciseCurrencyExchangeRate,
      gstExchangeRate: preciseCurrencyExchangeRate
    });
    setTempExchangeRate(preciseCurrencyExchangeRate);
  };

  const updateCurrency = ({
    currencyCode,
    exchangeRate,
    gstExchangeRate
  }: any) => {
    const updatedState = expenseBillState;
    const oldCurrency = tenantDetails.currency;
    const currencyChanged = oldCurrency !== currencyCode;
    const previousExchangeRate = 1;

    let calculatedGSTExchangeRate = 1;
    if (currencyChanged && gstExchangeRate) {
      calculatedGSTExchangeRate = gstExchangeRate / previousExchangeRate;
    }

    updatedState.docCurrencyCode = currencyCode;
    updatedState.docExchangeRate = exchangeRate;
    updatedState.gstExchangeRate = calculatedGSTExchangeRate;
    updatedState.previousExchangeRate = previousExchangeRate;

    let primaryExchangeRate = updatedState?.primaryExchangeRate;
    updatedState.primaryExchangeRate = primaryExchangeRate;
    setTempPrimaryExchangeRate(primaryExchangeRate);
    updateInlineItemCurrencyCode();
    reCalculateAmounts(updatedState);
  };

  const updateCurrencyExchangeRate = (exchangeRate: string) => {
    const updatedState = expenseBillState;
    if (!isNaN(Number(exchangeRate))) {
      updatedState.docExchangeRate = Number(exchangeRate);
      reCalculateAmounts(updatedState);
    }
  };

  const getCurrencyHistoryViaCode = async (code: string) => {
    if (Utility.isNotEmpty(code)) {
      const prevConfig = CurrencyService.currencyHistoryApiConfig;
      CurrencyService.currencyHistoryApiConfig = {
        Limit: 1000,
        Page: 0,
        SortDir: CURRENCY_HISTORY_CONSTANTS.SortDir,
        Sort: CURRENCY_HISTORY_CONSTANTS.Sort,
        Dir: CURRENCY_HISTORY_CONSTANTS.Dir,
        Start: 0,
        Query: `currencyCode=${code}`
      };
      const response: any = await CurrencyService.getCurrencyHistrory();
      CurrencyService.currencyHistoryApiConfig = prevConfig;
      return response?.content ?? [];
    }
    return [];
  };

  const updateInlineItemCurrencyCode = () => {
    let config = columnConfig;

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'amount':
          conf.formatter = (data: any) => {
            return DocumentConfigUtility.amountFormatter(
              data.value,
              expenseBillState.docCurrencyCode
            );
          };
          break;
        case 'taxAmount':
          conf.hidden = !showTaxHeader();
          conf.formatter = (data: any) => {
            return DocumentConfigUtility.amountFormatter(
              data.value,
              expenseBillState.docCurrencyCode
            );
          };
          break;
        case 'totalAmount':
          conf.formatter = (data: any) =>
            DocumentConfigUtility.amountFormatter(
              data.value,
              expenseBillState.docCurrencyCode
            );
          break;
        default:
          break;
      }
    });
    setColumnConfig(config.filter((col: any) => !col.hidden));
  };

  const getCalendarView = (
    selectedDate: any,
    onSelect: any,
    toggleView: any
  ) => {
    return (
      <DKCalendar
        className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
        style={{ right: 0, top: 20 }}
        selectedDate={selectedDate}
        onSelectDate={(newDate: Date) => {
          onSelect(newDate);
          toggleView(false);
        }}
        onClose={() => setTimeout(() => toggleView(false))}
      />
    );
  };

  const getRightInfoPanel = () => {
    return (
      <div className="column align-items-end">
        <div className="position-relative">
          <div
            className="row width-auto mb-l justify-content-between"
            style={{
              width: 200
            }}
          >
            <div className="row width-auto">
              <DKIcon
                src={DKIcons.data_type.ic_number}
                className="ic-s"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text={'No.'} className={'fw-m ml-r'} />
            </div>
            {(props.documentMode === DOCUMENT_MODE.EDIT ||
              props.documentMode === DOCUMENT_MODE.VIEW ||
              props.populateFormData?.seqCodeAlreadyDumped === true) &&
              props.populateFormData.documentSequenceCode && (
                <DKLabel
                  text={expenseBillState.documentSequenceCode}
                  className={'ml-r '}
                />
              )}
            {(props.documentMode === DOCUMENT_MODE.NEW ||
              props.documentMode === DOCUMENT_MODE.COPY ||
              (props.documentMode === DOCUMENT_MODE.VIEW &&
                !props.populateFormData.documentSequenceCode)) &&
              !props.populateFormData?.seqCodeAlreadyDumped && (
                <div className="-mr-1">
                  <CustomNumberFormatInput
                    module={CUSTOM_NUMBER_INPUT_MODULES.BILL}
                    selectedFormat={selectedFormat}
                    showCompact={true}
                    extraClass={'top-12 right-0'}
                  />
                </div>
              )}
          </div>
        </div>

        {getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST && (
          <>
            <div
              style={{
                wordBreak: 'break-word',
                width: '200px'
              }}
              className="row width-auto mb-l justify-start  cursor-pointer relative items-center"
            >
              <DKTooltipWrapper
                content={`
                    Click gear icon in COA to
                    check/map default RCM account`}
              >
                <DKIcon
                  src={DKIcons.ic_info}
                  className=" ic-r  ic-s  cursor-hand unselectable"
                  style={{ opacity: 1 }}
                />
              </DKTooltipWrapper>
              <DKCheckMark
                color="bg-button"
                isSelected={expenseBillState.applyRcmCheck}
                onClick={() => {
                  handleAndUpdateRCMCheck(!expenseBillState.applyRcmCheck);
                }}
                className="text-black z-index-1 ml-s"
                title={'Is RCM Applicable'}
              />
            </div>
          </>
        )}
        {!isDocumentInFullScreen && (
          <>
            <div className="position-relative">
              <div
                className="row width-auto mb-l justify-content-between cursor-pointer"
                style={{
                  width: 200
                }}
                onClick={() => {
                  if (checkifCustomFieldCanEdit) {
                    showCalendar(EXPENSE_BILL_CALENDAR_TYPE.BILL_DATE);
                  }
                }}
              >
                <div className="row width-auto">
                  <DKIcon
                    src={DKIcons.data_type.ic_date}
                    className="ic-s"
                    style={{ opacity: 0.6 }}
                  />
                  <DKLabel text={'Bill Date'} className={'fw-m ml-r'} />
                </div>
                <DKLabel
                  text={
                    expenseBillState.billDate.date
                      ? DateFormatService.getFormattedDateString(
                          expenseBillState.billDate.date,
                          BOOKS_DATE_FORMAT['DD-MM-YYYY']
                        )
                      : ''
                  }
                  className={'ml-r '}
                />
              </div>
              {expenseBillState.billDate.isOpen &&
                getCalendarView(
                  DateFormatService.getDateFromStr(
                    expenseBillState.billDate.date,
                    BOOKS_DATE_FORMAT['DD-MM-YYYY']
                  ),
                  (newDate: any) => {
                    validateAndUpdateDate(
                      EXPENSE_BILL_CALENDAR_TYPE.BILL_DATE,
                      newDate,
                      DateFormatService.getDateFromStr(
                        tenantInfo.bookBeginningStartDate,
                        BOOKS_DATE_FORMAT['YYYY-MM-DD']
                      ),
                      `Bill date cannot be before books beginning date.`,
                      true
                    );
                    updateConfig();
                    setTimeout(() => {
                      updateLineItemForSGTaxCalculation();
                    }, 100);
                    setDocumentDate(newDate);
                    setIsdocumentDateChanged(true);
                  },
                  (isOpen: any) => {
                    hideCalendar();
                  }
                )}
            </div>
            <div className="position-relative">
              <div
                className="row width-auto mb-l justify-content-between cursor-pointer"
                style={{
                  width: 200
                }}
                onClick={() => {
                  if (checkifCustomFieldCanEdit) {
                    showCalendar(EXPENSE_BILL_CALENDAR_TYPE.DUE_DATE);
                  }
                }}
              >
                <div className="row width-auto">
                  <DKIcon
                    src={DKIcons.data_type.ic_date}
                    className="ic-s"
                    style={{ opacity: 0.6 }}
                  />
                  <DKLabel text={'Due Date'} className={'fw-m ml-r'} />
                </div>
                <DKLabel
                  text={
                    expenseBillState.dueDate.date
                      ? DateFormatService.getFormattedDateString(
                          expenseBillState.dueDate.date,
                          BOOKS_DATE_FORMAT['DD-MM-YYYY']
                        )
                      : ''
                  }
                  className={'ml-r '}
                />
              </div>
              {expenseBillState.dueDate.isOpen &&
                getCalendarView(
                  DateFormatService.getDateFromStr(
                    expenseBillState.dueDate.date,
                    BOOKS_DATE_FORMAT['DD-MM-YYYY']
                  ),
                  (newDate: any) =>
                    validateAndUpdateDate(
                      EXPENSE_BILL_CALENDAR_TYPE.DUE_DATE,
                      newDate,
                      DateFormatService.getDateFromStr(
                        expenseBillState.billDate.date,
                        BOOKS_DATE_FORMAT['DD-MM-YYYY']
                      ),
                      `Due date cannot be before bill date.`,
                      false
                    ),
                  (isOpen: any) => {
                    hideCalendar();
                  }
                )}
            </div>
            <div className="position-relative">
              <div
                className="row width-auto mb-m justify-content-between"
                style={{
                  minWidth: 240
                }}
              >
                <div className="row width-auto">
                  <DKIcon
                    src={DKIcons.data_type.ic_number}
                    className="ic-xs-2"
                    style={{ opacity: 0.6 }}
                  />
                  <DKLabel text={'Supplier Inv No.'} className={'fw-m ml-r'} />
                </div>
                <div style={{ width: 100 }}>
                  <DKInput
                    title=""
                    textAlign="right"
                    value={expenseBillState.supplierInvoiceNo}
                    valueStyle={{
                      paddingTop: 0,
                      paddingBottom: 0
                    }}
                    canValidate={false}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    type={INPUT_TYPE.TEXT}
                    readOnly={
                      (props.documentMode === DOCUMENT_MODE.VIEW &&
                        props.draftData.draftType === DraftTypes.READONLY) ||
                      checkifCustomFieldCanEdit === false
                    }
                    onChange={(value: string) => {
                      setExpenseBillState((prevState: any) => {
                        return {
                          ...prevState,
                          supplierInvoiceNo: value
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            {tenantInfo.country === COUNTRY_CODES.IL &&
              !isDocumentInFullScreen && (
                <div className="position-relative walkthrough-step-5">
                  <div
                    className={`row width-auto mb-xs justify-content-between`}
                    style={{
                      width: 240
                    }}
                  >
                    <div className="row width-auto cursor-pointer">
                      <DKIcon
                        src={DKIcons.data_type.ic_number}
                        className="ic-xs-2"
                        style={{ opacity: 0.6 }}
                      />
                      <DKLabel text={'Tax Payer Id'} className={'fw-m ml-r'} />
                    </div>
                    {
                      <div
                        style={{
                          overflowWrap: 'break-word',
                          whiteSpace: 'pre-wrap',
                          minWidth: '100px'
                        }}
                        className="-mr-1"
                      >
                        <DKInput
                          required={false}
                          direction={INPUT_VIEW_DIRECTION.VERTICAL}
                          readOnly={true}
                          type={INPUT_TYPE.TEXT}
                          value={
                            expenseBillState?.contact?.taxPayerIdIsrael || ''
                          }
                          onChange={(value: any) => {}}
                        />
                      </div>
                    }
                  </div>
                  <div
                    className={`row width-auto mb-xs justify-content-between`}
                    style={{
                      width: 240
                    }}
                  >
                    <div className="row width-auto cursor-pointer">
                      <DKIcon
                        src={DKIcons.data_type.ic_number}
                        className="ic-xs-2"
                        style={{ opacity: 0.6 }}
                      />
                      <DKLabel
                        text={'Tax Registration Number'}
                        className={'fw-m ml-r'}
                      />
                    </div>
                    {
                      <div
                        style={{
                          overflowWrap: 'break-word',
                          whiteSpace: 'pre-wrap',
                          minWidth: '100px'
                        }}
                        className="-mr-1"
                      >
                        <DKInput
                          required={false}
                          direction={INPUT_VIEW_DIRECTION.VERTICAL}
                          readOnly={true}
                          type={INPUT_TYPE.TEXT}
                          value={expenseBillState?.contact?.taxNumber || ''}
                          onChange={(value: any) => {}}
                        />
                      </div>
                    }
                  </div>
                </div>
              )}
          </>
        )}
      </div>
    );
  };

  const getAddressCard = (title: string) => {
    let address = tenantInfo?.address;
    if (
      title === t(`DOCUMENT.BILL_TO`) &&
      Utility.isNotEmpty(tenantInfo.billingAddresses)
    ) {
      address = Utility.getPreferredAddress(tenantInfo, 'billingAddresses');
    } else {
      if (
        title === t(`DOCUMENT.SHIP_TO`) &&
        Utility.isNotEmpty(tenantInfo.shippingAddresses)
      ) {
        address = Utility.getPreferredAddress(tenantInfo, 'shippingAddresses');
      }
    }

    return (
      <>
        <div className={`row width-auto`}>
          <DKLabel text={title} className="fw-b fs-r text-gray" />
        </div>
        <div className={`row`}>
          <DKLabel text={tenantInfo.name} className="fw-m fs-r" />
        </div>
        <DKLabel
          text={!Utility.isEmpty(address) ? getFormattedAddress(address) : ''}
        />
      </>
    );
  };

  const getTenantAddressDetails = () => {
    return (
      <div>
        <div className="row width-auto align-items-start">
          <div
            className={`column document-address-block`}
            style={{
              minWidth: 170,
              maxWidth: 250,
              marginLeft: -10,
              padding: 10
            }}
          >
            {customLocation
              ? getCustomCompanyDetails(t(`DOCUMENT.BILL_TO`))
              : getAddressCard(t(`DOCUMENT.BILL_TO`))}
          </div>
          <div
            className={`column document-address-block`}
            style={{
              minWidth: 170,
              maxWidth: 250,
              marginLeft: 10,
              padding: 10
            }}
          >
            {customLocation
              ? getCustomCompanyDetails(t(`DOCUMENT.SHIP_TO`))
              : getAddressCard(t(`DOCUMENT.SHIP_TO`))}
          </div>
        </div>
      </div>
    );
  };

  const getDataGrid = () => {
    return (
      <DKDataGrid
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        allowColumnShift={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={
          expenseBillState.gridInfo.inlineItems
            ? expenseBillState.gridInfo.inlineItems.map((rowData: any) => ({
                ...rowData,
                rowContextMenu:
                  props.documentMode !== DOCUMENT_MODE.VIEW ||
                  props.draftData.draftType !== DraftTypes.READONLY
                    ? getContextMenu(rowData)
                    : []
              }))
            : []
        }
        columns={[
          ...columnConfig,
          {
            id: 'action',
            key: 'action',
            name: '',
            type: INPUT_TYPE.BUTTON,
            width: 50,
            options: []
          }
        ]}
        onRowUpdate={onRowUpdate}
        onRowClick={onRowClick}
        width={gridWidth}
      />
    );
  };

  // const addNewItem = () => {
  //   let rows = [...productRows];
  //   let newRow = DocumentConfigManager.getBlankRow(DOC_TYPE.EXPENSE_BILL);
  //   newRow.invalidFields = DocumentConfigManager.getRequiredFields(
  //     DOC_TYPE.EXPENSE_BILL
  //   );
  //   newRow['amount'] = 1;
  //   rows.push(newRow);
  //   setProductRows(rows);
  // };

  const addNewItem = (returnNewRow: boolean = false) => {
    const updatedState = { ...expenseBillState };
    let newRow = deepClone(expenseBillGridItem);

    if (newRow) {
      newRow.invalidFields = DocumentConfigManager.getRequiredFields(
        DOC_TYPE.EXPENSE_BILL
      );
    }

    // Set default values of CFs when new line is added
    availableCustomFields?.content?.forEach((item: any) => {
      if (
        item.modules?.includes(MODULES_NAME.ACCOUNT) &&
        item.status === STATUS_TYPE.ACTIVE &&
        item.shortName !== 'class'
      ) {
        if (item.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
          if (
            typeof item.defaultValue !== 'undefined' &&
            item.defaultValue !== null &&
            item.defaultValue !== ''
          ) {
            newRow[item.id] = DateFormatService.getDateFromStr(
              item.defaultValue,
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else {
            newRow[item.id] = '';
          }
        } else {
          if (
            typeof item.defaultValue !== 'undefined' &&
            item.defaultValue !== null &&
            item.defaultValue !== ''
          ) {
            newRow[item.id] = item.defaultValue;
          } else {
            newRow[item.id] = '';
          }
        }
      }
    });

    if (returnNewRow) {
      return newRow;
    } else {
      updatedState.gridInfo.inlineItems.push(newRow);
      setProductRows(
        (prevState: DocumentItem[]) => updatedState.gridInfo.inlineItems
      );
      setExpenseBillState({ ...updatedState });
    }
  };

  const onDelete = ({ rowIndex }: any) => {
    const updatedState = { ...expenseBillState };
    updatedState.gridInfo.inlineItems.splice(rowIndex, 1);
    setExpenseBillState({
      ...updatedState
    });
    setTimeout(() => {
      const updatedState = { ...expenseBillState };
      reCalculateAmounts(updatedState);
    }, 500);
  };

  const getDefaultTaxMemoOnTaxChange = (lineUpdates: any) => {
    let memoText = '';
    if (getTenantTaxSystem() === TAX_SYSTEM.UK) {
      if (
        lineUpdates.tax &&
        lineUpdates.tax.defaultMemoUk &&
        lineUpdates.tax.defaultMemoUk !== ''
      ) {
        memoText = lineUpdates?.tax?.defaultMemoUk;
      }
    }

    return memoText.trim();
  };

  const updateLineItemList = (updates: any, rowIndex: any, columnKey?: any) => {
    let updatedState = { ...expenseBillState };
    if (
      getTenantTaxSystem() !== TAX_SYSTEM.INDIA_GST &&
      getTenantTaxSystem() !== TAX_SYSTEM.US
    ) {
      updatedState.gridInfo.inlineItems[rowIndex]['userSetTaxes'] = false;
    }
    updatedState.gridInfo.inlineItems[rowIndex].invalidFields = updatedState
      .gridInfo.inlineItems[rowIndex].invalidFields
      ? [...updatedState.gridInfo.inlineItems[rowIndex].invalidFields].filter(
          (field) => field !== columnKey
        )
      : [];
    updatedState.gridInfo.inlineItems[rowIndex].gstType = gstType;

    if (columnKey === 'account') {
      updatedState.gridInfo.inlineItems[rowIndex][columnKey] =
        updates[columnKey];
      if (updatedState.gridInfo.inlineItems[rowIndex]['account']) {
        updatedState.gridInfo.inlineItems[rowIndex].invalidFields = [];
      }
      //description
      updatedState.gridInfo.inlineItems[rowIndex]['description'] =
        updates[columnKey].description;

      const taxDetails = taxList.find(
        (tax) => tax.code === updates[columnKey].taxCode
      );
      //tax
      updatedState.gridInfo.inlineItems[rowIndex]['tax'] =
        tenantInfo.country === COUNTRY_CODES.SG
          ? getUpdatedSGTax(taxDetails)
          : Utility.isNotEmpty(taxDetails)
          ? taxDetails
          : '';

      if (+updatedState.gridInfo.inlineItems[rowIndex]['amount'] === 0) {
        updatedState.gridInfo.inlineItems[rowIndex].invalidFields.push(
          'amount'
        );
      }

      // Handle custom fields when account is selected
      const availableCFs: any[] = accountsCFData;
      availableCFs.forEach((availableCF: any) => {
        const existingCF = updates[columnKey]?.customField?.find(
          (cf: any) => cf.id === availableCF.id
        );
        if (existingCF) {
          if (
            typeof existingCF.value !== 'undefined' &&
            existingCF.value !== null &&
            existingCF.value !== ''
          ) {
            if (
              availableCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              updatedState.gridInfo.inlineItems[rowIndex][availableCF.id] =
                DateFormatService.getDateFromStr(
                  existingCF.value,
                  BOOKS_DATE_FORMAT['MM/DD/YYYY']
                );
            } else if (
              availableCF.fieldType.toLowerCase() ===
              CUSTOM_FIELD_TYPE.USER.toLowerCase()
            ) {
              const cfValue = availableCF?.attributes?.find(
                (attr: any) => attr.code === existingCF.value
              );
              updatedState.gridInfo.inlineItems[rowIndex][availableCF.id] =
                cfValue ? cfValue : '';
            } else {
              updatedState.gridInfo.inlineItems[rowIndex][availableCF.id] =
                existingCF.value;
            }
          } else {
            updatedState.gridInfo.inlineItems[rowIndex][availableCF.id] = '';
          }
        } else {
          updatedState.gridInfo.inlineItems[rowIndex][availableCF.id] = '';
        }
      });
      updatedState.gridInfo.inlineItems[rowIndex] =
        checkIfLineLevelCustomFieldIsValid(
          updatedState.gridInfo.inlineItems[rowIndex],
          accountsCFData
        );
    } else if (columnKey === 'amount') {
      updatedState.gridInfo.inlineItems[rowIndex][columnKey] = Utility.roundOff(
        updates[columnKey],
        CURRENCY_PRECISION
      );
      if (+updatedState.gridInfo.inlineItems[rowIndex]['amount'] === 0) {
        updatedState.gridInfo.inlineItems[rowIndex].invalidFields.push(
          'amount'
        );
      }
    } else {
      const configForColumnKey = columnConfig.find(
        (config: any) => config.key === columnKey
      );

      if (configForColumnKey.isCustomField) {
        const cfInfo: any = customFieldsData.find(
          (cf: any) => cf.id === columnKey
        );
        if (cfInfo) {
          if (
            cfInfo.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
          ) {
            updatedState.gridInfo.inlineItems[rowIndex][columnKey] = new Date(
              updates[columnKey]
            );
          } else if (
            cfInfo.fieldType.toLowerCase() ===
            CUSTOM_FIELD_TYPE.USER.toLowerCase()
          ) {
            // Use updates[columnKey].value for User or Dropdown type
            updatedState.gridInfo.inlineItems[rowIndex][columnKey] =
              updates[columnKey];
          } else if (
            cfInfo.fieldType.toLowerCase() ===
            CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
          ) {
            updatedState.gridInfo.inlineItems[rowIndex][columnKey] =
              updates[columnKey].value === 'None' ? null : updates[columnKey];
            cfUpdatedTimeMap.current = {
              ...cfUpdatedTimeMap.current,
              [cfInfo.id]: new Date().getTime()
            };
            const availableAccountCFs: any[] = accountsCFData;
            const { rowData } = updateRowDataWithParentCFValues(
              updates[columnKey].value === 'None' ? null : updates[columnKey],
              { ...updatedState.gridInfo.inlineItems[rowIndex] },
              cfInfo,
              availableAccountCFs
            );
            updatedState.gridInfo.inlineItems[rowIndex] = rowData;
          } else {
            updatedState.gridInfo.inlineItems[rowIndex][columnKey] =
              updates[columnKey];
          }
        }
        updatedState.gridInfo.inlineItems[rowIndex] =
          checkIfLineLevelCustomFieldIsValid(
            updatedState.gridInfo.inlineItems[rowIndex],
            accountsCFData
          );
      } else {
        // Handle rest of the columns
        updatedState.gridInfo.inlineItems[rowIndex][columnKey] =
          updates[columnKey];
      }
    }

    newCalculations(updates, rowIndex, columnKey);
  };

  const updateTaxDetails = (item: any) => {
    let taxGroupDetails: any[] = [];
    if (!taxGroupDetails || !taxGroupDetails.length) {
      const taxDetails = calculateTaxGroup(item.tax, item.amount);
      if (item?.isRcmApplied || gstType === GST_TYPE.EXEMPT) {
        let updateTax: any = taxDetails.map((details: any) => {
          return { ...details, taxAmount: 0 };
        });
        taxGroupDetails = updateTax;
      } else {
        taxGroupDetails = taxDetails;
      }
    }
    return taxGroupDetails;
  };

  const newCalculations = (updates: any, rowIndex: any, columnKey?: any) => {
    let updatedState = expenseBillState;
    let isRcmApplied = false;

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      if (updatedState?.applyRcmCheck === true) {
        if (
          Utility.isEmpty_v2(updatedState.gridInfo.inlineItems[rowIndex]?.tax)
        ) {
          updatedState.gridInfo.inlineItems[rowIndex].tax = taxList.find(
            (tax) => tax.taxCode === 'GST0'
          );
        }
      }
    }

    if (Utility.isNotEmpty(columnKey) && columnKey === 'tax') {
      updatedState.gridInfo.inlineItems[rowIndex].tax = updates[columnKey];
      if (!Utility.isEmpty(updatedState.gridInfo.inlineItems[rowIndex].tax)) {
        updatedState.memo = getDefaultTaxMemoOnTaxChange(
          updatedState.gridInfo.inlineItems[rowIndex]
        );
      }
    }

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      updatedState.gridInfo.inlineItems[rowIndex].itcAdjustment =
        updatedState.gridInfo.inlineItems[rowIndex]?.account?.itcAdjustment;
      isRcmApplied = rcmAppliedIndiaWithCheckRCMApply(
        DOC_TYPE.EXPENSE_BILL,
        contact?.gstTreatment,
        updatedState.gridInfo.inlineItems[rowIndex],
        updatedState?.applyRcmCheck
      );
      updatedState.gridInfo.inlineItems[rowIndex].isRcmApplied = isRcmApplied;
    } else {
      updatedState.gridInfo.inlineItems[rowIndex].isRcmApplied = false;
    }

    const tax = taxList.find(
      (tax) =>
        tax.code === updatedState.gridInfo.inlineItems[rowIndex]?.tax?.code
    );
    let calculatedItem = calculateTaxGroupDetails(
      updatedState.gridInfo.inlineItems[rowIndex]
    );

    if (updatedState.gridInfo.inlineItems[rowIndex]['userSetTaxes'] !== true) {
      updatedState.gridInfo.inlineItems[rowIndex]['totalAmount'] =
        calculatedItem?.lineLevelTotal;
      updatedState.gridInfo.inlineItems[rowIndex]['taxAmount'] =
        calculatedItem?.taxAmount;
    }

    // taxDetails
    if (tax) {
      updatedState.gridInfo.inlineItems[rowIndex]['taxDetails'] =
        updateTaxDetails({
          ...updatedState.gridInfo.inlineItems[rowIndex]
        });
    }

    //summary-start
    let totalAmountWithoutTax: number = 0;
    let totalTaxAmountInSummary: number = 0;
    let totalTDS = 0;
    updatedState.gridInfo.inlineItems
      .filter((item: any) => !Utility.isEmpty(item.account))
      .forEach((item: any, lineRowIndex: any) => {
        const amount: number = Number(
          updatedState.gridInfo.inlineItems[lineRowIndex].amount
        );
        if (updatedState.docCurrencyCode !== tenantDetails.currency) {
          updatedState.gridInfo.inlineItems[lineRowIndex].baseAmount =
            Number(amount) / Number(updatedState.docExchangeRate);
        } else {
          updatedState.gridInfo.inlineItems[lineRowIndex].baseAmount =
            Number(amount);
        }

        let taxAmount = 0;
        if (showTaxHeader()) {
          taxAmount = item?.taxAmount;
        }
        updatedState.gridInfo.inlineItems[lineRowIndex].taxAmount = taxAmount;

        if (!item?.isRcmApplied) {
          totalTaxAmountInSummary = totalTaxAmountInSummary + taxAmount;
        }

        if (Number(amount)) {
          totalAmountWithoutTax = totalAmountWithoutTax + Number(amount);
          if (unitPriceGstInclusive) {
            totalAmountWithoutTax = totalAmountWithoutTax - taxAmount;
          }
        }
        if (typeof item.tdsAmount !== 'undefined' && item.tdsAmount !== null) {
          totalTDS += item.tdsAmount;
        }
      });
    let summarySubTotal = totalAmountWithoutTax - totalTDS;

    let summaryTotalAmount = summarySubTotal + totalTaxAmountInSummary;

    updatedState.isDocumentTouched = true;
    updatedState.summaryInfo = {
      ...updatedState.summaryInfo,
      subTotal: Utility.roundingOff(summarySubTotal, CURRENCY_PRECISION),
      taxAmount: Utility.roundingOff(
        totalTaxAmountInSummary,
        CURRENCY_PRECISION
      ),
      totalTdsAmount: Utility.roundingOff(totalTDS, CURRENCY_PRECISION),
      totalAmount: Utility.roundingOff(summaryTotalAmount, CURRENCY_PRECISION)
    };
    // update total amount in base currency
    if (updatedState.docCurrencyCode !== tenantDetails.currency) {
      updatedState.amountInBaseCurrency = Utility.roundOff(
        Number(updatedState.summaryInfo.totalAmount) /
          Number(updatedState.docExchangeRate),
        CURRENCY_PRECISION
      );
    } else {
      updatedState.amountInBaseCurrency = Utility.roundingOff(
        Number(updatedState.summaryInfo.totalAmount),
        CURRENCY_PRECISION
      );
    }
    //summary-end

    let updateLineTaxBifurcation = updateTaxBifurcation(
      updatedState.gridInfo.inlineItems[rowIndex]
    );
    updatedState.gridInfo.inlineItems[rowIndex] = updateLineTaxBifurcation;
    if (isRcmApplied) {
      updatedState.gridInfo.inlineItems[rowIndex]['totalAmount'] =
        calculatedItem?.lineLevelTotal - calculatedItem?.taxAmount;
      updatedState.gridInfo.inlineItems[rowIndex]['taxAmount'] = 0;
      updatedState.gridInfo.inlineItems[rowIndex]['cgstRate'] = 0;
      updatedState.gridInfo.inlineItems[rowIndex]['sgstRate'] = 0;
      updatedState.gridInfo.inlineItems[rowIndex]['igstRate'] = 0;
    }
    if (
      Store.getState().authInfo.currentTenantInfo.data.additionalSettings
        ?.ROUND_OFF?.autoRoundOff &&
      recalculateRounding.current &&
      !isManualRoundOff.current
    ) {
      const roundOffDiff = calculateRoundOffDiff(
        updatedState.summaryInfo['totalAmount']
      );

      updatedState.summaryInfo['totalAmount'] = Math.round(
        updatedState.summaryInfo['totalAmount'] + roundOffDiff
      );
      updatedState.summaryInfo['roundOffDifference'] = roundOffDiff;
    }
    setExpenseBillState({ ...updatedState });
  };

  const calculateRoundOffDiff = (tmpTotal: number) => {
    //Auto round off feature
    recalculateRounding.current = false;
    let roundOffDiff = 0;
    let tmpTarget: any = {};
    let roundOffValue1: any = {};
    switch (
      Store.getState().authInfo.currentTenantInfo.data.additionalSettings
        ?.ROUND_OFF?.roundingMethod
    ) {
      case ROUNDING_METHOD.ROUND_OFF:
        roundOffDiff = Utility.roundOff(
          Math.round(tmpTotal) - tmpTotal,
          CURRENCY_PRECISION
        );
        roundOffValue1['value'] = roundOffDiff;
        tmpTarget['target'] = roundOffValue1;
        handleRoundingOffChange(tmpTarget, tmpTotal);
        break;
      case ROUNDING_METHOD.ROUND_UP:
        roundOffDiff = Utility.roundOff(
          Math.ceil(tmpTotal) - tmpTotal,
          CURRENCY_PRECISION
        );
        roundOffValue1['value'] = roundOffDiff;
        tmpTarget['target'] = roundOffValue1;
        handleRoundingOffChange(tmpTarget, tmpTotal);
        break;
      case ROUNDING_METHOD.ROUND_DOWN:
        roundOffDiff = Utility.roundOff(
          Math.floor(tmpTotal) - tmpTotal,
          CURRENCY_PRECISION
        );
        roundOffValue1['value'] = roundOffDiff;
        tmpTarget['target'] = roundOffValue1;
        handleRoundingOffChange(tmpTarget, tmpTotal);
        break;
      default:
        roundOffDiff = Utility.roundOff(
          Math.round(tmpTotal) - tmpTotal,
          CURRENCY_PRECISION
        );
        roundOffValue1['value'] = roundOffDiff;
        tmpTarget['target'] = roundOffValue1;
        handleRoundingOffChange(tmpTarget, tmpTotal);
        break;
    }

    return roundOffDiff;
  };

  const reCalculateAmounts = (updatedState: any) => {
    //summary-start
    let totalAmountWithoutTax: number = 0;
    let totalTaxAmountInSummary: number = 0;
    let totalTDS = 0;
    updatedState.gridInfo.inlineItems
      .filter((item: any) => !Utility.isEmpty(item.account))
      .forEach((item: any, rowIndex: any) => {
        const amount: number = convertToCurrenctExchangeRate(
          updatedState.docExchangeRate,
          updatedState.previousExchangeRate,
          Number(updatedState.gridInfo.inlineItems[rowIndex].baseAmount)
        );
        updatedState.gridInfo.inlineItems[rowIndex].amount = amount;

        let taxAmount = 0;
        if (showTaxHeader()) {
          const tax = taxList.find((tax) => tax.code === item?.tax?.code);
          // taxDetails
          if (tax) {
            updatedState.gridInfo.inlineItems[rowIndex]['taxDetails'] =
              updateTaxDetails({
                ...updatedState.gridInfo.inlineItems[rowIndex]
              });
          }

          const taxDetails: any[] =
            updatedState.gridInfo.inlineItems[rowIndex]['taxDetails'];

          if (taxDetails) {
            taxDetails.forEach((taxDetail: any) => {
              taxAmount += Utility.roundOff(
                taxDetail.taxAmount,
                CURRENCY_PRECISION
              );
            });
          }
        }

        updatedState.gridInfo.inlineItems[rowIndex].taxAmount = taxAmount;
        totalTaxAmountInSummary = totalTaxAmountInSummary + taxAmount;
        if (Number(amount)) {
          updatedState.gridInfo.inlineItems[rowIndex].totalAmount =
            Number(amount) + taxAmount;
          totalAmountWithoutTax = totalAmountWithoutTax + Number(amount);
        }
        if (typeof item.tdsAmount !== 'undefined' && item.tdsAmount !== null) {
          totalTDS += item.tdsAmount;
        }
      });
    if (
      Store.getState().authInfo.currentTenantInfo.data.additionalSettings
        ?.ROUND_OFF?.autoRoundOff &&
      !isManualRoundOff.current
    ) {
      let tmpTotal = totalAmountWithoutTax + totalTaxAmountInSummary - totalTDS;

      const roundOffDiff = calculateRoundOffDiff(tmpTotal);

      updatedState.summaryInfo['totalAmount'] = Math.ceil(
        tmpTotal + roundOffDiff
      );

      updatedState.summaryInfo = {
        ...updatedState.summaryInfo,
        subTotal: totalAmountWithoutTax,
        taxAmount: totalTaxAmountInSummary,
        totalTdsAmount: totalTDS,
        roundOffDifference: roundOffDiff
      };
    } else {
      updatedState.summaryInfo = {
        ...updatedState.summaryInfo,
        subTotal: totalAmountWithoutTax,
        taxAmount: totalTaxAmountInSummary,
        totalTdsAmount: totalTDS,
        totalAmount: totalAmountWithoutTax + totalTaxAmountInSummary - totalTDS,
        roundOffDifference: 0
      };
    }
    setExpenseBillState({ ...updatedState });
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    setLastUpdatedColumn(columnKey);
    recalculateRounding.current = true;
    isManualRoundOff.current = false;
    updateLineItemList(rowData, rowIndex, columnKey);
  };

  const manualRoundOffChange = (e: any) => {
    isManualRoundOff.current = true;
    if (
      !Store.getState().authInfo.currentTenantInfo.data.additionalSettings
        ?.ROUND_OFF?.autoRoundOff
    ) {
      const updatedState = { ...expenseBillState };
      let totalAmountOnManualRoundOff = 0;
      let roundingOffDifference = updatedState?.summaryInfo?.roundOffDifference;
      if (Utility.isEmptyValue(roundingOffDifference)) {
        roundingOffDifference = 0;
      }
      if (parseFloat(e.target.value)) {
        totalAmountOnManualRoundOff =
          updatedState?.summaryInfo?.totalAmount -
          roundingOffDifference +
          parseFloat(e.target.value);
      } else {
        totalAmountOnManualRoundOff =
          updatedState?.summaryInfo?.totalAmount - roundingOffDifference;
      }
      updatedState.summaryInfo = {
        ...updatedState.summaryInfo,
        totalAmount: totalAmountOnManualRoundOff,
        roundOffDifference: parseFloat(e.target.value)
          ? parseFloat(e.target.value)
          : 0.0
      };
      handleRoundingOffChange(e, updatedState?.summaryInfo?.totalAmount);
      setExpenseBillState({ ...updatedState });
    } else {
      showAlert(
        '',
        'Please Disable Auto Round off from Organization Settings to apply Manual Round value'
      );
    }
    isManualRoundOff.current = false;
  };

  const handleRoundingOffChange = (e: any, totalWithoutRounding: number) => {
    const value = e.target.value;
    if (
      value === '' ||
      value === '-' ||
      (+value <= totalWithoutRounding &&
        +value >= Math.sign(-1) * totalWithoutRounding)
    ) {
      setRoundOffValue(value);
    }
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        showAddContactPopup && setShowAddContactPopup(false);
        showAccountPopup && setShowAccountPopup(false);
        showTaxPopup && setShowTaxPopup(false);
        showTDSCalculationPopup && setShowTDSCalculationPopup(false);
        break;
      case POPUP_CLICK_TYPE.CREATE_CONTACT:
        addContactRef.current?.storeCallbacksRef.copyContact();
        break;
      case POPUP_CLICK_TYPE.UPDATE_CONTACT:
        addContactRef.current?.storeCallbacksRef.updateContact();
        break;
      case POPUP_CLICK_TYPE.CREATE_COA:
        addCoaRef.current?.storeCallbacksRef.createCoa();
        break;
      case POPUP_CLICK_TYPE.CREATE_TAX:
        addTaxRef.current?.storeCallbacksRef.createTax();
        break;
      case POPUP_CLICK_TYPE.SUBMIT_TDS_AMOUNT:
        tdsAmountRef.current.storeCallbacksRef.submitTDSAmount();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CREATE_CONTACT:
        addContactRef.current.storeCallbacksRef.copyContact = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_CONTACT:
        addContactRef.current.storeCallbacksRef.updateContact =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_CONTACT_SUCCESS:
        setShowAddContactPopup(false);
        setDetailedContact(passingData.data.id);
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_COA:
        addCoaRef.current.storeCallbacksRef.createCoa = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_TAX:
        addTaxRef.current.storeCallbacksRef.createTax = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        showAddContactPopup && setShowAddContactPopup(false);
        showAccountPopup && setShowAccountPopup(false);
        showTaxPopup && setShowTaxPopup(false);
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_TAX_SUCCESS:
        if (lastUpdatedIndex != null) {
          let rows = [...productRows];
          rows[lastUpdatedIndex]['tax'] = passingData.data;
          updateConfig();
          setProductRows(rows);
        }
        break;
      case POPUP_CALLBACKS_TYPE.DEDUCT_TDS_SUBMIT:
        tdsAmountRef.current.storeCallbacksRef.submitTDSAmount =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.DEDUCT_TDS_SUCCESS:
        if (passingData?.data?.incomePayment) {
          setIsTDSDeducted(true);
          recalculateRounding.current = true;
          onTDSDeduct(passingData.data);
          showTDSCalculationPopup && setShowTDSCalculationPopup(false);
        }
        break;
    }
  };

  useEffect(() => {
    const updatedState = { ...expenseBillState };
    let oldColConfigs = [...columnConfig];

    if (!Utility.isEmpty(customFieldsData)) {
      updatedState?.gridInfo?.inlineItems?.forEach(
        (lineItem: any, lineItemIndex: any) => {
          // updatedState.lineItems[lineItemIndex]['cfs'] = lineItem?.customField;
          lineItem?.customField?.forEach((item: any, index: any) => {
            let filteredCF: any = customFieldsData?.find(
              (cf: any) => cf.id === item.id
            );
            if (!Utility.isEmpty(filteredCF)) {
              const newItem = getNewCFItem(
                item,
                filteredCF,
                props.documentMode !== DOCUMENT_MODE.VIEW
              );
              let cfValue;
              if (
                filteredCF.fieldType.toLowerCase() ===
                INPUT_TYPE.DATE.toLowerCase()
              ) {
                cfValue = DateFormatService.getDateFromStr(
                  item.value,
                  BOOKS_DATE_FORMAT['MM/DD/YYYY']
                );
              } else if (filteredCF.fieldType.toLowerCase() === 'user') {
                const tempCF = filteredCF?.attributes?.find(
                  (attr: any) => attr.code === item.value
                );
                if (tempCF) {
                  cfValue = tempCF ? tempCF : '';
                }
              } else if (
                filteredCF.fieldType.toLowerCase() ===
                CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
              ) {
                cfValue = item ? item : '';
              } else {
                cfValue = item.value ? item.value : '';
              }

              let alreadyPresentConfig = oldColConfigs?.find(
                (col: any) => col.id === item.id
              );

              if (Utility.isEmpty(alreadyPresentConfig)) {
                oldColConfigs.push(newItem);
              }
              updatedState.gridInfo.inlineItems[lineItemIndex][item.id] =
                cfValue;
            }
          });
        }
      );
      updatedState.gridInfo.inlineItems =
        updatedState?.gridInfo?.inlineItems.map((lineItem: any) => {
          let tempLineItem = { ...lineItem };
          if (Utility.isEmpty(tempLineItem.invalidFields)) {
            tempLineItem.invalidFields = [];
          }
          tempLineItem = checkIfLineLevelCustomFieldIsValid(
            tempLineItem,
            customFieldsData
          );
          return tempLineItem;
        });
      setColumnConfig([...oldColConfigs]);
      setExpenseBillState({ ...updatedState });
    }
  }, [customFieldsData]);

  useEffect(() => {
    if (!Utility.isEmpty(accountsCFData)) {
      let oldColConfigs = [...columnConfig];
      const accountCustomFields = accountsCFData;

      accountCustomFields?.forEach((prodCF: any) => {
        const newItem = getNewColumnForCF(prodCF);
        oldColConfigs.push(newItem);
      });

      setCustomFieldsData(accountCustomFields);
      setColumnConfig(oldColConfigs.filter((col: any) => !col.hidden));
    }
  }, [accountsCFData]);

  useEffect(() => {
    let isIndiaTenant = getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST;
    if (isIndiaTenant) {
      let isRCMApplicable = false;
      if (!!props?.draftData?.data?.populateFormData?.applyRcmCheck) {
        isRCMApplicable = props?.draftData?.data?.populateFormData
          ?.applyRcmCheck as boolean;
      } else {
        isRCMApplicable = false;
      }

      setExpenseBillState((prev: any) => {
        return { ...prev, applyRcmCheck: isRCMApplicable };
      });
    }
  }, [props?.draftData?.data?.populateFormData?.applyRcmCheck]);

  const getAccountsCFData = async () => {
    try {
      const cfData = await CustomFieldService.getCustomFields({
        status: 'ACTIVE',
        limit: '1000',
        module: 'ACCOUNT'
      });
      let sortedCF = cfData?.content?.length ? cfData?.content : [];
      sortedCF.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
      setAccountsCFData(sortedCF);
      return sortedCF;
    } catch (err: any) {}
  };

  const updateLineLevelCForder = (updatedCFs: any[]) => {
    let accountCFs: any[] = updatedCFs;
    accountCFs.sort(
      (field1: any, field2: any) =>
        field1.customFieldIndex - field2.customFieldIndex
    );

    // const sortedAccountCFsIds = accountCFs.map((accCF: any) => accCF.id);
    const existingCFConfigs = columnConfig.filter(
      (config: any) => config.isCustomField
    );
    const existingConfigsWithoutCFs = columnConfig.filter(
      (config: any) => !!!config.isCustomField
    );
    let updatedCFConfigs: any[] = [];
    accountCFs.forEach((accCF: any) => {
      const existingCFConfig = existingCFConfigs.find(
        (cf: any) => cf.id === accCF.id
      );
      if (existingCFConfig) {
        updatedCFConfigs.push({
          ...existingCFConfig
        });
      }
    });

    const classData = dimensionData?.content?.find(
      (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
    );
    updatedCFConfigs = updatedCFConfigs.map((cf: any) => {
      if (cf?.id === classData?.id) {
        cf = {
          ...cf,
          hidden: hideClassColumn()
        };
      }
      return cf;
    });
    setColumnConfig(
      [...existingConfigsWithoutCFs, ...updatedCFConfigs].filter(
        (col: any) => !col.hidden
      )
    );
  };

  const getAccountCustomFieldSettings = () => {
    const accountCFs: any[] = accountsCFData;
    return (
      <CustomFieldSettings
        fields={accountCFs}
        moduleName={MODULES_NAME.ACCOUNT}
        onSave={async () => {
          try {
            const updatedOrderedCFs = await getAccountsCFData();
            setOpenAccountCFSettings(false);
            updateLineLevelCForder(updatedOrderedCFs);
          } catch (err: any) {
            console.error('Error fetching product CFs: ', err);
          }
        }}
        onClose={() => setOpenAccountCFSettings(false)}
      />
    );
  };
  const getDetailAccountSelectionPopup = () => {
    return (
      <DefaultAccountsSettingsPopup
        onCancel={() => {
          setShowDefaultAccountsSettings(false);
        }}
        onSave={() => {
          setShowDefaultAccountsSettings(false);
          dispatch(fetchDefaultAccounts());
        }}
      />
    );
  };

  //////////////////////////////////// add contact popup - start ///////////////////////////////////////////
  const getAddContactPopup = () => {
    const buttonConfig: BtnType[] = [
      {
        title: t(`DOCUMENT.BUTTON.CANCEL`),
        class: 'border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: contactMode === DOCUMENT_MODE.NEW ? 'Create' : 'Update',
        class: 'bg-button text-white mr-ss',
        clickAction:
          contactMode === DOCUMENT_MODE.NEW
            ? POPUP_CLICK_TYPE.CREATE_CONTACT
            : POPUP_CLICK_TYPE.UPDATE_CONTACT
      }
    ];
    return showAddContactPopup ? (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={
          contactMode === DOCUMENT_MODE.NEW
            ? 'Create Contact'
            : 'Update Contact'
        }
        btnList={buttonConfig}
        disableClickOutside={true}
        width={!isDesktop ? '95%' : '40%'}
        minWidth={!isDesktop ? '' : '550px'}
        height={'95%'}
      >
        <AddContact
          activeTab={activeContactTab}
          contactMode={contactMode}
          populateFormData={contactMode === DOCUMENT_MODE.NEW ? null : contact}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          onSuccess={(res: any) => setDetailedContact(res.id)}
        />
      </PopupWrapper>
    ) : null;
  };
  //////////////////////////////////// add contact popup - end ///////////////////////////////////////////

  //////////////////////////////////// add account popup - start ///////////////////////////////////////////
  const getAddAccountPopup = () => {
    const popupBtnConfig: BtnType[] = [
      {
        title: t(`ACCOUNTING.CHART_OF_ACCOUNTS.BUTTON.CANCEL`),
        class: 'bg-gray1 border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: t(`ACCOUNTING.CHART_OF_ACCOUNTS.BUTTON.CREATE`),
        class: 'bg-app text-white',
        clickAction: POPUP_CLICK_TYPE.CREATE_COA
      }
    ];
    return showAccountPopup ? (
      <AddCoaPopup
        populateFormData={null}
        onCancel={() => {
          setShowAccountPopup(false);
        }}
      />
    ) : null;
  };
  //////////////////////////////////// add contact popup - end ///////////////////////////////////////////

  //////////////////////////////////// add tax popup - start ///////////////////////////////////////////
  const getTaxForm = () => (
    <PopupWrapper
      clickAction={catchClicks}
      type={POPUP_TYPE.POPUP}
      title={t(`SETTINGS.TAX.ADD_TAX_RATE`)}
      btnList={[
        {
          title: t(`SETTINGS.TAX.BUTTON.CANCEL`),
          class: 'border-m mr-s',
          clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
        },
        {
          title: t(`SETTINGS.TAX.BUTTON.CREATE`),
          class: 'bg-app text-white mr-ss',
          clickAction: POPUP_CLICK_TYPE.CREATE_TAX
        }
      ]}
      width="45%"
      height="75%"
    >
      <AddTax
        populateFormData={null}
        passingInteraction={(callback: CallBackPayloadType) => {
          parentChildInteraction(callback);
        }}
      />
    </PopupWrapper>
  );
  //////////////////////////////////// add tax popup - end ///////////////////////////////////////////

  //////////////////////////////////// attachments- start ////////////////////////////////////////////
  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', 'true');
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      if (target?.files) {
        Promise.all(
          target?.files &&
            Array.from(target.files).map((file: File) =>
              uploadAttachmentToAWS(file)
            )
        )
          .then((resList) => {
            resList = resList.filter((x: any) => x !== undefined);
            const newAttachments = [...attachments, ...resList];
            setAttachments(newAttachments);
          })
          .catch((err) => {
            console.log(err);
            showToast(
              'Something went wrong while uploading the attachment, please try again.'
            );
          });
      }
    });
    input.click();
  };

  const uploadAttachmentToAWS = async (file: File) => {
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: 'INVOICE'
    };
    return AttachmentService.uploadAttachment(file);
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {
        showAlert(
          'Error!',
          'Something went wrong, while downloading the attachment.'
        );
      });
  };

  const removeAttachment = (attachmentId: any) => {
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const newAttachments = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );
        setAttachments(newAttachments);
      })
      .catch(() => {
        showAlert(
          'Error!',
          'Something went wrong while removing the attachment, please try again.'
        );
      });
  };

  const getAttachments = () => {
    return (
      <div className="col justify-content-start flex-wrap">
        {attachments.map((attachment: any) => (
          <div
            className="row width-auto border-m border-radius-s p-h-r p-v-s mt-r bg-gray0"
            key={attachment.attachmentId}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-s cursor-pointer"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <DKButton
              title={attachment.attachmentFileName}
              className="ml-s cursor-pointer border-none"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-s ml-l cursor-pointer"
              onClick={() => {
                removeAttachment(attachment.attachmentId);
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  //////////////////////////////////// attachments- end ////////////////////////////////////////////

  const getExpandableContainer = () => {
    let totalPayment = 0;
    let needToShowGroupDetails = false;
    if (expenseBillState.knockoffInfo?.length) {
      totalPayment = expenseBillState.knockoffInfo.reduce(
        (total: number, paymentInfo: any) =>
          total + Number(paymentInfo?.amount * paymentInfo?.exchangeRate || 0),
        0
      );
      needToShowGroupDetails = Boolean(totalPayment);
    }

    return totalPayment ? (
      <>
        <div
          className="row width-auto mb-m justify-content-between align-items-start"
          style={{
            width: '100%',
            pointerEvents: 'all',
            cursor: needToShowGroupDetails ? 'pointer' : 'default'
          }}
        >
          <div
            className="row width-auto"
            style={{
              minWidth: 100
            }}
            onClick={() =>
              setShowPaymentGroupDetails((prevState) => !prevState)
            }
          >
            <div className="flex flex-row align-items-center">
              <span className="mr-1 ml-r fw-m ">Payments</span>
              {needToShowGroupDetails ? (
                <DKIcon
                  src={DKIcons.ic_arrow_right}
                  className="ml-xs ic-xs"
                  style={{
                    transition: 'transform 0.1s ease-in-out',
                    transform: showPaymentGroupDetails
                      ? 'rotate(90deg)'
                      : 'rotate(0deg)'
                  }}
                />
              ) : null}
            </div>
          </div>
          <DKLabel
            text={`${Utility.getCurrencySymbolFromCode(
              expenseBillState.docCurrencyCode
            )} ${NumberFormatService.getNumber(totalPayment)}`}
            style={{
              wordBreak: 'break-all'
            }}
            className={`ml-r text-wrap`}
          />
        </div>
        {showPaymentGroupDetails ? getPaymentsGroup() : null}
      </>
    ) : null;
  };

  const getPaymentsGroup = () => {
    return (
      <>
        {expenseBillState.knockoffInfo?.map((paymentInfo: any) => (
          <div key={paymentInfo.documentCode} className="parent-width">
            {getTitleAndAmount(
              paymentInfo.documentCode,
              paymentInfo.amount,
              null,
              'fw-r ml-xxl',
              '',
              paymentInfo.currency
            )}
          </div>
        ))}
      </>
    );
  };

  const showCommonTaxBlocks = () => {
    let flag = true;
    if (getTenantTaxSystem() === TAX_SYSTEM.US) {
      flag = false;
    }

    return flag;
  };

  const getAddClassForm = () => (
    <AddClass
      data={null}
      onSuccess={() => {
        dispatch(fetchCategoryDimensions());
        dispatch(fetchClassesByDimensionId());
      }}
      onCancel={() => {
        setShowAddClassPopup(false);
      }}
    />
  );

  const getCompanyDetails = () => {
    const canEditContact = props.documentMode !== DOCUMENT_MODE.EDIT;
    let companyAddress = expenseBillState.shipFrom;
    if (Utility.isEmpty(companyAddress)) {
      companyAddress = contact?.billingAddress?.length
        ? contact?.billingAddress[0]
        : null;
    }
    return (
      <div
        className={`column parent-block`}
        style={{
          maxWidth: 250,
          marginTop: canEditContact ? -10 : 0,
          marginLeft: canEditContact ? -10 : 0,
          padding: canEditContact ? 10 : 0
        }}
      >
        <div className="row width-auto">
          {Utility.isEmpty(contact) ? (
            getContactSelector()
          ) : (
            <div
              className={`${
                canEditContact && !Utility.isEmpty(contact)
                  ? 'cursor-pointer border-box'
                  : ''
              }`}
              onClick={() => {
                if (canEditContact && !Utility.isEmpty(contact)) {
                  setOpenContactSelector((prevValue) => !prevValue);
                }
              }}
            >
              <div
                className={`row justify-content-between ${
                  canEditContact ? 'listPickerBG' : ''
                }`}
              >
                <DKLabel
                  text={`${contact.name}-(${contact.currencyCode})`}
                  className="fw-m fs-r"
                />
              </div>
            </div>
          )}
        </div>
        {Utility.isEmpty(companyAddress) ? null : (
          <>
            <div>
              <div
                className="row width-auto listPickerBG cursor-pointer"
                onClick={() => {
                  if (
                    GranularPermissionsHelper.hasPermissionFor(
                      PERMISSIONS_BY_MODULE.CONTACTS.CREATE
                    )
                  ) {
                    if (!Utility.isEmpty(contact)) {
                      setOpenShipFromForBuy(true);
                    }
                  }
                }}
              >
                <DKLabel
                  text={getFormattedAddress(companyAddress)}
                  className="parent-width"
                />
              </div>
              {openShipFromForBuy && (
                <DKListPicker2
                  data={contact?.billingAddress}
                  className="position-absolute z-index-3 bg-white border-m shadow-m"
                  style={{ minWidth: 250 }}
                  renderer={(index: number, addressObj: any) => (
                    <div
                      style={{
                        width: 200,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'left'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: getFormattedAddress(addressObj)
                      }}
                    ></div>
                  )}
                  onEdit={(index: number, obj: any) => {}}
                  button={{
                    title: 'Manage address',
                    className:
                      'text-white fw-m bg-button justify-content-center',
                    onClick: () => {
                      setActiveContactTab(CONTACT_FORM_TAB.ADDRESS_INFO);
                      setContactMode(DOCUMENT_MODE.EDIT);
                      setShowAddContactPopup(true);
                      setOpenShipFromForBuy(false);
                    }
                  }}
                  canEdit={false}
                  onSelect={(index: number, addressObj: any) => {
                    setContact((prevState: any) => {
                      return {
                        ...prevState,
                        shippingAddress: [addressObj]
                      };
                    });
                    setExpenseBillState((prevState: any) => {
                      return {
                        ...prevState,
                        shipFrom: addressObj
                      };
                    });

                    setOpenShipFromForBuy(false);
                  }}
                  onClose={() => {
                    setOpenShipFromForBuy(false);
                  }}
                  allowSearch={false}
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const getDateAndOtherInfoBlocks = () => {
    const currentBillDate = DateFormatService.getDateFromStr(
      expenseBillState.billDate.date,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    const currentDueDate = DateFormatService.getDateFromStr(
      expenseBillState.dueDate.date,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    return (
      <div className="row align-items-end flex-wrap mt-r" style={{ gap: 12 }}>
        <div
          style={{
            width: 150,
            maxWidth: 200
          }}
        >
          <DKInput
            className="parent-width"
            title="Bill Date"
            value={currentBillDate}
            titleStyle={{ color: 'gray' }}
            valueStyle={{ minHeight: 33 }}
            type={INPUT_TYPE.DATE}
            onChange={(newDate: any) => {
              validateAndUpdateDate(
                EXPENSE_BILL_CALENDAR_TYPE.BILL_DATE,
                newDate,
                DateFormatService.getDateFromStr(
                  tenantInfo.bookBeginningStartDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                ),
                'Bill date cannot be before books beginning date.',
                true,
                currentBillDate
              );
              updateConfig();
              setTimeout(() => {
                updateLineItemForSGTaxCalculation();
              }, 100);
            }}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            readOnly={checkifCustomFieldCanEdit === false}
            dateFormat={convertBooksDateFormatToUILibraryFormat(
              tenantInfo.dateFormat
            )}
          />
        </div>
        <div
          style={{
            width: 150,
            maxWidth: 200
          }}
        >
          <DKInput
            className="parent-width"
            title="Due Date"
            value={currentDueDate}
            titleStyle={{ color: 'gray' }}
            valueStyle={{ minHeight: 33, paddingTop: 0, paddingBottom: 0 }}
            type={INPUT_TYPE.DATE}
            onChange={(newDate: any) => {
              validateAndUpdateDate(
                EXPENSE_BILL_CALENDAR_TYPE.DUE_DATE,
                newDate,
                currentBillDate,
                'Due date cannot be before bill date.',
                false,
                currentDueDate
              );
            }}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            readOnly={checkifCustomFieldCanEdit === false}
            dateFormat={convertBooksDateFormatToUILibraryFormat(
              tenantInfo.dateFormat
            )}
          />
        </div>
        <div
          style={{
            width: 150,
            maxWidth: 200,
            wordBreak: 'break-word'
          }}
        >
          <DKInput
            className="parent-width"
            title="Supplier Inv No."
            textAlign="right"
            value={expenseBillState.supplierInvoiceNo}
            canValidate={false}
            titleStyle={{ color: 'gray' }}
            valueStyle={{ minHeight: 33 }}
            type={INPUT_TYPE.TEXT}
            onChange={(value: any) => {
              setExpenseBillState((prevState: any) => {
                return {
                  ...prevState,
                  supplierInvoiceNo: value
                };
              });
            }}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            readOnly={
              (props.documentMode === DOCUMENT_MODE.VIEW &&
                props.draftData.draftType === DraftTypes.READONLY) ||
              checkifCustomFieldCanEdit === false
            }
          />
        </div>
        {tenantInfo.country === COUNTRY_CODES.IL && (
          <>
            <div
              style={{
                width: 150,
                maxWidth: 200,
                wordBreak: 'break-word'
              }}
            >
              <DKInput
                className="parent-width"
                title="Tax Payer Id"
                value={expenseBillState?.contact?.taxPayerIdIsrael || ''}
                titleStyle={{ color: 'gray' }}
                valueStyle={{ minHeight: 33 }}
                textAlign="left"
                type={INPUT_TYPE.TEXT}
                onChange={(value: any) => {}}
                canValidate={false}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                readOnly={true}
              />
            </div>
            <div
              style={{
                width: 150,
                maxWidth: 200,
                wordBreak: 'break-word'
              }}
            >
              <DKInput
                className="parent-width"
                title="Tax Registration Number"
                value={expenseBillState?.contact?.taxNumber || ''}
                titleStyle={{ color: 'gray' }}
                valueStyle={{ minHeight: 33 }}
                textAlign="left"
                type={INPUT_TYPE.TEXT}
                onChange={(value: any) => {}}
                canValidate={false}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                readOnly={true}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const supplyRenderer = (value1: any, value2: any) => {
    return (
      <div
        style={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
          marginBottom: '20px'
        }}
      >
        <div style={{ width: '200px' }}>
          <DKInput
            className=""
            title={'Destination of Supply'}
            value={value1}
            titleStyle={{ color: 'gray' }}
            valueStyle={{ minHeight: 33, paddingTop: 0, paddingBottom: 0 }}
            type={INPUT_TYPE.TEXT}
            onChange={() => {}}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            readOnly={true}
          />
        </div>
        <div style={{ width: '200px' }}>
          <DKInput
            className=""
            title={'Place of Supply'}
            value={value2}
            titleStyle={{ color: 'gray' }}
            valueStyle={{ minHeight: 33, paddingTop: 0, paddingBottom: 0 }}
            type={INPUT_TYPE.TEXT}
            onChange={() => {}}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            readOnly={true}
          />
        </div>
      </div>
    );
  };

  const getTaxBreakUp = () => {
    let totalTax = 0;
    let totalCgst = 0;
    let totalSgst = 0;
    let totalIgst = 0;
    let taxGroupInTax: any = [];
    expenseBillState?.gridInfo?.inlineItems.forEach((element: any) => {
      totalTax =
        totalTax + (element.taxAmount ? parseFloat(element.taxAmount) : 0);
      totalCgst =
        totalCgst + (element.cgstRate ? parseFloat(element.cgstRate) : 0);
      totalSgst =
        totalSgst + (element.sgstRate ? parseFloat(element.sgstRate) : 0);
      totalIgst =
        totalIgst + (element.igstRate ? parseFloat(element.igstRate) : 0);
      //creating Dictionary to avoid duplicacy of taxes if same additional tax is applied in more than one row.
      if (element?.selectedTaxCode?.additionalTaxIn) {
        let amountAlreadyAdded = taxGroupInTax[element.selectedTaxCode.name]
          ? taxGroupInTax[element.selectedTaxCode.name]
          : 0;
        taxGroupInTax[element.selectedTaxCode.name] =
          amountAlreadyAdded + element.taxAmount;
      }
    });
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      if (gstType === GST_TYPE.INTER) {
        return (
          <div className="column parent-width">
            <div className="row parent-width py-1 justify-content-between">
              <DKLabel className=" text-align-left" text={'IGST'} />
              <DKLabel
                className=" text-align-right"
                text={NumberFormatService.getNumber(
                  totalIgst ? (totalIgst as number) : 0
                )}
              />
            </div>
            {getKeyValueListForList(taxGroupInTax)}
          </div>
        );
      } else if (gstType === GST_TYPE.INTRA) {
        return (
          <div className="column py-1 parent-width justify-content-between app-font">
            <div className="row parent-width justify-content-between ">
              <DKLabel className=" text-align-left" text={'CGST'} />
              <DKLabel
                className=" text-align-right"
                text={NumberFormatService.getNumber(
                  totalCgst ? (totalCgst as number) : 0
                )}
              />
            </div>
            <div className="row parent-width justify-content-between ">
              <DKLabel className="text-align-left" text={'SGST'} />
              <DKLabel
                className="text-align-right"
                text={NumberFormatService.getNumber(
                  totalSgst ? (totalSgst as number) : 0
                )}
              />
            </div>
            {getKeyValueListForList(taxGroupInTax)}
          </div>
        );
      } else {
        return <></>;
      }
    }
  };

  const getKeyValueListForList = (additionalTax: any) => {
    return (
      <div className="column parent-width">
        {Object.entries(additionalTax).map(([key, val], index: number) => {
          return (
            <div className="row parent-width justify-content-between">
              <DKLabel className="text-align-left" text={key} />
              <DKLabel
                className="text-align-right"
                text={NumberFormatService.getNumber(val ? (val as number) : 0)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={`column parent-width parent-height position-relative p-r ${
        props.documentMode === DOCUMENT_MODE.VIEW ? 'pointer-events-none' : ''
      }`}
    >
      <div className="row justify-content-between align-items-start position-relative">
        <div>
          {(props.documentMode === DOCUMENT_MODE.NEW ||
            props.documentMode === DOCUMENT_MODE.VIEW ||
            props.documentMode === DOCUMENT_MODE.COPY) &&
          checkifCustomFieldCanEdit
            ? getContactDetails()
            : Utility.isEmpty(customLocation)
            ? getCompanyDetails()
            : showSelectedContactAddress()}
          {Utility.isUSorg() &&
            Utility.isDPLSettingEnabled() &&
            Utility.isNotEmpty(contact) &&
            Utility.isNotEmpty(dimensionData) &&
            isDplChecked() && (
              <div className="row">
                <ReCheckButton
                  onClick={() => {
                    const billingAddress: any[] = [];
                    const countryCodeMap: any = {};
                    COUNTRIES_WITH_CURRENCIES.forEach((country) => {
                      countryCodeMap[country?.country?.toLowerCase()] =
                        country?.countryCode;
                    });
                    if (contact?.billingAddress) {
                      contact?.billingAddress?.forEach((address: any) => {
                        if (address?.country) {
                          billingAddress.push({
                            ...address,
                            countryCode:
                              countryCodeMap?.[address?.country?.toLowerCase()]
                          });
                        }
                      });
                    }
                    const DplCustomFieldId = dimensionData?.content?.filter(
                      (customField: any) =>
                        customField?.system &&
                        customField?.shortName === 'denied_parties_custom_field'
                    )?.[0]?.id;
                    const dplContactCustomField = contact?.customField?.filter(
                      (cField: any) => cField?.id == DplCustomFieldId
                    );
                    if (
                      !dplContactCustomField ||
                      dplContactCustomField?.length == 0
                    )
                      return;
                    const payload = {
                      name: contact?.name ?? '',
                      billingAddress: billingAddress,
                      customField: dplContactCustomField
                    };
                    showLoader();
                    ContactService.recheck(contact?.id, payload)
                      .then((res) => {
                        removeLoader();
                        if (res) {
                          showToast(
                            'The contact you are using is under denied party list',
                            TOAST_TYPE.SUCCESS
                          );
                        } else {
                          showToast(
                            'The contact you are using is not under denied party list',
                            TOAST_TYPE.SUCCESS
                          );
                        }
                        dispatch(fetchContacts(''));
                      })
                      .catch((err) => {
                        removeLoader();
                      });
                  }}
                />
              </div>
            )}
        </div>
      </div>
      {isDocumentInFullScreen ? (
        <div className="column parent-width">
          <div className="row mt-r justify-content-between align-items-start">
            {getTenantAddressDetails()}
            {getRightInfoPanel()}
          </div>

          {getDateAndOtherInfoBlocks()}
          {getCustomFields()}
        </div>
      ) : (
        <div className="row mt-r justify-content-between align-items-start">
          <div className="column">
            {getTenantAddressDetails()}
            {getCustomFields()}
          </div>
          {getRightInfoPanel()}
        </div>
      )}
      {!Utility.isUSorg() &&
        Utility.isNotEmpty(sourceOfSupply) &&
        Utility.isNotEmpty(destinationOfSupply) &&
        supplyRenderer(destinationOfSupply, sourceOfSupply)}

      <div
        ref={gridContainerRef}
        className={`column parent-width ${
          props.documentMode === DOCUMENT_MODE.VIEW ? 'pointer-events-auto' : ''
        }`}
      >
        {expenseBillState.gridInfo.inlineItems && getDataGrid()}
      </div>

      {showTaxHeader() &&
        (tenantInfo.country === TAX_SYSTEM.INDIA_GST ||
          getTenantTaxSystem() === TAX_SYSTEM.SG) &&
        taxInclusiveCheckbox}

      {getAddContactPopup()}
      {getAddAccountPopup()}
      {showTaxPopup && getTaxForm()}

      {props.documentMode !== DOCUMENT_MODE.VIEW &&
        checkifCustomFieldCanEdit && (
          <DKButton
            title={`+ ${t('DOCUMENT.ADD_ITEM')}`}
            onClick={() => addNewItem()}
            className={`text-blue fw-m p-0`}
            style={{ marginTop: -10, zIndex: 1, paddingLeft: 0 }}
          />
        )}

      {/* <Grid
        gridName="Expense Bill Grid"
        headerConfig={expenseBillState.gridInfo.headerConfig}
        gridItems={expenseBillState.gridInfo.inlineItems}
        inlineItemDelete={onInlineItemDelete}
        inlineItemUpdated={onInlineItemUpdate}
        inlineItemAdded={onInlineItemAdd}
      /> */}

      <div
        className="flex flex-row items-start justify-content-between box-border w-full pb-xxl"
        style={{ marginTop: -10 }}
      >
        <div className="column mt-xl">
          <textarea
            className="resize-none p-2 border rounded outline-none border-gray-200 hover:border-gray-300 focus:border-gray-400"
            style={{
              width: 450,
              height: 130,
              backgroundColor: 'rgb(250, 250, 250)',
              border: '1px dashed rgb(200, 200, 200)'
            }}
            placeholder={t(`DOCUMENT.MEMO_OPTIONAL`)}
            value={expenseBillState.memo}
            onChange={(e: any) => {
              setExpenseBillState((prevState: any) => {
                return {
                  ...prevState,
                  memo: e.target.value
                };
              });
            }}
          ></textarea>
          <DKButton
            title="+ Attach Files"
            className="text-blue fw-m"
            style={{ paddingLeft: 0 }}
            onClick={triggerAttachmentUpload}
            disabled={!checkifCustomFieldCanEdit}
          />
          <div className="row ">{getAttachments()}</div>
          <div className="row mt-r">
            {!props.draftData.isLoading &&
              (props.documentMode === DOCUMENT_MODE.EDIT ||
                props.documentMode === DOCUMENT_MODE.VIEW) && (
                <DocumentActionMenu
                  booksDocument={{ ...props.populateFormData }}
                  draftId={props.draftData.id}
                  position="bottom"
                  draftType={props.documentMode}
                  auditLogView={props.auditLogView}
                />
              )}
          </div>
        </div>
        {checkUserPermission(PERMISSIONS_BY_MODULE.BILL.VIEW_PRICE) && (
          <div className="column mr-s">
            {getTitleAndAmount(
              t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.SUB_TOTAL`),
              expenseBillState.summaryInfo.subTotal,
              null,
              'fw-m',
              '',
              expenseBillState.docCurrencyCode
            )}
            {/*
            Hiding discount
            {getTitleAndAmount(
            t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.DISCOUNT`) + `(-)`,
            expenseBillState.summaryInfo.discount,
            null,
            'fw-m'
          )} */}
            {showTaxHeader() &&
              showCommonTaxBlocks() &&
              getTitleAndAmount(
                'Before-Tax',
                expenseBillState.summaryInfo.subTotal,
                null,
                'fw-m',
                '',
                expenseBillState.docCurrencyCode
              )}
            {showTaxHeader() && showCommonTaxBlocks() && (
              <div className="row parent-width justify-content-between app-font fw-m fs-m border-b gap-10">
                <DKButton
                  className="parent-width justify-content-between"
                  style={{ paddingLeft: 0 }}
                  title={'+TAX'}
                  icon={
                    showTaxDetails
                      ? DKIcons.ic_arrow_up2
                      : DKIcons.ic_arrow_down2
                  }
                  isReverse={true}
                  onClick={() => {
                    setshowTaxDetails(!showTaxDetails);
                  }}
                />
                <DKLabel
                  className=" text-align-right"
                  text={NumberFormatService.getNumber(
                    expenseBillState?.summaryInfo?.taxAmount ?? 0
                  )}
                />
              </div>
            )}
            {showTaxDetails &&
              getTenantTaxSystem() !== TAX_SYSTEM.US &&
              getTaxBreakUp()}
            {getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
              getTitleAndAmount(
                'TDS(-)',
                !isNaN(expenseBillState.summaryInfo.totalTdsAmount)
                  ? expenseBillState.summaryInfo.totalTdsAmount
                  : 0,
                null,
                'fw-m'
              )}
            <div
              className="row width-auto mb-m justify-content-between"
              style={{ width: '100%' }}
            >
              <div className="row width-auto">
                <DKLabel
                  text={t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.ROUNDING_OFF`)}
                  className={'fw-m ml-r'}
                />
              </div>

              <div className="flex flex-col items-end w-24 number-hide-arrows">
                <input
                  value={roundOffValue}
                  type="text"
                  className="border-transparent hover:border-gray-300 focus:border-gray-400 w-10/12 text-right border rounded-sm outline-none cursor-pointer"
                  onChange={manualRoundOffChange}
                  disabled={
                    props.documentMode === DOCUMENT_MODE.VIEW ||
                    props.draftData.draftType === DraftTypes.READONLY
                  }
                />
              </div>
            </div>
            {summaryTotalAmountSection(
              `${t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.TOTAL`)}`,

              +expenseBillState.summaryInfo.totalAmount,
              null,
              'fs-l fw-m',
              'fs-l fw-m'
            )}
            {expenseBillState.knockoffInfo?.length > 0 &&
              getExpandableContainer()}
          </div>
        )}
      </div>
      {showAddClassPopup && getAddClassForm()}
      {showTDSCalculationPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={`TDS Calculation`}
          btnList={[
            {
              title: `Cancel`,
              class: 'border-m mr-s',
              clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
            },
            {
              title: `Submit`,
              class: 'bg-app text-white mr-ss',
              clickAction: POPUP_CLICK_TYPE.SUBMIT_TDS_AMOUNT
            }
          ]}
          width="40%"
          height="65%"
          disableClickOutside={true}
        >
          <TDSCalculation
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            tdsData={currentRowTDSInfo}
          />
        </PopupWrapper>
      )}
      {openAccountCFSettings && getAccountCustomFieldSettings()}

      {showTaxRowPopup && (
        <TaxValueEditForm
          taxData={currentRowTaxInfo}
          setTaxChangeValue={(data: any) => {
            onTaxValueChange(data);
          }}
          onClose={() => {
            setCurrentRowTaxInfo(null);
            setShowTaxRowPopup(false);
          }}
        />
      )}
      {showITCPopup && getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST && (
        <ItcOptions
          itcData={currentRowITCInfo}
          setITCIneligibleOption={(data: any) => {
            onITCOptionSelected(data);
          }}
          onClose={() => {
            setCurrentRowITCInfo(null);
            setShowITCPopup(false);
          }}
        />
      )}
      {showDefaultAccountSettings && getDetailAccountSelectionPopup()}
    </div>
  );
};

export default NewExpenseBill;
