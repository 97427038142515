import { useEffect, useState } from 'react';
import { showAlert, DKSpinner } from 'deskera-ui-library';
import ic_convert_fulfill from '../../Assets/Icons/ic_convert_fulfill.svg';
import ic_convert_only from '../../Assets/Icons/ic_convert_only.svg';
import ic_convert_partially from '../../Assets/Icons/ic_convert_partially.svg';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  DOCUMENT_STATUS,
  DOC_TYPE,
  FULFILLMENT_STATUS,
  FULFILLMENT_TYPE,
  LABELS,
  MODULES_NAME
} from '../../Constants/Constant';
import Utility from '../../Utility/Utility';
import { InvoiceInitialState } from '../../Models/Invoice';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  createBlankDraft,
  draftTableId,
  selectDraftsColumnConfig
} from '../../Redux/Slices/DraftsSlice';
import { DraftTypes } from '../../Models/Drafts';
import DateFormatService from '../../Services/DateFormat';
import { getUpdatedSalesOrderObject } from './SalesOrderHelper';
import ContactService from '../../Services/Contact';
import { SalesOrder } from '../../Models/SalesOrder';
import SalesOrderService from '../../Services/SalesOrder';
import { localizedText } from '../../Services/Localization/Localization';
import { selectFullInvoiceCustomNumbersFormats } from '../../Redux/Slices/CustomNumberFormat';
import { differenceInDays, addDays } from 'date-fns';
import { checkUserPermission } from '../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { getValidTillDateFromDocDate } from '../../SharedComponents/DocumentForm/NewDocumentHelper';

interface SalesOrderToInvoiceProps {
  salesOrderDocument: SalesOrder;
  closePopup: () => void;
  closeParentDoc?: () => void;
}

enum SOConversionAction {
  PARTIAL_INVOICE = 'partial_invoice',
  CONVERT_ONLY = 'convert_only',
  CONVERT_AND_FULFILL = 'convert_and_fulfill'
}

export default function SalesOrderToInvoicePopup(
  props: SalesOrderToInvoiceProps
) {
  const [salesOrder, setSalesOrder] = useState(props.salesOrderDocument);
  const [isOpeningForm, setIsOpeningForm] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [type, setType] = useState<SOConversionAction>();
  const [contact, setContact] = useState<any>(null);

  const dispatch = useAppDispatch();
  const draftsTableId = useAppSelector(draftTableId);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const invoiceSequenceFormatsInStore = useAppSelector(
    selectFullInvoiceCustomNumbersFormats
  );

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const detailedContact = await ContactService.getContactDetailsById(
          props.salesOrderDocument?.contactDto?.id
        );
        setContact(detailedContact);
      } catch (err) {
        console.error('Error loading detailed contact: ', err);
      }
    };

    fetchContact();
  }, []);

  // Load Quote Details
  const loadSalesOrderDetails = async (
    doc: any,
    convertType: SOConversionAction
  ) => {
    const documentCode = doc.salesOrderCode;
    SalesOrderService.getSalesOrderByCode(documentCode).then(
      (data: any) => {
        let getSalesOrderDetailsData: any = getUpdatedSalesOrderObject(data);
        console.log('poNumber', doc.poNumber);

        if (convertType === SOConversionAction.CONVERT_AND_FULFILL) {
          if (
            !Utility.checkStockAvailability(
              getSalesOrderDetailsData.salesOrderItems || []
            )
          ) {
            setIsApiLoading(false);
            setIsOpeningForm(false);
            showAlert(
              'Error',
              'Fulfillment can not be done due to insufficient stock.'
            );
            return;
          }
        }

        const salesOrderDetailsData = {
          ...getSalesOrderDetailsData,
          linkedSalesInvoices: doc.linkedSalesInvoices,
          poNumber: doc?.poNumber,
          sourceDocTypeForConversion: MODULES_NAME.SALES_ORDER
        };
        setSalesOrder(salesOrderDetailsData);
        if (!Utility.isEmpty(salesOrderDetailsData)) {
          setIsApiLoading(false);

          switch (convertType) {
            case SOConversionAction.CONVERT_AND_FULFILL:
              prepareSOForConversion(
                salesOrderDetailsData,
                SOConversionAction.CONVERT_AND_FULFILL
              );
              break;
            case SOConversionAction.CONVERT_ONLY:
              prepareSOForConversion(
                salesOrderDetailsData,
                SOConversionAction.CONVERT_ONLY
              );
              break;
            case SOConversionAction.PARTIAL_INVOICE:
              prepareSOForConversion(
                salesOrderDetailsData,
                SOConversionAction.PARTIAL_INVOICE
              );
              break;

            default:
              break;
          }
        }
      },
      (err) => {
        setIsApiLoading(false);
        console.error('Error while fetching sales order details: ', err);
      }
    );
  };
  const parseAttachmentId = (attachment: any) => {
    if (Utility.isNumber(attachment)) {
      return attachment;
    }
    const parsedAttachment: any = JSON.parse(Utility.sanitizeJSON(attachment));
    return parsedAttachment?.attachmentId;
  };
  const isValidAttachment = (attachment: any) => {
    if (Utility.isNumber(attachment)) {
      return true;
    }
    try {
      const parsedAttachment: any = JSON.parse(
        Utility.sanitizeJSON(attachment)
      );
      return !Utility.isEmptyValue(parsedAttachment?.attachmentId);
    } catch {
      return false;
    }
  };

  const convertToPartialInvoice = async (selectedSO: SalesOrder) => {
    let partialInvoice: any = {
      ...InvoiceInitialState,
      ...selectedSO
    };
    if (!Utility.isEmpty(contact)) {
      partialInvoice = {
        ...partialInvoice,
        contactDto: contact
      };
    }
    partialInvoice = {
      ...partialInvoice,
      id: null,
      documentType: DOC_TYPE.INVOICE,
      isPartialInvoice: true,
      backOrder: false,
      openingInvoice: partialInvoice.openingInvoice || false,
      fulfillmentStatus: FULFILLMENT_STATUS.UNFULFILLED,
      fulfillmentType: partialInvoice.fulfillmentType,
      documentDate: partialInvoice.documentDate,
      documentSequenceCode: null,
      sequenceFormat: null,
      validTillDate: partialInvoice.salesOrderDueDate,
      fulfillmentDate: partialInvoice.fulfillmentDate,
      attachments: partialInvoice.entityId ? partialInvoice.attachments : [],
      attachmentIds: partialInvoice.entityId
        ? partialInvoice.attachments
            ?.filter((attachment: any) => {
              return isValidAttachment(attachment);
            })
            ?.map((attachment: any) => {
              return parseAttachmentId(attachment);
            })
        : [],
      isConverting: true,
      status: !partialInvoice.id ? DOCUMENT_STATUS.OPEN : partialInvoice.status,
      paymentStatus: partialInvoice.paymentStatus,
      taxInvoiceNo: partialInvoice.taxInvoiceNo,
      paymentInformation: partialInvoice.paymentInformation
        ? partialInvoice.paymentInformation
        : null,
      whtRate:
        partialInvoice.whtRate && partialInvoice.whtRate !== null
          ? partialInvoice.whtRate
          : 0,
      einvoiceInfoIndia: partialInvoice.einvoiceInfoIndia,
      einvoiceInfoIndiaCancel: partialInvoice.einvoiceInfoIndiaCancel,
      isCancelEinvoice: partialInvoice.isCancelEinvoice,
      reservedStock: partialInvoice.reservedStock
        ? partialInvoice.reservedStock
        : false,
      linkedDocuments: [
        {
          documentCode: selectedSO.salesOrderCode as string,
          documentType: selectedSO.documentType,
          documentSequenceCode: selectedSO.documentSequenceCode as string
        }
      ],
      contact: {
        ...partialInvoice.contact,
        ...partialInvoice.contactDto,
        address: Utility.getStringAddress(partialInvoice.contactDto)
      },
      salesInvoiceDate: partialInvoice.documentDate,
      salesInvoiceDueDate: partialInvoice.validTillDate,
      approvalStatus: APPROVAL_STATUS.NOT_REQUIRED,
      multiApprovalDetails: null
    };

    if (
      !Utility.isEmpty(selectedSO.linkedQuotationDocuments) &&
      !Utility.isEmpty(partialInvoice.linkedDocuments) &&
      selectedSO?.linkedQuotationDocuments != undefined
    ) {
      partialInvoice.linkedDocuments = [
        ...partialInvoice.linkedDocuments,
        {
          documentCode: selectedSO?.linkedQuotationDocuments?.[0]
            ?.documentCode as string,
          documentType: selectedSO?.linkedQuotationDocuments?.[0]?.documentType,
          documentSequenceCode: selectedSO?.linkedQuotationDocuments?.[0]
            ?.documentSequenceCode as string
        }
      ];
    }

    if (
      !Utility.isEmpty(selectedSO.linkedWorkOrderDocuments) &&
      !Utility.isEmpty(partialInvoice.linkedDocuments) &&
      selectedSO?.linkedWorkOrderDocuments != undefined
    ) {
      partialInvoice.linkedDocuments = [
        ...partialInvoice.linkedDocuments,
        ...selectedSO.linkedWorkOrderDocuments
      ];
    }

    const {
      contactDto,
      items,
      quotationCode,
      quotationItemDtoList,
      ...invoiceToForward
    } = partialInvoice;

    let payloadData: any = {
      type: LABELS.INVOICES,
      title: LABELS.INVOICES,
      isMaximized: true,
      isCenterAlign: true,
      populateFormData: invoiceToForward,
      tableId: draftsTableId,
      columnConfig: draftsColumnConfig
    };
    if (!Utility.isEmpty(invoiceToForward)) {
      dispatch(createBlankDraft({ payloadData, draftType: DraftTypes.NEW }));
      if (typeof props.closePopup !== 'undefined') {
        props.closePopup();
      }
      if (props.closeParentDoc) {
        props.closeParentDoc();
      }
    }
  };

  const prepareSOForConversion = async (
    data: SalesOrder,
    action: SOConversionAction
  ) => {
    let invoiceSequenceFormat: any;
    if (!Utility.isEmpty(invoiceSequenceFormatsInStore)) {
      invoiceSequenceFormat = invoiceSequenceFormatsInStore?.find(
        (seqFormat: any) => seqFormat?.isDefault
      );
      if (!Utility.isEmpty(invoiceSequenceFormat)) {
        invoiceSequenceFormat = invoiceSequenceFormat?.id;
      } else {
        invoiceSequenceFormat = '';
      }
    }
    let updatedSIItems =
      data.salesOrderItems?.filter(
        (item) => Utility.getPendingQtyForConversion(item) > 0
      ) ?? [];
    if (action === SOConversionAction.PARTIAL_INVOICE) {
      const isAnyItemHasFulfilled = updatedSIItems?.some(
        (ele: any) =>
          ele.fulfilledQuantity > 0 &&
          ele.fulfilledQuantity -
            (ele.invoicedQty ?? ele.fulfilledQuantityInvoiced ?? 0) >
            0
      );
      if (isAnyItemHasFulfilled) {
        updatedSIItems = updatedSIItems?.filter(
          (ele: any) =>
            ele.fulfilledQuantity > 0 &&
            ele.fulfilledQuantity -
              (ele.invoicedQty ?? ele.fulfilledQuantityInvoiced ?? 0) >
              0
        );
      }
    }
    let itemsPendingToConvert = 0;
    let selectedSO = {
      ...data,
      entityId: data.id ? data.id : undefined,
      documentType: DOC_TYPE.SALES_ORDER,
      recurring: false,
      recurringActivated: false,
      backOrder: false,
      documentCode: data.salesOrderCode,
      documentDate: data.salesOrderDate
        ? data.salesOrderDate
        : data.documentDate,
      validTillDate: data.salesOrderDueDate
        ? data.salesOrderDueDate
        : data.documentDate,
      currency: data.currency,
      currencyCode: data.currency,
      sourceFulfillmentStatus:
        data.fulfillmentStatus || FULFILLMENT_STATUS.UNFULFILLED,
      fulfillmentStatus:
        data.fulfillmentStatus || FULFILLMENT_STATUS.UNFULFILLED,
      fulfillmentType: data.fulfillmentType,
      fulfillmentDate: data.salesOrderDueDate
        ? data.salesOrderDueDate
        : data.fulfillmentDate,
      status: !data.id ? DOCUMENT_STATUS.OPEN : data.status,
      salesInvoiceItems: updatedSIItems?.map((item) => {
        const pendingQty = Utility.getPendingQtyForConversion(item);
        if (pendingQty > 0) {
          ++itemsPendingToConvert;
        }

        const qtyToConvert =
          action === SOConversionAction.PARTIAL_INVOICE
            ? Utility.getFulfilledQtyForConversion(item)
            : pendingQty;

        return {
          ...item,
          id: undefined,
          isPartialInvoice: true,
          documentItemCode: undefined,
          fulfillmentByDoc: null,
          linkedQuoteItem: item.id,
          pendingQtyToConvert: pendingQty,
          productQuantity: qtyToConvert,
          qtyFulfilledFromPps: 0,
          qtyConvertedToPps: 0,
          uomQuantity: Utility.getUomQuantity(
            qtyToConvert,
            item.documentUOMSchemaDefinition
          ),
          taxDetails: item.taxDetails.map((taxDetail: any) => {
            return {
              ...taxDetail,
              id: null,
              additionalTaxIn: null
            };
          }),
          nonEditableColumns: data.reservedStock
            ? ['productQuantity', 'uom']
            : [],
          reservedQuantitiesData: item.reservedQuantitiesData?.map(
            (reservedQtyItem: any) => ({
              ...reservedQtyItem,
              availableQuantity: Utility.getUomQuantity(
                reservedQtyItem.availableQuantity,
                item.documentUOMSchemaDefinition
              ),
              reservedQuantity: Utility.getUomQuantity(
                reservedQtyItem.reservedQuantity,
                item.documentUOMSchemaDefinition
              ),
              advancedTrackingMetaDtos: reservedQtyItem.advancedTrackingMetaDtos
                ?.length
                ? reservedQtyItem.advancedTrackingMetaDtos?.map(
                    (advTrackingDto: any) => {
                      return {
                        ...advTrackingDto,
                        batchSize: Utility.getUomQuantity(
                          advTrackingDto.batchSize,
                          item.documentUOMSchemaDefinition
                        ),
                        reservedQuantity: Utility.getUomQuantity(
                          advTrackingDto.reservedQuantity,
                          item.documentUOMSchemaDefinition
                        ),
                        reservedQuantityFulfilled: Utility.getUomQuantity(
                          advTrackingDto.reservedQuantityFulfilled,
                          item.documentUOMSchemaDefinition
                        ),
                        batchSizeFulfilled: Utility.getUomQuantity(
                          advTrackingDto.batchSizeFulfilled,
                          item.documentUOMSchemaDefinition
                        )
                      };
                    }
                  )
                : []
            })
          )
        };
      }),
      linkedSalesInvoices: data.linkedSalesInvoices,
      processedInPPS: data.processedInPPS,
      reservedStock: data.reservedStock,
      sequenceFormat: invoiceSequenceFormat,
      isDocumentTouched: true
    };

    if (itemsPendingToConvert === 0) {
      showAlert(
        '',
        'Sorry, all products in this Sales Order have been invoiced in full. You cannot convert this Sales Order to Invoice anymore'
      );
    } else {
      const currentDate = new Date();
      const laterDate = getValidTillDateFromDocDate(currentDate, contact);

      const currentDateStr = DateFormatService.getDateStrFromDate(
        currentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      const laterDateStr = DateFormatService.getDateStrFromDate(
        laterDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );

      selectedSO = {
        ...selectedSO,
        documentDate: currentDateStr,
        shipByDate: laterDateStr,
        validTillDate: laterDateStr
      };
      switch (action) {
        case SOConversionAction.CONVERT_AND_FULFILL:
          selectedSO = {
            ...selectedSO,
            autoFulfill: true,
            fulfillmentComplete: true
          };
          convertToPartialInvoice(selectedSO);
          break;
        case SOConversionAction.CONVERT_ONLY:
        case SOConversionAction.PARTIAL_INVOICE:
          convertToPartialInvoice(selectedSO);
          break;
        default:
          break;
      }
    }
  };
  const isRRBProductPresent = (lineItems: any) => {
    let rrbPresent = false;
    lineItems?.forEach((row: any) => {
      let binCode = row?.product?.binCode;
      let rackCode = row?.product?.rackCode;
      let rowCode = row?.product?.rowCode;
      if (
        !Utility.isEmpty(rowCode) ||
        !Utility.isEmpty(rackCode) ||
        !Utility.isEmpty(binCode)
      ) {
        rrbPresent = true;
      }
    });
    return rrbPresent;
  };
  const checkIsLocalizedUomQty = () => {
    return salesOrder.salesOrderItems?.some(
      (item: any) => item.isLocalizedUomQty
    );
  };
  return (
    <div className="flex flex-col w-full px-1 pb-3 text-sm p-1">
      <div className="flex flex-row w-full">
        {`You are about to convert this Sales Order to Invoice, please select an
        action below:`}
      </div>
      <div
        className="flex flex-col mt-3 w-full"
        style={{
          pointerEvents: isApiLoading ? 'none' : 'auto',
          cursor: isApiLoading ? 'no-drop' : 'pointer'
        }}
      >
        {!(
          salesOrder.linkedSalesInvoices &&
          salesOrder.linkedSalesInvoices?.length > 0
        ) && (
          <>
            {!Utility.isAdvancedTracking(salesOrder) &&
              checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.FULFILL) &&
              !salesOrder.reservedStock &&
              salesOrder.fulfillmentType !== FULFILLMENT_TYPE.PICK_PACK_SHIP &&
              !isRRBProductPresent(salesOrder.salesOrderItems) &&
              !checkIsLocalizedUomQty() &&
              Utility.getDocumentFulfilledQty(
                salesOrder.salesOrderItems || []
              ) === 0 && (
                <div
                  className={
                    'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m'
                  }
                  onClick={() => {
                    if (!isOpeningForm) {
                      setIsOpeningForm(true);

                      setType(SOConversionAction.CONVERT_AND_FULFILL);
                      setIsApiLoading(true);
                      loadSalesOrderDetails(
                        salesOrder,
                        SOConversionAction.CONVERT_AND_FULFILL
                      );
                    }
                  }}
                >
                  <div
                    className="flex rounded-l rounded-bl"
                    style={{ height: 80 }}
                  >
                    <img
                      src={ic_convert_fulfill}
                      alt="fulfill and convert"
                      style={{ width: 60 }}
                    />
                  </div>
                  <div
                    className="flex flex-col items-start px-4 leading-5 text-left"
                    style={{ width: '90%' }}
                  >
                    <span className="fw-m text-blue">
                      {localizedText('Fulfill and Convert')}
                    </span>
                    <span className="text-gray">
                      {localizedText(
                        'Fulfill this Sales Order in full before converting in to a Full Invoice.'
                      )}
                    </span>
                  </div>
                  {isApiLoading &&
                    type === SOConversionAction.CONVERT_AND_FULFILL && (
                      <div>
                        <DKSpinner
                          iconClassName="ic-r"
                          className="column pl-0 pr-s"
                        />
                      </div>
                    )}
                </div>
              )}
            <div
              className={
                'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m mt-3'
              }
              onClick={() => {
                if (!isOpeningForm) {
                  setIsOpeningForm(true);
                  setType(SOConversionAction.CONVERT_ONLY);
                  setIsApiLoading(true);

                  loadSalesOrderDetails(
                    salesOrder,
                    SOConversionAction.CONVERT_ONLY
                  );
                }
              }}
            >
              <div className="flex rounded-l rounded-bl" style={{ height: 60 }}>
                <img
                  src={ic_convert_only}
                  alt="convert fully"
                  style={{ width: 60 }}
                />
              </div>
              <div
                className="flex flex-col items-start px-4 leading-5 text-left"
                style={{ width: '90%' }}
              >
                <span className="fw-m text-blue">Convert Fully</span>
                <span className="text-gray">
                  {`Convert this Sales Order into a Full Invoice.`}
                </span>
              </div>
              {isApiLoading && type === SOConversionAction.CONVERT_ONLY && (
                <div>
                  <DKSpinner
                    iconClassName="ic-r"
                    className="column pl-0 pr-s"
                  />
                </div>
              )}
            </div>
          </>
        )}
        {!salesOrder.reservedStock && (
          <div
            className={
              'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m mt-3'
            }
            onClick={() => {
              if (!isOpeningForm) {
                setIsOpeningForm(true);

                setIsApiLoading(true);
                setType(SOConversionAction.PARTIAL_INVOICE);

                loadSalesOrderDetails(
                  salesOrder,
                  SOConversionAction.PARTIAL_INVOICE
                );
              }
            }}
          >
            <div className="flex rounded-l rounded-bl" style={{ height: 60 }}>
              <img
                src={ic_convert_partially}
                alt="convert partially"
                style={{ width: 60 }}
              />
            </div>
            <div
              className="flex flex-col items-start px-4 leading-5 text-left"
              style={{ width: '90%' }}
            >
              <span className="fw-m text-blue">Convert Partially</span>
              <span className="text-gray">
                {`Create a Partial Invoice from this Sales Order.`}
              </span>
            </div>
            {isApiLoading && type === SOConversionAction.PARTIAL_INVOICE && (
              <div>
                <DKSpinner iconClassName="ic-r" className="column pl-0 pr-s" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
