import React from "react";
import PayslipSection from "../../Components/PayslipComponent/PayslipSection";
import { CONSTANTS, CurrencyFormatType } from "../../Constants/Constants";
import Address from "../../Helper/Address";
import AddressParser, { AddressType, OwnerType } from "../../Helper/AddressParser";
import AppManager from "../../Manager/AppManager";
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../Translate/LanguageManager";
import Utility, { convertDateFromERPFormatToDocFormat, getConvertedDate, getIsPayslip } from "../../Utilities/Utility";
import PayslipFieldUtility from "./PayslipFieldUtility";
import PayslipTableUtility from "./PayslipTableUtility";
import { Asset } from "../../assets";

export default class PayslipUtility {

    static renderPayslipDetailsContainer(title, value, style) {
        var textAlign = 'right'
        if(style !== undefined) {
            if(style.textAlign !== undefined) {
                textAlign = style.textAlign
            }
        }
        return <div className={style ? style.divClass: undefined} style={{
            width: style ? style.width : undefined,
            paddingTop: style ? style.paddingTop: undefined,
            textAlign: textAlign,
        }}>
            {this.renderPayslipDetail(title, value, style)}
        </div>
    }

    static renderPayslipDetail(title, value, style) {
        var fontReverse = undefined
        var titleColor = 'rgb(111, 119, 125)'
        var valueColor = 'rgb(0, 0, 0)'
        var titleOpacity = 1
        if (style !== undefined && style !== null) {
            fontReverse = style.fontReverse
            if(style.titleColor !== undefined) {
                titleColor = style.titleColor
                valueColor = style.valueColor
                titleOpacity = style.titleOpacity
            }
        }
        var titleStyle = {
            fontWeight: 300,
            color: titleColor,
            opacity: titleOpacity
        }
        var valueStyle = {
            fontWeight: 700,
            color: valueColor
        }


        if (fontReverse !== undefined && fontReverse !== null) {
            if (fontReverse) {
                valueStyle = {
                    fontWeight: 300,
                    color: valueColor
                }
                titleStyle = {
                    fontWeight: 700,
                    color: titleColor,
                    opacity: titleOpacity
                }
            }
        }
        return <div style={{
            fontSize: 12,
            fontWeight: 700,
        }}>
            <div style={{
                paddingTop: 10,
                paddingRight: 5,
                paddingLeft: 5,
                color: titleStyle.color,
                fontWeight: titleStyle.fontWeight,
                opacity: titleStyle.opacity
            }}>
                {title.toUpperCase()}
            </div>
            <div style={{
                fontWeight: valueStyle.fontWeight,
                color: valueStyle.color,
                paddingRight: 5,
                paddingBottom: 10,
                paddingLeft: 5,
            }} dangerouslySetInnerHTML={{ __html: value }}>
            </div>
        </div>
    }

    static renderPayslipAutoGeneratorSection() {
        return <div style={{
            paddingTop: '10px',
            paddingBottom: '10px',
            fontSize: '11px',
            textAlign: 'right',
            fontWeight: '400',
        }}>
            {getLocalisedText('payslip_auto_message')}
        </div>
    }

    static renderTableContainer(title, header, data, tableStyle, headerStyle, style) {
        return <>
            <div style={{
                fontWeight: 700,
                paddingTop: 10,
                // marginTop: 20,
                marginRight: 2,
                marginLeft: 2,
                fontSize: 16,
                width: '100%',

                paddingLeft: style ? style.paddingLeft : undefined,
                paddingRight: style ? style.paddingRight : undefined,
            }}>
                <PayslipSection
                    header={title}
                    headerList={header}
                    data={data}
                    highlighted={style ? style.highlighted : undefined}
                    headerStyle={headerStyle}
                    tableStyle={tableStyle}
                />
            </div>
        </>
    }

    static showHeaderTitle() {
        var showHeaderTitle = TemplateSettingsManager.defaultTemplateSettings.documentInfo.type.isVisible
        if(showHeaderTitle !== undefined && showHeaderTitle !== null) {
            return showHeaderTitle
        }

        return true
    }

    static getCompanyName(data, isFromERP) {
        var name = ''
        if(isFromERP !== undefined && isFromERP !== null ) {
            if(isFromERP) {
                if (data.tenantInfo !== undefined && data.tenantInfo !== null) {
                    if (data.tenantInfo.organizationName !== undefined && data.tenantInfo.organizationName !== null) {
                        name = data.tenantInfo.organizationName
                    }
                }
            }
            else {
                name = 'company_name'
            }
        }
        return name
    }

    static getCompanyAddress(data, isFromERP) {
        var address = ''
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                if (data.tenantInfo !== undefined && data.tenantInfo !== null) {
                    if (data.tenantInfo.address !== undefined && data.tenantInfo.address !== null) {
                        var companyAddress = new Address(undefined, undefined, undefined, undefined, undefined, OwnerType.tenant)
                        companyAddress.initPayslipAddress(data.tenantInfo.address)
                        address = AddressParser.getPayslipFormattedAddress(undefined, AddressType.from, companyAddress)
                    }
                }
            }
            else {
                address = 'company_address'
            }
        }
        return address
    }

    static getEmployeeName(data, isFromERP) {
        var name = ''
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                if (data.employeeDetails !== undefined && data.employeeDetails !== null) {
                    if (data.employeeDetails.firstName !== undefined && data.employeeDetails.firstName !== null) {
                        name = data.employeeDetails.firstName
                    }
                    if (data.employeeDetails.lastName !== undefined && data.employeeDetails.lastName !== null) {
                        if (name === '') {
                            name = data.employeeDetails.lastName
                        }
                        else {
                            name = name + ' ' + data.employeeDetails.lastName
                        }
                    }
                }
            }
            else {
                name = '#EmployeeName#'
            }
        }
        return name
    }

    static getAliasName(data, isFromERP) {
        var alias = '';
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                if (data.employeeDetails !== undefined && data.employeeDetails !== null) {
                    if (data.employeeDetails.aliasName !== undefined && data.employeeDetails.aliasName !== null) {
                        alias = data.employeeDetails.aliasName;
                    }
                }
            }
            else {
                alias = '#AliasName#';
            }
        }
        return alias;
    }

    static getEmployeeAddress(data, isFromERP) {
        var address = ''
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                if (data.employeeDetails !== undefined && data.employeeDetails !== null) {
                    if (data.employeeDetails.address !== undefined && data.employeeDetails.address !== null) {
                        var employeeAddress = new Address(undefined, undefined, undefined, undefined, undefined, OwnerType.contact)
                        employeeAddress.initPayslipAddress(data.employeeDetails.address)
                        return AddressParser.getPayslipFormattedAddress(undefined, AddressType.billTo, employeeAddress)
                    }
                }
            }
            else {
                address = '#EmployeeAddress#'
            }
        }
        return address
    }

    static getDate(type, data, isFromERP) {
        var value = this.getLocalisationText(type)
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                var date = data[type]
                if (type ==='dateOfJoining') {
                    if (data.employeeDetails !== undefined && data.employeeDetails !== null) {
                        date = data.employeeDetails[type]
                    }
                }
                if (date !== undefined && date !== null) {
                    if (date.includes('T')) {
                        //remove the timezone info
                        var newDate = date.split('T')
                        if (newDate.length > 0) {
                            date = newDate[0]
                        }
                    }
                    if (date.includes('-')) {
                        var rawDate = date.split('-')
                        if (rawDate.length > 2) {
                            date = rawDate[2] + '/' + rawDate[1] + '/' + rawDate[0]
                        }
                    }
                    value = getConvertedDate(convertDateFromERPFormatToDocFormat(date), this.getDateFormat(data))
                }
                if (date === '' || date === null || date === undefined) {
                    value = 'NA'
                }
            }
        }

        return value
    }

    static getValue(type, data, isFromERP, prefix) {
        var value = this.getLocalisationText(type, prefix)
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                if (data !== undefined && data !== null) {
                    value = data[type]
                    if (value !== undefined && value !== null) {
                        if (typeof value === 'boolean') {
                            if(value) {
                                value = 'TRUE'
                            }
                            else {
                                value = 'FALSE'
                            }
                        }
                    }
                }
                if ( data === null || data[type] === '' || data[type] === null || data[type] === undefined) {
                    value = 'NA'
                }
            }
        }
        return value
    }

    static getCurrentValue(type, data, isFromERP, currency) {
        var value = this.getValue(type, data, isFromERP)
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                if (value !== undefined && value !== null) {
                    var current = value['current']
                    if (current !== undefined && current !== null) {
                        value = current
                        if(currency !== undefined) {
                            value = Utility.toCurrencyFormat(value, currency)
                        }
                    }
                }
            }
        }
        return value
    }

    static getYTDValue(type, data, isFromERP, currency) {
        var value = this.getValue(type, data, isFromERP, 'YTD')
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                if (value !== undefined && value !== null) {
                    var ytd = value['YTD']
                    if (ytd !== undefined && ytd !== null) {
                        value = ytd
                        if (currency !== undefined) {
                            value = Utility.toCurrencyFormat(ytd, currency)
                        }
                    }
                }
            }
        }
        return value
    }

    static getNricFinWpnValue(data, isFromERP) {
        let value = 'NA';
        const NRIC = this.getValue("nric", data, isFromERP);
        const FIN = this.getValue("fin", data, isFromERP);
        const WPN = this.getValue("workPermitNumber", data, isFromERP);
        if (isFromERP) {
            if (NRIC !== "NA") {
                value = NRIC;
            } else if (FIN !== "NA") {
                value = FIN;
            } else if (WPN !== "NA") {
                value = WPN;
            }
        }
        return value;
    }

    static getPaidDaysValue(data, isFromERP) {
        let paidDays = 'NA';
        const days = this.getValue("days", data, isFromERP);
        const daysOff = this.getValue("daysOff", data, isFromERP);
        if (isFromERP) {
            if (days !== "NA" && daysOff !== "NA") {
                const value = days - daysOff;
                if (!isNaN(value)) {
                    paidDays = value;
                }
            }
        }
        return paidDays;
    }

    static getCheckAmount(data, label, currency) {
        var checkAmountRow = undefined
        if(data !== undefined && data !== null) {
            if(data.length > 0) {
                var totalCurrent = 0
                var totalYTD = 0
                data.forEach(element => {
                    totalCurrent = totalCurrent + parseFloat(element[1])
                    totalYTD = totalYTD + parseFloat(element[2])
                });
                checkAmountRow = [label, Utility.toCurrencyFormat(totalCurrent, currency), Utility.toCurrencyFormat(totalYTD, currency)]
            }
        }
        return checkAmountRow
    }

    static getPayPeriod(data, isFromERP) {
        var payPeriod = '#PayDate#'
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                payPeriod = ''
                var workPeriod = data.workPeriod
                if (workPeriod !== undefined && workPeriod !== null) {
                    if (workPeriod.from !== undefined && workPeriod.from !== null && workPeriod.from !== '') {
                        var from = getConvertedDate(convertDateFromERPFormatToDocFormat(this.changeDateFormat('to', workPeriod.from)), this.getDateFormat(data))
                        payPeriod = from
                    }

                    if (workPeriod.to !== undefined && workPeriod.to !== null && workPeriod.to !== '') {
                        var to = getConvertedDate(convertDateFromERPFormatToDocFormat(this.changeDateFormat('to', workPeriod.to)), this.getDateFormat(data))
                        if (payPeriod === '') {
                            payPeriod = to
                        }
                        else {
                            payPeriod = payPeriod + ' - ' + to
                        }
                    }
                }
                if (payPeriod === '' || payPeriod === null || payPeriod === undefined) {
                    payPeriod = 'NA'
                }

            }
        }
        return payPeriod
    }

    static changeDateFormat(type, date) {
        var newDate = ''
        if(type === 'from' || type === 'to') {
            if(date.includes('/')) {
                newDate = date.split('/')
                if (newDate.length > 2) {
                    date = newDate[1] + '/' + newDate[0] + '/' + newDate[2]
                }
            }
            return date
        }
        else if(type === 'join_date' || type === 'pay_date') {
            if (date.includes('T')) {
                //remove the timezone info
                newDate = date.split('T')
                if (newDate.length > 0) {
                    date = newDate[0]
                }
            }
            if (date.includes('-')) {
                var rawDate = date.split('-')
                if (rawDate.length > 2) {
                    date = rawDate[2] + '/' + rawDate[1] + '/' + rawDate[0]
                }
            }
            return date
        }
        return date
    }

    static getEmployeeEarningTable(data, isFromERP, currency) {
        var list = []
        var payPeriod = this.getPayPeriod(data, isFromERP)
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                var countryCode = PayslipUtility.getCountryCode(data);
                if(countryCode === 'SG' || countryCode === 'MY') {
                    if (data.basicPayrun !== undefined && data.basicPayrun !== null) {
                        if (data.basicPayrun.current !== undefined && data.basicPayrun.current !== null) {
                            if (countryCode === 'MY' && !TemplateSettingsManager.getIsPayslipBasicVisible()) {
                                list.push([getLocalisedText('basic_salary'), Utility.toCurrencyFormat(data.netPayrun.current, currency)])
                            } else {
                                list.push([getLocalisedText('basic_salary'), Utility.toCurrencyFormat(data.basicPayrun.current, currency)])
                            }
                        }
                    }

                    if (TemplateSettingsManager.getIsPayslipOvertimeVisible()) {
                        if (Array.isArray(data["overtime"]) && data["otPayRate"] !== null && data["otPayRate"] !== undefined) {
                            data["overtime"].forEach((ot) => {
                                list.push([`${Utility.toCurrencyFormat(ot.rate)} Overtime (${Utility.toCurrencyFormat(ot.value)} Hrs X ${Utility.toCurrencyFormat(data["otPayRate"], currency)})`, Utility.toCurrencyFormat(ot.amount, currency)]);
                            });
                        }
                    }
                } else {
                    var totalCurrent = 0
                    var totalYTD = 0
                    if (data['earnings'] !== undefined && data['earnings'] !== null) {
                        if (data['earnings'].length > 0) {
                            data['earnings'].forEach(element => {
                                var newArray = []
                                if(element.YTD === undefined || element.YTD === null) {
                                    element.YTD = element.current
                                }
                                if(countryCode === 'IN') {
                                    const earningsUpdatedDescription = this.getEarningsDescription(element.description);
                                    newArray.push(earningsUpdatedDescription, Utility.toCurrencyFormat(element.rate, currency), payPeriod, Utility.toCurrencyFormat(element.current, currency), Utility.toCurrencyFormat(element.YTD, currency))
                                } else {
                                    newArray.push(element.description, Utility.toCurrencyFormat(element.rate, currency), payPeriod, Utility.toCurrencyFormat(element.current, currency), Utility.toCurrencyFormat(element.YTD, currency))
                                }
                                totalCurrent = totalCurrent + parseFloat(element.current)
                                totalYTD = totalYTD + parseFloat(element.YTD)
                                list.push(newArray)
                            });
                        }
                    }

                    if (TemplateSettingsManager.getIsPayslipOvertimeVisible()) {
                        if (Array.isArray(data["overtime"]) && data["overtime"].length > 0 && data["otPayRate"] !== null && data["otPayRate"] !== undefined) {
                            data["overtime"].forEach((ot) => {
                                list.push([`${Utility.toCurrencyFormat(ot.rate)} Overtime (${Utility.toCurrencyFormat(ot.value)} Hrs)`, Utility.toCurrencyFormat(data["otPayRate"], currency), payPeriod, Utility.toCurrencyFormat(ot.amount, currency), 'NA']);
                                totalCurrent = totalCurrent + parseFloat(ot.amount);
                            });
                        }
                    }

                    if (list.length > 0) {
                        list.push([getLocalisedText('gross_earnings'), '', '', Utility.toCurrencyFormat(totalCurrent, currency), Utility.toCurrencyFormat(totalYTD, currency)]);
                    }
                }
                if (list.length === 0) {
                    if (countryCode === 'SG' || countryCode === 'MY') {
                        list.push(['NA', Utility.toCurrencyFormat(0, currency)])
                    }
                    else {
                        list.push(['NA', 'NA', 'NA', Utility.toCurrencyFormat(0, currency), Utility.toCurrencyFormat(0, currency)])
                    }
                }
            }
        }
        return list
    }

    static getEarningsDescription(description) {
        const earningsConfiguration = TemplateSettingsManager.defaultTemplateSettings.payslipEarningsFieldConfiguration;
        if(earningsConfiguration !== null && earningsConfiguration !== undefined) {
            switch(description) {
                case CONSTANTS.FIXED_ALLOWANCE :
                    const index = earningsConfiguration.findIndex(f => f.type === CONSTANTS.FIXED_ALLOWANCE_TYPE);
                    if(index !== -1) {
                        const fixedAllowanceConfig = earningsConfiguration[index];
                        return fixedAllowanceConfig.name;
                    }
                    break;
                default:
    
            }
        }
        return description;
    }

    static getDeductionsTable(data, isFromERP, currency) {
        var list = []
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                var countryCode = this.getCountryCode(data)
                if (data['deductions'] !== undefined && data['deductions'] !== null) {
                    if (data['deductions'].length > 0) {
                        data['deductions'].forEach(element => {
                            var newArray = []
                            if (countryCode === 'SG' || countryCode === 'MY') {
                                newArray.push(element.description, Utility.toCurrencyFormat(element.current, currency))
                            }
                            else {
                                if (element.YTD === undefined || element.YTD === null) {
                                    newArray.push(element.description, element.type, Utility.toCurrencyFormat(element.current, currency), Utility.toCurrencyFormat(element.current, currency))
                                }
                                else {
                                    newArray.push(element.description, element.type, Utility.toCurrencyFormat(element.current, currency), Utility.toCurrencyFormat(element.YTD, currency))
                                }
                            }
                            list.push(newArray)
                        });
                    }
                }

                var additionalDeduction = this.getAdditionalDeductionRow(data, countryCode, currency)
                if(additionalDeduction.length > 0) {
                    additionalDeduction.forEach(element => {
                        list.push(element)
                    });
                }

                if (countryCode !== 'SG' && TemplateSettingsManager.getIsPayslipLOPVisible()) {
                    if (data.lossOfPay !== undefined && data.lossOfPay !== null) {
                        if (countryCode === 'MY') {
                            list.push(['LOP', Utility.toCurrencyFormat(data.lossOfPay, currency)])
                        } else {
                            list.push(['LOP', 'NA', Utility.toCurrencyFormat(data.lossOfPay, currency), 'NA'])
                        }
                    }
                }

                if (list.length === 0) {
                    if (countryCode === 'SG' || countryCode === 'MY') {
                        list.push(['NA', Utility.toCurrencyFormat(0, currency)])
                    }
                    else {
                        list.push(['NA', 'NA', Utility.toCurrencyFormat(0, currency), Utility.toCurrencyFormat(0, currency)])
                    }
                }
            }
        }
        return list
    }

    static getAdditionalDeductionRow(data, countryCode, currency) {
        var list = []
        if(countryCode === 'SG') {
            var compliance = data.compliance
            if (compliance !== undefined && compliance !== null) {
                var employeeContribution = compliance.employeeContribution
                if (employeeContribution !== undefined && employeeContribution !== null ) {
                    if (employeeContribution.cpfContribution !== undefined && employeeContribution.cpfContribution !== null && employeeContribution.cpfContribution) {
                        list.push(['Employee CPF', Utility.toCurrencyFormat(employeeContribution.cpfContribution, currency)])
                    }
                    if (employeeContribution.mbmf !== undefined && employeeContribution.mbmf !== null && employeeContribution.mbmf) {
                        list.push(['MBMF', Utility.toCurrencyFormat(employeeContribution.mbmf, currency)])
                    }
                    if (employeeContribution.sinda !== undefined && employeeContribution.sinda !== null && employeeContribution.sinda) {
                        list.push(['SINDA', Utility.toCurrencyFormat(employeeContribution.sinda, currency)])
                    }
                    if (employeeContribution.cdac !== undefined && employeeContribution.cdac !== null && employeeContribution.cdac) {
                        list.push(['CDAC', Utility.toCurrencyFormat(employeeContribution.cdac, currency)])
                    }
                    if (employeeContribution.ecf !== undefined && employeeContribution.ecf !== null && employeeContribution.ecf) {
                        list.push(['ECF', Utility.toCurrencyFormat(employeeContribution.ecf, currency)])
                    }
                }
            }

            if (data.lossOfPay !== undefined && data.lossOfPay !== null) {
                list.push(['LOP', Utility.toCurrencyFormat(data.lossOfPay, currency)])
            }
        }
        else if (countryCode === 'MY') {
            if (data.employeeTax !== undefined && data.employeeTax !== null) {
                if (data.employeeTax.length > 0) {
                    data.employeeTax.forEach(element => {
                        list.push([element.tax, Utility.toCurrencyFormat(element.current, currency)])
                    });
                }
            }
        }
        return list
    }

    static getAllowanceTable(data, isFromERP, currency) {
        var list = []
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                var countryCode = this.getCountryCode(data)
                if (countryCode === 'SG' || countryCode === 'MY') {
                    if (data['earnings'] !== undefined && data['earnings'] !== null) {
                        if (data['earnings'].length > 0) {
                            data['earnings'].forEach(element => {
                                list.push([element.description, Utility.toCurrencyFormat(element.current, currency)])
                            });
                        }
                    }
                    if (list.length === 0) {
                        list.push(['NA', Utility.toCurrencyFormat(0, currency)])
                    }
                }
            }
        }
        return list
    }

    static getTaxesTable(data, isFromERP, currency, partyType) {
        var list = []
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                if (data.compliance !== undefined && data.compliance !== null) {
                    if (data.compliance['taxDetails'] !== undefined && data.compliance['taxDetails'] !== null) {
                        if (data.compliance['taxDetails'].length > 0) {
                            data.compliance['taxDetails'].forEach(element => {
                                if (element.payeeType === partyType) {
                                    var newArray = []
                                    newArray.push(element.taxName, Utility.toCurrencyFormat(element.taxAmount, currency), Utility.toCurrencyFormat(element.yearToDate, currency))
                                    list.push(newArray)
                                }
                            });
                        }
                    }
                }

                if(list.length === 0) {
                    list.push(['NA', Utility.toCurrencyFormat(0, currency), Utility.toCurrencyFormat(0, currency)])
                }
            }
        }
        return list
    }

    static getEmployerContribution(data, isFromERP, currency) {
        var list = []
        var totalAmount = 0
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                var countryCode = this.getCountryCode(data)
                if (countryCode === 'MY') {
                    if (data.employerTax !== undefined && data.employerTax !== null) {
                        if (data.employerTax.length > 0) {
                            totalAmount = 0
                            data.employerTax.forEach(element => {
                                list.push([element.tax, Utility.toCurrencyFormat(element.current, currency)])
                                totalAmount = totalAmount + element.current
                            });
                            list.push(['Total', Utility.toCurrencyFormat(totalAmount, currency)])
                        }
                    }
                }
                else if (countryCode === 'SG') {
                    var compliance = data.compliance
                    if (compliance !== undefined && compliance !== null) {
                        var employerContribution = compliance.employerContribution
                        if (employerContribution !== undefined && employerContribution !== null) {
                            totalAmount = 0
                            if (employerContribution.cpfContribution !== undefined && employerContribution.cpfContribution !== null) {
                                list.push(['CPF (Employer)', Utility.toCurrencyFormat(employerContribution.cpfContribution, currency)])
                                totalAmount = totalAmount + parseFloat(employerContribution.cpfContribution)
                            }
                            if (employerContribution.employerSDL !== undefined && employerContribution.employerSDL !== null) {
                                list.push(['SDL', Utility.toCurrencyFormat(employerContribution.employerSDL, currency)])
                                totalAmount = totalAmount + parseFloat(employerContribution.employerSDL)
                            }
                            if (employerContribution.employerFWL !== undefined && employerContribution.employerFWL !== null) {
                                list.push(['FWL', Utility.toCurrencyFormat(employerContribution.employerFWL, currency)])
                                totalAmount = totalAmount + parseFloat(employerContribution.employerFWL)
                            }
                        }
                        list.push(['Total', Utility.toCurrencyFormat(totalAmount, currency)])
                    }
                }
            }
        }
        return list
    }

    static getNetPayTable(data, isFromERP, currency) {
        var list = []
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                list.push([getLocalisedText('net_pay'), this.getCurrentValue('netPay', data, isFromERP, currency)])
            }
        }
        return list
    }

    static getSummaryTable(data, isFromERP, currency, isOtherTemplate) {
        var list = [
            { label: getLocalisedText('gross_earnings'), value: 'grossEarning' },
            { label: getLocalisedText('pre_tax_deductions_contributions'), value: 'preTaxDeduction' },
            { label: getLocalisedText('taxes'), value: 'taxes' },
            { label: getLocalisedText('post_tax_deductions_contributions'), value: 'postTaxDeduction' },
            { label: getLocalisedText('net_pay_contributions'), value: 'netPay' },
            { label: getLocalisedText('check_amount'), value: 'netPay' },
        ]

        if(isOtherTemplate) {
            list = [
                { label: getLocalisedText('basic_salary'), value: 'netPayrun' },
                { label: getLocalisedText('additional_earnings'), value: 'additionalEarnings' },
                { label: getLocalisedText('pre_tax_deductions_contributions'), value: 'preTaxDeduction' },
                { label: getLocalisedText('taxes'), value: 'taxes' },
                { label: getLocalisedText('post_tax_deductions_contributions'), value: 'postTaxDeduction' },
                { label: getLocalisedText('net_pay_contributions'), value: 'netPay' },
                { label: getLocalisedText('check_amount'), value: 'netPay' },
            ]
        }

        var rowRecords = []
        var rawData = []
        list.forEach(element => {
            var current = 0
            var ytd = 0
            if (element.value === 'additionalEarnings') {
                var additionalEarnings = this.getAdditionalEarning(data)
                current = additionalEarnings.current
                ytd = additionalEarnings.ytd
            }
            else {
                if (this.getCurrentValue(element.value, data, isFromERP, undefined) !== undefined) {
                    current = this.getCurrentValue(element.value, data, isFromERP, undefined)
                }

                if (this.getYTDValue(element.value, data, isFromERP, undefined) !== undefined) {
                    ytd = this.getYTDValue(element.value, data, isFromERP, undefined)
                }
            }
            if(ytd === undefined || ytd === null || isNaN(ytd)) {
                ytd = current
            }
            rowRecords.push([element.label,
                Utility.toCurrencyFormat(current, currency),
                Utility.toCurrencyFormat(ytd, currency)]
            )
            rawData.push([element.label, current, ytd]
            )
        });

        // rowRecords.push(this.getCheckAmount(rawData, getLocalisedText('check_amount'), currency))
        return rowRecords
    }

    static getAdditionalEarning(data) {
        var totalCurrent = 0
        var totalYTD = 0
        if (data['earnings'] !== undefined && data['earnings'] !== null) {
            if (data['earnings'].length > 0) {
                data['earnings'].forEach(element => {
                    totalCurrent = totalCurrent + parseFloat(element.current)
                    totalYTD = totalYTD + parseFloat(element.YTD)
                });
            }
        }

        return {
            current: totalCurrent,
            ytd: totalYTD,
        }
    }


    static getCountryCode(data) {
        var countryCode = '';
        if(data !== undefined && data !== null) {
            if (data.tenantConfiguration !== undefined && data.tenantConfiguration !== null) {
                if (data.tenantConfiguration.countryCode !== undefined && data.tenantConfiguration.countryCode !== null) {
                    countryCode = data.tenantConfiguration.countryCode;
                }
            }
        }

        return countryCode;
    }

    static getDateFormat(data) {
        var dateFormat = undefined
        if (data.dateFormat !== undefined && data.dateFormat !== null) {
            if (data.dateFormat !== undefined && data.dateFormat !== null) {
                dateFormat = data.dateFormat
            }
        }
        else if (data.tenantConfiguration !== undefined && data.tenantConfiguration !== null) {
            if (data.tenantConfiguration.dateFormat !== undefined && data.tenantConfiguration.dateFormat !== null) {
                dateFormat = data.tenantConfiguration.dateFormat
            }
        }
        return dateFormat
    }


    static setDateFormat(data, newDateFormat) {
        var newData = data
        if (data.tenantConfiguration !== undefined && data.tenantConfiguration !== null) {
            if (data.tenantConfiguration.dateFormat !== undefined && data.tenantConfiguration.dateFormat !== null) {
                newData.tenantConfiguration.dateFormat = newDateFormat
            }
        }

        if (data.dateFormat !== undefined && data.dateFormat !== null) {
            data.dateFormat = newDateFormat
        }

        return newData
    }

    static resetMockData(data, id) {
        if (getIsPayslip(data.type)) {
            if (data.payslip.isMockData !== undefined) {
                if (data.payslip.isMockData && !this.isGetShareTemplate(data, id)) {
                    data.payslip = this.getMockData(id)
                    TemplateSettingsManager.updatePayslipTableConfiguration(PayslipTableUtility.getPayslipDefaultTable(data))
                    TemplateSettingsManager.updatePayslipFieldConfiguration(PayslipFieldUtility.getDefaultFields(data.payslip))
                    this.resetDecimalPoint(data.payslip)
                    this.resetCurrency(data)
                }
            }
            data.dateFormat = this.getDateFormat(data.payslip)
        }
        return data
    }

    static isGetShareTemplate(data, id) {
        var isShare = false
        var countryCode = this.getCountryCode(data.payslip)
        if(countryCode === 'IN' || countryCode === 'US') {
            var shareTemplateList = [1, 6, 7, 8, 9, 10]
            var template = shareTemplateList.find(x => x === id)
            if(template !== undefined) {
                isShare = true
            }
        }
        return isShare
    }

    static getMockData(templateId) {
        switch (templateId) {
            case 1:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
                return Asset.mockData.IN_Payslip
            case 2:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
                return Asset.mockData.US_Payslip
            case 3:
            case 20:
            case 21:
            case 22:
            case 23:
                return Asset.mockData.SG_Payslip
            case 4:
            case 19:
                return Asset.mockData.MY_Payslip
            case 5:
                return Asset.mockData.default_payslip
            
                
            default:
        }
    }

    static resetDecimalPoint(data) {
        if (data.tenantConfiguration !== undefined && data.tenantConfiguration !== null) {
            var tenantConfiguration = data.tenantConfiguration

            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.decimalScale === undefined ||
                (TemplateSettingsManager.getRemoteTemplateUID() === null ||
                    TemplateSettingsManager.getRemoteTemplateUID() === undefined)) {
                var scale = 2

                if (tenantConfiguration.decimalFormat !== null && tenantConfiguration.decimalFormat !== undefined) {
                    scale = parseInt(tenantConfiguration.decimalFormat)
                }

                TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['decimalScale'] = { 'value': scale }
                AppManager.setDecimalScale(scale)
            }
            else {
                AppManager.setDecimalScale(TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.decimalScale.value)
            }
        }
    }

    static resetCurrency(data) {
        //Currency symbol
        if(data.payslip !== undefined && data.payslip !== null) {
            var payslip = data.payslip
            if (payslip.tenantInfo !== undefined && payslip.tenantInfo !== null) {
                //currency
                if (payslip.tenantInfo.currencySymbol !== undefined && payslip.tenantInfo.currencySymbol !== null) {
                    data.currency = payslip.tenantInfo.currencySymbol
                    data.currencyName = payslip.tenantInfo.currency
                }
                else {
                    data.currencyName = payslip.tenantInfo.currency
                }
            }
        }


        var templateCurrency = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency
        if (templateCurrency !== undefined && templateCurrency !== null) {
            var newTemplateCurrency = templateCurrency
            if (templateCurrency.format !== null && templateCurrency.format !== undefined) {
                if (templateCurrency.format === CurrencyFormatType.SYMBOL &&
                    (data.currency === null || data.currency === undefined)
                ) {
                    var currency = {
                        isVisible: templateCurrency.isVisible,
                        format: CurrencyFormatType.NAME,
                        customValue: undefined
                    }
                    newTemplateCurrency = currency
                }
            }

            //display the type according to document currency symbol/name
            if (newTemplateCurrency.format === CurrencyFormatType.SYMBOL) {
                data.currencyCustomValue = data.currency
            }
            else if (newTemplateCurrency.format === CurrencyFormatType.NAME) {
                data.currencyCustomValue = data.currencyName
            }
            else if (newTemplateCurrency.format === CurrencyFormatType.SYMBOL_NAME) {
                data.currencyCustomValue = (data.currencyName ? data.currencyName : '$') + ' ' + (data.currency ? data.currency : '$')
            }

            data.showCurrency = newTemplateCurrency.isVisible
            data.showCheckCurrency = newTemplateCurrency.isVisibleCheckCurrency
        }
        else {
            var defaultCurrency = {
                isVisible: true,
                format: data.currency !== undefined && data.currency !== null ? CurrencyFormatType.CUSTOM : CurrencyFormatType.NAME,
                customValue: data.currency !== undefined && data.currency !== null ? data.currency : data.currencyName
            }

            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency = defaultCurrency
            data.showCurrency = true
            data.currencyFormat = defaultCurrency.format
            data.currencyCustomValue = defaultCurrency.customValue
            data.showCheckCurrency = false
        }

        return data
    }

    static getLocalisationText(type, prefix) {
        var value = type
        var prefixValue = prefix ? prefix : ''
        switch (type) {
            case 'SSN':
                value = '#SocialSecurityNumber#'
                break;
            case 'federalFilingStatus':
                value = '#TaxableMaritalStatus#'
                break;
            case 'taxExemptions':
                value = '#Exemptions#'
                break;
            case 'payDate':
                value = '#PayDate#'
                break;
            case 'pfAccountNumber':
                value = '#PFAccountNumber#'
                break;
            case 'esiNumber':
                value = '#ESINumber#'
                break;
            case 'uanNumber':
                value = '#UANNumber#'
                break;
            case 'designation':
                value = '#Designation#'
                break;
            case 'grossEarning':
                value = '#GrossEarning' + prefixValue + '#'
                break;
            case 'preTaxDeduction':
                value = '#PreTaxDeduction' + prefixValue + '#'
                break;
            case 'taxes':
                value = '#Taxes' + prefixValue + '#'
                break;
            case 'postTaxDeduction':
                value = '#PostTaxDeduction' + prefixValue + '#'
                break;
            case 'netPay':
                value = '#NetPay' + prefixValue + '#'
                break;
            default:
                break;
        }
        return value
    }
    static getEmployeeBankAccNumber(data, isFromERP) {
        var accountNumber = ''
        if (isFromERP !== undefined && isFromERP !== null) {
            if (isFromERP) {
                if (data.employeeDetails !== undefined && data.employeeDetails !== null) {
                    if (data.employeeDetails.paymentDetails !== undefined && data.employeeDetails.paymentDetails !== null) {
                        accountNumber = data.employeeDetails.paymentDetails.accountNumber === ""
                            ? "NA"
                            : data.employeeDetails.paymentDetails.accountNumber;
                    }
                }
            }
            else {
                accountNumber = 'NA'
            }
        }
        return accountNumber
    }

    static getDefaultPayslipEarningHeader(templateId) {
        const defaultPayslipEarningHeader_IN = [
            { index: 0, label: getLocalisedText('description'), type: 'description', isSelected: true, width: '25%' },
            { index: 1, label: getLocalisedText('rate'), type: 'rate', isSelected: true, width: '15%' },
            { index: 2, label: getLocalisedText('duration'), type: 'duration', isSelected: true, width: '30%' },
            { index: 3, label: getLocalisedText('current'), type: 'current', isSelected: true, width: '15%' },
            { index: 4, label: getLocalisedText('year_to_date'), type: 'year_to_date', isSelected: true, width: '15%' },
        ]

        const defaultPayslipEarningHeader_IN_12 = [
            { index: 0, label: getLocalisedText('description'), type: 'description', isSelected: true, width: '25%' },
            { index: 1, label: getLocalisedText('rate'), type: 'rate', isSelected: true, width: '15%' },
            { index: 2, label: getLocalisedText('duration'), type: 'duration', isSelected: true, width: '30%' },
            { index: 3, label: getLocalisedText('current'), type: 'current', isSelected: true, width: '15%' },
            { index: 4, label: getLocalisedText('year_to_date'), type: 'year_to_date', isSelected: false, width: '15%' },
        ]

        const defaultPayslipEarningHeader_SG = [
            { index: 0, label: getLocalisedText('description'), type: 'description', isSelected: true, width: '50%' },
            { index: 1, label: getLocalisedText('rate'), type: 'rate', isSelected: true, width: '50%' },
        ]

        switch (templateId) {
            case 1:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
                return defaultPayslipEarningHeader_IN
            case 12:
                return defaultPayslipEarningHeader_IN_12
            case 2:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
                return defaultPayslipEarningHeader_IN
            case 3:
            case 20:
            case 21:
            case 22:
            case 23:
                return defaultPayslipEarningHeader_SG
            case 4:
            case 19:
                return defaultPayslipEarningHeader_SG
            case 5:
                return defaultPayslipEarningHeader_IN
            default:
        }
    }

    static processEmployeeEarningsData(documentData, header, employeeEarningsData) {
        let newHeader = header
        let newData = employeeEarningsData

        const employeeEarningColumns = TemplateSettingsManager.defaultTemplateSettings.payslipEmployeeEarningsTableConfiguration 
        if (employeeEarningColumns && employeeEarningColumns !== null) {
            let selectedEmployeeEarningsColumn = employeeEarningColumns.filter(x => x.isSelected)
            newHeader = selectedEmployeeEarningsColumn.map(x => x.label)
            newData = []
            employeeEarningsData.forEach(row => {
                let newColumns = []
                selectedEmployeeEarningsColumn.forEach(column => {
                    newColumns.push(row[column.index])
                });
                newData.push(newColumns)
            });
        }
        else if (documentData.templateId === 12) {
            const defaultEarningsHeaders = this.getDefaultPayslipEarningHeader(12).filter(x => x.isSelected)
            newHeader = defaultEarningsHeaders.map(x => x.label)
            newData = []
            employeeEarningsData.forEach(row => {
                let newColumns = []
                defaultEarningsHeaders.forEach(column => {
                    newColumns.push(row[column.index])
                });
                newData.push(newColumns)
            });
        }
        return {newHeader, newData}
    }
}
