import { DKIcon } from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  COUNTRY_CODES,
  DOC_TYPE,
  POPUP_CALLBACKS_TYPE,
  QTY_ROUNDOFF_PRECISION,
  TRACKING_TYPE
} from '../../Constants/Constant';
import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import DateFormatService from '../../Services/DateFormat';
import ic_barcode_green from '../../Assets/Icons/ic_barcode_green.svg';

import { CallBackPayloadType } from '../../Models/Interfaces';
import { selectWarehouse } from '../../Redux/Slices/WarehouseSlice';
import SalesReturnTable from './PurchaseReturnTable';
import PurchaseReturnService from '../../Services/PurchaseReturn';
import Utility from '../../Utility/Utility';
import WarehouseService from '../../Services/Warehouse';

export interface SalesReturnsRecordProps {
  documentDetails: any;
  salesReturnsDetails: any;
  passingInteraction?: (callback: CallBackPayloadType) => void;
  documentType: DOC_TYPE;
  isDeleted: (val: boolean) => void;
  isClosed: (val: boolean) => void;
}

const PurchaseReturnRecordWithDetails: React.FC<SalesReturnsRecordProps> = (
  props
) => {
  const [fulfillmentDetails, setFulfillmentDetails] = useState<any>(
    props.salesReturnsDetails
  );
  const [fulfillmentData, setFulfillmentData] = useState<any>(
    fulfillmentDetails[0]
  );
  const [documentDetails, setDocumentDetails] = useState<any>(
    props.documentDetails
  );
  const [itemsDescriptionArray, setItemDescriptionArray] = useState<any[]>([]);
  const [res, setRes] = useState<any>({});

  const tenantInfo = useAppSelector(activeTenantInfo);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isVisibleState, setIsVisibleState] = useState<boolean>(false);
  const [isSerail, setIsSerial] = useState<boolean>(false);
  const [allWarehouses, setAllWarehouses] = useState<any>();

  const lineItemsTable = useRef(null);
  const warehousesData = useAppSelector(selectWarehouse);

  useEffect(() => {
    getAllWarehouses();
  }, []);

  useEffect(() => {
    let itemsArray =
      documentDetails?.purchaseInvoiceProducts ||
      documentDetails?.purchaseOrderItems;
    setItemDescriptionArray(itemsArray);
    if (tenantInfo.country === COUNTRY_CODES.IN) setIsVisibleState(true);
  }, [itemsDescriptionArray]);

  useEffect(() => {
    if (props.salesReturnsDetails) {
      setFulfillmentDetails(props.salesReturnsDetails);
      setFulfillmentData(props?.salesReturnsDetails?.[0]);
    }
    setDocumentDetails(props.documentDetails);
  }, [props]);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.FULFILLMENT_RECORDS,
        data: () => {
          closePopup();
        }
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  const getAllWarehouses = async () => {
    const res = await WarehouseService.getAllWarehouses();
    setAllWarehouses(res?.content);
  };

  const closePopup = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setShowPopup(false);
        break;
    }
  };

  const getWarehouseName = (code: any) => {
    let wh = allWarehouses?.filter((ele: any) => ele.code === code);
    return wh && wh?.[0]?.name;
  };

  return (
    <div className="flex justify-content-between parent-width p-4">
      <div className="flex flex-col parent-width">
        <div className="flex justify-content-between w-full">
          <div className="flex flex-col w-48">
            <span className="text-black fs-r ml-0 font-medium">Warehouse</span>
            <div className="position-relative pt-2">
              {getWarehouseName(fulfillmentData?.warehouseCode)}
            </div>
          </div>
          {fulfillmentData.reasonToReturn &&
            fulfillmentData.reasonToReturn !== '' && (
              <div className="flex flex-col w-48">
                <span className="text-black fs-r ml-0 font-medium">Reason</span>
                <div className="position-relative pt-2">
                  {fulfillmentData.reasonToReturn}
                </div>
              </div>
            )}
          <div className="flex flex-col w-48">
            <span className="text-black fs-r ml-0 font-medium">
              Return Date
            </span>
            <div className="position-relative pt-2">
              {DateFormatService.getFormattedDateString(
                fulfillmentData?.purchaseReturnDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              )}
            </div>
          </div>
        </div>
        <div
          className="flex justify-content-between invoice-table"
          style={{
            position: 'relative',
            top: '30px'
          }}
        >
          <table
            ref={lineItemsTable}
            className="table mb-5"
            style={{ width: '100%' }}
          >
            <thead>
              <tr>
                <th
                  className="text-center"
                  style={{ width: 40, minWidth: 40, maxWidth: 40 }}
                >
                  #
                </th>
                <th className="text-left" style={{ width: 150, minWidth: 200 }}>
                  Product Name
                </th>
                {/* <th className="text-left">Product Description</th> */}
                <th
                  className="text-right"
                  style={{ width: 100, minWidth: 100 }}
                >
                  Received Qty
                </th>
                <th
                  className="text-right"
                  style={{ width: 100, minWidth: 100 }}
                >
                  Returned Qty
                </th>
              </tr>
            </thead>
            <tbody>
              {fulfillmentData &&
                fulfillmentData.purchaseReturnItems.map((item: any, i: any) => {
                  return (
                    <tr className="dk-data-grid-row-bg">
                      <td className="text-align-center px-2 py-4 fs-r">
                        {i + 1}
                      </td>
                      <td className="px-2 py-4 fs-r">{item.productName}</td>
                      {/* <td className="px-2 py-4 fs-r">
                        {itemsDescriptionArray[i]?.productDescription}
                      </td> */}
                      <td className="text-align-right px-2 py-4 fs-r">
                        {Utility.roundingOff(
                          item.documentUOMSchemaDefinition
                            ? item.uomQuantityReceived
                            : item.quantityReceived,
                          QTY_ROUNDOFF_PRECISION
                        )}
                      </td>
                      <td className="text-align-right px-2 py-4 fs-r">
                        {Utility.roundingOff(
                          item.documentUOMSchemaDefinition
                            ? item.uomQuantityToReturn
                            : item.quantityToReturn,
                          QTY_ROUNDOFF_PRECISION
                        )}
                      </td>
                      {item.advancedTrackingType !== TRACKING_TYPE.NONE && (
                        <td
                          className="text-align-right pl-5 py-4 fs-r"
                          style={{ width: 40, minWidth: 40, maxWidth: 40 }}
                        >
                          <DKIcon
                            src={ic_barcode_green}
                            onClick={() => {
                              if (item.advancedTrackingType === 'BATCH') {
                                setIsSerial(false);
                              }
                              if (item.advancedTrackingType === 'SERIAL') {
                                setIsSerial(true);
                              }
                              PurchaseReturnService.getAdvanceTrackingRecord(
                                item.itemSequenceCode
                              ).then((res: any) => {
                                setRes({
                                  ...res,
                                  transactionRefCode: item.itemSequenceCode
                                });
                                setShowPopup(true);
                              });
                            }}
                            title="Advanced Tracking"
                            className="ic-s flex align-items-center cursor-hand"
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      {showPopup && (
        <SalesReturnTable
          populateFormData={res}
          isSerial={isSerail}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};

export default PurchaseReturnRecordWithDetails;
