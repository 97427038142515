import React, { useEffect, useState } from 'react';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';
import {
  DKButton,
  DKDataGrid,
  DKIcons,
  DKInput,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import WorkoutService from '../../../Services/Workout';
import ProductService from '../../../Services/Product';
import { JWO_RESERVATION_CONFIG } from '../../../StaticColumnConfigs/StaticColumnConfigs';
import ic_barcode_red from '../../../Assets/Icons/ic_barcode_red.svg';
import ic_barcode_green from '../../../Assets/Icons/ic_barcode_green.svg';
import NoneTrackedAssignment from '../../../SharedComponents/WarehouseManagement/NoneTrackedAssignment';
import { prepareBomExplosionProductForAllocation } from '../BomExplosion/BOMExplosionHelper';
import { ADVANCE_TRACKING } from '../../../Constants/Enum';
import {
  DOC_TYPE,
  MODULE_TYPE,
  NONE,
  PRODUCT_TYPE,
  TRACKING_TYPE
} from '../../../Constants/Constant';
import Utility from '../../../Utility/Utility';
import RawMaterialHelper from '../WorkOrder/InnerComponents/BomMaterialComponent/RawMaterialHelper';
import BatchTrackingAssignment from '../../../SharedComponents/AdvancedTrackingPopup/BatchTrackingAssignment';
import MRPProductsService from '../../../Services/MRP/MRPProducts';
import { useAppDispatch } from '../../../Redux/Hooks';
import { fetchBatchTrackingProducts } from '../../../Redux/Slices/BatchTrackingSlice';
import SerialTrackedAssignment from '../../../SharedComponents/AdvancedTrackingPopup/SerialTrackedAssignment';
import { fetchSerialTrackingProducts } from '../../../Redux/Slices/SerialTrackingSlice';

const ReserveJWO = (props: any) => {
  const [bomDetails, setBomDetails] = useState<any>([]);

  const [rowData, setRowData] = useState<any>([]);
  const [showNoneTracked, setShowNoneTracked] = useState<any>(false);
  const [showBatchTracked, setShowBatchTracked] = useState<any>(false);
  const [showSerialTracked, setShowSerialTracked] = useState<any>(false);

  const [warehouseProduct, setWarehouseProduct] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [shortInfo, setShortInfo] = useState<any>([]);
  const columnConfig = JWO_RESERVATION_CONFIG;

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (props?.booksDocument?.jwoData) {
      setRowData(props?.booksDocument?.jwoData);
    } else if (props?.data) {
      ProductService.getProductsByProductIds(
        [props.data.product.productId],
        false,
        false
      ).then((res) => {
        let bomDetails = res[0].bomMetaDetailsList
          .find((bom: any) => bom.active && bom.isDefault)
          .bomProductsConfiguration?.filter(
            (bom: any) =>
              bom.produceProductType === NONE &&
              bom.productType !== PRODUCT_TYPE.NON_TRACKED
          );

        getShortInfo(bomDetails);
      });
    }
  }, [props]);

  const getShortInfo = (bomDetails: any) => {
    let payload: any = [];
    bomDetails.forEach((bom: any) => {
      payload.push({
        documentUOMSchemaDefinition: bom.documentUOMSchemaDefinition,
        productCode: bom.productCode,
        quantity: bom.quantity * props.data.productQuantity,
        uomQuantity: bom.quantity * props.data.productQuantity
      });
    });

    MRPProductsService.getProductShortInfo(payload).then((res: any) => {
      setShortInfo(res);
      let rowData: any = getRowData(bomDetails, res);
      setRowData(rowData);
      setBomDetails(bomDetails);
    });
  };

  const getRowButtons = (bom: any) => {
    const btns = [];
    const totalQuantityOfComponentProduct =
      bom?.singleBOMQty * props.data.productQuantity;
    const alreadyReservedQuantity =
      props.data.reservedQuantitiesData?.find(
        (reserveItem: any) => reserveItem?.productCode === bom?.productCode
      )?.reservedQuantity ?? 0;
    const requiredQuantity =
      totalQuantityOfComponentProduct - alreadyReservedQuantity;
    if (requiredQuantity !== 0) {
      btns.push({
        icon:
          bom.reservedQuantity === bom.bomQty
            ? ic_barcode_green
            : ic_barcode_red,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: ({ rowIndex, rowData }: any) => {
          let newRowData: any = {
            ...rowData,
            productId: rowData.productCode,
            name: rowData.itemName,
            qty: bom?.qty ?? 0,
            rowIndex: rowIndex
          };

          if (newRowData.advancedTracking === TRACKING_TYPE.BATCH) {
            dispatch(
              fetchBatchTrackingProducts({
                productCode: rowData.productCode,
                enableQCWarehouse: false
              })
            );
            setShowBatchTracked(true);
          } else if (newRowData.advancedTracking === TRACKING_TYPE.SERIAL) {
            dispatch(
              fetchSerialTrackingProducts({
                productCode: rowData.productCode,
                enableQCWarehouse: false
              })
            );
            setShowSerialTracked(true);
          } else {
            setShowNoneTracked(true);
          }

          setSelectedRow(newRowData);
          setWarehouseProduct(
            prepareBomExplosionProductForAllocation(
              newRowData,
              newRowData.advanceTracking
            )
          );
        }
      });
    }

    return btns;
  };

  const getRowData = (bomDetails: any, shortInfo: any) => {
    let rows: any = [];
    if (Utility.isEmpty(props.data)) {
      return [];
    }
    bomDetails.forEach((bom: any, index: any) => {
      const totalQuantityOfComponentProduct =
        bom?.quantity *
        Utility.getUomWarehouseQuantityWithoutRoundOff(
          props.data.productQuantity,
          props.data.documentUOMSchemaDefinition
        );

      let alreadyReservedQuantity = 0;
      let arr = props.data.reservedQuantitiesData?.filter(
        (reserveItem: any) => reserveItem?.productCode === bom?.productCode
      );
      if (arr?.length) {
        arr.forEach((row: any) => {
          alreadyReservedQuantity += row.reservedQuantity;
        });
      }

      const existingWarehouseInventoryData = [
        props.data.reservedQuantitiesData?.find(
          (reserveItem: any) => reserveItem?.productCode === bom?.productCode
        ) ?? []
      ];
      const requiredQuantity =
        totalQuantityOfComponentProduct - alreadyReservedQuantity;
      rows.push({
        ...bom,
        ...shortInfo[index],
        qty: requiredQuantity,
        quantity: totalQuantityOfComponentProduct,
        rqty: alreadyReservedQuantity,
        rowButtons: getRowButtons(bom),
        reservedQuantity: alreadyReservedQuantity,
        alreadyReservedQuantity,
        // requiredQuantityColumn: totalQuantityOfComponentProduct,
        bomQty: totalQuantityOfComponentProduct,
        singleBOMQty: Utility.getUomQuantity(
          bom?.quantity,
          bom.documentUOMSchemaDefinition
        ),
        warehouseCode: null,
        rowCode: null,
        rackCode: null,
        binCode: null
      });
    });
    return rows;
  };

  const getQuantityValidation = (data: any, requiredQty: any, product: any) => {
    let totalQuantity = data?.reduce(
      (prev: number, current: any) =>
        prev +
        Number(current.quantity || current.qtyToFulfil || current.batchSize),
      0
    );
    if (
      product?.documentUOMSchemaDefinition &&
      product.advancedTracking !== ADVANCE_TRACKING.NONE
    ) {
      totalQuantity = Utility.getUomQuantityWithoutRoundOff(
        totalQuantity,
        product?.documentUOMSchemaDefinition
      );
    }
    if (Number(totalQuantity) !== Number(requiredQty)) {
      showAlert(
        'Error!',
        'Quantity Allocated should be equal to Required quantity.'
      );
      return true;
    } else {
      return false;
    }
  };

  const getDataGrid = () => {
    return (
      <>
        <DKDataGrid
          styles={{
            mainGridHolder: { maxHeight: 320, paddingBottom: 0 },
            shadowHolder: { maxHeight: 300 }
          }}
          needShadow={false}
          needBorder={true}
          needColumnIcons={false}
          needTrailingColumn={true}
          allowColumnSort={false}
          allowColumnAdd={false}
          allowColumnEdit={false}
          // allowRowEdit={!Utility.isEmpty(props.selectedRecord) ? false : true}
          columns={[
            ...columnConfig,
            {
              key: 'actions',
              name: 'Actions',
              type: INPUT_TYPE.BUTTON,
              actionButtonOptions: [],
              width: 180
            }
          ]}
          rows={rowData.map((row: any, index: any) => {
            return { ...row, rowButtons: getRowButtons(row) };
          })}
          //  onRowUpdate={rowEdit}
          currentPage={1}
          totalPageCount={1}
          title={''}
          allowColumnShift={false}
          allowBulkOperation={false}
          needActionColumn={true}
        />
      </>
    );
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle gap-2">
          <DKLabel
            text={`Reserve Component Materials for ${props.data.product.name}`}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          {/* {props.allowFullScreen && getShrinkExpandButton()} */}
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <div
            className={`row border-radius-m ${
              //  apiCallInProgress
              //    ? ' bg-gray1 text-gray border-m pr-2' :
              ' bg-button text-white'
            }`}
          >
            <DKButton title={'Save'} onClick={() => props.onSave(rowData)} />
            {/* {apiCallInProgress && <DKSpinner iconClassName="ic-s" />} */}
          </div>
        </div>
      </div>
    );
  };
  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: 1000,
            width: '90%',
            maxHeight: '50%',
            height: '50%',
            padding: 0,
            paddingBottom: 30
          }}
        >
          {getHeader()}
          <div
            className="column parent-width parent-height p-r"
            style={{
              overflowY: 'scroll',
              overflowX: 'hidden',
              scrollbarWidth: 'none'
            }}
          >
            <div
              id="popup-container"
              className="column parent-width parent-height"
              style={{
                pointerEvents: 'auto'
              }}
            >
              <div className="m-v-r parent-width">
                <div className="m-v-r parent-width">{getDataGrid()}</div>
              </div>
            </div>
            {/* batch product  */}
          </div>
        </div>
        {showNoneTracked && (
          <NoneTrackedAssignment
            isEditMode={true}
            showAutoAllocateButton={false}
            parentDocType={DOC_TYPE.JWO_RESERVATION}
            details={{
              ...warehouseProduct,
              pendingQuantity: Utility.getUomWarehouseQuantityWithoutRoundOff(
                selectedRow?.qty,
                warehouseProduct?.documentUOMSchemaDefinition
              ),
              requiredQuantity: selectedRow.qty,
              productQuantity: selectedRow.qty,
              parentQuantityToFulfill: selectedRow.qty
            }}
            mrpTitle="Material"
            module={MODULE_TYPE.SELL}
            taggedWarehouse={
              Utility.isBinAllocationMandatory() ||
              Utility.isWarehouseTaggingEnabled()
                ? props.activeWOtargetWarehouse
                : null
            }
            defaultProductWarehouse={RawMaterialHelper.getDefaultActiveWarehouse()}
            existingWarehouseInventoryData={
              warehouseProduct.warehouseInventoryData ?? []
            }
            onSave={(data: any) => {
              const rowIndex = selectedRow.rowIndex;
              const updatedData = data?.map((itemData: any) => {
                return {
                  ...itemData,
                  quantity: itemData?.quantity
                };
              });
              rowData[rowIndex].warehouseInventoryData = updatedData;
              let quantityFulfilled = 0;
              updatedData?.forEach((row: any) => {
                quantityFulfilled += row.quantity;
              });
              rowData[rowIndex].rqty =
                quantityFulfilled + selectedRow?.alreadyReservedQuantity;
              rowData[rowIndex].reservedQuantity =
                quantityFulfilled + selectedRow?.alreadyReservedQuantity;
              if (data?.length) {
                rowData[rowIndex].warehouseCode = data[0].warehouseCode;
              }

              setRowData([...rowData]);
              setShowNoneTracked(false);
            }}
            onCancel={() => {
              setShowNoneTracked(false);
            }}
          ></NoneTrackedAssignment>
        )}
        {showBatchTracked && (
          <BatchTrackingAssignment
            isMrpFlow={true}
            showAutoAllocateButton={false}
            mrpTitle="Material"
            parentDocType={DOC_TYPE.JWO_RESERVATION}
            itemDetails={{
              ...warehouseProduct,
              pendingQuantity: selectedRow?.qty,
              quantityRequired: Utility.getUomWarehouseQuantity(
                selectedRow?.qty,
                selectedRow.documentUOMSchemaDefinition
              ),
              requiredQuantity: selectedRow.qty,
              productQuantity: selectedRow.qty,
              parentQuantityToFulfill: selectedRow.qty
            }}
            module={MODULE_TYPE.SELL}
            existingWarehouseInventoryData={
              selectedRow.warehouseInventoryData ?? []
            }
            // defaultProductWarehouse={selectedRowForTracking?.warehouseCode}
            taggedWarehouse={
              Utility.isBinAllocationMandatory() ||
              Utility.isWarehouseTaggingEnabled()
                ? props.activeWOtargetWarehouse
                : null
            }
            onBatchSave={(data: any, quantityAllocated: any) => {
              let copyBomProductData = [...rowData];
              let findBomProductConfigIndex = selectedRow.rowIndex;
              if (findBomProductConfigIndex > -1) {
                let selectedBomProductConfig =
                  copyBomProductData[findBomProductConfigIndex];
                selectedBomProductConfig.wareHouseAlloted = true;
                selectedBomProductConfig.warehouseInventoryData = data?.map(
                  (dataItem: any) => {
                    return {
                      ...dataItem,
                      reservedQuantity: dataItem?.qtyToFulfil,
                      rowcode: dataItem?.row ? dataItem?.row?.code : null,
                      rackcode: dataItem?.rack ? dataItem?.rack?.code : null,
                      bincode: dataItem?.bin ? dataItem?.bin?.code : null
                    };
                  }
                );
                if (Utility.isEmpty(data)) {
                  selectedBomProductConfig.isQuickCommit = true;
                }
                copyBomProductData[findBomProductConfigIndex] =
                  selectedBomProductConfig;
                copyBomProductData[findBomProductConfigIndex].rqty =
                  quantityAllocated + selectedRow?.alreadyReservedQuantity;
                copyBomProductData[findBomProductConfigIndex].reservedQuantity =
                  quantityAllocated + selectedRow?.alreadyReservedQuantity;

                if (data?.length) {
                  copyBomProductData[findBomProductConfigIndex].warehouseCode =
                    data[0].warehouseCode;
                }
                setRowData(copyBomProductData);
                setShowBatchTracked(false);
              }
            }}
            onClose={() => {
              setShowBatchTracked(false);
            }}
          ></BatchTrackingAssignment>
        )}

        {showSerialTracked && (
          <SerialTrackedAssignment
            isMRP={true}
            showAutoAllocateButton={false}
            mrpTitle="Material"
            parentDocType={DOC_TYPE.JWO_RESERVATION}
            itemDetails={{
              ...warehouseProduct,
              pendingQuantity: Utility.getUomWarehouseQuantityWithoutRoundOff(
                selectedRow?.qty,
                warehouseProduct?.documentUOMSchemaDefinition
              ),
              requiredQuantity: selectedRow.qty,
              productQuantity: selectedRow.qty,
              parentQuantityToFulfill: selectedRow.qty
            }}
            module={MODULE_TYPE.SELL}
            taggedWarehouse={
              Utility.isBinAllocationMandatory() ||
              Utility.isWarehouseTaggingEnabled()
                ? props.activeWOtargetWarehouse
                : null
            }
            existingWarehouseInventoryData={
              selectedRow.warehouseInventoryData ?? []
            }
            defaultProductWarehouse={selectedRow?.warehouseCode}
            onSerialSave={(data: any, quantityAllocated: any) => {
              let copyBomProductData = [...rowData];
              let findBomProductConfigIndex = selectedRow.rowIndex;
              if (findBomProductConfigIndex > -1) {
                let selectedBomProductConfig =
                  copyBomProductData[findBomProductConfigIndex];
                selectedBomProductConfig.wareHouseAlloted = true;
                selectedBomProductConfig.warehouseInventoryData = data?.map(
                  (dataItem: any) => {
                    return {
                      ...dataItem,
                      reservedQuantity: dataItem?.qtyToFulfil,
                      rowcode: dataItem?.row ? dataItem?.row?.code : null,
                      rackcode: dataItem?.rack ? dataItem?.rack?.code : null,
                      bincode: dataItem?.bin ? dataItem?.bin?.code : null
                    };
                  }
                );
                if (Utility.isEmpty(data)) {
                  selectedBomProductConfig.isQuickCommit = true;
                }
                copyBomProductData[findBomProductConfigIndex] =
                  selectedBomProductConfig;
                copyBomProductData[findBomProductConfigIndex].rqty =
                  data.length ?? 0 + selectedRow?.alreadyReservedQuantity ?? 0;
                copyBomProductData[findBomProductConfigIndex].reservedQuantity =
                  data.length ?? 0 + selectedRow?.alreadyReservedQuantity ?? 0;
                copyBomProductData[findBomProductConfigIndex].rqty =
                  Utility.getUomQuantity(
                    copyBomProductData[findBomProductConfigIndex].rqty,
                    warehouseProduct?.documentUOMSchemaDefinition
                  );
                copyBomProductData[findBomProductConfigIndex].reservedQuantity =
                  Utility.getUomQuantity(
                    copyBomProductData[findBomProductConfigIndex]
                      .reservedQuantity,
                    warehouseProduct?.documentUOMSchemaDefinition
                  );
                if (data?.length) {
                  copyBomProductData[findBomProductConfigIndex].warehouseCode =
                    data[0].warehouseCode;
                }
                setRowData(copyBomProductData);
                setShowSerialTracked(false);
              }
            }}
            onClose={() => {
              setShowSerialTracked(false);
            }}
          />
        )}
      </div>
    </DynamicPopupWrapper>
  );
};

export default ReserveJWO;
