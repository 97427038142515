import React, { useState } from 'react';
import {
  DKLabel,
  DKButton,
  DKInput,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKDataGrid,
  showAlert,
  showLoader,
  removeLoader,
  DKSpinner
} from 'deskera-ui-library';
import {
  addHolidayList,
  getEmptyHolidayObj,
  IHoliday,
  IHolidayListPayload,
  updateHolidayList
} from '../../../Services/MRP/HolidayList';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../../Utility/Utility';
import {
  getDaysBetweenDates,
  WEEKDAYS,
  WEEKDAYS_NAMES
} from '../Constants/HolidayList';
import { getHolidayListSubGridColumns } from '../Constants/MRPColumnConfigs';
import { addYears, isAfter, subDays } from 'date-fns/esm';
import DateFormatService from '../../../Services/DateFormat';
import { BOOKS_DATE_FORMAT, MODULES_NAME } from '../../../Constants/Constant';
import { addDays, isBefore } from 'date-fns';
import { isEmpty } from 'lodash';
import { INPUT_TITLE_STYLE } from '../Constants/UIHelper';
import { useAppSelector } from '../../../Redux/Hooks';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
export interface IAddHolidayListProps {
  holidayList?: IHolidayListPayload;
  onSave: (data: any) => void;
  onCancel: () => void;
}

const ADD_HOLIDAY_LIST_FORM_FIELDS = [
  {
    name: 'name',
    label: 'Holiday List Name',
    type: INPUT_TYPE.TEXT,
    required: true
  },
  {
    name: 'fromDate',
    label: 'From Date',
    type: INPUT_TYPE.DATE,
    required: true
  },
  {
    name: 'toDate',
    label: 'To Date',
    type: INPUT_TYPE.DATE,
    required: true
  }
];
const initialFormData: any = {
  name: '',
  fromDate: '',
  toDate: ''
};
export const AddHolidayList: React.FC<IAddHolidayListProps> = (props) => {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [holidayList, setHolidayList] = React.useState<IHoliday[]>([]);
  const [formData, setFormData] = React.useState(initialFormData);
  const [selectedWeekDays, setSelectedWeekDays] = React.useState([]);
  const [updating, setUpdating] = useState(false);
  const [canValidate, setCanValidate] = useState(false);
  let onSaveClicked = React.useRef(true);
  React.useEffect(() => {
    checkForUpdateCase();
  }, []);
  const checkForUpdateCase = () => {
    if (props.holidayList && !Utility.isEmpty(props.holidayList)) {
      setFormData({
        name: props.holidayList.name,
        fromDate: DateFormatService.getDateFromStr(
          props.holidayList.fromDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        ),
        toDate: DateFormatService.getDateFromStr(
          props.holidayList.toDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      });
      setHolidayList(
        props.holidayList.holidayListDetails.map((holiday: IHoliday) => ({
          ...holiday,
          holidayDate: DateFormatService.getDateFromStr(
            holiday.holidayDate as string,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        }))
      );
    }
  };
  const onAddRow = () => {
    let copyOfHolidayList = [...holidayList];
    copyOfHolidayList.push({
      ...getEmptyHolidayObj({ srNo: copyOfHolidayList.length + 1 })
    });
    setHolidayList(copyOfHolidayList);
  };
  const onHolidayListUpdate = (index: number, data: any) => {
    let copyOfHolidayList = [...holidayList];
    if (typeof data.holidayDate === 'string') {
      data.holidayDate = new Date(data.holidayDate);
    }
    if (Utility.isEmpty(data.holidayDate)) {
      data.invalidFields = [];
    }
    if (
      isAfter(data.holidayDate, formData.toDate) ||
      isBefore(data.holidayDate, formData.fromDate)
    ) {
      data.holidayDate = null;
      data.invalidFields = ['holidayDate'];
    }
    copyOfHolidayList[index] = { ...copyOfHolidayList[index], ...data };
    setHolidayList(copyOfHolidayList);
  };
  const onFormDataChange = (field: any, data: any) => {
    let copyOfFormData = { ...formData };
    copyOfFormData[field] = data;
    if (field === 'fromDate') {
      copyOfFormData['toDate'] = addDays(data, 1);
    }
    setFormData(copyOfFormData);
  };
  const onAddWeeklyHoliday = () => {
    let copyOfHolidayList = [...holidayList];
    if (selectedWeekDays?.length && formData.fromDate && formData.toDate) {
      selectedWeekDays?.map((selectedWeekDay) => {
        const allDaysBtwFromAndTo: Date[] = getDaysBetweenDates(
          formData.fromDate,
          formData.toDate,
          selectedWeekDay
        );
        const existingDaysAsString = copyOfHolidayList.map(
          (holiday: IHoliday) => holiday?.holidayDate?.toString()
        );

        allDaysBtwFromAndTo?.forEach((date: Date) => {
          if (existingDaysAsString?.indexOf(date.toString()) === -1) {
            copyOfHolidayList.push({
              srNo: copyOfHolidayList.length + 1,
              holidayDate: date,
              description: WEEKDAYS_NAMES[selectedWeekDay]
            });
          }
        });
      });
      setHolidayList(copyOfHolidayList);
    }
  };
  const onSave = () => {
    onSaveClicked.current = true;
    setCanValidate(true);
    if (Utility.isEmpty(formData.name)) {
      return;
    }
    const isFormValid =
      Object.keys(formData).every((key: any) => !!formData[key]) &&
      holidayList.every((holiday: IHoliday) => !!holiday.holidayDate);
    if (isFormValid) {
      showLoader(`${props.holidayList ? 'Updating' : 'Adding'} holiday list`);
      onSaveClicked.current = false;
      const payload: IHolidayListPayload = {
        name: formData.name,
        fromDate: DateFormatService.getDateStrFromDate(
          formData.fromDate as Date,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        toDate: DateFormatService.getDateStrFromDate(
          formData.toDate as Date,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        holidayListDetails: holidayList.map((holiday: IHoliday) => {
          delete holiday.invalidFields;
          return {
            ...holiday,
            holidayDate: DateFormatService.getDateStrFromDate(
              holiday.holidayDate as Date,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
          };
        })
      };
      setUpdating(true);
      if (Utility.isEmpty(props.holidayList)) {
        addHolidayList(payload)
          .then((response: any) => {
            setUpdating(false);
            if (response?.id) {
              props.onSave(response);
            }
          })
          .finally(() => {
            setUpdating(false);
            removeLoader();
          });
      } else {
        payload.id = props.holidayList?.id;
        updateHolidayList(payload)
          .then((response: any) => {
            setUpdating(false);
            if (response?.id) {
              props.onSave(response);
            }
          })
          .finally(() => {
            setUpdating(false);
            removeLoader();
          });
      }
    } else {
      showAlert('Error', 'Please fill all the mandatory fields');
    }
  };
  const onDelete = ({ rowIndex, rowData }: any) => {
    let copyOfHolidayList = [...holidayList];
    copyOfHolidayList = copyOfHolidayList.filter(
      (holiday: IHoliday) => holiday.srNo !== rowData.srNo
    );

    const isValid = copyOfHolidayList.every((v: any) => (
      Utility.checkActiveDateRangeValidation(
        (typeof v.holidayDate === 'string') ?
          (DateFormatService.getDateFromStr(
            v.holidayDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )) : v.holidayDate,
        tenantInfo,
        'Holiday date',
        MODULES_NAME.MRP_HOLIDAY_LIST
      )
    ))
    if (!isValid) {
      return;
    }

    setHolidayList(copyOfHolidayList);
  };
  const validateFormFields = (field: string) => {
    const { fromDate, toDate } = formData;
    let isValid = true;
    switch (field) {
      case 'fromDate':
        if (toDate) {
          if (isAfter(fromDate, toDate)) {
            isValid = false;
          }
        }
        break;
      case 'toDate':
        if (fromDate) {
          if (isBefore(toDate, fromDate)) {
            isValid = false;
          }
        }
        break;
    }
    return isValid;
  };
  const getErrorMessage = (field: string) => {
    const { fromDate, toDate, name } = formData;
    switch (field) {
      case 'name':
        if (isEmpty(name)) return `${field} is required`;
        break;
      case 'fromDate':
        if (isAfter(fromDate, toDate))
          return `From Date should be less that To Date`;
        break;
      case 'toDate':
        if (isAfter(fromDate, toDate))
          return `To Date should be greater that From Date`;
        break;
      default:
        return `Enter valid ${field}`;
    }
  };
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel
            text={`${
              !Utility.isEmpty(props.holidayList) ? `Update` : `Add`
            } Holiday List`}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <div
            className={`row border-radius-m ${
              updating ? ' bg-gray1 border-m pr-2' : ' bg-button text-white'
            }`}
          >
            <DKButton title={'Save'} onClick={onSave} />
            {updating && <DKSpinner iconClassName="ic-s" />}
          </div>
        </div>
      </div>
    );
  };
  const getForm = () => {
    return (
      <>
        <div className="row mb-l">
          <DKInput
            className="parent-width"
            title={ADD_HOLIDAY_LIST_FORM_FIELDS[0].label}
            placeholder={`Ex. Thanks Giving Week`}
            titleStyle={INPUT_TITLE_STYLE}
            value={formData[ADD_HOLIDAY_LIST_FORM_FIELDS[0].name]}
            type={ADD_HOLIDAY_LIST_FORM_FIELDS[0].type}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={ADD_HOLIDAY_LIST_FORM_FIELDS[0].required}
            canValidate={canValidate}
            // errorMessage={getErrorMessage(ADD_HOLIDAY_LIST_FORM_FIELDS[0].name)}
            // validator={() =>
            //   validateFormFields(ADD_HOLIDAY_LIST_FORM_FIELDS[0].name)
            // }
            onChange={(value: any) =>
              onFormDataChange(ADD_HOLIDAY_LIST_FORM_FIELDS[0].name, value)
            }
          />
        </div>
        <div className="row gap-2">
          <DKInput
            className="parent-width"
            title={ADD_HOLIDAY_LIST_FORM_FIELDS[1].label}
            titleStyle={INPUT_TITLE_STYLE}
            value={formData[ADD_HOLIDAY_LIST_FORM_FIELDS[1].name]}
            type={ADD_HOLIDAY_LIST_FORM_FIELDS[1].type}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={ADD_HOLIDAY_LIST_FORM_FIELDS[1].required}
            canValidate={onSaveClicked.current}
            dateFormat={convertBooksDateFormatToUILibraryFormat(
              tenantInfo.dateFormat
            )}
            errorMessage={getErrorMessage(ADD_HOLIDAY_LIST_FORM_FIELDS[1].name)}
            validator={() =>
              validateFormFields(ADD_HOLIDAY_LIST_FORM_FIELDS[1].name)
            }
            onChange={(value: any) =>
              onFormDataChange(ADD_HOLIDAY_LIST_FORM_FIELDS[1].name, value)
            }
          />
          <DKInput
            className="parent-width"
            title={ADD_HOLIDAY_LIST_FORM_FIELDS[2].label}
            titleStyle={INPUT_TITLE_STYLE}
            value={formData[ADD_HOLIDAY_LIST_FORM_FIELDS[2].name]}
            type={ADD_HOLIDAY_LIST_FORM_FIELDS[2].type}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={ADD_HOLIDAY_LIST_FORM_FIELDS[2].required}
            canValidate={onSaveClicked.current}
            dateFormat={convertBooksDateFormatToUILibraryFormat(
              tenantInfo.dateFormat
            )}
            errorMessage={getErrorMessage(ADD_HOLIDAY_LIST_FORM_FIELDS[2].name)}
            validator={() =>
              validateFormFields(ADD_HOLIDAY_LIST_FORM_FIELDS[2].name)
            }
            onChange={(value: any) =>
              onFormDataChange(ADD_HOLIDAY_LIST_FORM_FIELDS[2].name, value)
            }
          />
        </div>
      </>
    );
  };
  const getWeeklyHolidaySection = () => {
    return (
      <>
        <div className="row mt-l">
          <DKLabel
            text={`Add Weekly Holiday`}
            className="fw-m fs-r mt-s mb-s"
          />
        </div>
        <div className="row align-items-end">
          <DKInput
            className="parent-width mt-s"
            // title={'Weekly Off'}
            titleStyle={INPUT_TITLE_STYLE}
            required={false}
            value={selectedWeekDays}
            type={INPUT_TYPE.DROPDOWN}
            onChange={(value: any) => {
              setSelectedWeekDays(value);
            }}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            formatter={(obj: any) => obj.name}
            options={WEEKDAYS_NAMES}
            dropdownConfig={{
              multiSelect: true,
              allowSearch: false,
              data: WEEKDAYS_NAMES,
              onClear: () => {},
              onSelect: () => {},
              renderer: '',
              title: 'Select option'
            }}
          />
          <div className="parent-width">
            <DKButton
              title={'+ Add Weekly Holiday'}
              className="parent-width text-blue ml-r fw-m row"
              disabled={
                Utility.isEmpty(selectedWeekDays) ||
                !formData.fromDate ||
                !formData.toDate
              }
              onClick={onAddWeeklyHoliday}
            />
          </div>
        </div>
      </>
    );
  };
  const getHolidayList = () => {
    const isRowSelected = holidayList?.some((holiday) => holiday.selected);
    const allRowSelected =
      holidayList.length && holidayList?.every((holiday) => holiday.selected);
    return (
      <div className="mt-l holiday-grid-data">
        <DKDataGrid
          title={'Holidays'}
          columns={getHolidayListSubGridColumns(onDelete)}
          rows={holidayList}
          allowRowAdd={false}
          allowRowUpdate={true}
          allowColumnAdd={false}
          allowColumnEdit={false}
          allowColumnDelete={false}
          allowColumnShift={false}
          allowBulkOperation={true}
          needTrailingColumn={false}
          isAllRowSelected={allRowSelected}
          onRowUpdate={(data: any) => {
            onHolidayListUpdate(data.rowIndex, data.rowData);
          }}
          onAllRowSelect={({ selected }: any) => {
            setHolidayList(
              deepClone(holidayList).map((row) => ({ ...row, selected }))
            );
          }}
          buttons={
            isRowSelected && [
              {
                title: 'Delete Selected',
                className: 'fw-m text-red p-0',
                onClick: () => {
                  let copyOfHolidayList = [...holidayList];
                  setHolidayList(
                    copyOfHolidayList
                      .filter((holiday) => !holiday.selected)
                      .map((holiday, index) => ({
                        ...holiday,
                        srNo: index + 1
                      }))
                  );
                }
              }
            ]
          }
          onRowSelect={({ rowIndex, rowData }: any) => {
            let copyOfHolidayList = [...holidayList];
            copyOfHolidayList[rowIndex] = rowData;
            setHolidayList(copyOfHolidayList);
          }}
        />
        <div className="column">
          <DKButton
            title={'+ Add holiday'}
            className="text-blue fw-b"
            onClick={onAddRow}
            disabled={!formData.fromDate || !formData.toDate}
            style={{
              paddingTop: 0,
              paddingLeft: 0
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <div style={{ zIndex: 9998 }} className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 650,
          width: '90%',
          maxHeight: '95%',
          padding: 0,
          overflow: 'visible'
        }}
      >
        {getHeader()}
        <div className="parent-width p-l hide-scroll-bar gap-4">
          {getForm()}
          {getWeeklyHolidaySection()}
          {getHolidayList()}
        </div>
      </div>
    </div>
  );
};
